/* eslint-disable no-console */
import React, { Component, Fragment } from 'react';
import {
  Container,
  Button,
  Row,
  Col,
  InputGroup,
  FormControl
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import Table from '../../../ui/Table';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import fetchDux from '../../../../state/fetch-dux';

import DeleteUserModal from './DeleteUserModal';
import ResetUserPasswordModal from './ResetUserPasswordModal';
import SendUserWelcomeModal from './SendUserWelcomeModal';
import moment from 'moment';
import Loading from '../../../ui/Loading';
import getQueryFromSearch from '../../../paginatiion/getQueryFromSearch';

const columns = [
  // {
  //   Header: 'Id',
  //   accessor: '_id'
  // },
  // {
  //   Header: 'Type',
  //   accessor: 'method'
  // },
  {
    Header: 'Message',
    accessor: 'message'
  },
  {
    Header: 'File Name',
    accessor: 'filename'
  },
  // {
  //   Header: 'IP Address',
  //   accessor: 'ipAddress'
  // },
  {
    Header: 'Date',
    accessor: 'transactionTime',
    Cell: ({ value }) => {
      if (!value || !moment(value).isValid()) return '';
      return moment(value).format('MM/DD/YYYY hh:mm a');
    }
  }
];

const getManufacturer = user => {
  if (!user || !user.manufacturer) return {};
  return user.manufacturer[0];
};

const getDistributor = user => {
  if (!user || !user.distributor) return {};
  return user.distributor[0];
};

const getTerms = user => {
  if (!user || !user.terms) return {};
  return user.terms;
};

const showTerms = version => {
  window.open(`/terms-of-service/${version}`, '_blank');
};

class UsersDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copyBtn: 'Copy'
    };
  }
  componentDidMount() {
    const {
      match,
      getUserById,
      getUserLogs
    } = this.props;
    const { id } = match.params || {};
    getUserById(id);
    getUserLogs(id);
  }

  handleFetchData(state) {
    const {
      location: { search }
    } = this.props;
    const query = getQueryFromSearch(search);
    const {
      page,
      pageSize,
      sorted
    } = state || {};

    const {
      id,
      desc
    } = (sorted || [])[0] || {};

    this.handleSearchOrders({
      page,
      pageSize,
      sortBy: id,
      desc,
      search: query.search
    });
  }

  render() {
    const {
      result,
      match,

      showModal,
      logsResult
    } = this.props;
    const { id } = match.params || {};
    const {
      result: user
    } = result || {};
    const {
      email,
      imisId,
      nameFirst,
      nameLast,
      accountType,
      emailNotifications,
      showApiKey,
      viewOnly,
      passwordEmail
    } = user || {};

    const manufacturer = getManufacturer(user);
    const distributor = getDistributor(user);
    const terms = getTerms(user);
    const {
      result: logs,
      inProgress: logsInProgress
    } = logsResult || {};

    return (
      <Layout route="users-details">
        <main>

          <div className="layout-header">
            <Container>

              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <Icon name="home" />
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/users">
                    Users
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  User Details
                </li>
              </ol>

              <div className="header">
                <h1>
                  <Icon name="users" /> {nameFirst} {nameLast}
                </h1>
                <div>
                  <Link to={`/users/${id}/edit`} className="btn btn-secondary">
                    Edit
                  </Link>
                  {/* <Button
                    className="btn btn-secondary"
                    onClick={() => showModal('DisableUserModal')}
                  >
                    Disable
                  </Button> */}
                  <Button
                    className="btn btn-danger"
                    onClick={() => showModal('DeleteUserModal')}
                  >
                    Delete
                  </Button>
                </div>
              </div>

            </Container>
          </div>

          <Container>
            <div>

              <Row>
                <Col md={6}>
                  <div className="box">
                    <div className="box-header">
                      <h3>User Details</h3>
                    </div>
                    <div className="box-body">

                      <table className="table table-data">
                        <tbody>
                          {<tr>
                            <th>IMIS ID</th>
                            <td>{imisId}</td>
                          </tr>}
                          <tr>
                            <th>Name</th>
                            <td>{nameFirst} {nameLast}</td>
                          </tr>
                          <tr>
                            <th>Email</th>
                            <td>{email}</td>
                          </tr>
                          <tr><td>&nbsp;</td><td>&nbsp;</td></tr>
                        </tbody>
                      </table>
                      <table className="table table-data table-borderless">
                        <tbody>
                          <tr>
                            <th>Email Notifications:</th>
                            <td>
                              {emailNotifications
                                ? <Icon name="check" className="text-success" />
                                : <Icon name="times" className="text-danger" />}
                            </td>
                            <th>Show Api Key:</th>
                            <td>
                              {showApiKey
                                ? <Icon name="check" className="text-success" />
                                : <Icon name="times" className="text-danger" />}
                            </td>
                          </tr>
                          <tr>
                            <th>View Only:</th>
                            <td>
                              {viewOnly
                                ? <Icon name="check" className="text-success" />
                                : <Icon name="times" className="text-danger" />}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="box-actions">
                        <Button
                          className="btn btn-sm btn-primary"
                          onClick={() => {
                            showModal('SendUserWelcomeModal');
                            this.setState({ copyBtn: 'Copy' });
                          }}
                        >
                          Send Welcome Email
                        </Button>
                        <Button
                          className="btn btn-sm btn-primary"
                          onClick={() => {
                            showModal('ResetUserPasswordModal');
                            this.setState({ copyBtn: 'Copy' });
                          }}
                        >
                          Reset Password
                        </Button>
                      </div>
                    </div>
                  </div>

                </Col>
                <Col md={6}>
                  {accountType === 'manufacturer' &&
                    <div className="box">
                      <div className="box-header">
                        <h3>Manufacturer Company Details</h3>
                      </div>
                      <div className="box-body">

                        <table className="table table-data">
                          <tbody>
                            <tr>
                              <th>IMIS ID</th>
                              <td>{manufacturer.imisId}</td>
                            </tr>
                            <tr>
                              <th>Manufacturer Name</th>
                              <td>{manufacturer.name}</td>
                            </tr>
                          </tbody>
                        </table>

                        <div className="box-actions">
                          <Link
                            to={`/manufacturers/${manufacturer._id}`}
                            className="btn btn-sm btn-primary"
                          >
                            View Details
                          </Link>
                        </div>

                      </div>

                    </div>}
                  {accountType === 'distributor' &&
                    <Fragment>
                      <div className="box">
                        <div className="box-header">
                          <h3>Distributor Company Details</h3>
                        </div>
                        <div className="box-body">

                          <table className="table table-data">
                            <tbody>
                              <tr>
                                <th>IMIS ID</th>
                                <td>{distributor ? distributor.imisId : ''}</td>
                              </tr>
                              <tr>
                                <th>Distributor Name</th>
                                <td>{distributor ? distributor.name : ''}</td>
                              </tr>
                            </tbody>
                          </table>

                          <div className="box-actions">
                            <Link
                              to={
                                `/distributors/${distributor ? distributor._id : ''}`
                              }
                              className="btn btn-sm btn-primary"
                            >
                              View Details
                            </Link>
                          </div>

                        </div>
                      </div>
                      <div className="box">

                        <div className="box-header">
                          <h3>Terms of Service Details</h3>
                        </div>
                        <div className="box-body">

                          <table className="table table-data">
                            <tbody>
                              <tr>
                                <th>Accepted on: </th>
                                <td>
                                  {terms.accepted === true
                                    ? moment(terms.date).format(
                                      'MM/DD/YYYY hh:mm a'
                                    )
                                    : ''}
                                </td>
                              </tr>
                              <tr>
                                <th>Agreement Version: </th>
                                <td>
                                  <a
                                    onClick={() =>
                                      showTerms(terms.termsVersion)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {terms.accepted === true
                                      ? <span>
                                        {terms.termsVersion}
                                        <Icon
                                          name="external-link-alt"
                                          size="xs"
                                          style={{
                                            marginLeft: '5px',
                                            verticalAlign: 'top'
                                          }}
                                        />
                                      </span>
                                      : ''}
                                  </a>
                                </td>
                              </tr>
                              {/* <tr> */}
                              {/* <th>IP Address: </th>
                                <td>
                                  {terms.accepted === true
                                    ? terms.ipAddress
                                    : ''}
                                </td> */}
                              {/* </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </div>

                    </Fragment>}
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="py-2">
                    {!passwordEmail &&
                      <p
                        className="p-3 mb-2 border bg-white rounded text-muted text-center"
                      >
                        <span className="font-weight-bold">
                          No Generated Password links
                        </span>
                      </p>}
                    {passwordEmail &&
                      <InputGroup className="mb-3">
                        <FormControl
                          aria-describedby="basic-addon2"
                          defaultValue={passwordEmail}
                          disabled
                        />
                        <Button
                          variant="outline-secondary"
                          id="button-addon2"
                          onClick={() => {
                            navigator.clipboard.writeText(passwordEmail);
                            this.setState({ copyBtn: 'Copied' });
                          }}
                        >
                          {this.state.copyBtn}
                        </Button>
                      </InputGroup>}
                  </div>
                </Col>
              </Row>
              <h3 className="layout-small-header">Activity Logs</h3>

              <Table
                showSearch
                data={logs || []}
                columns={columns}
                noDataText={
                  logsInProgress
                    ? <Loading message="Loading logs..." />
                    : 'No logs found.'
                }
              />

            </div>
          </Container>

          <DeleteUserModal
            name="DeleteUserModal"
            user={user || {}}
            match={match}
          />
          {/* <DisableUserModal name="DisableUserModal" /> */}
          <ResetUserPasswordModal
            name="ResetUserPasswordModal"
            user={user || {}}
            match={match}
          />
          <SendUserWelcomeModal
            name="SendUserWelcomeModal"
            user={user || {}}
            match={match}
          />

        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  const result = state.getUserById.toJS();
  const logsResult = state.getUserLogs.toJS();
  return {
    result,
    logsResult
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getUserById: fetchDux.getUserById.createAction,
      getUserLogs: fetchDux.getUserLogs.createAction
    },
    dispatch
  );
export default connect(mapState, mapDispatch)(UsersDetails);
