import React, { useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../ui/Layout';
import Icon from '../../ui/Icon';
import fetchDux from '../../../state/fetch-dux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

export const Homepage = props => {
  useEffect(
    () => {
      const { user, getDistributorDetails, getManufacturerById } = props;
      const { accountType, accountTypeJoinId } = user;
      if (accountType === 'manufacturer')
        getManufacturerById(accountTypeJoinId);
      else if (accountType === 'distributor') getDistributorDetails();
    },
    [props]
  );

  return (
    <Layout route="homepage">
      <main>
        <div className="layout-header">
          <Container>

            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <Icon name="home" />
                </Link>
              </li>
              <li className="breadcrumb-item active">
                Dashboard
              </li>
            </ol>

            <div className="header">
              <h1>
                <Icon name="clipboard" /> Dashboard
              </h1>
            </div>

          </Container>
        </div>

        <Container>

          <Row>
            <Col>

              <div className="quick-search">
                <div className="actions">
                  <Link className="action" to="/users">
                    <Icon name="users" />
                    <strong>Search<br />Users</strong>
                  </Link>
                  <Link className="action" to="/users/create">
                    <Icon name="user-plus" />
                    <strong>Create<br />User</strong>
                  </Link>
                </div>
              </div>

            </Col>
            <Col>

              <div className="quick-search">
                <div className="actions">
                  <Link className="action" to="/manufacturers">
                    <Icon name="industry" />
                    <strong>Search<br />Manufacturers</strong>
                  </Link>
                  <Link className="action" to="/manufacturers/create">
                    <span className="fa-stack">
                      <Icon fixedWidth name="industry" />
                      <Icon name="plus" />
                    </span>
                    <strong>Create<br />Manufacturer</strong>
                  </Link>
                </div>
              </div>

            </Col>
            <Col>

              <div className="quick-search">
                <div className="actions">
                  <Link className="action" to="/distributors">
                    <Icon name="warehouse" />
                    <strong>Search<br />Distributors</strong>
                  </Link>
                  <Link className="action" to="/distributors/create">
                    <span className="fa-stack">
                      <Icon fixedWidth name="warehouse" />
                      <Icon name="plus" />
                    </span>
                    <strong>Create<br />Distributor</strong>
                  </Link>
                </div>
              </div>

            </Col>

            <Col>
              <div className="quick-search">
                <div className="actions">
                  <Link className="action" to="/products">
                    <Icon name="boxes" />
                    <strong>Search<br />Products</strong>
                  </Link>
                  <Link className="action" to="/logs">
                    <Icon name="users" />
                    <strong>Search<br />Logs</strong>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <div className="quick-search">
                <div className="actions">
                  <Link className="action" to="/references">
                    <Icon name="tasks" />
                    <strong>Manage<br />References</strong>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

      </main>
    </Layout>
  );
};

const mapState = state => {
  const {
    result: user,
    error
  } = state.login.toJS();

  const {
    result: manufacturer
  } = state.getManufacturerById.toJS();

  const {
    result: distributor
  } = state.getDistributorDetails.toJS();

  return {
    user,
    manufacturer,
    distributor,
    error
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getManufacturerById: fetchDux.getManufacturerById.createAction,
      getDistributorDetails: fetchDux.getDistributorDetails.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(Homepage);
