import React from 'react';
import { Col, Button } from 'react-bootstrap';
import Table from '../../../../ui/Table';
import moment from 'moment';
import { downloadTermsReport } from '../../../../../api/api';

const getColumns = reportType => {
  switch (reportType) {
    case 'Terms Agreement':
      return [
        {
          Header: 'Name',
          accessor: 'name',
          sortable: true,
          resizable: true,
          show: true,
          className: 'text-center'
        },
        {
          Header: 'Email',
          accessor: 'email',
          sortable: true,
          resizable: true,
          show: true,
          className: 'text-center'
        },
        {
          Header: 'Last Login (UTC)',
          accessor: 'lastLogin',
          sortable: true,
          resizable: true,
          show: true,
          Cell: ({ value }) => {
            return (
              <span>
                {moment.utc(value).isValid()
                  ? moment.utc(value).format('MM/DD/YYYY, hh:mm A')
                  : '-'}
              </span>
            );
          },
          className: 'text-center'
        },
        {
          Header: 'Accepted Date (UTC)',
          accessor: 'terms.date',
          sortable: true,
          resizable: true,
          show: true,
          Cell: ({ value }) => {
            return (
              <span>
                {moment.utc(value).isValid()
                  ? moment.utc(value).format('MM/DD/YYYY, hh:mm A')
                  : '-'}
              </span>
            );
          },
          className: 'text-center'
        },
        {
          Header: 'Accepted',
          accessor: 'terms.accepted',
          sortable: true,
          resizable: true,
          show: true,
          className: 'text-center',
          Cell: ({ value }) =>
            value === true || value === false
              ? value.toString().toUpperCase()
              : ''
        }
      ];

    default:
  }
};

const TermsAgreement = (
  {
    result,
    selectedReportType,
    defaultPageSize,
    inProgress,
    handleFetchData,
    activeColumns
  }
) => {
  const { results = [], count, numPages } = result || {};
  const columns = getColumns(selectedReportType);
  if (activeColumns.length !== 0) {
    columns.forEach(col => {
      col.show = activeColumns.includes(col.accessor);
    });
  }
  return (
    <Col sm={12} style={{ marginTop: '1rem' }}>
      <div className="table-terms-agreement">
        <Table
          manual
          data={results}
          columns={columns}
          key={selectedReportType}
          defaultPageSize={defaultPageSize}
          showPagination={true}
          noDataText={inProgress ? 'Loading' : 'No data found'}
          pages={numPages || 0}
          defaultSorted={[
            {
              id: 'terms.date',
              desc: true
            }
          ]}
          onFetchData={handleFetchData}
          onColumnChange={activeColumns => {
            window.localStorage.setItem('termsAgeementColumns', activeColumns);
          }}
          showColumnsSelector={true}
        />
      </div>

    </Col>
  );
};

export default TermsAgreement;
