const filtersDict = {
  ProdCode: 'Product Code',
  assetTypes: 'Asset Type',
  CatEnum: 'Category Name',
  lastUpdatedDate: 'Last Updated Timestamp',
  manufacturerId: 'Manufacturer Id',
  priceTypes: 'Price Type',
  priceAmount: 'Price Amount',
  ProdShortDesc: 'Product Short Description',
  ProdLongDesc: 'Product Long Description',
  Stocked: 'Stocked',
  priceAmountMathSymbols:'Price Comparison',
  PriceLastUpdatedDatetime:'Price Last Updated Datetime',
  priceLookback:'Price Lookback Period (days)',
};

export default filtersDict;
