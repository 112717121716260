import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import fetchDux from '../../../../state/fetch-dux';
import CreateDistributorModalForm from './CreateDistributorModalForm';
class CreateDistributorModal extends Component {
  componentDidMount() {
    this.props.clearCreateDistributor();
  }
  closeDistributorModal = () => {
    this.props.closeModal();
    this.props.clearCreateDistributor();
  };
  render() {
    const {
      openModal,
      closeModal,
      name,
      reload,
      error
    } = this.props;
    const handleSubmit = values => {
      this.props.createDistributor(
        values,
        'distirbutor-create-modal-form',
        null,
        data => {
          reload(data);
          closeModal();
        }
      );
    };
    return (
      <Modal
        show={openModal === name}
        onHide={this.closeDistributorModal}
        className="modal-success"
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New Distributor</Modal.Title>
        </Modal.Header>
        <CreateDistributorModalForm
          distributorCreateError={error}
          closeModal={this.closeDistributorModal}
          onSubmit={handleSubmit}
        />
      </Modal>
    );
  }
}
const mapState = state => {
  const {
    result: values,
    error
  } = state.createDistributor.toJS();
  return {
    values,
    error,
    openModal: state.modals
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      createDistributor: fetchDux.createDistributor.createAction,
      clearCreateDistributor: fetchDux.createDistributor.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(CreateDistributorModal);
