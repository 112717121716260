import React from 'react';
import { Col } from 'react-bootstrap'
const Details = ({ GTIN, HTSCode, SlsClassCode, CfgfItemFlg, Deleted, ProdWt, WtUOM }) => {
    return (
        <Col md={6}>
            <div className="box">
                <div className="box-header">
                    <h3>Details</h3>
                </div>
                <div className="box-body">

                    <table className="table table-data">
                        <tbody>
                            <tr>
                                <th>GTIN</th>
                                <td>{GTIN}</td>
                            </tr>
                            <tr>
                                <th>HTS Code</th>
                                <td>{HTSCode}</td>
                            </tr>
                            <tr>
                                <th>Sls Class Code</th>
                                <td>{SlsClassCode}</td>
                            </tr>
                            <tr>
                                <th>Cfgf Item Flag</th>
                                <td>{CfgfItemFlg}</td>
                            </tr>
                            <tr>
                                <th>Deleted</th>
                                <td>{Deleted}</td>
                            </tr>
                            <tr>
                                <th>Weight</th>
                                <td>{ProdWt}</td>
                            </tr>
                            <tr>
                                <th>Weight Unit of Measure</th>
                                <td>{WtUOM}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </Col>
    )
}


export default Details;