import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { Field, FieldArray } from 'redux-form';
import FormField from '../../../forms/FormField';
import Icon from '../../../ui/Icon';

const required = value => value ? null : 'Required';

const renderProdLinkDetails = ({ fields, lookupsResult }) => {
  const { LinkType } = lookupsResult || {};
  const linkTypeOptions = (LinkType || [])
    .map(({ enumKey }) => ({ value: enumKey, label: enumKey }));
  return (
    <div>
      <div className="buttonContainer">
        <Button
          variant="outline-primary"
          size="sm"
          onClick={() => fields.push({})}
        >
          <Icon name="plus" /> ProdLink
        </Button>
      </div>
      <div>
        {fields.map((prodLinkData, index) => {
          return (
            <div key={index}>
              <Row>
                <Col sm={12}>
                  <div className="header">
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => fields.remove(index)}
                    >
                      <Icon name="trash" />
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Field
                    name={`${prodLinkData}.LinkType`}
                    label="LinkType *"
                    type="text"
                    component={FormField.SelectReact}
                    options={linkTypeOptions}
                    validate={[required]}
                    isClearable
                  />
                </Col>

                <Col sm={6}>
                  <Field
                    name={`${prodLinkData}.LinkProdCode`}
                    label="LinkProdCode *"
                    type="text"
                    component={FormField.Input}
                    validate={[required]}
                  />
                </Col>
              </Row>
              {index !== fields.length - 1 && <div className="divider" />}
            </div>
          );
        })}

      </div>

    </div>
  );
};

const ProdLinkDetails = ({ lookupsResult }) => {
  return (
    <Col sm={12}>
      <div className="box">
        <div className="box-header">
          <h3>Prod Link Details</h3>
        </div>
        <div className="box-body">
          <FieldArray
            name="ProdLink"
            component={renderProdLinkDetails}
            props={{ lookupsResult }}
          />

        </div>
      </div>
    </Col>
  );
};

export default ProdLinkDetails;
