import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';

const DisableManufacturerModal = (
  {
    openModal,
    closeModal,
    name,
    handleDisable,
    manufacturer,
    inProgress,
    isActive
  }
) => (
  <Modal show={openModal === name} onHide={closeModal} className="modal-danger">
    <Modal.Header closeButton>
      <Modal.Title>
        {isActive ? 'Disable' : 'Enable'} Manufacturer Access
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h5>
        Are you sure you wish to
        {' '}
        {isActive ? 'disable' : 'enable'}
        {' '}
        access for manufacturer
        {' '}
        <b>{manufacturer.name}</b>
        ?
      </h5>
    </Modal.Body>
    <Modal.Footer>
      <Button
        size="sm"
        variant="outline-secondary"
        onClick={closeModal}
        disabled={inProgress}
      >
        Cancel
      </Button>
      {isActive &&
        <Button
          size="sm"
          variant="danger"
          onClick={handleDisable}
          disabled={inProgress}
        >
          Disable Access
        </Button>}
      {!isActive &&
        <Button
          size="sm"
          variant="success"
          onClick={handleDisable}
          disabled={inProgress}
        >
          Enable Access
        </Button>}
    </Modal.Footer>
  </Modal>
);

const mapState = state => {
  const { inProgress } = state.postToggleManufacturerStatus.toJS();
  return {
    inProgress,
    openModal: state.modals
  };
};

const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(DisableManufacturerModal);
