import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import { connect } from 'react-redux';
import fetchDux from '../../../../state/fetch-dux';
import ManufacturersSearchResults from './ManufacturersSearchResults';
import { bindActionCreators } from 'redux';

class ManufacturersSearch extends Component {
  componentDidMount() {
    // make our api call
    this.props.getManufacturers();
  }
  render() {
    const { result, history } = this.props;

    const {
      result: manufacturers
    } = result || {};
    return (
      <Layout route="manufacturers-search">
        <main>

          <div className="layout-header">
            <Container>

              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <Icon name="home" />
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  Manufacturers
                </li>
              </ol>

              <div className="header">
                <h1>
                  <Icon name="industry" /> Manufacturers
                </h1>
                <div>
                  <Link
                    to="/manufacturers/create"
                    className="btn btn-secondary"
                  >
                    Create Manufacturers
                  </Link>
                </div>
              </div>

            </Container>
          </div>

          <Container>
            <ManufacturersSearchResults
              manufacturers={manufacturers}
              history={history}
            />
          </Container>

        </main>
      </Layout>
    );
  }
}
const mapState = state => {
  const result = state.getManufacturers.toJS();
  return {
    result
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getManufacturers: fetchDux.getManufacturers.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ManufacturersSearch);
