import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Button, Modal } from 'react-bootstrap';

const required = value => value ? undefined : 'Required';

const CreateDistributorModalForm = props => {
  const {
    handleSubmit,
    submitting,
    closeModal,
    distributorCreateError
  } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Modal.Body>

        <Field
          name="imisId"
          type="text"
          component={FormField.Input}
          label="IMIS ID"
          validate={[required]}
          required
        />

        <Field
          name="name"
          type="text"
          component={FormField.Input}
          label="Distributor Name"
          validate={[required]}
          required
        />

      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          variant="outline-secondary"
          onClick={closeModal}
          disabled={submitting}
        >
          Cancel
        </Button>
        <Button
          size="sm"
          variant="success"
          onClick={handleSubmit}
          disabled={submitting}
        >
          Create Distributor
        </Button>
      </Modal.Footer>
      {distributorCreateError &&
        distributorCreateError.message &&
        <p>{distributorCreateError.message}</p>}
    </form>
  );
};

export default reduxForm({
  form: 'distirbutor-create-modal-form'
})(CreateDistributorModalForm);
