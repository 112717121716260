import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Button, Modal } from 'react-bootstrap';

const required = value => value ? undefined : 'Required';

const AddUserModalForm = props => {
  const { handleSubmit, submitting, closeModal,usersResult } = props;
  let userResultOptions = [];
  if (usersResult != null) {
    const{
      result:user
    }=usersResult
    userResultOptions = (user.items || []).map(({ _id, nameFirst }) => ({
      value: _id,
      label: nameFirst
    }))
  }
  return (
    <form onSubmit={handleSubmit}>
      <Modal.Body>

        <Field
          name="user"
          type="text"
          component={FormField.SelectReact}
          label="Search Users"
          options={userResultOptions}
        />

      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="outline-secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button size="sm" variant="success" onClick={handleSubmit}>
          Add User
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default reduxForm({
  form: 'add-user-modal-form'
})(AddUserModalForm);
