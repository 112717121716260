import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Alert,
  ToggleButtonGroup,
  ToggleButton
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icon from '../../../ui/Icon';
import Layout from '../../../ui/Layout';
import ReactSelect, { components } from 'react-select';
import { bindActionCreators } from 'redux';
import fetchDux from '../../../../state/fetch-dux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Loading from '../../../ui/Loading';
import ReportingResult from './ReportingResults';
import ReportingMetrics from './ReportingMetrics';
import MetricsResults from './MetricsResults';
import ProductsAnalytics from './analytics/ProductAnalytics';
import ActivityLog from './activityLog/ActivityLog';
import TermsAgreement from './termsAgreement/termsAgreement';
import moment from 'moment';
import {
  downloadActivityLog,
  downloadContactsReport,
  downloadTermsReport,
  downloadProductAnalytics
} from '../../../../api/api';
import { reset, change, formValueSelector } from 'redux-form';

import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Fragment } from 'react';
import ManufacturersContactsReport
  from './contactReports/manufacturersContactsReport';
import DistributorsContactsReport
  from './contactReports/distributorsContactsReport';

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon name={'caret-down'} />
    </components.DropdownIndicator>
  );
};

const getStyles = () => {
  return {
    control: (provided, state) => {
      const { menuIsOpen } = state;
      return {
        ...provided,
        boxShadow: menuIsOpen
          ? '3px -2px 4px -1px #cccccc, -3px -2px 4px -1px #cccccc'
          : '2px 2px 4px 0px #cccccc, -2px -2px 4px 0px #cccccc',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        borderBottomLeftRadius: menuIsOpen ? '0px' : '8px',
        borderBottomRightRadius: menuIsOpen ? '0px' : '8px',
        borderColor: '#cccccc',
        '&:hover': { borderColor: '#cccccc' },
        borderWidth: menuIsOpen ? 0 : '1px'
      };
    },
    indicatorSeparator: provided => {
      return {
        ...provided,
        display: 'none'
      };
    },
    option: (provided, state) => {
      const { isSelected, isFocused } = state;
      return isSelected
        ? {
          ...provided,
          backgroundColor: '#12173D',
          ':active': { backgroundColor: 'rgba(18,23,61,0.8)' }
        }
        : isFocused
          ? {
            ...provided,
            backgroundColor: 'rgba(18,23,61,0.5)',
            ':active': { backgroundColor: 'rgba(18,23,61,0.8)' }
          }
          : {
            ...provided,
            ':active': { backgroundColor: 'rgba(18,23,61,0.8)' }
          };
    },
    menu: provided => {
      return {
        ...provided,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        top: '65%',
        boxShadow: '3px 2px 4px -1px #cccccc , -3px 2px 4px -1px #cccccc',
        paddingTop: '10px'
      };
    }
  };
};

const getDesc = desc => {
  if (desc === false || desc === 'false') return 'false';
  return 'true';
};

const formatQuery = (
  {
    page,
    pageSize,
    desc,
    sortBy,
    filter,
    ...rest
  }
) => ({
  page: page || '0',
  pageSize: pageSize || '10',
  desc: getDesc(desc),
  sortBy: sortBy || 'Name',
  filter: filter || 0,
  ...rest
});

const userResultOptions = [
  { value: '', label: 'All' },
  { value: 'distributor', label: 'distributor' },
  { value: 'manufacturer', label: 'manufacturer' }
];

const contactsReportOptions = [
  { value: 'manufacturer', label: 'Manufacturer' },
  { value: 'distributor', label: 'Distributor' }
];

class Reporting extends Component {
  constructor(props) {
    super(props);
    const storedManufacturerWithProducts = localStorage.getItem('manufacturerWithProducts');
    this.state = { defaultPageSize: 20, selectedFilter: 0, manufacturerWithProducts: storedManufacturerWithProducts === true, };
    this.controller = new AbortController();
  }

  componentWillUnmount() {
    this.controller.abort();
  }

  static getDerivedStateFromProps(nextProps, currentState) {
    const {
      location: { search },
      getAdminReports
    } = nextProps;
    const { currentSearch } = currentState;
    if (search !== currentSearch) {
      const payload = queryString.parse(search);
      const { selectedReportType, filter } = payload;
      if (selectedReportType) {
        getAdminReports(payload);
        return {
          currentSearch: search,
          selectedReportType,
          selectedFilter: parseInt(filter, 10)
        };
      }
    }
    return null;
  }

  componentDidMount() {
    this.handleManufacturerWithProducts();

    const {
      location: { search },
      getAdminMetrics
    } = this.props;
    const payload = queryString.parse(search);
    const { selectedReportType } = payload;
    if (selectedReportType) {
      getAdminMetrics({ selectedReportType });
    }
  }

  handleManufacturerWithProducts = () => {
    const {
      location: { search },
      history,
      location: { pathname }
    } = this.props;

    localStorage.setItem('manufacturerWithProducts', !this.state.manufacturerWithProducts);

    const params = {
      ...queryString.parse(search),
      manufacturerWithProducts: !this.state.manufacturerWithProducts,
      page: 0
    };
    const query = formatQuery(params);
    const { selectedReportType } = query;
    if (!selectedReportType) return;
    const qs = queryString.stringify(query);
    history.push({ pathname, search: qs });
  };

  conponentDidUpdate(prevProps) {
    if (this.props.manufacturerWithProducts !== prevProps.manufacturerWithProducts) {
      this.handleFetchData();
    }
  }

  handleFetchData = state => {
    const {
      location: { search },
      history,
      location: { pathname }
    } = this.props;
    const {
      page,
      pageSize,
      sorted
    } = state || {};

    const {
      id,
      desc
    } = (sorted || [])[0] || {};

    if (!search) return;

    const params = {
      ...queryString.parse(search),
      page,
      pageSize,
      sortBy: id,
      desc
    };
    const query = formatQuery(params);
    const { sortBy } = query;
    const { selectedReportType } = query;
    if (!selectedReportType) return;
    const qs = queryString.stringify({
      ...query,
      sortBy: selectedReportType === 'Product Analytics' && sortBy === 'Name'
        ? 'manufacturerName'
        : sortBy
    });
    history.push({ pathname, search: qs });
  };

  onFilterSelectionChange = e => {
    this.setState({ selectedFilter: e });
    const {
      location: { search },
      history,
      location: { pathname }
    } = this.props;

    const params = {
      ...queryString.parse(search),
      filter: e,
      page: 0
    };
    const query = formatQuery(params);
    const { selectedReportType } = query;
    if (!selectedReportType) return;
    const qs = queryString.stringify(query);
    history.push({ pathname, search: qs });
  };

  render() {
    const {
      downloadReports,
      location: { pathname, search },
      history,
      inProgress,
      result,
      error,
      getAdminMetrics,
      adminMetricsResult,
      adminMetricsInProgress,
      handleSubmit,
      companyTypeValue
    } = this.props;
    const { selectedReportType, defaultPageSize, selectedFilter } = this.state;
    const options = [
      { value: 'Activity Log', label: 'Activity Log' },
      { value: 'Contacts Report', label: 'Contacts Report' },
      { value: 'DataQuality', label: 'Data Quality' },
      { value: 'Distributors', label: 'Distributors' },
      { value: 'Manufacturers', label: 'Manufacturers' },
      { value: 'Products', label: 'Products' },
      { value: 'Product Analytics', label: 'Product Analytics' },
      { value: 'Terms Agreement', label: 'Terms Agreement' }
    ];
    return (
      <Layout route="admin-reporting">
        <main>
          <div className="layout-header">
            <Container fluid>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <Icon name="home" />
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  Reporting
                </li>
              </ol>
              <div className="header">
                <h1>
                  <Icon name="list" /> Reporting
                </h1>
              </div>
            </Container>
          </div>

          <Container fluid>
            <Row
              style={{ justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Col sm={12}>
                <div style={{ color: '#AAACB9' }}>
                  Select the desired report below to view the data
                </div>
              </Col>
              <Col sm={4} style={{ marginTop: '0.5rem' }}>
                <Row>
                  <Col sm={10}>
                    <ReactSelect
                      components={{ DropdownIndicator }}
                      placeholder={'Select a report...'}
                      options={options}
                      value={options.find(
                        ({ value }) => value === selectedReportType
                      )}
                      styles={getStyles()}
                      onChange={({ value }) => {
                        const parsedSearch = queryString.parse(search) || {};
                        const query = formatQuery(parsedSearch);
                        const filter = value === 'Activity Log' ||
                          value === 'Terms Agreement' ||
                          value === 'Contacts Report'
                          ? 0
                          : value === 'DataQuality' || value === 'Products'
                            ? 3
                            : selectedFilter;
                        const sortBy = value === 'DataQuality' ||
                          value === 'Products'
                          ? 'date'
                          : value === 'Product Analytics'
                            ? 'manufacturerName'
                            : value === 'Activity Log'
                              ? 'dateTime'
                              : value === 'Contacts Report'
                                ? 'companyName'
                                : 'Name';

                        const newSearch = queryString.stringify({
                          ...query,
                          page: 0,
                          pageSize: defaultPageSize,
                          selectedReportType: value,
                          desc: false,
                          filter,
                          sortBy
                        });
                        history.push({ pathname, search: newSearch });
                        const payload = {
                          selectedReportType: value
                        };
                        getAdminMetrics(payload);
                      }}
                      isDisabled={inProgress || adminMetricsInProgress}
                    />
                  </Col>
                  {error &&
                    <Col sm={12} style={{ marginTop: '1rem' }}>
                      <Alert variant="danger">{error.message || ''}</Alert>
                    </Col>}
                </Row>
              </Col>
              {selectedReportType &&
                selectedReportType === 'Product Analytics' &&
                <Fragment>
                  <Row
                    style={{ marginTop: '20px', justifyContent: 'flex-end' }}
                  >
                    <Col sm={12}>
                      <form>
                        <Row>
                          <Col sm={12}>
                            <Button
                              variant="outline-secondary"
                              type="button"
                              style={{ marginRight: '15px' }}
                              onClick={handleSubmit(values => {
                                const {
                                  location: { search }
                                } = this.props;

                                const parsedSearch = queryString.parse(
                                  search
                                ) || {};
                                const query = formatQuery(parsedSearch);

                                const payload = queryString.stringify({
                                  ...query,
                                  selectedReportType: 'Product Analytics'
                                });

                                downloadProductAnalytics({
                                  payload,
                                  Filename: 'product-analytics.csv'
                                });
                              })}
                            >
                              Download Results
                            </Button>
                          </Col>
                        </Row>
                      </form>
                    </Col>
                  </Row>
                  <ProductsAnalytics
                    result={result}
                    selectedReportType={selectedReportType}
                    defaultPageSize={defaultPageSize}
                    inProgress={inProgress}
                    handleFetchData={this.handleFetchData}
                    activeColumns={
                      window.localStorage.getItem('productAnalyticsColumns') || ''
                    }
                  />
                </Fragment>}
              {selectedReportType &&
                selectedReportType === 'Terms Agreement' &&
                <Fragment>
                  <Row
                    style={{ marginTop: '20px', justifyContent: 'flex-end' }}
                  >
                    <Col sm={12}>
                      <form>
                        <Row>
                          <Col sm={12}>
                            <Button
                              variant="outline-secondary"
                              type="button"
                              style={{ marginRight: '15px' }}
                              onClick={handleSubmit(values => {
                                const {
                                  location: { search }
                                } = this.props;

                                const parsedSearch = queryString.parse(
                                  search
                                ) || {};
                                const query = formatQuery(parsedSearch);

                                const payload = queryString.stringify({
                                  ...query,
                                  selectedReportType: 'Terms Agreement'
                                });

                                downloadTermsReport({
                                  payload,
                                  Filename: 'terms-agreement.csv'
                                });
                              })}
                            >
                              Download Results
                            </Button>
                          </Col>
                        </Row>
                      </form>
                    </Col>
                  </Row>
                  <TermsAgreement
                    result={result}
                    selectedReportType={selectedReportType}
                    defaultPageSize={defaultPageSize}
                    inProgress={inProgress}
                    handleFetchData={this.handleFetchData}
                    activeColumns={
                      window.localStorage.getItem('termsAgeementColumns') || ''
                    }
                  />
                </Fragment>}
              {selectedReportType &&
                (selectedReportType === 'Manufacturers' ||
                  selectedReportType === 'Distributors') &&
                <Col sm="3">
                  <Button
                    style={{
                      textTransform: 'none',
                      float: 'right',
                      whiteSpace: 'nowrap'
                    }}
                    disabled={inProgress}
                    onClick={() => {
                      const parsedSearch = queryString.parse(search) || {};
                      const { sortBy, desc } = parsedSearch;
                      const payload = {
                        selectedReportType,
                        download: true,
                        sortBy,
                        desc
                      };
                      downloadReports({
                        payload,
                        Filename: `${selectedReportType.toLowerCase()}-report.csv`,
                        abortControllerSignal: this.controller.signal
                      });
                    }}
                  >
                    {`Export ${selectedReportType} Data`}
                  </Button>
                </Col>}
            </Row>
            {selectedReportType &&
              selectedReportType === 'Activity Log' &&
              <Fragment>
                <Row style={{ marginTop: '20px', justifyContent: 'flex-end' }}>
                  <Col sm={12}>
                    <form
                      className="advanced-search"
                      onSubmit={handleSubmit(values => {
                        const {
                          location: { search },
                          history,
                          location: { pathname }
                        } = this.props;
                        const parsedSearch = queryString.parse(search) || {};
                        const query = formatQuery(parsedSearch);
                        const formattedDateFrom = moment(
                          values.dateFrom
                        ).format('YYYY-MM-DD');
                        const formattedDateTo = moment(values.dateTo).format(
                          'YYYY-MM-DD'
                        );

                        const newSearch = queryString.stringify({
                          ...query,
                          page: 0,
                          selectedReportType: 'Activity Log',
                          dateFrom: values.dateFrom
                            ? formattedDateFrom
                            : undefined,
                          dateTo: values.dateTo ? formattedDateTo : undefined,
                          companyType: values.companyTypeAL
                        });

                        history.push({ pathname, search: newSearch });
                      })}
                    >
                      <Row>
                        <Col sm={2}>
                          <Field
                            name="companyTypeAL"
                            type="text"
                            component={FormField.SelectReact}
                            label="Company Type"
                            options={userResultOptions}
                            isClearable
                          />
                        </Col>
                        <Col sm={2}>
                          <Field
                            name="dateFrom"
                            type="text"
                            component={FormField.Datepicker}
                            label="Date From"
                            isClearable
                          />
                        </Col>
                        <Col sm={2}>
                          <Field
                            name="dateTo"
                            type="text"
                            component={FormField.Datepicker}
                            label="Date To"
                            isClearable
                          />
                        </Col>
                        <Col sm={4}>
                          <Button
                            variant="primary"
                            type="submit"
                            disabled={false}
                            style={{ marginRight: '5px', marginTop: '30px' }}
                          >
                            SEARCH
                          </Button>
                          <Button
                            variant="danger"
                            type="button"
                            style={{ marginRight: '5px', marginTop: '30px' }}
                            onClick={() => {
                              const { dispatch } = this.props;
                              const sortBy = 'dateTime';
                              const newSearch = queryString.stringify({
                                page: 0,
                                pageSize: this.state.defaultPageSize,
                                selectedReportType: 'Activity Log',
                                desc: true,
                                sortBy
                              });

                              dispatch(reset('reportingForm'));
                              dispatch(
                                change('reportingForm', 'companyType', '')
                              );

                              history.push({ pathname, search: newSearch });
                            }}
                          >
                            Reset Filters
                          </Button>
                          <Button
                            variant="outline-secondary"
                            type="button"
                            style={{ marginTop: '30px' }}
                            onClick={handleSubmit(values => {
                              const { location: { search } } = this.props;
                              const parsedSearch = queryString.parse(
                                search
                              ) || {};
                              const query = formatQuery(parsedSearch);
                              const formattedDateFrom = moment(
                                values.dateFrom
                              ).format('YYYY-MM-DD');
                              const formattedDateTo = moment(
                                values.dateTo
                              ).format('YYYY-MM-DD');

                              const payload = queryString.stringify({
                                ...query,
                                page: 0,
                                pageSize: this.state.defaultPageSize,
                                selectedReportType: 'Activity Log',
                                dateFrom: values.dateFrom
                                  ? formattedDateFrom
                                  : undefined,
                                dateTo: values.dateTo
                                  ? formattedDateTo
                                  : undefined,
                                companyType: values.companyTypeAL
                              });

                              downloadActivityLog({
                                payload,
                                Filename: 'activity-log.csv'
                              });
                            })}
                          >
                            Download Results
                          </Button>
                        </Col>
                        <div
                          className="horizontalRuler"
                          style={{ marginBottom: '10px', marginTop: '10px' }}
                        />
                      </Row>
                    </form>
                  </Col>
                </Row>
                <Row>
                  <ActivityLog
                    result={result}
                    selectedReportType={selectedReportType}
                    defaultPageSize={defaultPageSize}
                    inProgress={inProgress}
                    handleFetchData={this.handleFetchData}
                    activeColumns={
                      window.localStorage.getItem('activityLogColumns') || ''
                    }
                  />
                </Row>
              </Fragment>}
            {selectedReportType &&
              selectedReportType === 'Contacts Report' &&
              <Fragment>
                <Row style={{ marginTop: '20px', justifyContent: 'flex-end' }}>
                  <Col sm={12}>
                    <form
                      className="advanced-search"
                      onSubmit={handleSubmit(values => {
                        const { companyType } = values || {};
                        const {
                          location: { search },
                          history,
                          location: { pathname },
                          manufacturerWithProducts
                        } = this.props;
                        const parsedSearch = queryString.parse(search) || {};
                        const query = formatQuery(parsedSearch);
                        const newSearch = queryString.stringify({
                          ...query,
                          page: 0,
                          pageSize: this.state.defaultPageSize,
                          selectedReportType: 'Contacts Report',
                          companyType,
                          manufacturerWithProducts: companyTypeValue ===
                            'manufacturer'
                            ? manufacturerWithProducts
                            : null
                        });

                        history.push({ pathname, search: newSearch });
                      })}
                    >
                      <Row>
                        <Col sm={3}>
                          <Field
                            name="companyType"
                            type="text"
                            component={FormField.SelectReact}
                            label="Company Type"
                            options={contactsReportOptions}
                            onChange={this.onFilterSelectionChange}
                          />
                        </Col>

                        {companyTypeValue === 'manufacturer' &&
                          <Col sm={3} style={{ marginTop: '35px' }}>
                            <Field
                              name="manufacturerWithProducts"
                              type="checkbox"
                              component={FormField.InlineCheckbox}
                              label="Manufacturers with Products"
                              value={companyTypeValue === 'manufacturer'}
                              onChange={this.handleManufacturerWithProducts}
                            />
                          </Col>}

                        <Col sm={4}>
                          <Button
                            variant="primary"
                            type="submit"
                            disabled={false}
                            style={{ marginRight: '5px', marginTop: '30px' }}
                          >
                            SEARCH
                          </Button>
                          <Button
                            variant="outline-secondary"
                            type="button"
                            style={{ marginTop: '30px' }}
                            onClick={handleSubmit(values => {
                              const { location: { search } } = this.props;
                              const parsedSearch = queryString.parse(
                                search
                              ) || {};
                              const query = formatQuery(parsedSearch);

                              const payload = queryString.stringify({
                                ...query,
                                page: 0,
                                pageSize: this.state.defaultPageSize,
                                selectedReportType: 'Contacts Report',
                                companyType: values.companyType
                              });
                              downloadContactsReport({
                                payload,
                                Filename: `${values.companyType.toLowerCase()}s-contacts-report.csv`
                              });
                            })}
                          >
                            Download Results
                          </Button>
                        </Col>
                        <div
                          className="horizontalRuler"
                          style={{ marginBottom: '10px', marginTop: '10px' }}
                        />
                      </Row>
                    </form>
                  </Col>
                </Row>
                <Row>
                  {companyTypeValue === 'manufacturer' &&
                    <ManufacturersContactsReport
                      result={result}
                      selectedReportType={selectedReportType}
                      defaultPageSize={defaultPageSize}
                      inProgress={inProgress}
                      handleFetchData={this.handleFetchData}
                      activeColumns={
                        window.localStorage.getItem('companyImisId') || ''
                      }
                      companyTypeValue={companyTypeValue}
                      manufacturerWithProducts={this.state.manufacturerWithProducts}
                    />}

                  {companyTypeValue === 'distributor' &&
                    <DistributorsContactsReport
                      result={result}
                      selectedReportType={selectedReportType}
                      defaultPageSize={defaultPageSize}
                      inProgress={inProgress}
                      handleFetchData={this.handleFetchData}
                      activeColumns={
                        window.localStorage.getItem('companyImisId') || ''
                      }
                      companyTypeValue={companyTypeValue}
                    />}
                </Row>
              </Fragment>}

            {adminMetricsInProgress &&
              <Fragment>
                <div className="horizontalRuler" />
                <Loading fullPage />
              </Fragment>}
            {selectedReportType &&
              selectedReportType !== 'Product Analytics' &&
              selectedReportType !== 'Terms Agreement' &&
              selectedReportType !== 'Activity Log' &&
              !adminMetricsInProgress &&
              <Row>
                <ReportingMetrics
                  adminMetricsResult={adminMetricsResult}
                  selectedReportType={selectedReportType}
                />
              </Row>}
            {selectedReportType &&
              selectedReportType !== 'Product Analytics' &&
              selectedReportType !== 'Terms Agreement' &&
              selectedReportType !== 'Activity Log' &&
              !adminMetricsInProgress &&
              <Fragment>
                {(selectedReportType === 'Manufacturers' ||
                  selectedReportType === 'Distributors') &&
                  <Row>
                    <Col sm={12}>
                      <ToggleButtonGroup
                        name="filter"
                        type="radio"
                        className="box-tabs"
                        value={selectedFilter}
                        onChange={this.onFilterSelectionChange}
                      >
                        <ToggleButton
                          value={0}
                          className={
                            selectedFilter === 0
                              ? 'toggleButton selected'
                              : 'toggleButton'
                          }
                        >
                          All
                        </ToggleButton>
                        <ToggleButton
                          value={1}
                          className={
                            selectedFilter === 1
                              ? 'toggleButton selected'
                              : 'toggleButton'
                          }
                        >
                          Registered
                        </ToggleButton>

                        <ToggleButton
                          value={2}
                          className={
                            selectedFilter === 2
                              ? 'toggleButton selected'
                              : 'toggleButton'
                          }
                        >
                          Non-Registered
                        </ToggleButton>

                        <ToggleButton
                          value={3}
                          className={
                            selectedFilter === 3
                              ? 'toggleButton selected'
                              : 'toggleButton'
                          }
                        >
                          Metrics
                        </ToggleButton>

                      </ToggleButtonGroup>
                    </Col>
                  </Row>}
                <Row>
                  {selectedFilter === 3 &&
                    <MetricsResults
                      result={result}
                      selectedReportType={selectedReportType}
                      defaultPageSize={defaultPageSize}
                      inProgress={inProgress}
                      handleFetchData={this.handleFetchData}
                    />}
                  {selectedFilter !== 3 &&
                    (selectedReportType === 'Manufacturers' ||
                      selectedReportType === 'Distributors') &&
                    <ReportingResult
                      result={result}
                      selectedReportType={selectedReportType}
                      defaultPageSize={defaultPageSize}
                      inProgress={inProgress}
                      handleFetchData={this.handleFetchData}
                    />}
                </Row>
              </Fragment>}
          </Container>
        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress,
    error
  } = state.getAdminReports.toJS();

  const {
    result: adminMetricsResult,
    inProgress: adminMetricsInProgress,
    error: adminMetricsError
  } = state.getAdminMetrics.toJS();

  const selector = formValueSelector('reportingForm');
  const companyTypeValue = selector(state, 'companyType');
  const manufacturerWithProducts = selector(state, 'manufacturerWithProducts');

  return {
    result,
    inProgress,
    adminMetricsInProgress,
    error: error || adminMetricsError,
    adminMetricsResult,
    companyTypeValue,
    manufacturerWithProducts
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      downloadReports: fetchDux.downloadReports.createAction,
      getAdminReports: fetchDux.getAdminReports.createAction,
      getAdminMetrics: fetchDux.getAdminMetrics.createAction
    },
    dispatch
  );

const ReportingForm = reduxForm({
  form: 'reportingForm',
  initialValues: {
    manufacturerWithProducts: true,
    companyType: 'manufacturer',
    companyTypeAL: ''
  }
})(Reporting);

export default connect(mapState, mapDispatch)(ReportingForm);
