import duxFactory from './fetch-dux-factory';
import * as api from '../api/api';

export default {
  login: duxFactory({
    apiFn: api.postLogin,
    actionPrefix: 'POST_LOGIN',
    successListeners: ['POST_ADMIN_IMPERSONATE']
  }),
  validateLogin: duxFactory({
    apiFn: api.validateLogin,
    actionPrefix: 'POST_VALIDATE_LOGIN',
    requestListeners: ['POST_LOGIN'],
    successListeners: ['POST_LOGIN', 'POST_ADMIN_IMPERSONATE']
  }),
  validateToken: duxFactory({
    apiFn: api.validateToken,
    actionPrefix: 'POST_VALIDATE_TOKEN'
  }),
  acceptTermsOfService: duxFactory({
    apiFn: api.acceptTermsOfService,
    actionPrefix: 'ACCEPT_TERMS_OF_SERVICE'
  }),
  cancelTermsOfService: duxFactory({
    apiFn: api.cancelTermsOfService,
    actionPrefix: 'CANCEL_TERMS_OF_SERVICE'
  }),
  getTermsOfService: duxFactory({
    apiFn: api.getTermsOfService,
    actionPrefix: 'GET_TERMS_OF_SERVICE'
  }),
  getTermsByVersion: duxFactory({
    apiFn: api.getTermsByVersion,
    actionPrefix: 'GET_TERMS_BY_VERSION'
  }),
  getDistributors: duxFactory({
    apiFn: api.getDistributors,
    actionPrefix: 'GET_DISTRIBUTORS'
  }),
  getUsers: duxFactory({
    apiFn: api.getUsers,
    actionPrefix: 'GET_USERS'
  }),
  getUserById: duxFactory({
    apiFn: api.getUserById,
    actionPrefix: 'GET_USERS_ID',
    successListeners: ['CREATE_PASSWORD_ID', 'RESET_PASSWORD_ID']
  }),
  getManufacturers: duxFactory({
    apiFn: api.getManufacturers,
    actionPrefix: 'GET_MANUFACTURERS'
  }),
  getChildManufacturers: duxFactory({
    apiFn: api.getChildManufacturers,
    actionPrefix: 'GET_MANUFACTURERS_CHILDREN'
  }),
  createUser: duxFactory({
    apiFn: api.createUser,
    actionPrefix: 'CREATE_USER'
  }),
  updateUserById: duxFactory({
    apiFn: api.updateUserById,
    actionPrefix: 'UPDATE_USER_ID'
  }),
  createManufacturer: duxFactory({
    apiFn: api.createManufacturer,
    actionPrefix: 'CREATE_MANUFACTURER'
  }),
  getManufacturerById: duxFactory({
    apiFn: api.getManufacturerById,
    actionPrefix: 'GET_MANUFACTURERS_ID',
    successListeners: [
      'POST_TOGGLE_MANUFACTURER_STATUS',
      'REMOVE_CHILD_MANUFACTURER_ID',
      'ADD_CHILD_MANUFACTURER_ID'
    ]
  }),
  updateManufacturerById: duxFactory({
    apiFn: api.updateManufacturerById,
    actionPrefix: 'UPDATE_MANUFACTURER_ID'
  }),
  getDistributorById: duxFactory({
    apiFn: api.getDistributorById,
    actionPrefix: 'GET_DISTRIBUTORS_ID'
  }),
  getDistributorDetails: duxFactory({
    apiFn: api.getDistributorDetails,
    actionPrefix: 'GET_DISTRIBUTORS_DETAILS'
  }),
  createDistributor: duxFactory({
    apiFn: api.createDistributor,
    actionPrefix: 'CREATE_DISTRIBUTOR'
  }),
  updateDistributorById: duxFactory({
    apiFn: api.updateDistributorById,
    actionPrefix: 'UPDATE_DISTRIBUTOR_ID'
  }),
  deleteDistributorById: duxFactory({
    apiFn: api.deleteDistributorById,
    actionPrefix: 'DELETE_DISTRIBUTOR_ID'
  }),
  createDistributorApiKeyById: duxFactory({
    apiFn: api.createDistributorApiKeyById,
    actionPrefix: 'GENERATE_DISTRIBUTOR_APIKEY_ID'
  }),
  updateDistributorApiKeyById: duxFactory({
    apiFn: api.updateDistributorApiKeyById,
    actionPrefix: 'UPDATE_DISTRIBUTOR_APIKEY_ID'
  }),
  deleteDistributorApiKeyById: duxFactory({
    apiFn: api.deleteDistributorApiKeyById,
    actionPrefix: 'DELETE_DISTRIBUTOR_APIKEY_ID'
  }),
  deleteManufacturerById: duxFactory({
    apiFn: api.deleteManufacturerById,
    actionPrefix: 'DELETE_MANUFACTURER_ID'
  }),
  addChildrensManufacturers: duxFactory({
    apiFn: api.addChildrensManufacturers,
    actionPrefix: 'ADD_CHILD_MANUFACTURER_ID'
  }),
  removeChildManufacturerById: duxFactory({
    apiFn: api.removeChildManufacturerById,
    actionPrefix: 'REMOVE_CHILD_MANUFACTURER_ID'
  }),
  deleteUserById: duxFactory({
    apiFn: api.deleteUserById,
    actionPrefix: 'DELETE_USER_ID'
  }),
  resetPasswordById: duxFactory({
    apiFn: api.resetPasswordById,
    actionPrefix: 'RESET_PASSWORD_ID'
  }),
  createPasswordById: duxFactory({
    apiFn: api.createPasswordById,
    actionPrefix: 'CREATE_PASSWORD_ID'
  }),
  validateTokenAndSavePassword: duxFactory({
    apiFn: api.validateTokenAndSavePassword,
    actionPrefix: 'VERIFY_SAVE_PASSWORD'
  }),
  getAllFiles: duxFactory({
    apiFn: api.getAllFiles,
    actionPrefix: 'GET_ALL_FILES'
  }),
  getAllLogs: duxFactory({
    apiFn: api.getAllLogs,
    actionPrefix: 'GET_ALL_LOGS'
  }),
  getUserLogs: duxFactory({
    apiFn: api.getUserLogs,
    actionPrefix: 'GET_USER_LOGS'
  }),
  getManufacturerLogs: duxFactory({
    apiFn: api.getManufacturerLogs,
    actionPrefix: 'GET_MANUFACTURER_LOGS'
  }),
  getDistributorLogs: duxFactory({
    apiFn: api.getDistributorLogs,
    actionPrefix: 'GET_DISTRIBUTOR_LOGS'
  }),
  postToggleManufacturerStatus: duxFactory({
    apiFn: api.postToggleManufacturerStatus,
    actionPrefix: 'POST_TOGGLE_MANUFACTURER_STATUS',
    successEmit: ['CLOSE_MODAL']
  }),
  getLookups: duxFactory({
    apiFn: api.getLookups,
    actionPrefix: 'GET_LOOKUPS'
  }),
  getProducts: duxFactory({
    apiFn: api.getProducts,
    actionPrefix: 'GET_PRODUCTS'
  }),
  getProductById: duxFactory({
    apiFn: api.getProductById,
    actionPrefix: 'GET_PRODUCT_DETAILS'
  }),
  deleteProductById: duxFactory({
    apiFn: api.deleteProductById,
    actionPrefix: 'DELETE_PRODUCT_ID'
  }),
  uploadManufacturerProducts: duxFactory({
    apiFn: api.uploadManufacturerProducts,
    actionPrefix: 'UPLOAD_MANUFACTURER_PRODUCTS'
  }),
  getProductsUploadStatus: duxFactory({
    apiFn: api.getProductsUploadStatus,
    actionPrefix: 'GET_PRODUCTS_UPLOAD_STATUS'
  }),
  downloadProductsFile: duxFactory({
    apiFn: api.downloadProductsFile,
    actionPrefix: 'DOWNLOAD_PRODUCTS_FILE'
  }),
  getAllProductsFilename: duxFactory({
    apiFn: api.getAllProductsFilename,
    actionPrefix: 'DOWNLOAD_PRODUCTS_FILENAME'
  }),
  downloadAllProductsFile: duxFactory({
    apiFn: api.downloadAllProductsFile,
    actionPrefix: 'DOWNLOAD_ALL_PRODUCTS_FILE'
  }),
  saveCSVJobs: duxFactory({
    apiFn: api.saveCSVJobs,
    actionPrefix: 'SAVE_CSV_JOBS'
  }),
  getCSVJobs: duxFactory({
    apiFn: api.getCSVJobs,
    actionPrefix: 'GET_CSV_JOBS'
  }),
  deleteCSVJobById: duxFactory({
    apiFn: api.deleteCSVJobById,
    actionPrefix: 'DELETE_CSV_JOB_ID'
  }),
  createFTPJobs: duxFactory({
    apiFn: api.createFTPJobs,
    actionPrefix: 'CREATE_FTP_JOBS'
  }),
  getFTPJobs: duxFactory({
    apiFn: api.getFTPJobs,
    actionPrefix: 'GET_FTP_JOBS'
  }),
  deleteFTPJobById: duxFactory({
    apiFn: api.deleteFTPJobById,
    actionPrefix: 'DELETE_FTP_JOB_ID'
  }),
  getProductReferences: duxFactory({
    apiFn: api.getProductReferences,
    actionPrefix: 'GET_PRODUCT_REFERENCES'
  }),
  addProductReference: duxFactory({
    apiFn: api.addProductReference,
    actionPrefix: 'ADD_PRODUCT_REFERENCE'
  }),
  editProductReference: duxFactory({
    apiFn: api.editProductReference,
    actionPrefix: 'EDIT_PRODUCT_REFERENCE'
  }),
  getProductMiscLookups: duxFactory({
    apiFn: api.getProductMiscLookups,
    actionPrefix: 'GET_PRODUCT_MISC_LOOKUPS'
  }),
  deleteProductReference: duxFactory({
    apiFn: api.deleteProductReference,
    actionPrefix: 'DELETE_PRODUCT_REFERENCE'
  }),
  testConnection: duxFactory({
    apiFn: api.testConnection,
    actionPrefix: 'TEST_CONNECTION'
  }),
  downloadReports: duxFactory({
    apiFn: api.downloadReports,
    actionPrefix: 'DOWNLOAD_REPORTS'
  }),
  downloadActivityLog: duxFactory({
    apiFn: api.downloadActivityLog,
    actionPrefix: 'DOWNLOAD_ACTIVITY_LOG'
  }),
  getAdminReports: duxFactory({
    apiFn: api.getAdminReports,
    actionPrefix: 'GET_ADMIN_REPORTS'
  }),
  getAdminMetrics: duxFactory({
    apiFn: api.getAdminMetrics,
    actionPrefix: 'GET_ADMIN_METRICS'
  }),
  getAssetById: duxFactory({
    apiFn: api.downloadProductAsset,
    actionPrefix: 'GET_ASSET_BY_ID'
  }),
  getProductLookups: duxFactory({
    apiFn: api.getProductLookups,
    actionPrefix: 'GET_PRODUCT_LOOKUPS'
  }),
  postProduct: duxFactory({
    apiFn: api.postProduct,
    actionPrefix: 'POST_PRODUCT'
  }),
  getProductCodes: duxFactory({
    apiFn: api.getProductCodes,
    actionPrefix: 'GET_PRODUCT_CODES'
  }),
  getFAQs: duxFactory({
    apiFn: api.getFAQs,
    actionPrefix: 'GET_FAQS'
  }),
  getFAQsById: duxFactory({
    apiFn: api.getFAQsById,
    actionPrefix: 'GET_FAQS_BY_ID'
  }),

  asyncValidateProductFields: duxFactory({
    apiFn: api.asyncValidateProductFields,
    actionPrefix: 'VALIDATE_PRODUCT_FIELDS'
  }),

  editProduct: duxFactory({
    apiFn: api.editProduct,
    actionPrefix: 'EDIT_PRODUCT'
  }),

  getUploadExceptions: duxFactory({
    apiFn: api.getUploadExceptions,
    actionPrefix: 'GET_UPLOAD_EXCEPTIONS'
  }),

  getAdminLookups: duxFactory({
    apiFn: api.getAdminLookups,
    actionPrefix: 'GET_ADMIN_LOOK_UPS'
  }),

  postAdminImpersonate: duxFactory({
    apiFn: api.postAdminImpersonate,
    actionPrefix: 'POST_ADMIN_IMPERSONATE'
  }),

  createFAQGroup: duxFactory({
    apiFn: api.createFAQGroup,
    actionPrefix: 'CREATE_FAQ_GROUP'
  }),

  getAllFAQs: duxFactory({
    apiFn: api.getAllFAQs,
    actionPrefix: 'GET_ALL_FAQS'
  }),

  getAllFAQGroups: duxFactory({
    apiFn: api.getAllFAQGroups,
    actionPrefix: 'GET_ALL_FAQ_GROUPS'
  }),

  createFAQArticle: duxFactory({
    apiFn: api.createFAQArticle,
    actionPrefix: 'CREATE_FAQ_ARTICLE'
  }),

  deleteFAQArticle: duxFactory({
    apiFn: api.deleteFAQArticle,
    actionPrefix: 'DELETE_FAQ_ARTICLE'
  }),

  getFAQArtcileById: duxFactory({
    apiFn: api.getFAQArticleById,
    actionPrefix: 'GET_FAQ_ARTICLE_BY_ID'
  }),

  updateFAQArticleById: duxFactory({
    apiFn: api.updateFAQArticleById,
    actionPrefix: 'UPDATE_FAQ_ARTICLE_BY_ID'
  }),

  updateFAQGroupById: duxFactory({
    apiFn: api.updateFAQGroupById,
    actionPrefix: 'UPDATE_FAQ_GROUP_BY_ID'
  }),

  deleteFAQGroup: duxFactory({
    apiFn: api.deleteFAQGroup,
    actionPrefix: 'DELETE_FAQ_GROUP'
  }),

  downloadProductInitialTemplate: duxFactory({
    apiFn: api.downloadProductInitialTemplate,
    actionPrefix: 'DOWNLOAD_PRODUCT_INITIAL_TEMPLATE'
  }),

  downloadProductPriceTemplate: duxFactory({
    apiFn: api.downloadProductPriceTemplate,
    actionPrefix: 'DOWNLOAD_PRODUCT_PRICE_TEMPLATE'
  }),

  downloadProductAssetTemplate: duxFactory({
    apiFn: api.downloadProductAssetTemplate,
    actionPrefix: 'DOWNLOAD_PRODUCT_ASSET_TEMPLATE'
  }),

  getProductsCount: duxFactory({
    apiFn: api.getProductsCount,
    actionPrefix: 'GET_PRODUCTS_COUNT'
  })
};
