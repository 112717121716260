import React, { Component } from 'react';
import Layout from '../../ui/Layout';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icon from '../../ui/Icon';
import ReactSelect, { components } from 'react-select';
import ReferencesResult from './ReferencesResult';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../state/modals-dux';
import fetchDux from '../../../state/fetch-dux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Loading from '../../ui/Loading';
import AddReferenceModal from './AddReferenceModal';
import EditReferenceModal from './EditReferenceModal';

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon name={'caret-down'} />
    </components.DropdownIndicator>
  );
};

const getStyles = () => {
  return {
    control: (provided, state) => {
      const { menuIsOpen } = state;
      return {
        ...provided,
        boxShadow: menuIsOpen
          ? '3px -2px 4px -1px #cccccc, -3px -2px 4px -1px #cccccc'
          : '2px 2px 4px 0px #cccccc, -2px -2px 4px 0px #cccccc',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        borderBottomLeftRadius: menuIsOpen ? '0px' : '8px',
        borderBottomRightRadius: menuIsOpen ? '0px' : '8px',
        borderColor: '#cccccc',
        '&:hover': { borderColor: '#cccccc' },
        borderWidth: menuIsOpen ? 0 : '1px'
      };
    },
    indicatorSeparator: provided => {
      return {
        ...provided,
        display: 'none'
      };
    },
    option: (provided, state) => {
      const { isSelected, isFocused } = state;
      return isSelected
        ? {
            ...provided,
            backgroundColor: '#12173D',
            ':active': { backgroundColor: 'rgba(18,23,61,0.8)' }
          }
        : isFocused
            ? {
                ...provided,
                backgroundColor: 'rgba(18,23,61,0.5)',
                ':active': { backgroundColor: 'rgba(18,23,61,0.8)' }
              }
            : {
                ...provided,
                ':active': { backgroundColor: 'rgba(18,23,61,0.8)' }
              };
    },
    menu: provided => {
      return {
        ...provided,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        top: '65%',
        boxShadow: '3px 2px 4px -1px #cccccc , -3px 2px 4px -1px #cccccc',
        paddingTop: '10px'
      };
    }
  };
};

const getDesc = desc => {
  if (desc === false || desc === 'false') return 'false';
  return 'true';
};

const formatQuery = (
  {
    page,
    pageSize,
    desc,
    sortBy,
    ...rest
  }
) => ({
  page: page || '0',
  pageSize: pageSize || '10',
  desc: getDesc(desc),
  sortBy: sortBy || 'value',
  ...rest
});

class References extends Component {
  constructor(props) {
    super(props);
    this.state = { defaultPageSize: 20 };
  }

  componentWillUnmount() {
    const { clearProductReferences } = this.props;
    clearProductReferences();
  }

  handleFetchData = state => {
    const {
      location: { search },
      history,
      location: { pathname }
    } = this.props;
    const {
      page,
      pageSize,
      sorted
    } = state || {};
    const {
      id,
      desc
    } = (sorted || [])[0] || {};
    if (!search) return;
    const params = {
      ...queryString.parse(search),
      page,
      pageSize,
      sortBy: id,
      desc
    };
    const query = formatQuery(params);
    const { lookup } = query;
    if (!lookup) return;
    const qs = queryString.stringify(query);
    history.push({ pathname, search: qs });
  };

  static getDerivedStateFromProps(nextProps, currentState) {
    const {
      location: { search },
      getProductReferences,
      addResult,
      clearAddProductReference,
      editResult,
      clearEditProductReference,
      getProductMiscLookups,
      deleteResult,
      clearDeleteProductReference,
      deleteError
    } = nextProps;
    const { currentSearch } = currentState;
    if (search !== currentSearch) {
      const payload = queryString.parse(search);
      const { lookup, search: querySearch } = payload;
      if (lookup) {
        getProductReferences(payload);
        if (lookup === 'featureEnums') getProductMiscLookups();
        if (deleteError) clearDeleteProductReference();
        return {
          currentSearch: search,
          selectedLookup: lookup,
          querySearch
        };
      }
    } else {
      const { ok: addOk } = addResult || {};
      const { ok: editOk } = editResult || {};
      const { ok: deleteOk } = deleteResult || {};
      if (addOk || editOk || deleteOk) {
        if (deleteError) clearDeleteProductReference();
        const payload = queryString.parse(search);
        const { lookup } = payload;
        if (lookup) getProductReferences(payload);
        if (addOk) clearAddProductReference();
        else if (editOk) clearEditProductReference();
        else if (deleteOk) clearDeleteProductReference();
      }
    }
    return null;
  }

  editClickHandler = rowData => {
    const { showModal } = this.props;
    this.setState(
      {
        editRowData: rowData
      },
      () => showModal('EditReferenceModal')
    );
  };

  searchChangeHandler = value => {
    const { location: { pathname, search }, history } = this.props;
    const parsedSearch = queryString.parse(search) || {};
    const query = formatQuery(parsedSearch);
    const newSearch = queryString.stringify({
      ...query,
      search: value
    });
    history.push({ pathname, search: newSearch });
  };

  render() {
    const {
      result,
      location: { pathname, search },
      history,
      inProgress,
      showModal,
      error,
      deleteProductReference,
      deleteInProgress
    } = this.props;
    const {
      defaultPageSize,
      selectedLookup,
      editRowData,
      querySearch
    } = this.state;
    const options = [
      { value: 'categoryEnums', label: 'Categories' },
      { value: 'measureEnums', label: 'Measures' },
      { value: 'featureEnums', label: 'Features' }
    ];
    return (
      <Layout route="manage-references">
        <main>
          <div className="layout-header">
            <Container>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <Icon name="home" />
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  Manage References
                </li>
              </ol>

              <div className="header">
                <h1>
                  <Icon name="tasks" /> Manage References
                </h1>
              </div>

            </Container>
          </div>
          <Container>
            <Row
              style={{
                marginBottom: '2rem',
                paddingBottom: '2rem',
                borderBottom: 'solid 1px #cccccc'
              }}
            >
              <Col sm={12}>
                <div style={{ color: '#AAACB9' }}>
                  Use the section below to begin editing actively managed references
                </div>
              </Col>
              <Col sm={3} style={{ marginTop: '0.5rem' }}>
                <ReactSelect
                  components={{ DropdownIndicator }}
                  placeholder={'Select a reference list...'}
                  options={options}
                  value={options.find(({ value }) => value === selectedLookup)}
                  styles={getStyles()}
                  onChange={({ value }) => {
                    const parsedSearch = queryString.parse(search) || {};
                    const query = formatQuery(parsedSearch);
                    const { search: querySearch, ...rest } = query;
                    const newSearch = queryString.stringify({
                      ...rest,
                      page: 0,
                      pageSize: defaultPageSize,
                      lookup: value,
                      desc: false
                    });
                    history.push({ pathname, search: newSearch });
                  }}
                  isDisabled={inProgress}
                />
              </Col>
              {error &&
                <Col sm={12} style={{ marginTop: '1rem' }}>
                  <Alert variant="danger">{error.message || ''}</Alert>
                </Col>}
              {inProgress && <Loading style={{ marginTop: '0.7rem' }} small />}
            </Row>
            {selectedLookup &&
              <Row>
                <ReferencesResult
                  result={result}
                  defaultPageSize={defaultPageSize}
                  handleFetchData={this.handleFetchData}
                  inProgress={inProgress}
                  showModal={showModal}
                  editClickHandler={this.editClickHandler}
                  selectedLookup={selectedLookup}
                  deleteProductReference={deleteProductReference}
                  deleteInProgress={deleteInProgress}
                  searchChangeHandler={this.searchChangeHandler}
                  querySearch={querySearch}
                />
              </Row>}

            <AddReferenceModal
              name={'AddReferenceModal'}
              selectedLookup={selectedLookup}
            />
            <EditReferenceModal
              name={'EditReferenceModal'}
              selectedLookup={selectedLookup}
              rowData={editRowData}
            />
          </Container>
        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress,
    error: getReferencesError
  } = state.getProductReferences.toJS();

  const {
    result: addResult
  } = state.addProductReference.toJS();

  const {
    result: editResult
  } = state.editProductReference.toJS();

  const {
    result: deleteResult,
    inProgress: deleteInProgress,
    error: deleteError
  } = state.deleteProductReference.toJS();

  return {
    result,
    inProgress,
    error: getReferencesError || deleteError,
    addResult,
    editResult,
    deleteResult,
    deleteInProgress,
    deleteError
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getProductReferences: fetchDux.getProductReferences.createAction,
      clearAddProductReference: fetchDux.addProductReference.clearAction,
      clearEditProductReference: fetchDux.editProductReference.clearAction,
      clearProductReferences: fetchDux.getProductReferences.clearAction,
      getProductMiscLookups: fetchDux.getProductMiscLookups.createAction,
      deleteProductReference: fetchDux.deleteProductReference.createAction,
      clearDeleteProductReference: fetchDux.deleteProductReference.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(References);
