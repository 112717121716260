import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import queryString from 'query-string';
import { connect } from 'react-redux';
import fetchDux from '../../../../state/fetch-dux';
import { bindActionCreators } from 'redux';
import Loading from '../../../ui/Loading';
import LogsSearchResults from './LogsSearchResults';
import getQueryFromSearch from '../../../paginatiion/getQueryFromSearch';
import formatQuery from '../../../paginatiion/formatQuery';

class LogsSearch extends Component {

  constructor(props) {
    super(props);
    const { history, location: { pathname, search } } = this.props;
    
    this.state = {
      prevSearch: search
    };

    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);
    history.push({ pathname, search: qs });

    this.handleFetchData = this.handleFetchData.bind(this);
    this.handleSearchOrders = this.handleSearchOrders.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  componentDidMount() {
    const { location: { search }, getAllLogs } = this.props;
    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);
    getAllLogs(qs);
  }

  // componentWillReceiveProps(nextProps) {
  //   const { location: { search } } = nextProps;
  //   if (this.props.location.search !== search) {
  //     this.callSearch(search);
  //   }
  // }

  handleFetchData(state) {
    const {
      location: { search }
    } = this.props;
    const query = getQueryFromSearch(search);
    const {
      page,
      pageSize,
      sorted
    } = state || {};

    const {
      id,
      desc
    } = (sorted || [])[0] || {};

    this.handleSearchOrders({
      page,
      pageSize,
      sortBy: id,
      desc,
      search: query.search
    });
  }

  callSearch(search) {
    const {
      getAllLogs,
      location: { pathname },
      history
    } = this.props;
    history.push({ pathname, search });
    getAllLogs(search, null, true);
  }

  handleSearchOrders(values) {
    const { history, location: { pathname } } = this.props;

    const query = formatQuery(values);
    const qs = queryString.stringify(query);
    history.push({ pathname, search: qs });
  }

  handleSearchChange(value) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    this.handleSearchOrders(
      Object.assign({}, query, { search: value, page: '0' })
    );
  }

  static getDerivedStateFromProps(nextProps, currentState) {
    const { location: { search } } = nextProps;
    if (search !== currentState.prevSearch) {
      const qs = getQueryFromSearch(search);
      const query = queryString.stringify(qs);
      nextProps.getAllLogs(query);
      return {
        prevSearch: search
      };
    } else {
      return null;
    }
  }

  render() {
    const { 
      result,
      inProgress,
      error,
      location: { search },
    } = this.props;

    const paging = getQueryFromSearch(search);
    
    const loadingComp = () => {
      return inProgress
        ? <div className="-loading -active loading-table">
          <Loading fullpage message="Loading Logs..." />
        </div>
        : null;
    };

    return (
      <Layout route="logs-search">
        <main>

          <div className="layout-header">
            <Container>

              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <Icon name="home" />
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  Logs
              </li>
              </ol>

              <div className="header">
                <h1>
                  <Icon name="file-alt" /> Logs
              </h1>
              </div>

            </Container>
          </div>

          <Container>
            <LogsSearchResults 
              result={result}
              paging={paging}
              error={error}
              onFetchData={this.handleFetchData}
              handleSearchChange={this.handleSearchChange}
              inProgress={false}
              loadingComp={loadingComp}
            />
          </Container>

        </main>
      </Layout>
    );
  };
}
const mapState = state => {
  const {
    result,
    inProgress,
    error
  }
    = state.getAllLogs.toJS();
  return {
    result,
    inProgress,
    error
  }
}

const mapDispatch = dispatch => bindActionCreators({
  getAllLogs: fetchDux.getAllLogs.createAction
}, dispatch)

export default connect(mapState, mapDispatch)(LogsSearch);
