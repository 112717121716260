import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import FormField from '../../../forms/FormField';

const required = value => value ? null : 'Required';
const minLength8 = value =>
  (value || '').length >= 8 || !value
    ? null
    : `GTIN must be a minimum 8 digits`;

const checkRegex = value => {
  if (value && value.trim().length > 0) {
    const checks = [
      /^\d{4}$/,
      /^\d{4}\.\d{2}$/,
      /^\d{4}\.\d{2}\.\d{2}$/,
      /^\d{4}\.\d{2}.\d{4}$/
    ].map(expression => {
      const regExp = new RegExp(expression);
      return regExp.test(value);
    });
    if (checks.includes(true)) {
      return undefined;
    }
    return 'Invalid HTS pattern';
  }
  return undefined;
};

const ProductDetails = ({ lookupsResult, surchargeDetails }) => {
  const {
    UOM,
    YesNo,
    CtryCode,
    SurchargeType: surchargeType,
    SurchargeBasis: surchargeBasis
  } = lookupsResult || {};
  const unitOfMeasurementOptions = (UOM || [])
    .map(({ value, enumKey }) => ({ value: enumKey, label: value }));
  const yesNoOptions = (YesNo || [])
    .map(({ enumKey }) => ({ value: enumKey, label: enumKey }));
  const countryCodeOptions = (CtryCode || [])
    .map(({ value, enumKey }) => ({ label: value, value: enumKey }));
  const surchargeTypeOptions = (surchargeType || [])
    .map(({ enumKey }) => ({ label: enumKey, value: enumKey }));
  const surchargeBaseOptions = (surchargeBasis || [])
    .map(({ enumKey }) => ({ label: enumKey, value: enumKey }));
  const {
    SurchargeType,
    SurchargeBasis,
    SurchargeValue,
    SurchargeInclMap
  } = surchargeDetails;

  const surchargeTypeRequired = SurchargeBasis ||
    SurchargeValue ||
    SurchargeInclMap
    ? true
    : false;
  const surchargeBasisRequired = SurchargeType ||
    SurchargeValue ||
    SurchargeInclMap
    ? true
    : false;
  const surchargeValueRequired = SurchargeType ||
    SurchargeBasis ||
    SurchargeInclMap
    ? true
    : false;
  const surchargeInclMapRequired = SurchargeType ||
    SurchargeBasis ||
    SurchargeValue
    ? true
    : false;
  return (
    <Col sm={12}>
      <div className="box">
        <div className="box-header">
          <h3>Product Details</h3>
        </div>
        <div className="box-body">
          <Row>
            <Col sm={6}>
              <Field
                name="WtUOM"
                label="Weight Unit of Measurement"
                type="text"
                component={FormField.SelectReact}
                options={unitOfMeasurementOptions}
                isClearable
              />
            </Col>

            <Col sm={6}>
              <Field
                name="ProdWt"
                label="Product Weight"
                type="text"
                component={FormField.Input}
                normalize={(value, previousValue) => {
                  if (value && value.trim().length > 0) {
                    const regExp = /^(\d+)?([.]?\d{0,2})?$/;
                    if (regExp.test(value)) {
                      return value;
                    }
                    return previousValue;
                  }
                  return value;
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Field
                name="SlsClassCode"
                label="Sls Class Code"
                type="text"
                component={FormField.Input}
                maxLength={20}
              />
            </Col>
            <Col sm={6}>
              <Field
                name="GTIN"
                label="GTIN"
                type="text"
                component={FormField.Input}
                maxLength={13}
                validate={[minLength8]}
                normalize={(value, previousValue) => {
                  if (value && value.trim().length > 0) {
                    const regExp = /^\d+$/;
                    if (regExp.test(value)) {
                      return value;
                    }
                    return previousValue;
                  }
                  return value;
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <Field
                name="CfgfItemFlg"
                label="Cfgf Item Flag"
                type="text"
                component={FormField.SelectReact}
                options={yesNoOptions}
                isClearable
              />
            </Col>

            <Col sm={6}>
              <Field
                name="COO"
                label="Country Code"
                type="text"
                component={FormField.SelectReact}
                options={countryCodeOptions}
                isClearable
              />
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <Field
                name="HTSCode"
                label="HTS Code"
                type="text"
                component={FormField.Input}
                maxLength={12}
                validate={[checkRegex]}
              />
            </Col>

            <Col sm={6}>
              <Field
                name="Deleted"
                label="Deleted"
                type="text"
                component={FormField.SelectReact}
                options={yesNoOptions}
                isClearable
              />
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <Field
                name="SurchargeType"
                label={
                  surchargeTypeRequired ? 'Surcharge Type *' : 'Surcharge Type'
                }
                type="text"
                component={FormField.SelectReact}
                options={surchargeTypeOptions}
                validate={surchargeTypeRequired ? [required] : []}
                isClearable
              />
            </Col>

            <Col sm={6}>
              <Field
                name="SurchargeBasis"
                label={
                  surchargeBasisRequired
                    ? 'Surcharge Basis *'
                    : 'Surcharge Basis'
                }
                type="text"
                component={FormField.SelectReact}
                options={surchargeBaseOptions}
                validate={surchargeBasisRequired ? [required] : []}
                isClearable
              />
            </Col>

          </Row>

          <Row>
            <Col sm={6}>
              <Field
                name="SurchargeValue"
                label={
                  surchargeValueRequired
                    ? 'Surcharge Value *'
                    : 'Surcharge Value'
                }
                type="text"
                component={FormField.Input}
                validate={surchargeValueRequired ? [required] : []}
                normalize={(value, previousValue) => {
                  if (value && value.trim().length > 0) {
                    const regExp = /^(\d+)?([.]?\d{0,2})?$/;
                    if (regExp.test(value)) {
                      return value;
                    }
                    return previousValue;
                  }
                  return value;
                }}
              />
            </Col>

            <Col sm={6}>
              <Field
                name="SurchargeInclMAP"
                label={
                  surchargeInclMapRequired
                    ? 'Surcharge Incl Map *'
                    : 'Surcharge Incl Map'
                }
                type="text"
                component={FormField.SelectReact}
                options={yesNoOptions}
                validate={surchargeInclMapRequired ? [required] : []}
                isClearable
              />
            </Col>
          </Row>
        </div>
      </div>
    </Col>
  );
};

export default ProductDetails;
