import React from 'react';
import { Link } from 'react-router-dom';
import Table from '../../../ui/Table';
import Loading from '../../../ui/Loading';

const columns = [
  {
    Header: 'IMIS ID',
    accessor: 'imisId',
    Cell: ({ value, original: row }) => (
      <Link to={`/manufacturers/${row._id}`}>{value}</Link>
    )
  },
  {
    Header: 'Manufacturer Short',
    accessor: 'shortKey'
  },
  {
    Header: 'Manufacturer Name',
    accessor: 'name'
  },
  {
    Header: 'Users',
    accessor: 'numUsers',
    Cell: ({ value }) => {
      return (
        <span>
          {value}
        </span>
      );
    }
  },
  {
    Header: 'Child Companies',
    accessor: 'childMfrCount',
    Cell: ({ value }) => {
      return (
        <span>
          {value}
        </span>
      );
    }
  }
];

const ManufacturersSearchResults = (
  {
    manufacturers,
    history
  }
) => {
  const {
    items
  } = manufacturers || {};
  return (
    <Table
      showSearch
      data={items || []}
      columns={columns}
      noDataText={
        !items
          ? <Loading message="Loading Manufacturers..." />
          : 'No Users Found'
      }
      getTrProps={(c, value) => {
        const { original } = value || {};
        if (!original) return {};
        const { _id } = original || {};
        return {
          style: {
            cursor: 'pointer'
          },
          onClick: () => {
            history.push(`/manufacturers/${_id}`);
          }
        };
      }}
    />
  );
};

export default ManufacturersSearchResults;
