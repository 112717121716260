import React, { Component } from 'react';
import { Container, Button, Tabs, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../../../ui/Layout';
import Icon from '../../../../ui/Icon';
import Table from '../../../../ui/Table';
import { connect } from 'react-redux';
import fetchDux from '../../../../../state/fetch-dux';
import ModalAddFAQsGroup from './ModalAddFAQsGroup';
import ModalEditFAQsGroup from './ModalEditFAQsGroup';
import ModalDeleteFAQsGroup from './ModalDeleteFAQsGroup';
import ModalDeleteFAQ from './ModalDeleteFAQ';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../../state/modals-dux';
import moment from 'moment';

const getFAQGroupColumns = showModal => {
  const columnsGroups = [
    {
      Header: 'Name',
      accessor: 'groupName'
    },
    {
      Header: 'Articles',
      accessor: 'articlesCount',
      className: 'text-center'
    },
    {
      Header: 'Distirbutors',
      accessor: 'viewOptionsDistributors',
      className: 'text-center',
      Cell: ({ value }) => {
        return (
          <div>
            {value === true
              ? <Icon className="text-success" name="check" />
              : <Icon className="text-danger" name="times" />}
          </div>
        );
      }
    },
    {
      Header: 'Manufacturers',
      accessor: 'viewOptionsManufacturers',
      className: 'text-center',
      Cell: ({ value }) => {
        return (
          <div>
            {value === true
              ? <Icon className="text-success" name="check" />
              : <Icon className="text-danger" name="times" />}
          </div>
        );
      }
    },
    {
      Header: '',
      accessor: '_id',
      Cell: ({ value, original: row }) => {
        return (
          <div className="text-right">
            <Button
              size="sm"
              variant="primary"
              onClick={() =>
                showModal({ name: 'EditFAQsGroup', faqGroup: row })}
            >
              Edit
            </Button>
            {' '}
            <Button
              size="sm"
              variant="danger"
              onClick={() =>
                showModal({
                  name: 'DeleteFAQsGroup',
                  faqGroupId: row._id,
                  faqGroupName: row.groupName
                })}
            >
              Delete
            </Button>
          </div>
        );
      }
    }
  ];
  return columnsGroups;
};

const getFAQColumns = showModal => {
  const columnsFAQs = [
    {
      Header: 'Question',
      accessor: 'question'
    },
    {
      Header: 'Group',
      accessor: 'groupName'
    },
    {
      Header: 'Published',
      accessor: 'isPublished',
      className: 'text-center',
      Cell: ({ value }) => {
        return (
          <div>
            {value === true
              ? <Icon className="text-success" name="check" />
              : <Icon className="text-danger" name="times" />}
          </div>
        );
      }
    },
    {
      Header: 'Last Updated',
      accessor: 'dateLastUpdated',
      className: 'text-center',
      Cell: ({ value }) => {
        return <span>{moment(value).format('MM/DD/YYYY')}</span>;
      }
    },
    {
      Header: '',
      accessor: '_id',
      Cell: ({ value, original: row }) => {
        return (
          <div className="text-right">
            <Link
              className="btn btn-sm btn-primary"
              to={`/admin/faqs/${row._id}/edit`}
            >
              Edit
            </Link>
            {' '}
            <Button
              size="sm"
              variant="danger"
              onClick={() =>
                showModal({
                  name: 'DeleteFAQ',
                  faqId: row._id,
                  faqName: row.question
                })}
            >
              Delete
            </Button>
          </div>
        );
      }
    }
  ];

  return columnsFAQs;
};

class FAQsSearch extends Component {
  componentDidMount() {
    this.props.getAllFAQs();
  }
  render() {
    const {
      showModal,
      createFAQGroup,
      closeModal,
      result,
      deleteFAQArticle,
      getAllFAQs
    } = this.props;
    const { articles, groups } = result || {};
    return (
      <Layout route="faqs-search">
        <main>

          <div className="layout-header">
            <Container>

              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <Icon name="home" />
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  Frequently Asked Questions
                </li>
              </ol>

              <div className="header">
                <h1>
                  <Icon name="comments" /> Frequently Asked Questions
                </h1>
                <div>
                  <Button
                    className="btn btn-secondary"
                    onClick={() => showModal('AddFAQsGroup')}
                  >
                    Add Group
                  </Button>
                  <Link to={`/admin/faqs/create`} className="btn btn-secondary">
                    Add Article
                  </Link>
                </div>
              </div>

            </Container>
          </div>

          <Container>
            <Tabs defaultActiveKey="groups" className="box-tabs">
              <Tab eventKey="groups" title="Groups">

                <Table
                  showSearch
                  data={groups}
                  columns={getFAQGroupColumns(showModal)}
                  noDataText="No FAQ groups found"
                />

              </Tab>
              <Tab eventKey="articles" title="Articles">

                <Table
                  showSearch
                  data={articles}
                  columns={getFAQColumns(showModal)}
                  noDataText="No FAQ found"
                />

              </Tab>
            </Tabs>
          </Container>

          <ModalAddFAQsGroup
            name="AddFAQsGroup"
            handleSubmit={values => {
              createFAQGroup(values, 'faq-group-create-form', null, () => {
                closeModal('AddFAQsGroup');
                getAllFAQs();
              });
            }}
          />
          <ModalEditFAQsGroup name="EditFAQsGroup" />
          <ModalDeleteFAQsGroup name="DeleteFAQsGroup" />
          <ModalDeleteFAQ
            name="DeleteFAQ"
            deleteFAQArticle={deleteFAQArticle}
          />

        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  const { result } = state.getAllFAQs.toJS();
  return {
    result
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getAllFAQs: fetchDux.getAllFAQs.createAction,
      createFAQGroup: fetchDux.createFAQGroup.createAction,
      deleteFAQArticle: fetchDux.deleteFAQArticle.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(FAQsSearch);
