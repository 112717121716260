import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../../state/fetch-dux';
import { actionCreators } from '../../../../state/modals-dux';
import UploadProductsForm from './UploadProductsForm';

class ModalUploadProducts extends Component {
  constructor(props) {
    super(props);
    this.state = { closing: false };
  }

  cleanUp = () => {
    const {
      clearGetProductsUploadStatus,
      clearUploadManufacturerProducts
    } = this.props;
    this.setState(
      {
        uploadInProgress: false,
        uploadMessage: null,
        completed: false,
        failedProducts: null,
        successProducts: null,
        totalProducts: -1,
        jobId: null,
        finshedInsertOrUpdate: false,
        failed: false,
        errorMessage: null,
        updatedCount: 0,
        productsNotFound: 0,
        isValidateOnly: false
      },
      () => {
        clearGetProductsUploadStatus();
        clearUploadManufacturerProducts();
      }
    );
  };

  componentDidUpdate = () => {
    const {
      uploadJobResult,
      getProductsUploadStatus,
      uploadStatusResult,
      uploadError,
      uploadStatusError,
      clearGetProductsUploadStatus
    } = this.props;
    const { jobId } = uploadJobResult || {};
    if (jobId) {
      const {
        status = [],
        completed,
        successProducts,
        failedProducts,
        totalProducts,
        finshedInsertOrUpdate,
        failed,
        errorMessage,
        isValidateOnly,
        updatedCount,
        productsNotFound
      } = uploadStatusResult || {};
      if (!completed && !this.state.startedTimeout && !failed) {
        this.setState({
          startedTimeout: true,
          jobId
        });
        const initGetProductsUploadStatus = () => {
          getProductsUploadStatus(jobId, null, null, ({
            completed,
            failed
          }) => {
            if (!completed && !failed) {
              window.setTimeout(
                () => {
                  initGetProductsUploadStatus();
                },
                1 * 1000
              );
            } else {
              this.setState({ startedTimeout: false, inProgress: false });
            }
          });
        };
        initGetProductsUploadStatus();
      } else {
        if (uploadStatusError) {
          clearGetProductsUploadStatus();
          window.setTimeout(
            () => {
              this.setState({
                startedTimeout: false
              });
            },
            1 * 2500
          );
        }
      }
      const recentUpdate = status[status.length - 1];
      if (recentUpdate) {
        const { status } = recentUpdate;
        if (status !== this.state.uploadMessage) {
          this.setState({
            uploadInProgress: completed ? false : true,
            uploadMessage: status,
            completed,
            failedProducts,
            successProducts,
            totalProducts,
            finshedInsertOrUpdate,
            failed,
            errorMessage,
            isValidateOnly,
            updatedCount,
            productsNotFound
          });
        }
      }
    } else {
      if (uploadError && !this.state.failed) {
        const { message } = uploadError;
        this.setState({
          failed: true,
          errorMessage: message
        });
      } else {
        if (this.state.uploadMessage) {
          this.setState({
            uploadInProgress: false,
            uploadMessage: null,
            completed: false,
            failedProducts: null,
            successProducts: null,
            totalProducts: -1,
            jobId: null,
            finshedInsertOrUpdate: false,
            failed: false,
            errorMessage: null,
            updatedCount: 0,
            productsNotFound: 0,
            isValidateOnly: false
          });
        }
      }
    }
  };

  handleProductsSave = values => {
    const {
      uploadManufacturerProducts,
      clearGetProductsUploadStatus,
      clearUploadManufacturerProducts
    } = this.props;
    const {
      file,
      saveProducts,
      updatePriceFieldsOnly,
      updateAssetFieldsOnly
    } = values;
    if (this.state.jobId) {
      const payload = {
        saveProducts: true,
        jobId: this.state.jobId,
        updatePriceFieldsOnly,
        updateAssetFieldsOnly
      };
      this.setState({ inProgress: true });
      clearGetProductsUploadStatus();
      clearUploadManufacturerProducts();
      uploadManufacturerProducts(payload);
    } else {
      const payload = {
        uploadFile: file,
        saveProducts: saveProducts,
        updatePriceFieldsOnly,
        updateAssetFieldsOnly
      };
      this.setState({ inProgress: true, isValidateOnly: false });
      uploadManufacturerProducts(payload);
    }
  };

  render() {
    const {
      openModal,
      closeModal,
      clearGetProductsUploadStatus,
      clearUploadManufacturerProducts,
      uploadJobResult,
      uploadError,
      uploadStatusResult
    } = this.props;

    const { jobId } = uploadJobResult || {};
    const { errorMessage } = uploadStatusResult || {};

    const handleCancelImport = () => {
      clearUploadManufacturerProducts();
      this.setState({
        failed: false,
        errorMessage: null,
        inProgress: false,
        uploadMessage: null
      });
      clearGetProductsUploadStatus();
    };

    // const handleProductsUpload = payload => {
    //   uploadManufacturerProducts(payload);
    // };

    return (
      <Modal
        show={openModal === 'UploadProductsModal'}
        className="modal-success modal-upload-products"
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Upload Products</Modal.Title>
        </Modal.Header>
        <UploadProductsForm
          onSubmit={this.handleProductsSave}
          closeModal={closeModal}
          initialValues={{ downloadedExceptions: false }}
          uploadInProgress={this.state.uploadInProgress}
          completed={this.state.completed}
          uploadMessage={this.state.uploadMessage}
          //  submitting={postSaveProductsInProgress}
          handleCancelImport={handleCancelImport}
          successProducts={this.state.successProducts}
          failedProducts={this.state.failedProducts}
          totalProducts={this.state.totalProducts}
          finshedInsertOrUpdate={this.state.finshedInsertOrUpdate}
          inProgress={this.state.inProgress}
          failed={this.state.failed}
          errorMessage={this.state.errorMessage}
          isValidateOnly={this.state.isValidateOnly}
          jobId={jobId}
          uploadError={uploadError}
          fileValidationFailed={errorMessage}
          updatedCount={this.state.updatedCount}
          productsNotFound={this.state.productsNotFound}
          cleanUp={this.cleanUp}
        />
      </Modal>
    );
  }
}

const mapState = state => {
  const {
    result: uploadJobResult,
    error: uploadError
  } = state.uploadManufacturerProducts.toJS() || {};
  const {
    result: uploadStatusResult,
    error: uploadStatusError
  } = state.getProductsUploadStatus.toJS() || {};

  return {
    openModal: state.modals,
    uploadJobResult,
    uploadStatusResult,
    uploadError,
    uploadStatusError
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      uploadManufacturerProducts: fetchDux.uploadManufacturerProducts.createAction,
      getProductsUploadStatus: fetchDux.getProductsUploadStatus.createAction,
      clearGetProductsUploadStatus: fetchDux.getProductsUploadStatus.clearAction,
      clearUploadManufacturerProducts: fetchDux.uploadManufacturerProducts.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ModalUploadProducts);
