import React from 'react';
import { Col } from 'react-bootstrap';

const ProductInfo = ({ ProdCode, MfrName, CatEnum, COO, Stocked, lastUpdatedDate, PriceLastUpdatedDatetime }) => {

    return (
        <Col md={6}>
            <div className="box">
                <div className="box-header">
                    <h3>Product</h3>
                </div>
                <div className="box-body">

                    <table className="table table-data">
                        <tbody>
                            <tr>
                                <th>Product Code</th>
                                <td>{ProdCode}</td>
                            </tr>
                            <tr>
                                <th>Manufacturer</th>
                                <td>{MfrName}</td>
                                <td />
                            </tr>
                            <tr>
                                <th>Category</th>
                                <td>{CatEnum}</td>
                            </tr>
                            <tr>
                                <th>Country of origin</th>
                                <td>{COO}</td>
                            </tr>
                            <tr>
                                <th>Stocked</th>
                                <td>{Stocked}</td>
                            </tr>
                            <tr>
                                <th>Last Updated Date</th>
                                <td>{lastUpdatedDate}</td>
                            </tr>
                            <tr>
                                <th>Price Updated Date</th>
                                <td>{PriceLastUpdatedDatetime}</td>
                            </tr>
                            <tr>
                                <th><br></br></th>
                                <td>{' '}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>

        </Col>
    )
}

export default ProductInfo;