import React from 'react';
import { Col } from 'react-bootstrap';
import Table from '../../../ui/Table';
import moment from 'moment-timezone';

const getColumns = selectedReportType => {
  if (selectedReportType === 'Manufacturers') {
    return [
      {
        Header: 'Date',
        accessor: 'date',
        className: 'text-center',
        Cell: ({ value }) => (
          <span>
            {value
              ? moment(value).tz('America/Chicago').format('DD MMM YYYY LT')
              : '-'}
          </span>
        )
      },
      {
        Header: 'Total Manufacturers',
        accessor: 'metrics.totalManufacturersCount',
        className: 'text-center'
      },
      {
        Header: 'Registered Companies',
        accessor: 'metrics.registeredCompanies',
        className: 'text-center'
      },
      {
        Header: 'Uploaded Companies',
        accessor: 'metrics.uploadedCompanies',
        className: 'text-center'
      },
      {
        Header: 'Login Companies',
        accessor: 'metrics.loginCompanies',
        className: 'text-center'
      },
      {
        Header: 'Active Companies',
        accessor: 'metrics.activeCompanies',
        className: 'text-center'
      }
    ];
  }

  if (selectedReportType === 'Distributors') {
    return [
      {
        Header: 'Date',
        accessor: 'date',
        className: 'text-center',
        Cell: ({ value }) => (
          <span>
            {value
              ? moment(value).tz('America/Chicago').format('DD MMM YYYY LT')
              : '-'}
          </span>
        )
      },
      {
        Header: 'Total Distributors',
        accessor: 'metrics.totalDistributorsCount',
        className: 'text-center'
      },
      {
        Header: 'Registered Companies',
        accessor: 'metrics.registeredCompanies',
        className: 'text-center'
      },
      {
        Header: 'Login Companies',
        accessor: 'metrics.loginCompanies',
        className: 'text-center'
      },
      {
        Header: 'Active Companies',
        accessor: 'metrics.activeCompanies',
        className: 'text-center'
      }
    ];
  }

  if (selectedReportType === 'Products') {
    return [
      {
        Header: 'Date',
        accessor: 'date',
        className: 'text-center',
        Cell: ({ value }) => (
          <span>
            {value
              ? moment(value).tz('America/Chicago').format('DD MMM YYYY LT')
              : '-'}
          </span>
        )
      },
      {
        Header: 'Total Products',
        accessor: 'metrics.totalProductsCount',
        className: 'text-center'
      },

      {
        Header: 'Active Products',
        accessor: 'metrics.activeProductsCount',
        className: 'text-center'
      }
    ];
  }

  if (selectedReportType === 'DataQuality') {
    return [
      {
        Header: 'Date',
        accessor: 'date',
        className: 'text-center',
        Cell: ({ value }) => (
          <span>
            {value
              ? moment(value).tz('America/Chicago').format('DD MMM YYYY LT')
              : '-'}
          </span>
        )
      },
      {
        Header: 'Duplicate Products',
        accessor: 'metrics.duplicateProducts',
        className: 'text-center'
      },

      {
        Header: 'Products Without LastUpdatedDate',
        accessor: 'metrics.productsWithoutLastUpdatedDate',
        className: 'text-center'
      }
    ];
  }
};

const MetricsResults = props => {
  const {
    result,
    selectedReportType,
    defaultPageSize,
    inProgress,
    handleFetchData
  } = props;
  const { results, count, numPages } = result || {};
  return (
    <Col sm={12} style={{ marginTop: '1rem' }}>
      <div className="table-products">
        <Table
          manual
          data={results}
          columns={getColumns(selectedReportType)}
          key={selectedReportType}
          defaultPageSize={defaultPageSize}
          showPagination={true}
          noDataText={inProgress ? 'Loading' : 'No data found'}
          pages={numPages || 0}
          defaultSorted={selectedReportType === 'Products' || selectedReportType === 'DataQuality' ?
            [
              {
                id: 'date',
                desc: true
              }
            ]
            :
            [
              {
                id: 'Name',
                desc: false
              }
            ]}
          onFetchData={handleFetchData}
        />
      </div>
    </Col>
  );
};

export default MetricsResults;
