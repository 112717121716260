import React, { Component, Fragment } from 'react';
import { Button, Col, FormControl, Row } from 'react-bootstrap';
import Icon from '../../ui/Icon';
import Loading from '../../ui/Loading';
import Table from '../../ui/Table';

const getColumns = (
  {
    editClickHandler,
    selectedLookup,
    deleteClickHandler,
    deleteProductReference,
    deleteInProgress
  }
) => {
  const columns = [
    {
      Header: 'Name',
      accessor: 'value',
      className: 'text-center'
    },
    {
      Header: 'Enumeration',
      accessor: 'enumKey',
      className: 'text-center'
    },
    {
      Header: 'Actions',
      width: 300,
      Cell: ({ original }) => {
        const { products = 0, initDelete } = original;
        return initDelete
          ? <div className="actions">
              {deleteInProgress
                ? <Loading xsmall />
                : <Fragment>
                    <span style={{ display: 'inline-block' }}>
                      Are you sure you want to delete?
                    </span>
                    <div
                      className="iconDiv"
                      onClick={() =>
                        deleteProductReference(
                          { ...original, lookup: selectedLookup },
                          null,
                          null,
                          result => {
                            const { ok } = result;
                            if (ok) deleteClickHandler({ _id: null });
                          }
                        )}
                      disabled={deleteInProgress}
                    >
                      <Icon name="check" className="positive" />
                    </div>
                    <div
                      className="iconDiv"
                      onClick={() => deleteClickHandler({ _id: null })}
                      disabled={deleteInProgress}
                    >
                      <Icon name="times" />
                    </div>
                  </Fragment>}
            </div>
          : <Fragment>
              <div
                className="actions"
                style={{ pointerEvents: products === 0 ? 'auto' : 'none' }}
              >
                <div
                  className="iconDiv"
                  onClick={() => editClickHandler(original)}
                  disabled={products > 0 || deleteInProgress}
                >
                  <Icon name={'edit'} />

                </div>

                <div
                  className="iconDiv"
                  disabled={products > 0 || deleteInProgress}
                  style={{ pointerEvents: products === 0 ? 'auto' : 'none' }}
                  onClick={() => deleteClickHandler(original)}
                >
                  <Icon name={'trash'} className="delete" />

                </div>
                {products > 0 &&
                  <span>
                    {`${products} product(s)`}
                  </span>}
              </div>
            </Fragment>;
      }
    }
  ];

  if (selectedLookup === 'featureEnums') {
    columns.splice(2, 0, {
      Header: 'Type',
      accessor: 'dataType',
      className: 'text-center',
      sortable: false
    });
    columns.splice(3, 0, {
      Header: 'Reference',
      accessor: 'ref',
      className: 'text-center',
      sortable: false,
      Cell: ({ value }) => (
        <span>
          {value && value.trim().length > 0 ? value : 'NA'}
        </span>
      )
    });
  }
  return columns;
};

class ReferencesResult extends Component {
  constructor(props) {
    super(props);
    this.state = { initDeleteId: null };
    this.timeoutId = null;
  }

  getFilteredData = () => {
    const { result } = this.props;
    const { results } = result || {};
    const { initDeleteId } = this.state;
    if (!results) return;
    const filteredResults = (results || []).map(row => {
      const { _id } = row;
      return _id === initDeleteId
        ? { ...row, initDelete: true }
        : { ...row, initDelete: false };
    });
    return filteredResults;
  };

  initDelete = ({ _id }) => {
    this.setState({ initDeleteId: _id });
  };

  static getDerivedStateFromProps(nextProps, currentState) {
    const { selectedLookup } = nextProps;
    const { selectedLookup: stateSelectedLookUp } = currentState;
    if (selectedLookup !== stateSelectedLookUp) {
      return {
        search: null,
        selectedLookup
      };
    }
    return null;
  }

  searchChangeHandler = () => {
    const { search } = this.state;
    const { searchChangeHandler } = this.props;
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
    this.timeoutId = setTimeout(
      () => {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
        searchChangeHandler(search);
      },
      500
    );
  };

  render() {
    const {
      result,
      defaultPageSize,
      handleFetchData,
      inProgress,
      showModal,
      selectedLookup,
      editClickHandler,
      deleteProductReference,
      deleteInProgress,
      querySearch
    } = this.props;
    const { numPages } = result || {};
    const { search } = this.state;
    const results = this.getFilteredData();
    const searchValue = search || querySearch;
    return (
      <Fragment>
        <Col sm={12}>
          <Row style={{ justifyContent: 'space-between' }}>
            <Col sm={4}>
              <div className="form-group">
                <FormControl
                  type="text"
                  placeholder="Search these results.."
                  onChange={event =>
                    this.setState({ search: event.target.value }, () => {
                      this.searchChangeHandler();
                    })}
                  value={searchValue || ''}
                />
              </div>
            </Col>
            <Col sm={4}>
              <Button
                variant="primary"
                size="small"
                style={{ float: 'right', textTransform: 'none' }}
                disabled={inProgress}
                onClick={() => showModal('AddReferenceModal')}
              >
                Create Record
              </Button>
            </Col>
          </Row>
        </Col>
        <Col sm={12}>
          <div className="table-products">
            <Table
              manual
              key={selectedLookup === 'featureEnums' ? 0 : 1}
              columns={getColumns({
                editClickHandler,
                selectedLookup,
                deleteClickHandler: this.initDelete,
                deleteProductReference,
                deleteInProgress
              })}
              data={results}
              defaultPageSize={defaultPageSize}
              showPagination={true}
              onFetchData={handleFetchData}
              noDataText={inProgress ? 'Loading' : 'No data found'}
              pages={numPages || 0}
              defaultSorted={[
                {
                  id: 'value',
                  desc: false
                }
              ]}
            />
          </div>
        </Col>
      </Fragment>
    );
  }
}

export default ReferencesResult;
