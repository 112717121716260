import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Button, Row, Col } from 'react-bootstrap';

const required = value => value ? undefined : 'Required';

const ManufacturersEditForm = props => {
  const { handleSubmit, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col md={4}>
          <div className="box">
            <div className="box-body">

              <Field
                name="imisId"
                type="text"
                component={FormField.Input}
                label="IMIS ID"
                validate={[required]}
              />

              <Field
                name="name"
                type="text"
                component={FormField.Input}
                label="Manufacturer Name"
                validate={[required]}
              />

            </div>
          </div>
        </Col>

        <Col md={4}>
          <div className='box'>
            <div className='box-body'>
              <Field
                name="enableManualProducts"
                type="checkbox"
                component={FormField.InputToggle}
                label="Enable Product Editing"
              />
            </div>
          </div>
        </Col>
      </Row>

      <Button variant="primary" type="submit" disabled={submitting}>
        Save Changes
      </Button>

    </form>
  );
};

export default reduxForm({
  form: 'manufacturers-edit-form',
  enableReinitialize: true
})(ManufacturersEditForm);
