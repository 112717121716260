import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Alert, Button } from 'react-bootstrap';
import fetchDux from '../../../state/fetch-dux';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

const required = value => value ? undefined : 'Required';

const ActAsForm = (props) => {
    useEffect(() => {
        const { getAdminLookups } = props;
        getAdminLookups();
    }, []);
    const { handleSubmit, loading, actAs, result, impersonateError } = props;
    const { manufacturers, distributors } = result || {};
    const mfrOptions = (manufacturers || []).map(({ _id, name }) => ({ label: name, value: _id }));
    const distOptions = (distributors || []).map(({ _id, name }) => ({ label: name, value: _id }))
    return (
        <form onSubmit={handleSubmit}>
            <Modal.Body>
                {impersonateError && <Alert variant='danger'>{impersonateError.message || ''}</Alert>}
                <Field
                    name='actAs'
                    label='Impersonate As'
                    component={FormField.SelectReact}
                    options={
                        [
                            {
                                value: 'distributor',
                                label: 'Distributor'
                            },
                            {
                                value: 'manufacturer',
                                label: 'Manufacturer'
                            }
                        ]
                    }
                    validate={[required]}
                />

                <Field
                    name='selectedAccount'
                    label={`Select ${actAs || ''}`}
                    component={FormField.SelectReact}
                    validate={[required]}
                    options={actAs === 'manufacturer' ? mfrOptions : distOptions}
                />
            </Modal.Body>

            <Modal.Footer style={{ justifyContent: 'flex-end' }}>
                <Button variant="primary" type="submit" disabled={loading}>
                    Continue
                </Button>
            </Modal.Footer>
        </form>
    );

}

const selector = formValueSelector('actAs-form');

const mapState = (state) => {
    const actAs = selector(state, 'actAs');
    const {
        result
    } = state.getAdminLookups.toJS();

    const {
        error
    } = state.postAdminImpersonate.toJS();
    return {
        actAs,
        result,
        impersonateError: error
    }
}

const mapDispatch = dispatch =>
    bindActionCreators({
        getAdminLookups: fetchDux.getAdminLookups.createAction
    }, dispatch)


const form = reduxForm({
    form: 'actAs-form',
    enableReinitialize: true
})(ActAsForm);

export default connect(mapState, mapDispatch)(form);