import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import { Row, Col, Alert, Container } from 'react-bootstrap';

import Layout from '../../ui/Layout';
import ResetPasswordForm from './ResetPasswordForm';
const { location } = window || {};

class ResetPassword extends Component {
  componentWillMount() {
    //for Token Validation while rendering page
    var token = this.props.location.pathname.split('/')[2];
    this.props.validateToken(token);
  }
  render() {
    const {
      user,
      error,
      inProgress
    } = this.props;
    let id = '';
    let email = '';
    let tokenid = '';
    if (user !== null && user !== undefined) {
      id = user.id;
      email = user.email;
      tokenid = user.tokenid;
    }

    const postResetPassword = values => {
      values.id = id;
      values.email = email;
      values.tokenid = tokenid;
      if (!values.tokenid) {
        return;
      }
      // Api Call for Validating token and saving password after clicking on submit
      this.props.validateTokenAndSavePassword(values, null, null, data => {
        const {
          _id
        } = data || {};
        if (!_id) {
          return;
        }
        //slowing down redirect
        setTimeout(
          function() {
            location.href = '/login';
          },
          1000
        );
      });
    };

    return (
      <Layout route="reset-password" hideNav>
        <Container>
          <Row className="justify-content-sm-center align-items-center">
            <Col sm md={4}>
              <div className="brand" />
              {error &&
                <Alert variant="danger">
                  {error.status === 401
                    ? 'Password link has expired'
                    : 'An error occurred changing password'}
                </Alert>}
              <ResetPasswordForm
                onSubmit={postResetPassword}
                inProgress={inProgress}
              />
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}
const mapState = state => {
  const {
    result: user,
    inProgress: inProgressValidateToken,
    error
  } = state.validateToken.toJS();

  const {
    inProgress: inProgressSubmitReset,
    error: validateTokenAndSavePasswordError
  } = state.validateTokenAndSavePassword.toJS();

  return {
    user,
    error: validateTokenAndSavePasswordError || error,
    inProgress: inProgressSubmitReset || inProgressValidateToken
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      validateToken: fetchDux.validateToken.createAction,
      validateTokenAndSavePassword: fetchDux.validateTokenAndSavePassword.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ResetPassword);
