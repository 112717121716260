import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';

import { connect } from 'react-redux';
import fetchDux from '../../../../state/fetch-dux';

import UsersSearchResults from './UsersSearchResults';
import { bindActionCreators } from 'redux';

class UsersSearch extends Component {
  componentDidMount() {
    // make our api call
    this.props.getUsers();
  }
  render() {
    const { result, history } = this.props;

    const {
      result: users
    } = result || {};

    return (
      <Layout route="users-search">
        <main>

          <div className="layout-header">
            <Container>

              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <Icon name="home" />
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  Users
                </li>
              </ol>

              <div className="header">
                <h1>
                  <Icon name="users" /> Users
                </h1>
                <div>
                  <Link to="/users/create" className="btn btn-secondary">
                    Create User
                  </Link>
                </div>
              </div>

            </Container>
          </div>

          <Container>
            <UsersSearchResults users={users} history={history} />
          </Container>

        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  const result = state.getUsers.toJS();
  return {
    result
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getUsers: fetchDux.getUsers.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(UsersSearch);
