import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Button, Modal } from 'react-bootstrap';

const required = value => value ? undefined : 'Required';

const CreateManufacturerModalForm = props => {
  const {
    handleSubmit,
    submitting,
    closeModal,
    manufacturerCreateError
  } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Modal.Body>

        <Field
          name="imisId"
          type="text"
          component={FormField.Input}
          label="IMIS ID"
          validate={[required]}
          required
        />

        <Field
          name="name"
          type="text"
          component={FormField.Input}
          label="Manufacturer Name"
          validate={[required]}
          required
        />

      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          variant="outline-secondary"
          onClick={closeModal}
          disabled={submitting}
        >
          Cancel
        </Button>
        <Button
          size="sm"
          variant="success"
          onClick={handleSubmit}
          disabled={submitting}
        >
          Create Manufacturer
        </Button>
      </Modal.Footer>
      {manufacturerCreateError &&
        manufacturerCreateError.message &&
        <p>{manufacturerCreateError.message}</p>}
    </form>
  );
};

export default reduxForm({
  form: 'manufacturer-create-modal-form'
})(CreateManufacturerModalForm);
