import React from 'react';
import { Col } from 'react-bootstrap';
const ShippingDetails = ({ Shipping }) => {
    const {
        ShipDep,
        ShipDimUOM,
        ShipHt,
        ShipWid,
        ShipWt,
        ShipWtUOM,
        ShipNotes,
        FrtClassCode,
        LTLOnlyFlg,
        NMFCCode,
        PrimShipZip,
        ShipVol,
        ShipVolUOM,
        FreeShipAvail,
        FreeShipCond
    } = Shipping || {};
    return (
        Shipping ?
            <Col sm={12}>
                <div className="box">
                    <div className="box-header">
                        <h3>Shipping</h3>
                    </div>
                    <div className="box-body">
                        <table className="table table-data">
                            <tbody>
                                <tr>
                                    <th>Frt Class Code</th>
                                    <td>{FrtClassCode}</td>
                                </tr>
                                <tr>
                                    <th>LTL Only Flag</th>
                                    <td>{LTLOnlyFlg}</td>
                                </tr>
                                <tr>
                                    <th>NMFC Code</th>
                                    <td>{NMFCCode}</td>
                                </tr>
                                <tr>
                                    <th>Primary Shipping Zip</th>
                                    <td>{PrimShipZip}</td>
                                </tr>
                                <tr>
                                    <th>Depth</th>
                                    <td>{ShipDep}</td>
                                </tr>
                                <tr>
                                    <th>Height</th>
                                    <td>{ShipHt}</td>
                                </tr>
                                <tr>
                                    <th>Width</th>
                                    <td>{ShipWid}</td>
                                </tr>
                                <tr>
                                    <th>Dimensions Unit of Measure</th>
                                    <td>{ShipDimUOM}</td>
                                </tr>
                                <tr>
                                    <th>Weight</th>
                                    <td>{ShipWt}</td>
                                </tr>
                                <tr>
                                    <th>Weight Unit of Measure</th>
                                    <td>{ShipWtUOM}</td>
                                </tr>
                                <tr>
                                    <th>Volume</th>
                                    <td>{ShipVol}</td>
                                </tr>
                                <tr>
                                    <th>Volume Unit of Measure</th>
                                    <td>{ShipVolUOM}</td>
                                </tr>
                                <tr>
                                    <th>Free Shipping Available</th>
                                    <td style={{ wordBreak: "break-all" }}>{FreeShipAvail}</td>
                                </tr>
                                <tr>
                                    <th>Free Shipping Conditions</th>
                                    <td style={{ wordBreak: "break-all" }}>{FreeShipCond}</td>
                                </tr>
                                <tr>
                                    <th>Notes</th>
                                    <td style={{ wordBreak: "break-all" }}>{ShipNotes}</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </Col>
            :
            null
    )
}

export default ShippingDetails;