import React from 'react';
import { Link } from 'react-router-dom';

const FAQsSearchResults = ({ results, search, inProgress }) => {
  return (
    <div className="faq-search-results">
      {(results || []).length > 0 &&
        <div>
          <h3>
            <b>{(results || []).length}</b> search results for <b>"{search}"</b>
          </h3>
          <div className="search-results">
            {(results || []).map((article, i) => (
              <Link
                key={i}
                className="search-result"
                to={`/faqs/${article.url}`}
              >
                <h5>{article.category}</h5>
                <div>{article.question}</div>
              </Link>
            ))}
          </div>
        </div>}
      {(results || []).length === 0 && !inProgress &&
        <div className="faq-search-empty">
          No articles found for <b>"{search}"</b>
        </div>}
    </div>
  );
};
export default FAQsSearchResults;
