import React, { Component } from 'react';
import { Container, Button, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import Table from '../../../ui/Table';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import fetchDux from '../../../../state/fetch-dux';
import AddUserModal
  from '../../manufacturers/manufacturers-details/AddUserModal';
import DeleteDistributorModal from './DeleteDistributorModal';
import moment from 'moment';
import Loading from '../../../ui/Loading';
import CopyButton from '../../../ui/CopyButton';
import DeleteKeyModal
  from '../distributors-delete-apikey/DistributorDeleteApiKey';
import DistributorDeleteApiKey
  from '../distributors-delete-apikey/DistributorDeleteApiKey';
import DistributorUpdateApiKey
  from '../distributors-update-apikey/DistributorUpdateApiKey';
import AlertBox from '../../../ui/AlertBox';

const columnsLogs = [
  {
    Header: 'Message',
    accessor: 'message'
  },
  {
    Header: 'File Name',
    accessor: 'filename'
  },
  // {
  //   Header: 'IP Address',
  //   accessor: 'ipAddress'
  // },
  {
    Header: 'User',
    accessor: 'user',
    Cell: ({ value, original: row }) => {
      const user = value[0] || row.userAlt[0];
      if (!user) return '';
      return (
        <Link to={`/users/${user._id}`}>
          {user.nameFirst} {user.nameLast}
        </Link>
      );
    }
  },
  {
    Header: 'Date',
    accessor: 'transactionTime',
    Cell: ({ value }) => {
      if (!value || !moment(value).isValid()) return '';
      return moment(value).format('MM/DD/YYYY hh:mm a');
    }
  }
];

const columnsUsers = [
  // {
  //   Header: 'IMIS ID',
  //   accessor: 'imisId'
  // },
  {
    Header: 'First Name',
    accessor: 'nameFirst'
  },
  {
    Header: 'Last Name',
    accessor: 'nameLast'
  },
  {
    Header: 'Email',
    accessor: 'email',
    Cell: ({ value, original: row }) => (
      <Link to={`/users/${row._id}`}>{value}</Link>
    )
  }
];

class DistributorsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRekey: false,
      showDeleteModal: false,
      ip: '',
      isLoading: true
    };
  }
  componentWillMount() {
    this.props.getUsers();
  }
  componentDidMount() {
    if (this.state.isLoading) {
      // Perform actions for page reload
      this.props.getDistributorDetails();
      // Reset the state to avoid triggering reload on every render
      this.setState({ isLoading: false });
    }

    const {
      match,
      getDistributorById
    } = this.props;
    const { id } = match.params || {};
    getDistributorById(id);
    this.props.getDistributorLogs(id);

    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        this.setState({ ip: data.ip });
      });
  }

  limitCharacters(string, limit) {
    if (string.length > limit) {
      return string.substring(0, limit) + '...';
    } else {
      return string;
    }
  }

  handleCloseDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
      shouldReload: true
    });
  };

  render() {
    const {
      inProgress,
      result,
      match,
      history,
      showModal,
      usersResult,
      logsResult
    } = this.props;
    const { id } = match.params || {};
    const {
      result: distributor
    } = result || {};
    const {
      _id,
      name,
      imisId,
      users,
      apiKey,
      projects,
      _id: distributorId
    } = distributor || {};
    let usersCount = (users || []).length;
    let usersList = users || [];
    let distributorDetailsResult = distributor || {};
    const {
      result: logs,
      inProgress: distributorLogsLoading
    } = logsResult || {};
    const projectData = projects && projects.length > 0
      ? projects.find(project => project.status === 'active')
      : null;
    const activeHistory = projectData &&
      projectData.history &&
      projectData.history.length > 0
      ? projectData.history[projectData.history.length - 1]
      : null;
    const submittedDate = moment(
      activeHistory && activeHistory.submittedDate
    ).format('MM/DD/YYYY hh:mm A');

    return (
      <Layout route="distributors-details">
        <main>

          <div className="layout-header">
            <Container>

              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <Icon name="home" />
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/distributors">
                    Distributor Companies
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  Distributor Company Details
                </li>
              </ol>

              <div className="header">
                <h1>
                  <Icon name="warehouse" /> {name}
                </h1>
                <div>
                  {/* <Button
                    className="btn btn-secondary"
                    onClick={() => showModal('AddUserModal')}
                  >
                    Add User
                  </Button> */}
                  <Link
                    to={`/distributors/${id}/edit`}
                    className="btn btn-secondary"
                  >
                    Edit
                  </Link>
                  <Button
                    className="btn btn-danger"
                    onClick={() => showModal('DeleteDistributorModal')}
                  >
                    Delete
                  </Button>
                </div>
              </div>

            </Container>
          </div>

          <Container>
            <div>

              <Row>
                <Col md={6}>

                  {!apiKey &&
                    <AlertBox variant="info">
                      Please note that in order to use the API, you will need to request a new key below.
                    </AlertBox>}

                  <div className="box">
                    <div className="box-header">
                      <h3>Distributor Company Details</h3>
                    </div>
                    <div className="box-body">

                      <table className="table table-data">
                        <tbody>
                          <tr>
                            <th>IMIS ID</th>
                            <td>{imisId}</td>
                          </tr>
                          <tr>
                            <th>Name</th>
                            <td>{name}</td>
                          </tr>
                          <tr>
                            <th>Api Key</th>
                            <td>
                              <Row>
                                <Col>
                                  {!apiKey
                                    ? 'No Api Key Generated'
                                    : this.limitCharacters(apiKey, 15)}
                                </Col>
                                <Col><CopyButton text={apiKey} /></Col>
                              </Row>
                            </td>
                          </tr>
                          <tr>
                            <th>Users</th>
                            <td>{usersCount}</td>
                          </tr>
                        </tbody>
                      </table>
                      <Link to={`/users/create?id=${_id}`}>
                        <Button size="sm">Create User</Button>
                      </Link>
                    </div>
                  </div>

                </Col>
                <Col md={6}>
                  {projectData &&
                    <div className="box">
                      <div className="box-header">
                        <h3>API Key Details</h3>
                      </div>
                      <div className="box-body">

                        <table className="table table-data">
                          <tbody>
                            <tr>
                              <th>Description of Use</th>
                              <td>
                                <Row>
                                  <Col>{projectData.description}</Col>
                                </Row>
                              </td>
                            </tr>
                            <tr>
                              <th>Accepted By:</th>
                              <td>{activeHistory.actionBy.name}</td>
                            </tr>
                            <tr>
                              <th>Date/Time</th>
                              <td>{submittedDate}</td>
                            </tr>
                            <tr>
                              {/* <th>IP Address:</th>
                              <td>{activeHistory.actionBy.ipAddress}</td> */}
                            </tr>
                          </tbody>

                          <tfoot>
                            <tr>
                              <td colSpan="2" className="text-right">
                                <DistributorUpdateApiKey
                                  show={this.state.showRekey}
                                  handleShow={this.handleShowRekeyModal}
                                  handleClose={this.handleCloseRekeyModal}
                                />
                                {' '}
                                <DistributorDeleteApiKey
                                  show={this.state.showDeleteModal}
                                  handleShow={this.handleShowDeleteModal}
                                  handleClose={this.handleCloseDeleteModal}
                                  distributorId={distributorId}
                                />
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>}
                </Col>
              </Row>

              <Tabs defaultActiveKey="users" className="box-tabs">
                <Tab eventKey="users" title="Users">
                  <Table
                    showSearch
                    data={usersList}
                    columns={columnsUsers}
                    noDataText="No users found"
                    getTrProps={(c, value) => {
                      const { original } = value || {};
                      if (!original) return {};
                      const { _id } = original || {};
                      return {
                        style: {
                          cursor: 'pointer'
                        },
                        onClick: () => {
                          history.push(`/users/${_id}`);
                        }
                      };
                    }}
                  />

                </Tab>
                <Tab eventKey="logs" title="Logs">

                  <Table
                    showSearch
                    data={logs || []}
                    columns={columnsLogs}
                    noDataText={
                      distributorLogsLoading
                        ? <Loading message="Loading logs..." />
                        : 'No logs found'
                    }
                  />

                </Tab>
              </Tabs>

            </div>
          </Container>

          <AddUserModal name="AddUserModal" usersResult={usersResult} />
          <DeleteDistributorModal
            name="DeleteDistributorModal"
            distributor={distributorDetailsResult}
            match={match}
          />

        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  const result = state.getDistributorById.toJS();
  const usersResult = state.getUsers.toJS();
  const logsResult = state.getDistributorLogs.toJS();
  const {
    result: distributor
  } = state.getDistributorDetails.toJS();
  return {
    result,
    usersResult,
    logsResult,
    distributor
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getDistributorById: fetchDux.getDistributorById.createAction,
      getUsers: fetchDux.getUsers.createAction,
      getDistributorLogs: fetchDux.getDistributorLogs.createAction,
      getDistributorDetails: fetchDux.getDistributorDetails.createAction
    },
    dispatch
  );
export default connect(mapState, mapDispatch)(DistributorsDetails);
