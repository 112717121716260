import React from 'react';
import { Link } from 'react-router-dom';
import Table from '../../../ui/Table';
import moment from 'moment';

const columns = [
  // {
  //   Header: 'Id',
  //   accessor: '_id'
  // },
  {
    Header: 'Type',
    accessor: 'type'
  },
  {
    Header: 'Message',
    accessor: 'message'
  },
  {
    Header: 'User',
    accessor: 'name',
  },
  {
    Header: 'Company',
    accessor: 'company',
  },
  {
    Header: 'File Name',
    accessor: 'filename',
    Cell: ({ value }) => {
      if (value) return value
      return 'N/A'
    }
  },
  {
    Header: 'Date',
    accessor: 'date',
    Cell: ({ value }) => {
      if (!value || !moment(value).isValid()) return ''
      return moment(value).format('MM/DD/YYYY hh:mm a')
    }
  }
];


const LogsSearchResults = (
  {
    result,
    paging,
    inProgress,
    onFetchData,
    handleSearchChange,
    error,
    loadingComp
  }
) => {
  const {
    count,
    numPages,
    items
  } = result || {};
  const {
    sortBy,
    desc,
    page,
    pageSize,
    search
  } = paging || {};

  const sorted = [
    {
      id: sortBy,
      desc: desc === 'true'
    }
  ];

  return (
    <Table
      keepTable
      manual
      showColumnsSelector
      showSearch
      serverSearch
      search={search}
      onSearchChange={handleSearchChange}
      data={items || []}
      columns={columns}
      onFetchData={onFetchData}
      noDataText={inProgress ? 'Loading' : 'No logs found'}
      showNumResults={true}
      defaultSorted={sorted}
      page={Number(page)}
      pages={error ? 0 : numPages}
      defaultPageSize={Number(pageSize)}
      inProgress={inProgress}
      paging={paging}
      error={error}
      showPageJump={true}
      totalResults={count}
      LoadingComponent={loadingComp}
    />
  );
};

export default LogsSearchResults;
