import React from 'react';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Button, Row, Col, DropdownButton } from 'react-bootstrap';
import Icon from '../../../ui/Icon';
import { connect } from 'react-redux';

const greaterThanZero = value =>
  parseInt(value, 10) < 0 ? 'Must be greater than 0' : undefined;

const mathSymbolsOptions = [
  { value: 'lt', label: '<' },
  { value: 'lte', label: '<=' },
  { value: 'eq', label: '=' },
  { value: 'gt', label: '>' },
  { value: 'gte', label: '>=' },
  { value: 'range', label: 'range' }
];

const FormGroupNumeral = ({ fieldName, label, isRange }) => (
  <div className="form-group-numeral">
    <div className="symbols">
      <Field
        name={`${fieldName}MathSymbols`}
        options={mathSymbolsOptions}
        component={FormField.SelectReact}
      />
    </div>
    {!isRange &&
      <div className="single">
        <Field
          name={fieldName}
          type="number"
          component={FormField.Input}
          label={label}
          required
        />
      </div>}
    {isRange &&
      <div className="range">
        <Field
          name={`${fieldName}Min`}
          type="number"
          component={FormField.Input}
          label={label}
          placeholder="Min"
          required
        />
        <Field
          name={`${fieldName}Max`}
          type="number"
          component={FormField.Input}
          placeholder="Max"
          required
        />
      </div>}
  </div>
);

const ProductsSearchForm = props => {
  const {
    handleSubmit,
    submitting,
    filters,
    setFilterDisplay,
    shouldDisplayFilter,
    resetFilter,
    priceAmountMathSymbols,
    lookups,
    accountType,
    reset,
    clearFields,
    saveSearch,
    isAdmin
    // change
    // weightUOMMathSymbols,
    // productWeightMathSymbols
  } = props;
  const {
    AssetTypes,
    Categories,
    PriceTypes,
    Manufacturers,
    Stocked
  } = lookups || {};
  // const censoredFilters = (accountType !== 'manufacturer') ? filters : (filters || []).filter(({ value }) => !(value === 'Manufacturers'));
  const censoredFilters = filters;

  return (
    <form onSubmit={handleSubmit} className="advanced-search">

      <Row>
        <Col md={4}>

          <Field
            name="ProdCode"
            type="text"
            component={FormField.Input}
            label="Product Code"
          />

        </Col>
        {shouldDisplayFilter('AssetTypes') &&
          <Col md={4}>
            <Field
              name="assetTypes"
              component={FormField.MultiSelect}
              label="Asset Type"
              options={AssetTypes}
              isMulti
            />

          </Col>}
        {shouldDisplayFilter('Categories') &&
          <Col md={4}>

            <Field
              name="CatEnum"
              component={FormField.MultiSelect}
              label="Category Name"
              options={Categories}
              isMulti
            />

          </Col>}
        {shouldDisplayFilter('lastUpdatedDate') &&
          <Col md={4}>

            <Field
              name="lastUpdatedDate"
              component={FormField.Datetimepicker}
              label="Last Updated Timestamp"
            />

          </Col>}
        {shouldDisplayFilter('PriceLastUpdatedDatetime') &&
          <Col md={4}>

            <Field
              name="PriceLastUpdatedDatetime"
              component={FormField.Datetimepicker}
              label="Price Last Updated Timestamp"
            />

          </Col>}
        {shouldDisplayFilter('priceLookback') &&
          <Col md={4}>
            <Field
              name="priceLookback"
              type="number"
              component={FormField.Input}
              label="Price Updated Lookback (days)"
              required
              validate={[greaterThanZero]}
            />
          </Col>}
        {shouldDisplayFilter('Manufacturers') &&
          <Col md={4}>

            <Field
              name="manufacturerId"
              component={FormField.MultiSelect}
              label="Manufacturer Name"
              options={Manufacturers}
              isMulti
            />

          </Col>}
        {shouldDisplayFilter('PriceTypes') &&
          <React.Fragment>
            <Col md={4}>

              <Field
                name="priceTypes"
                component={FormField.MultiSelect}
                label="Price Type"
                options={PriceTypes}
                isMulti
              />

            </Col>
            <Col md={4}>

              <FormGroupNumeral
                fieldName="priceAmount"
                label="Price Amount"
                isRange={priceAmountMathSymbols === 'range'}
              />

            </Col>
          </React.Fragment>}
        {shouldDisplayFilter('productDesc') &&
          <React.Fragment>
            <Col md={4}>

              <Field
                name="ProdShortDesc"
                type="text"
                component={FormField.Input}
                label="Product Short Description"
              />

            </Col>
            <Col md={4}>

              <Field
                name="ProdLongDesc"
                type="text"
                component={FormField.Input}
                label="Product Long Description"
              />

            </Col>
          </React.Fragment>}
        {/* {shouldDisplayFilter('salesClassCode') &&
          <Col md={4}>

            <Field
              name="salesClassCode"
              component={FormField.SelectReact}
              label="Sales Class Code"
            />

          </Col>} */}
        {/* {shouldDisplayFilter('weight') &&
          <React.Fragment>
            <Col md={4}>

              <FormGroupNumeral
                fieldName="weightUOM"
                label="Weight UOM"
                isRange={weightUOMMathSymbols === 'range'}
              />

            </Col>
            <Col md={4}>

              <FormGroupNumeral
                fieldName="productWeight"
                label="Product Weight"
                isRange={productWeightMathSymbols === 'range'}
              />

            </Col>
          </React.Fragment>} */}
        {/* {shouldDisplayFilter('htsCode') &&
          <Col md={4}>

            <Field
              name="htsCode"
              type="text"
              component={FormField.Input}
              label="HTS Code"
            />

          </Col>} */}
        {/* {shouldDisplayFilter('countryOfOrigin') &&
          <Col md={4}>

            <Field
              name="countryOfOrigin"
              component={FormField.SelectReact}
              label="Country Of Origin"
            />

          </Col>} */}

        {shouldDisplayFilter('Stocked') &&
          <Col md={4}>

            <Field
              name="Stocked"
              component={FormField.MultiSelect}
              label="Stocked"
              options={Stocked}
              isMulti
            />

          </Col>}
      </Row>

      {/*
      <hr />

      <Row>
        <Col md={4} lg={3}>

          <Field
            name="stock"
            component={FormField.InlineCheckbox}
            label="Stock"
          />

        </Col>
        <Col md={4} lg={3}>

          <Field
            name="configurableItemFlag"
            component={FormField.InlineCheckbox}
            label="Configurable Item Flag"
          />

        </Col>
        <Col md={4} lg={3}>

          <Field name="map" component={FormField.InlineCheckbox} label="MAP" />

        </Col>
        <Col md={4} lg={3}>

          <Field
            name="gtin"
            component={FormField.InlineCheckbox}
            label="GTIN"
          />

        </Col>
        <Col md={4} lg={3}>

          <Field
            name="toggle3"
            component={FormField.InlineCheckbox}
            label="UMRP"
          />

        </Col>
        <Col md={4} lg={3}>

          <Field name="mrp" component={FormField.InlineCheckbox} label="MRP" />

        </Col>
        <Col md={4} lg={3}>

          <Field
            name="gsaMap"
            component={FormField.InlineCheckbox}
            label="GSA MAP"
          />

        </Col>
      </Row>
      */}

      <hr />

      <Row>
        <Col>
          <DropdownButton
            className="checkbox-dropdown"
            size="sm"
            variant="success"
            title="Select Filters"
          >
            {censoredFilters.map(({ value, label, show, formName }, index) => {
              if (value === 'Manufacturers') {
                return null; // Exclude "manufacturer" from rendering in the dropdown
              }

              return (
                <div
                  key={index}
                  className="dropdown-item"
                  onClick={() =>
                    setFilterDisplay(
                      formName,
                      value,
                      !show,
                      clearFields,
                      change
                    )}
                >
                  <Icon name={show ? 'toggle-on' : 'toggle-off'} />
                  {label}
                </div>
              );
            })}
          </DropdownButton>
        </Col>
        <Col>

          <div className="text-right">
            <Button
              variant="danger"
              size="sm"
              type="button"
              onClick={() => {
                resetFilter({ reset });
              }}
            >
              Reset Filters
            </Button>
            {' '}
            {!(accountType === 'manufacturer') &&
              !isAdmin &&
              <Button
                variant="primary"
                size="sm"
                type="button"
                onClick={() => saveSearch()}
                disabled={submitting}
              >
                Save
              </Button>}
            {' '}
            <Button
              variant="primary"
              size="sm"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
          </div>

        </Col>
        <p style={{ fontSize: 'smaller', margin: '15px', marginTop: '30px' }}>
          The FEDA Data Portal includes information about manufacturers' foodservice equipment and supplies, including pricing information.
          {' '}
          Although manufacturers have committed to implementing procedures to help ensure the accuracy of this information,
          please understand that neither FEDA nor the manufacturers guarantee that such information is error-free.
        </p>
      </Row>

    </form>
  );
};

const formName = 'products-search-form';
const selector = formValueSelector(formName);

const mapState = state => {
  const priceAmountMathSymbols = selector(state, 'priceAmountMathSymbols');
  const weightUOMMathSymbols = selector(state, 'weightUOMMathSymbols');
  const productWeightMathSymbols = selector(state, 'productWeightMathSymbols');
  return {
    priceAmountMathSymbols,
    weightUOMMathSymbols,
    productWeightMathSymbols
  };
};

const form = reduxForm({
  form: formName,
  enableReinitialize: true
})(ProductsSearchForm);

export default connect(mapState)(form);
