import React from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Button, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { connect } from 'react-redux';

const required = value => value ? undefined : 'Required';

const UsersCreateForm = props => {
  const {
    handleSubmit,
    submitting,
    showModal,
    manufacturers,
    distributors,
    userCreateError,
    accountTypeValue,
    change
  } = props;

  const manufacturerOptions = ((manufacturers || {}).items || []).map(({
    _id,
    name
  }) => ({
    value: _id,
    label: name
  }));

  const distributorOptions = ((distributors || {}).items || []).map(({
    _id,
    name
  }) => ({
    value: _id,
    label: name
  }));

  const handleAccountTypeChange = value => {
    change('accountType', value);
    change('accountTypeJoinId', null);
  };
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col md={8}>
          <div className="box">
            <div className="box-body">

              <Row>
                {/* <Col sm={6}>

                  <Field
                    name="imisId"
                    type="text"
                    component={FormField.Input}
                    label="IMIS ID"
                    validate={[required]}
                  />

                </Col> */}
              </Row>

              {/* <hr /> */}

              <Row>
                <Col sm={6}>

                  <Field
                    name="nameFirst"
                    type="text"
                    component={FormField.Input}
                    label="First Name"
                    validate={[required]}
                  />

                </Col>
                <Col sm={6}>

                  <Field
                    name="nameLast"
                    type="text"
                    component={FormField.Input}
                    label="Last Name"
                    validate={[required]}
                  />

                </Col>
                <Col sm={6}>

                  <Field
                    name="email"
                    type="email"
                    component={FormField.Input}
                    label="Email"
                    validate={[required]}
                  />
                </Col>
                <Col sm={6}>
                  <Field
                    name="sendPasswordEmail"
                    type="checkbox"
                    component={FormField.InputToggle}
                    labelStyle={{
                      display: 'inline-block',
                      marginLeft: '5px',
                      marginTop: '35px'
                    }}
                    label="Send Welcome Email"
                  />

                </Col>
              </Row>

            </div>
          </div>
        </Col>
        <Col md={4}>
          <div className="box">
            <div className="box-body">

              <Tabs
                className="box-tabs"
                onSelect={handleAccountTypeChange}
                activeKey={accountTypeValue}
              >
                <Tab eventKey="manufacturer" title="Manufacturer">

                  <Field
                    name="accountTypeJoinId"
                    type="text"
                    component={FormField.SelectReact}
                    label="Search Manufacturer Companies"
                    options={manufacturerOptions}
                    validate={[required]}
                  />
                  <div className="text-center">
                    <p className="text-muted">or</p>
                    <Button
                      variant="primary"
                      size="sm"
                      type="button"
                      onClick={() => showModal('CreateManufacturerModal')}
                    >
                      Create New
                    </Button>
                  </div>

                </Tab>
                <Tab eventKey="distributor" title="Distributor">

                  <Field
                    name="accountTypeJoinId"
                    type="text"
                    component={FormField.SelectReact}
                    label="Search Distributor Companies"
                    options={distributorOptions}
                    validate={[required]}
                  />

                  <div className="text-center">
                    <p className="text-muted">or</p>
                    <Button
                      variant="primary"
                      size="sm"
                      type="button"
                      onClick={() => showModal('CreateDistributorModal')}
                    >
                      Create New
                    </Button>
                  </div>

                </Tab>
              </Tabs>

            </div>
          </div>
        </Col>
      </Row>

      <Button variant="success" type="submit" disabled={submitting}>
        Create New User
      </Button>

      {userCreateError &&
        userCreateError.message &&
        <p>{userCreateError.message}</p>}

    </form>
  );
};

const formName = 'user-create-form';

const form = reduxForm({
  form: formName
})(UsersCreateForm);

const selector = formValueSelector(formName);

const mapState = state => {
  const accountTypeValue = selector(state, 'accountType');
  return {
    accountTypeValue
  };
};

export default connect(mapState)(form);
