import React from 'react';
import { Col } from 'react-bootstrap';

const SurchargeDetails = ({ SurchargeType, SurchargeBasis, SurchargeInclMAP, SurchargeValue }) => {

    return (
        SurchargeType ?
            <Col sm={12}>
                <div className='box'>
                    <div className='box-header'>
                        <h3>Surcharge Details</h3>
                    </div>
                    <div className='box-body'>
                        <table className='table table-data'>
                            <tbody>
                                <tr>
                                    <th>Surcharge Type</th>
                                    <td>{SurchargeType}</td>
                                </tr>
                                <tr>
                                    <th>Surcharge Basis</th>
                                    <td>{SurchargeBasis}</td>
                                </tr>
                                <tr>
                                    <th>Surcharge Value</th>
                                    <td>{SurchargeValue}</td>
                                </tr>
                                <tr>
                                    <th>Surcharge Incl Map</th>
                                    <td>{SurchargeInclMAP}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Col>
            :
            null
    )
}

export default SurchargeDetails;