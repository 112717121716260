import queryString from 'query-string';
import formatQuery from './formatQuery';

const getQueryFromSearch =  search => {
    const query = queryString.parse(search) || {};
    return formatQuery(query);
}


export default getQueryFromSearch
