import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import fetchDux from '../../../../state/fetch-dux';
const { location } = window || {};

class DeleteProductModal extends Component {
  render() {
    const {
      openModal,
      closeModal,
      match,
      product,
      name
    } = this.props;
    const { id } = match.params || {};
    let productName = product.name || '';
    const handleDelete = () => {
      product.id = id;
      this.props.deleteProductById(product, null, null, () => {
        location.href = '/products';
      });
      closeModal();
    };
    return (
      <Modal
        show={openModal === name}
        onHide={closeModal}
        className="modal-danger"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure you wish to delete <b>{productName}</b>?</h5>
          <div className="text-muted">This action cannot be reverted</div>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button size="sm" variant="danger" onClick={handleDelete}>
            Delete Product
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      deleteProductById: fetchDux.deleteProductById.createAction
    },
    dispatch
  );
export default connect(mapState, mapDispatch)(DeleteProductModal);
