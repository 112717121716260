import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../../state/modals-dux';
import ModalAddFAQsGroupForm from './ModalAddFAQsGroupForm';

class ModalAddFAQsGroup extends Component {
  render() {
    const {
      name,
      openModal,
      closeModal,
      handleSubmit,
      createError
    } = this.props;
    return (
      <Modal show={openModal === name} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add FAQ Group</Modal.Title>
        </Modal.Header>
        <ModalAddFAQsGroupForm {...{ closeModal, onSubmit: handleSubmit, createError }} />
      </Modal>
    );
  }
}
const mapState = state => {

  const {
    error: createError
  } = state.createFAQGroup.toJS();
  return {
    openModal: state.modals,
    createError
  }
}
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ModalAddFAQsGroup);
