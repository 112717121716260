/* eslint-disable jsx-a11y/href-no-hash */
import React, { Fragment } from 'react';
import { Button, Container } from 'react-bootstrap';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import { Link } from 'react-router-dom';
import ValidateUploadForm from './ValidateUploiadForm';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../../state/fetch-dux';
import Notification from '../../../ui/Notification';
import Loading from '../../../ui/Loading';
import ProductExceptions from './ProductExceptions';
import { downloadExceptionsFile } from '../../../../api/api';
import download from 'downloadjs';
import { withRouter } from 'react-router-dom';
class ProductsValidation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.controller = new AbortController();
  }

  componentWillUnmount() {
    const {
      clearUploadManufacturerProducts,
      clearGetProductsUploadStatus,
      clearUploadResult,
      clearUploadExceptions
    } = this.props;

    clearUploadManufacturerProducts();
    clearGetProductsUploadStatus();
    clearUploadResult();
    clearUploadExceptions();
    this.controller.abort();
  }

  componentDidUpdate() {
    const {
      uploadJobResult,
      getProductsUploadStatus,
      uploadStatusResult,
      uploadError,
      uploadStatusError,
      clearGetProductsUploadStatus,
      getUploadExceptions
    } = this.props;

    const { jobId } = uploadJobResult || {};

    if (jobId) {
      const {
        status = [],
        completed,
        failedProducts,
        finshedInsertOrUpdate,
        failed,
        errorMessage
      } = uploadStatusResult || {};

      if (completed && !this.state.showSuccessMessage && !failed) {
        if (failedProducts.length === 0) {
          this.setState({
            successMessage: 'All the products in the file uploaded are valid'
          });
        } else {
          getUploadExceptions({ jobId, download: false });
        }
        this.setState({
          showSuccessMessage: true
        });
        return;
      }

      if (!completed && !this.state.startedTimeout && !failed) {
        this.setState({
          startedTimeout: true,
          jobId
        });
        const initGetProductsUploadStatus = () => {
          getProductsUploadStatus(jobId, null, null, ({
            completed,
            failed
          }) => {
            if (!completed && !failed) {
              window.setTimeout(
                () => {
                  initGetProductsUploadStatus();
                },
                1 * 1000
              );
            } else {
              this.setState({ startedTimeout: false, inProgress: false });
            }
          });
        };
        initGetProductsUploadStatus();
      } else {
        if (uploadStatusError) {
          clearGetProductsUploadStatus();
          window.setTimeout(
            () => {
              this.setState({
                startedTimeout: false
              });
            },
            1 * 2500
          );
        }
      }
      const recentUpdate = status[status.length - 1];
      if (recentUpdate) {
        const { status } = recentUpdate;
        if (status !== this.state.uploadMessage) {
          this.setState({
            uploadInProgress: completed ? false : true,
            uploadMessage: status,
            completed,
            finshedInsertOrUpdate,
            failed,
            errorMessage
          });
        }
      }
    } else {
      if (uploadError && !this.state.failed) {
        const { message } = uploadError;
        this.setState({
          failed: true,
          errorMessage: message
        });
      } else {
        if (this.state.uploadMessage) {
          this.setState({
            uploadInProgress: false,
            uploadMessage: null,
            completed: false,
            finshedInsertOrUpdate: false,
            failed: false,
            errorMessage: null
          });
        }
      }
    }
  }

  uploadProductsFile = values => {
    const {
      uploadManufacturerProducts
    } = this.props;
    const { file, updatePriceFieldsOnly, updateAssetFieldsOnly } = values;
    const payload = {
      uploadFile: file,
      updatePriceFieldsOnly,
      updateAssetFieldsOnly,
      saveProducts: false
    };
    uploadManufacturerProducts(payload);
  };

  onCancelHandler = () => {
    const { history } = this.props;
    history.push('/products');
  };

  render() {
    const {
      uploadError,
      uploadStatusResult,
      clearGetProductsUploadStatus,
      uploadStatusError,
      clearUploadResult,
      loadingExceptions,
      exceptionsResult,
      clearUploadExceptions,
      clearUploadManufacturerProducts,
      uploadJobResult
    } = this.props;
    const {
      inProgress,
      uploadInProgress,
      uploadMessage,
      showSuccessMessage,
      successMessage
    } = this.state;
    const { errorMessage, completed, failed } = uploadStatusResult || {};
    const { jobId } = uploadJobResult || {};
    const showUpload = !uploadInProgress && !completed && !inProgress;
    return (
      <Layout route="upload-exceptions">
        <main>
          <div className="layout-header">
            <Container>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <Icon name="home" />
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/products">
                    Products
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  Validate Products File
                </li>
              </ol>
              <div className="header">
                <h1>
                  <Icon name="download" /> Validate Products File
                </h1>
              </div>
            </Container>
          </div>

          <Container style={{ height: '100vh' }}>
            {showUpload &&
              <ValidateUploadForm onSubmit={this.uploadProductsFile} />}

            {(uploadInProgress || inProgress) &&
              !failed &&
              <div className="modal-loading">
                <Loading message={uploadMessage || 'Uploading please wait..'} />
              </div>}

            {loadingExceptions &&
              <div className="modal-loading">
                <Loading message={'Loading exceptions. Please wait....'} />
              </div>}

            {(uploadError || uploadStatusError || failed) &&
              <Notification
                key="upload-product"
                duration={3}
                closable={true}
                type="danger"
                onClose={() => {
                  clearUploadManufacturerProducts();
                  clearGetProductsUploadStatus();
                  clearUploadResult();
                  clearUploadExceptions();
                }}
              >
                {errorMessage ||
                  (uploadStatusError || {}).message ||
                  errorMessage ||
                  ''}
              </Notification>}

            {showSuccessMessage && successMessage
              ? <div
                  style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
                >
                  <div style={{ textAlign: 'center', fontSize: '2rem' }}>
                    {successMessage}
                  </div>
                  <Button
                    type="submit"
                    variant="primary"
                    size="sm"
                    onClick={this.onCancelHandler}
                    style={{
                      width: '100px',
                      height: '40px',
                      alignSelf: 'center'
                    }}
                  >
                    Back
                  </Button>
                </div>
              : exceptionsResult &&
                  <Fragment>
                    <div
                      style={{
                        marginBottom: '1rem',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}
                    >
                      <span>
                        Following are the exceptions in the file uploaded.
                        <span>
                          <a
                            href="#"
                            onClick={async () => {
                              const response = await downloadExceptionsFile({
                                jobId,
                                abortControllerSignal: this.controller.signal
                              });
                              const blob = await response.blob();
                              download(blob, 'products_exceptions.csv');
                            }}
                          >
                            {' '}Click here
                          </a> to download the complete list.
                        </span>
                      </span>
                      <Button
                        onClick={() => {
                          clearGetProductsUploadStatus();
                          clearUploadResult();
                          clearUploadExceptions();
                          clearUploadManufacturerProducts();
                          this.setState({
                            showSuccessMessage: false,
                            successMessage: null
                          });
                        }}
                      >
                        {`< Back`}
                      </Button>
                    </div>
                    <ProductExceptions exceptionsResult={exceptionsResult} />
                  </Fragment>}
          </Container>

        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result: uploadJobResult,
    error: uploadError
  } = state.uploadManufacturerProducts.toJS() || {};
  const {
    result: uploadStatusResult,
    error: uploadStatusError
  } = state.getProductsUploadStatus.toJS() || {};

  const {
    result: exceptionsResult,
    inProgress: loadingExceptions
  } = state.getUploadExceptions.toJS();

  return {
    openModal: state.modals,
    uploadJobResult,
    uploadStatusResult,
    uploadError,
    uploadStatusError,
    exceptionsResult,
    loadingExceptions
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      uploadManufacturerProducts: fetchDux.uploadManufacturerProducts.createAction,
      clearUploadResult: fetchDux.uploadManufacturerProducts.clearAction,
      getProductsUploadStatus: fetchDux.getProductsUploadStatus.createAction,
      clearGetProductsUploadStatus: fetchDux.getProductsUploadStatus.clearAction,
      clearUploadManufacturerProducts: fetchDux.uploadManufacturerProducts.clearAction,
      getUploadExceptions: fetchDux.getUploadExceptions.createAction,
      clearUploadExceptions: fetchDux.getUploadExceptions.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(withRouter(ProductsValidation));
