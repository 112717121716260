import React, { useState } from 'react';
import Table from '../../../ui/Table';
import FormField from '../../../../components/forms/FormField';
import Loading from '../../../ui/Loading';

const columns = [
  {
    Header: 'First Name',
    accessor: 'nameFirst'
  },
  {
    Header: 'Last Name',
    accessor: 'nameLast'
  },
  {
    Header: 'Email',
    accessor: 'email'
  },
  {
    Header: 'Account Type',
    accessor: 'accountType',
    Cell: ({ value: accountType }) => {
      const value = accountType === 'manufacturer'
        ? 'Manufacturer'
        : accountType === 'distributor' ? 'Distributor' : '';
      return (
        <span>
          {value}
        </span>
      );
    }
  },
  {
    Header: 'Company',
    accessor: 'accountType',
    Cell: ({ value: accountType, original: row }) => {
      const { manufacturer, distributor } = row || {};
      const value = accountType === 'manufacturer'
        ? manufacturer
        : accountType === 'distributor' ? distributor : null;

      if (!value || !value.length) return '';

      return (
        <span>
          {(value[0] || []).name}
        </span>
      );
    }
  }
];

const AfterSearch = (
  {
    value,
    manufacturerOptions,
    handleChange
  }
) => {
  return (
    <div style={{ width: '500px' }}>
      <FormField.SelectReact
        options={[
          { label: 'Search All Users', value: 'all' },
          { label: 'Search Manufacturer Company Users', value: 'manufacturer' },
          { label: 'Search Distributor Company Users', value: 'distributor' }
        ]}
        meta={{}}
        input={{
          name: 'manufacturerFilter',
          onBlur: () => {},
          onChange: handleChange,
          onFocus: () => {},
          value
        }}
      />
    </div>
  );
};

const filterItems = (items, value) => {
  if (!value || value === 'all') return items;
  return (items || []).filter(({ accountType }) => accountType === value);
};
const UsersSearchResults = (
  {
    users,
    history
  }
) => {
  const {
    items
  } = users || {};
  const [value, setValue] = useState('all');

  const handleChange = value => setValue(value);

  const filteredItems = filterItems(items, value);

  return (
    <Table
      showSearch
      data={filteredItems || []}
      columns={columns}
      noDataText={
        !items ? <Loading message="Loading Users..." /> : 'No Users Found'
      }
      BeforeSearch={<AfterSearch handleChange={handleChange} value={value} />}
      getTrProps={(c, value) => {
        const { original } = value || {};
        if (!original) return {};
        const { _id } = original || {};
        return {
          style: {
            cursor: 'pointer'
          },
          onClick: () => {
            history.push(`/users/${_id}`);
          }
        };
      }}
    />
  );
};

export default UsersSearchResults;
