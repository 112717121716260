import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../../../ui/Layout';
import Icon from '../../../../ui/Icon';
import { connect } from 'react-redux';
import fetchDux from '../../../../../state/fetch-dux';
import FAQUpdateForm from './FAQUpdateForm';
import { bindActionCreators } from 'redux';
import Loading from '../../../../ui/Loading';

class FAQUpdate extends Component {

  constructor(props) {
    super(props);
    const { match, getFAQArticleById, getAllFAQGroups } = this.props;
    const { id } = match.params;
    getFAQArticleById(id);
    getAllFAQGroups();
  }

  componentWillUnmount() {
    this.props.clearGetFAQArticleById();
  }

  render() {

    const {
      result,
      faqGroups,
      match,
      updateFAQArticleById,
      history,
      updateError,
      inProgress
    } = this.props;
    const { id } = match.params;

    const getInitalValues = () => {

      return {
        groupName: (result || {}).group,
        updateError,
        ...result,
        group: ((result || {}).group || {})._id
      }
    }
    const faqGroupOptions = (faqGroups || []).map(({ groupName, _id }) => ({ label: groupName, value: _id }))
    return (
      <Layout route="faqs-search">
        <main>

          <div className="layout-header">
            <Container>

              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <Icon name="home" />
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  Frequently Asked Questions
                </li>
              </ol>

              <div className="header">
                <h1>
                  <Icon name="comments" /> Update Frequently Asked Question
                </h1>
              </div>

            </Container>
          </div>

          <Container>
            {
              inProgress && <Loading />
            }
            {
              !inProgress &&
              <FAQUpdateForm
                initialValues={getInitalValues()}
                faqGroupOptions={faqGroupOptions}
                onSubmit={
                  (values) => {
                    const payload = {
                      ...values,
                      answer: typeof values.answer === 'object' ? JSON.stringify(values.answer) : values.answer
                    }
                    updateFAQArticleById({ payload, id: id }, 'faq-update-form', null, () => {
                      history.push('/admin/faqs');
                    });
                  }
                }
              />
            }

          </Container>

        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress
  } = state.getFAQArtcileById.toJS();

  const {
    result: faqGroups,
    inProgress: groupsInProgress
  } = state.getAllFAQGroups.toJS();

  const {
    error: updateError
  } = state.updateFAQArticleById.toJS();

  return {
    result,
    inProgress: groupsInProgress || inProgress,
    faqGroups,
    updateError
  }
};

const mapDispatch = dispatch => bindActionCreators({
  getFAQArticleById: fetchDux.getFAQArtcileById.createAction,
  getAllFAQGroups: fetchDux.getAllFAQGroups.createAction,
  updateFAQArticleById: fetchDux.updateFAQArticleById.createAction,
  clearGetFAQArticleById: fetchDux.getFAQArtcileById.clearAction,
}, dispatch);

export default connect(mapState, mapDispatch)(FAQUpdate);
