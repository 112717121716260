import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import fetchDux from '../../../../state/fetch-dux';
const { location } = window || {};
class DeleteChildModal extends Component {
    render() {
        const {
            openModal,
            closeModal,
            manufacturerId,
            companyDetails,
            name,
            getChildManufacturers
        } = this.props;
        let manufacturerName = companyDetails && companyDetails.name || '';
        const handleDelete = () => {
            this.props.removeChildManufacturerById(
                {
                    companyId: companyDetails.companyId,
                    id: manufacturerId,
                    payload: companyDetails
                },
                null,
                null,
                ()=>{
                  getChildManufacturers();
                }
            );
            closeModal();
        };
        return (
            <Modal
                show={openModal === name}
                onHide={closeModal}
                className="modal-danger"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Remove Relationship</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Are you sure you wish to remove this child company? <b>{manufacturerName}</b>?</h5>
                    <div className="text-muted">This action cannot be reverted</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="outline-secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button size="sm" variant="danger" onClick={handleDelete}>
                        Delete Child Company
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch =>
    bindActionCreators(
        {
            ...actionCreators,
            removeChildManufacturerById: fetchDux.removeChildManufacturerById.createAction,
            getChildManufacturers: fetchDux.getChildManufacturers.createAction,
        },
        dispatch
    );
export default connect(mapState, mapDispatch)(DeleteChildModal);
