import React from 'react';
import { Link } from 'react-router-dom';
import Table from '../../../ui/Table';
import Loading from '../../../ui/Loading';

const columns = [
  {
    Header: 'IMIS ID',
    accessor: 'imisId',
    Cell: ({ value, original: row }) => <Link to={`/distributors/${row._id}`}>{value}</Link>
  },
  {
    Header: 'Distributor Name',
    accessor: 'name'
  },
  {
    Header: 'Users',
    accessor: 'numUsers',
    Cell: ({ value }) => {
      return (
        <span>
          {value}
        </span>
      )
    }
  }
];

const DistributorsSearchResults = (
  {
    distributors,
    history
  }
) => {
  const {
    count,
    numPages,
    items
  } = distributors || {};
  return (
    <Table
      showSearch
      data={items || []}
      columns={columns}
      noDataText={!items ? <Loading message="Loading Distributors..." /> : 'No Users Found'}
      getTrProps={(c, value) => {
        const { original } = value || {}
        if (!original) return {};
        const { _id } = original || {};
        return {
          style: {
            cursor: 'pointer'
          },
          onClick: () => {
            history.push(`/distributors/${_id}`)
          }
        }
      }}
    />
  );
}
export default DistributorsSearchResults;
