import React, { Component } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../../state/modals-dux';
import fetchDux from '../../../../../state/fetch-dux';

class ModalDeleteFAQsGroup extends Component {
  render() {
    const {
      openModal,
      closeModal,
      handleDelete,
      name,
      deleteFAQGroup,
      deleteInProgress,
      getAllFAQs,
      deleteError
    } = this.props;
    const { name: modalName, faqGroupName, faqGroupId } = openModal || {};
    return (
      <Modal
        show={modalName === name}
        onHide={() => {
          if (!deleteInProgress) closeModal();
        }}
        className="modal-danger"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete FAQs Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure you wish to delete <b>{faqGroupName}</b>?</h5>
          {
            deleteError &&
            <Alert variant="danger">{deleteError.message || ''}</Alert>
          }
          <div className="text-muted">This action cannot be reverted</div>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-secondary" onClick={closeModal} disabled={deleteInProgress}>
            Cancel
          </Button>
          <Button
            size="sm"
            variant="danger"
            disabled={deleteInProgress}
            onClick={() => deleteFAQGroup(faqGroupId, null, null, () => {
              closeModal();
              getAllFAQs();
            })}>
            Delete FAQs Group
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
const mapState = state => {

  const {
    inProgress: deleteInProgress,
    error: deleteError
  } = state.deleteFAQGroup.toJS();
  return {
    openModal: state.modals,
    deleteInProgress,
    deleteError
  }
}
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      deleteFAQGroup: fetchDux.deleteFAQGroup.createAction,
      getAllFAQs: fetchDux.getAllFAQs.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ModalDeleteFAQsGroup);
