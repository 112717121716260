/* eslint-disable array-callback-return */
/* eslint-disable default-case */
import React from 'react';
import { Link } from 'react-router-dom';
import Table from '../../../ui/Table';
import moment from 'moment';

const columns = [
  {
    Header: 'Product Code',
    accessor: 'ProdCode',
    Cell: ({ value, original: row }) => (
      <Link to={`/products/details/${row._id}`}>{value}</Link>
    ),
    className: 'text-left'
  },
  {
    Header: 'Manufacturer',
    accessor: 'MfrName',
    className: 'text-left'
  },
  {
    Header: 'Category',
    accessor: 'CatEnum',
    className: 'text-left'
  },
  {
    Header: 'Product Short Desc',
    accessor: 'ProdShortDesc',
    className: 'text-left'
  },
  {
    Header: 'Product Long Desc',
    accessor: 'ProdLongDesc',
    className: 'text-left',
    show: false
  },
  {
    Header: 'Stocked',
    accessor: 'Stocked',
    className: 'text-left',
    show: false
  },
  {
    Header: 'Last Updated Date',
    accessor: 'lastUpdatedDate',
    className: 'text-left',
    Cell: ({ value }) => (
      <span>
        {value
          ? moment.utc(value).format('MM/DD/YYYY, hh:mm A').toUpperCase()
          : '-'}
      </span>
    )
  },
  {
    Header: 'Price Last Updated Date',
    accessor: 'PriceLastUpdatedDatetime',
    className: 'text-left',
    Cell: ({ value }) => (
      <span>
        {value
          ? moment.utc(value).format('MM/DD/YYYY, hh:mm A').toUpperCase()
          : '-'}
      </span>
    )
  },
  {
    Header: 'Price (List)',
    accessor: 'priceList',
    className: 'text-right',
    show: false,
    Cell: ({ value }) => {
      const {
        PriceType,
        PriceAmount,
        PriceCurrCode
      } = value || {};
      if (!PriceType || !PriceAmount) return <span>-</span>;
      return <span>{PriceAmount} {PriceCurrCode || 'USD'}</span>;
    }
  },
  {
    Header: 'Price (MAP)',
    accessor: 'priceMap',
    className: 'text-right',
    show: false,
    Cell: ({ value }) => {
      const {
        PriceType,
        PriceAmount,
        PriceCurrCode
      } = value || {};
      if (!PriceType || !PriceAmount) return <span>-</span>;
      return <span>{PriceAmount} {PriceCurrCode || 'USD'}</span>;
    }
  },
  {
    Header: 'Price (UMRP)',
    accessor: 'priceUmrp',
    className: 'text-right',
    show: false,
    Cell: ({ value }) => {
      const {
        PriceType,
        PriceAmount,
        PriceCurrCode
      } = value || {};
      if (!PriceType || !PriceAmount) return <span>-</span>;
      return <span>{PriceAmount} {PriceCurrCode || 'USD'}</span>;
    }
  },
  {
    Header: 'Price (MRP)',
    accessor: 'priceMrp',
    className: 'text-right',
    show: false,
    Cell: ({ value }) => {
      const {
        PriceType,
        PriceAmount,
        PriceCurrCode
      } = value || {};
      if (!PriceType || !PriceAmount) return <span>-</span>;
      return <span>{PriceAmount} {PriceCurrCode || 'USD'}</span>;
    }
  },
  {
    Header: 'Price (GSAMRP)',
    accessor: 'priceGsamrp',
    className: 'text-right',
    show: false,
    Cell: ({ value }) => {
      const {
        PriceType,
        PriceAmount,
        PriceCurrCode
      } = value || {};
      if (!PriceType || !PriceAmount) return <span>-</span>;
      return <span>{PriceAmount} {PriceCurrCode || 'USD'}</span>;
    }
  },
  {
    Header: 'Price (Net)',
    accessor: 'priceNet',
    className: 'text-right',
    show: false,
    Cell: ({ value }) => {
      const {
        PriceType,
        PriceAmount,
        PriceCurrCode
      } = value || {};
      if (!PriceType || !PriceAmount) return <span>-</span>;
      return <span>{PriceAmount} {PriceCurrCode || 'USD'}</span>;
    }
  }
];

const ProductsSearchResults = (
  {
    products,
    history,
    paging,
    inProgress,
    onFetchData,
    error,
    loadingComp,
    invalidSortColumn
  }
) => {
  const {
    numPages,
    items,
    count
  } = products || {};
  const {
    sortBy,
    desc,
    page,
    pageSize
  } = paging || {};

  const sorted = [
    {
      id: sortBy,
      desc: desc === 'true'
    }
  ];
  const breakOutPriceTypes = (items || []).map(item => {
    const {
      Price
    } = item || {};

    const FinalPrice = Price.map(nestedArray => {
      const [priceObject] = nestedArray;
      return priceObject;
    }).filter(priceObject => priceObject !== undefined && priceObject !== null);

    (FinalPrice || []).map(({ PriceType, PriceAmount }) => {
      switch (PriceType) {
        case 'List':
          item.priceList = { PriceType, PriceAmount };
          break;
        case 'MAP':
          item.priceMap = { PriceType, PriceAmount };
          break;
        case 'UMRP':
          item.priceUmrp = { PriceType, PriceAmount };
          break;
        case 'MRP':
          item.priceMrp = { PriceType, PriceAmount };
          break;
        case 'GSAMRP':
          item.priceGsamrp = { PriceType, PriceAmount };
          break;
        case 'Net':
          item.priceNet = { PriceType, PriceAmount };
          break;
      }
    });
    return item;
  });

  return (
    <div className="table-products">
      <Table
        data={breakOutPriceTypes || []}
        columns={columns}
        showColumnsSelector={true}
        showNumResults={true}
        noDataText={inProgress ? 'Loading' : 'No products found'}
        defaultSorted={sorted}
        page={Number(page)}
        pages={error ? 0 : numPages}
        defaultPageSize={Number(pageSize)}
        inProgress={inProgress}
        onFetchData={onFetchData}
        totalResults={count}
        keepTable
        manual
        showPageJump={true}
        LoadingComponent={loadingComp}
        invalidSortColumn={invalidSortColumn}
        // getTrProps={(c, value) => {
        //   const { original } = value || {};
        //   if (!original) return {};
        //   const { _id } = original || {};
        //   return {
        //     style: {
        //       cursor: 'pointer'
        //     },
        //     onClick: () => {
        //       // history.push(`/products/${_id}`);
        //     }
        //   };
        // }}
      />
    </div>
  );
};

export default ProductsSearchResults;
