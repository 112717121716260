import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import Table from '../../../ui/Table';
import moment from 'moment-timezone';



const getColumns = (selectedReportType) => {
    return selectedReportType === 'Manufacturers'
        ? [
            {
                Header: 'Name',
                accessor: 'Name',
                className: 'text-center'
            },
            {
                Header: 'IMIS ID',
                accessor: 'imisId',
                className: 'text-center'
            },
            {
                Header: 'Parent Company',
                accessor: 'parentCompany',
                className: 'text-center'
            },
            {
                Header: 'Total Products',
                accessor: 'productCount',
                className: 'text-center'
            },
            {
                Header: 'First Upload Date',
                accessor: 'firstUploadDate',
                className: 'text-center',
                Cell: ({ value }) =>
                    <span>
                        {
                            value ?
                                moment(value)
                                    .tz('America/Chicago')
                                    .format('DD MMM YYYY LT')
                                :
                                '-'
                        }
                    </span>
            },
            {
                Header: 'Registered User',
                accessor: 'hasUserReg',
                className: 'text-center',
                Cell: ({ value }) => <span>{value ? 'True' : 'False'}</span>
            },
            {
                Header: 'Last Upload',
                accessor: 'lastUpload',
                className: 'text-center',
                Cell: ({ value }) =>
                    <span>
                        {
                            value ?
                                moment(value, 'dddd, MMMM Do YYYY, h:mm:ss a')
                                    .tz('America/Chicago')
                                    .format('DD MMM YYYY LT') :
                                '-'
                        }
                    </span>,

            },
            {
                Header: 'Last Login',
                accessor: 'lastLogin',
                className: 'text-center',
                Cell: ({ value }) =>
                    <span>
                        {
                            value ?
                                moment(value)
                                    .tz('America/Chicago')
                                    .format('DD MMM YYYY LT') :
                                '-'
                        }
                    </span>
            }
        ]
        :
        [
            {
                Header: 'Name',
                accessor: 'Name',
                className: 'text-center'
            },
            {
                Header: 'IMIS ID',
                accessor: 'imisId',
                className: 'text-center'
            },
            {
                Header: 'Registered User',
                accessor: 'hasUserReg',
                className: 'text-center',
                Cell: ({ value }) => <span>{value ? 'True' : 'False'}</span>
            },
            {
                Header: 'Last Login',
                accessor: 'lastLogin',
                className: 'text-center',
                Cell: ({ value }) =>
                    <span>
                        {
                            value ?
                                moment(value)
                                    .tz('America/Chicago')
                                    .format('DD MMM YYYY LT')
                                : '-'
                        }
                    </span>
            }
        ]
}
class ReportingResult extends Component {

    render() {
        const {
            result,
            selectedReportType,
            defaultPageSize,
            inProgress,
            handleFetchData
        } = this.props;
        const { results, count, numPages } = result || {};
        return (
            <Col sm={12} style={{ marginTop: '1rem' }}>
                <div className='table-products'>
                    <Table
                        manual
                        data={results}
                        columns={getColumns(selectedReportType)}
                        key={selectedReportType}
                        defaultPageSize={defaultPageSize}
                        showPagination={true}
                        noDataText={inProgress ? 'Loading' : 'No data found'}
                        pages={numPages || 0}
                        defaultSorted={[
                            {
                                id: 'Name',
                                desc: false
                            }
                        ]}
                        onFetchData={handleFetchData}
                    />
                </div>

            </Col>

        )
    }
}


export default ReportingResult;