import React from 'react';
import { Col } from 'react-bootstrap';
const MeasureDetails = ({ Measure }) => {
    return (
        (Measure || []).length > 0 ?
            <Col md={6}>

                <div className="box">
                    <div className="box-header">
                        <h3>Measure</h3>
                    </div>
                    <div className="box-body">

                        <table className="table table-data-horizontal">
                            <thead>
                                <tr>
                                    <th>Measure</th>
                                    <th>Value</th>
                                    <th>Unit of Measure</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Measure.map((a, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{a.MeasEnum || ''}</td>
                                            <td>{a.MeasVal || ''}</td>
                                            <td>{a.UOMCode || ''}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>

                    </div>
                </div>

            </Col>
            :
            null

    )
}

export default MeasureDetails;