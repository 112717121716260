import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import fetchDux from '../../../../state/fetch-dux';

import AddUserModalForm from './AddUserModalForm';

export class AddUserModal extends Component {
  render() {
    const {
      openModal,
      closeModal,
      name,
      usersResult
    } = this.props;

    return (
      <Modal
        show={openModal === name}
        onHide={closeModal}
        className="modal-success"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <AddUserModalForm closeModal={closeModal} usersResult={usersResult} />
      </Modal>
    );
  }
}
const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators
    },
    dispatch
  );
export default connect(mapState, mapDispatch)(AddUserModal);
