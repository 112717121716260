import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../state/modals-dux';

class DeleteEmailModal extends Component {
  render() {
    const {
      openModal,
      closeModal,
      label,
      name,
      handleDelete
    } = this.props;
    return (
      <Modal
        show={openModal === name}
        onHide={closeModal}
        className="modal-danger"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure you wish to delete <b>{label}</b>?</h5>
          <div className="text-muted">This action cannot be reverted</div>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button size="sm" variant="danger" onClick={handleDelete}>
            Delete Record
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators
    },
    dispatch
  );
export default connect(mapState, mapDispatch)(DeleteEmailModal);
