/* eslint-disable no-console */
import React, { Component } from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import fetchDux from '../../../../state/fetch-dux';
import DeleteProductModal from './DeleteProductModal';
import moment from 'moment';
import numeral from 'numeral';
import PackDetails from './product-components/PackDetails';
import ShippingDetails from './product-components/ShippingDetails';
import SurchargeDetails from './product-components/SurchargeDetails';
import AssetDetails from './product-components/AssetDetails';
import Details from './product-components/Details';
import ProductInfo from './product-components/ProductInfo';
import ProductDescription from './product-components/ProductDescription';
import PriceDetails from './product-components/PriceDetails';
import MeasureDetails from './product-components/MeasureDetails';
import GroupDetails from './product-components/GroupDetails';
import FeatureDetails from './product-components/FeatureDetails';

import Loading from '../../../ui/Loading';
import Notification from '../../../ui/Notification';
// import ProductOwner from '../products-new/ProductOwner';

const formatTime = d => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).format('MMM D, Y');
};

const formatPrice = d => {
  if (d === 0) return '0';
  if (!d) return '';
  return numeral(d).format('0,0.00');
};

class ProductsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleFeaturePrice: null
    };
  }
  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params || {};
    this.props.getProductById(id);
  }
  render() {
    const {
      result,
      manufacturer,
      match,
      history,
      showModal,
      getAssetById,
      downloadAsset,
      downloadAssetInProgress,
      downloadAssetError,
      clearGetAssetById,
      user,
      deleteProductError,
      clearDeleteProductById
    } = this.props;
    const { id } = match.params || {};
    const { enableManualProducts } = user;
    const {
      result: product,
      inProgress
    } = result || {};
    // const { childManufacturers } = manufacturer || {}
    const {
      _id,
      CatEnum,
      ProdCode,
      ProdShortDesc,
      ProdLongDesc,
      Stocked,
      Price,
      Group,
      Feature,
      Asset,
      Measure,
      Shipping,
      lastUpdatedDate,
      Deleted,
      COO,
      CfgfItemFlg,
      GTIN,
      HTSCode,
      ProdWt,
      SlsClassCode,
      WtUOM,
      MfrName,
      SurchargeType,
      SurchargeBasis,
      SurchargeValue,
      SurchargeInclMAP,
      Pack,
      PriceLastUpdatedDatetime
    } = product || {};
    return (
      <Layout route="product-details">
        <main>
          <div className="layout-header">
            <Container>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <Icon name="home" />
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/products">
                    Products
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  Product Details
                </li>
              </ol>

              {/* <div className="header">
                <h1>
                  <Icon name="box" /> {ProdCode}
                </h1>
                <div>
                  {Deleted !== 'Yes' &&
                    <Button
                      className="btn btn-danger"
                      onClick={() => showModal('DeleteProductModal')}
                    >
                      Delete
                    </Button>}
                </div>
              </div> */}

            </Container>
          </div>
          <Container>
            <div>
              {Deleted === 'Yes' &&
                <div className="alert alert-danger">
                  <Icon name="exclamation-triangle" />
                  {' '}
                  <span>This product has been deleted</span>
                </div>}
              {Deleted === 'No' &&
                enableManualProducts &&
                <Row
                  style={{ justifyContent: 'flex-end', marginBottom: '1rem' }}
                >
                  <Col sm={4}>
                    <Button
                      size="sm"
                      variant="danger"
                      style={{ float: 'right' }}
                      onClick={() => showModal('DeleteProductModal')}
                    >
                      Delete
                    </Button>

                    <Button
                      size="sm"
                      style={{ float: 'right', marginRight: '1rem' }}
                      onClick={() => {
                        history.push(`/products/edit/${id}`);
                      }}
                    >
                      Edit
                    </Button>
                  </Col>
                </Row>}
              {inProgress && <Loading />}
              {!inProgress &&
                <Row>
                  <ProductInfo
                    {...{
                      ProdCode,
                      MfrName,
                      CatEnum,
                      COO,
                      Stocked,
                      lastUpdatedDate: formatTime(lastUpdatedDate),
                      PriceLastUpdatedDatetime: formatTime(
                        PriceLastUpdatedDatetime
                      )
                    }}
                  />
                  <Details
                    {...{
                      GTIN,
                      HTSCode,
                      SlsClassCode,
                      CfgfItemFlg,
                      Deleted,
                      ProdWt,
                      WtUOM
                    }}
                  />
                  <SurchargeDetails
                    {...{
                      SurchargeType,
                      SurchargeBasis,
                      SurchargeValue: formatPrice(SurchargeValue),
                      SurchargeInclMAP
                    }}
                  />
                  <ShippingDetails Shipping={Shipping} />
                  <PackDetails Pack={Pack} />
                  <ProductDescription {...{ ProdLongDesc, ProdShortDesc }} />
                  <AssetDetails
                    Asset={Asset}
                    Product={product}
                    HandleDownload={getAssetById}
                    downloadAsset={downloadAsset}
                    downloadAssetInProgress={downloadAssetInProgress}
                    downloadAssetError={downloadAssetError}
                  />
                  <PriceDetails Price={Price} />
                  <MeasureDetails Measure={Measure} />
                  <GroupDetails Group={Group} />
                  <FeatureDetails Feature={Feature} />
                </Row>}

            </div>
          </Container>
          {downloadAssetError &&
            <Notification
              key="company-load-fail"
              duration={3}
              closable={true}
              type="danger"
              onClose={clearGetAssetById}
            >
              {'Failed to download Asset, please try again.'}
            </Notification>}
          <DeleteProductModal
            name="DeleteProductModal"
            product={product || {}}
            match={match}
            history={history}
          />
          {deleteProductError &&
            <Notification
              key="delete-product"
              duration={3}
              closable={true}
              type="danger"
              onClose={clearDeleteProductById}
            >
              {'Failed to delete product, please try again.'}
            </Notification>}
        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  const result = state.getProductById.toJS();
  const {
    result: manufacturer
  } = state.getManufacturerById.toJS();
  const {
    result: downloadAsset,
    inProgress: downloadAssetInProgress,
    error: downloadAssetError
  } = state.getAssetById.toJS();
  const user = state.login.toJS().result;
  const {
    error: deleteProductError
  } = state.deleteProductById.toJS();
  return {
    result,
    manufacturer,
    downloadAsset,
    downloadAssetInProgress,
    downloadAssetError,
    user,
    deleteProductError
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getProductById: fetchDux.getProductById.createAction,
      getAssetById: fetchDux.getAssetById.createAction,
      clearGetAssetById: fetchDux.getAssetById.clearAction,
      clearDeleteProductById: fetchDux.deleteProductById.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ProductsDetails);
