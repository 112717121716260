import getDesc from './getDesc';

const formatQuery = ( {
    page,
    pageSize,
    sortBy,
    desc,
    search
  }) => ({
    page: page || '0',
    pageSize: pageSize || '10',
    sortBy: sortBy || 'transactionTime',
    desc: getDesc(desc),
    search: search || ''
  });



export default formatQuery
