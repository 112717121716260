import React from 'react'
import { Row, Col } from 'react-bootstrap';

const Exceptions = ({ result }) => {

    const {
        missingColumns,
        failedValidationColumns,
        invalidDataTypeColumns,
        invalidProdLinks
    } = result || {};

    return (
        <Col className='exceptions'>

            <Row>
                <Col>
                    <strong>Exceptions</strong>
                </Col>
            </Row>

            <Row style={{width:'100%'}}>
                {
                    missingColumns &&
                    missingColumns.length > 0 &&
                    <Col sm={3} className='exceptionsContainer'>
                        <strong className='text-danger'>Missing columns</strong>
                        {
                            missingColumns.map(({ columnName }, index) => {
                                return (
                                    <label key={index}>{columnName}</label>
                                )
                            })
                        }
                    </Col>
                }

                {
                    invalidDataTypeColumns &&
                    invalidDataTypeColumns.length > 0 &&
                    <Col sm={3} className='exceptionsContainer'>
                        <strong className='text-danger'>Invalid datatype columns</strong>
                        {
                            invalidDataTypeColumns.map(({ columnName, expected, received }, index) => {
                                return (
                                    <div key={index} className='messageContainer'>
                                        <label>{columnName}</label>
                                        <span>{`Expected ${expected}, Received ${received}`}</span>
                                    </div>
                                )
                            })
                        }
                    </Col>
                }
                {
                    invalidProdLinks &&
                    invalidProdLinks.length > 0 &&
                    <Col sm={3} className='exceptionsContainer'>
                        <strong className='text-danger'>Invalid ProdLinks</strong>
                        {
                            invalidProdLinks.map(({ columnName, expected, received }, index) => {
                                return (
                                    <div key={index} className='messageContainer'>
                                        <label>{columnName}</label>
                                        <span>{`Expected ${expected}, Received ${received}`}</span>
                                    </div>
                                )
                            })
                        }
                    </Col>
                }
                {
                    failedValidationColumns &&
                    failedValidationColumns.length > 0 &&
                    <Col sm={3} className='exceptionsContainer'>
                        <strong className='text-danger'>Failed Validations</strong>
                        {
                            failedValidationColumns.map(({ columnName, expected, received }, index) => {
                                return (
                                    <div key={index} className='messageContainer'>
                                        <label>{columnName}</label>
                                        <span>{`Expected ${expected}, Received ${received}`}</span>
                                    </div>
                                )
                            })
                        }
                    </Col>
                }

            </Row>
        </Col>

    )
}


export default Exceptions;