import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import ManufacturersSearch from './manufacturers-search/ManufacturersSearch.js';
import ManufacturersCreate from './manufacturers-create/ManufacturersCreate.js';
import ManufacturersEdit from './manufacturers-edit/ManufacturersEdit.js';
import ManufacturersDetails
  from './manufacturers-details/ManufacturersDetails.js';

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={ManufacturersSearch} />
    <Route exact path={`${match.url}/create`} component={ManufacturersCreate} />
    <Route exact path={`${match.url}/:id`} component={ManufacturersDetails} />
    <Route exact path={`${match.url}/:id/edit`} component={ManufacturersEdit} />
    <Redirect to={`${match.url}`} />
  </Switch>
);
