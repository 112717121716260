/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/href-no-hash */
import React from 'react';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import Loading from '../../../ui/Loading';
import Icon from '../../../ui/Icon';
import { connect } from 'react-redux';
import download from 'downloadjs';
import { bindActionCreators } from 'redux';
import { downloadExceptionsFile } from '../../../../api/api';

const FileDetail = ({ input, valid }) => {
  const { value } = input || {};
  return (
    <div>
      <h5><b>Filename:</b></h5>
      <div>
        {value.name || ''}
        &nbsp;
        {' '}
        {valid && <Icon style={{ color: '#28a745' }} name="check" />}
      </div>
    </div>
  );
};

const validateFile = value => {
  const { name } = value || {};
  if (!value || !name) return 'Required';
  return undefined;
};

const validateFileType = value => {
  const { name } = value || {};
  const typeMatch = (name || '').match(/\.([^.]*)$/) || [];
  if (!(typeMatch || []).length) {
    return 'Invalid FileType';
  }
  const fileType = typeMatch[1].toLowerCase();
  if (fileType !== 'csv' && fileType !== 'json') {
    return 'Invalid FileType';
  }
};

const UploadProductsForm = props => {
  const {
    handleSubmit,
    submitting,
    valid,
    closeModal,
    uploadInProgress,
    completed,
    uploadMessage,
    handleCancelImport,
    successProducts,
    failedProducts,
    totalProducts,
    finshedInsertOrUpdate,
    inProgress,
    failed,
    errorMessage,
    isValidateOnly,
    jobId,
    downloadedExceptions,
    handleChange,
    uploadError,
    fileValidationFailed,
    saveProducts,
    updatePriceFieldsOnly,
    updatedCount,
    productsNotFound,
    updateAssetFieldsOnly,
    cleanUp
  } = props;
  const showUpload = !uploadInProgress && !completed && !inProgress;
  const getInvalidColumnsData = () => {
    const count = (failedProducts || []).reduce((prev, current) => {
      const {
        missingColumns,
        invalidDataTypeColumns,
        failedValidationColumns,
        invalidProdLinks
      } = current;
      const {
        missingColumnProductsLength,
        invalidDataTypeProductsLength,
        invalidValidationProductsLength,
        invalidProdLinkProductsLength
      } = prev;
      if (missingColumns && missingColumns.length > 0) {
        prev = {
          ...prev,
          missingColumnProductsLength: missingColumnProductsLength + 1,
          show: true
        };
      }
      if (invalidDataTypeColumns && invalidDataTypeColumns.length > 0) {
        prev = {
          ...prev,
          invalidDataTypeProductsLength: invalidDataTypeProductsLength + 1,
          show: true
        };
      }
      if (failedValidationColumns && failedValidationColumns.length > 0) {
        prev = {
          ...prev,
          invalidValidationProductsLength: invalidValidationProductsLength + 1,
          show: true
        };
      }
      if (invalidProdLinks && invalidProdLinks.length > 0) {
        prev = {
          ...prev,
          invalidProdLinkProductsLength: invalidProdLinkProductsLength + 1,
          show: true
        };
      }
      return prev;
    }, {
      missingColumnProductsLength: 0,
      invalidDataTypeProductsLength: 0,
      invalidValidationProductsLength: 0,
      invalidProdLinkProductsLength: 0
    });
    return count;
  };
  const invalidColumnsData = getInvalidColumnsData();

  const getFinalStatusDisplay = (
    {
      missingColumnProductsLength,
      invalidDataTypeProductsLength,
      invalidValidationProductsLength,
      invalidProdLinkProductsLength
    }
  ) => {
    return (
      <div className="exceptions">
        <strong>Exceptions</strong>
        {missingColumnProductsLength > 0 &&
          <div>
            <b>{missingColumnProductsLength}</b>
            {' '}
            {missingColumnProductsLength === 1 ? `product` : `products`}
            {' '}
            found with missing columns.
          </div>}
        {invalidDataTypeProductsLength > 0 &&
          <div>
            <b>{invalidDataTypeProductsLength}</b>
            {' '}
            {invalidDataTypeProductsLength === 1 ? `product` : `products`}
            {' '}
            found with invalid dataTypes (number/string mismatch).
          </div>}
        {invalidValidationProductsLength > 0 &&
          <div>
            <b>{invalidValidationProductsLength}</b>
            {' '}
            {invalidValidationProductsLength === 1 ? `product` : `products`}
            {' '}
            found with rule violations (wrong ref value, exceeded character limits, etc...)
          </div>}
        {invalidProdLinkProductsLength > 0 &&
          <div>
            <b>{invalidProdLinkProductsLength}</b>
            {' '}
            {invalidProdLinkProductsLength === 1 ? `product` : `products`}
            {' '}
            found with invalid ProdLinks. i.e ProdLinks to either invalid products in the file or products that do not exist already in the database.
          </div>}
      </div>
    );
  };
  return (
    <form onSubmit={handleSubmit}>

      <div className="modal-body">

        {uploadError ||
          (fileValidationFailed &&
            <Alert variant="danger">
              <h5>Failed to upload document</h5>
              <div>{errorMessage || fileValidationFailed || ''}</div>
            </Alert>)}

        {showUpload &&
          <div>
            <Field
              disabled={submitting}
              name="file"
              component={FormField.InputFileDropzone}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Field
                name="saveProducts"
                component={FormField.InputToggle}
                type="checkbox"
                label="Save Products"
              />

              <Field
                name="updateAssetFieldsOnly"
                component={FormField.InputToggle}
                type="checkbox"
                label="Update Asset Only"
                onChange={() => {
                  handleChange(
                    'upload-products-form',
                    'updatePriceFieldsOnly',
                    false
                  );
                }}
              />
              <Field
                name="updatePriceFieldsOnly"
                component={FormField.InputToggle}
                type="checkbox"
                label="Update Price Only"
                onChange={() => {
                  handleChange(
                    'upload-products-form',
                    'updateAssetFieldsOnly',
                    false
                  );
                }}
              />
            </div>

            {valid &&
              <div className="file-info">
                <Field
                  name="file"
                  component={FileDetail}
                  validate={[validateFile, validateFileType]}
                  valid={valid}
                />
              </div>}
          </div>}

        {(uploadInProgress || inProgress) &&
          !failed &&
          <div className="modal-loading">
            <Loading message={uploadMessage || 'Uploading please wait..'} />
          </div>}

        {completed &&
          !finshedInsertOrUpdate &&
          ((Array.isArray(failedProducts)
            ? failedProducts.length
            : failedProducts > 0) ||
            (Array.isArray(successProducts)
              ? successProducts.length
              : successProducts > 0)) &&
          <div>
            <Row className="results">
              <Col>
                <div className="result gray">
                  <span>{totalProducts}</span>
                  <strong>Uploaded products</strong>
                </div>
              </Col>
              <Col>
                <div className="result green">
                  <span>{successProducts}</span>
                  <strong>Valid products</strong>
                </div>
              </Col>
              <Col>
                <div className="result red">
                  <span>{failedProducts.length}</span>
                  <strong>Exceptions</strong>
                </div>
              </Col>
            </Row>

            <p className="text-center">
              Out of
              {' '}
              <b>{totalProducts}</b>
              {' '}
              {totalProducts === 1 ? `product` : `products`}
              ,
              <b> {successProducts}</b>
              {' '}
              {successProducts === 1 ? `product is` : `products are`}
              {' '}
              valid
              {invalidColumnsData.show
                ? <span>
                    {' '}
                    and
                    {' '}
                    <b>{failedProducts.length}</b>
                    {' '}
                    {failedProducts.length === 1 ? 'product' : 'products'}
                    {' '}
                    have the following exceptions:
                    {' '}
                  </span>
                : `.`}
            </p>
            {invalidColumnsData.show
              ? <div>
                  {getFinalStatusDisplay(invalidColumnsData)}
                  <p className="text-center">
                    {!downloadedExceptions &&
                      <span>
                        {successProducts && successProducts > 0
                          ? `Click save to continue. `
                          : `None of the products uploaded are valid. `}
                        <a
                          href="#"
                          style={{ color: '#dc3545' }}
                          onClick={async () => {
                            handleChange(
                              'upload-products-form',
                              'downloadedExceptions',
                              true
                            );
                            const response = await downloadExceptionsFile({
                              jobId
                            });
                            const blob = await response.blob();
                            download(blob, 'products_exceptions.csv');
                          }}
                        >
                          Click here
                        </a> to get the complete list of exceptions.
                      </span>}
                    {downloadedExceptions &&
                      <span>
                        {successProducts && successProducts > 0
                          ? `Click save to continue, while the complete list of exceptions is being downloaded. `
                          : `None of the products uploaded are valid. `}
                      </span>}
                  </p>
                </div>
              : <p className="text-center">Click save to continue.</p>}
          </div>}

        {completed &&
          finshedInsertOrUpdate &&
          <div className="success-message">
            <Icon name="check" />
            {updatePriceFieldsOnly || updateAssetFieldsOnly
              ? updatedCount > 0
                  ? updatedCount === successProducts
                      ? <p>{uploadMessage}</p>
                      : <p>
                          {
                            `Out of ${successProducts} products, a total of ${updatedCount} product(s) have their ${updateAssetFieldsOnly ? 'asset' : 'price'} information updated.`
                          }
                        </p>
                  : <p>
                      {!productsNotFound &&
                        `${successProducts} product(s) successfuly change their ${updateAssetFieldsOnly ? 'asset' : 'price'} information.`}
                    </p>
              : <p>{uploadMessage}</p>}
          </div>}
        {completed &&
          finshedInsertOrUpdate &&
          productsNotFound > 0 &&
          (updatePriceFieldsOnly || updateAssetFieldsOnly) &&
          <div className="success-message">
            {`${updateAssetFieldsOnly ? 'Asset' : 'Price'} Upload Successful`}
            {/* <a
              href="#"
              style={{ color: '#dc3545' }}
              onClick={async () => {
                const response = await downloadProductsNotFoundFile({
                  jobId
                });
                const blob = await response.blob();
                download(blob, 'products_notfound.csv');
              }}
            > Click here</a> to get the complete list of products which are not available . */}
          </div>}

      </div>

      {!finshedInsertOrUpdate &&
        <div className="modal-footer">
          <Button
            variant="outline-secondary"
            type="button"
            onClick={() => {
              cleanUp();
              closeModal();
              handleCancelImport();
            }}
            disabled={(uploadInProgress || inProgress) && !failed}
          >
            Cancel
          </Button>
          <Button
            variant="success"
            type="submit"
            disabled={
              !valid || uploadInProgress || inProgress || successProducts === 0
            }
          >
            {isValidateOnly ? 'Save' : saveProducts ? 'Upload' : 'Validate'}
          </Button>
        </div>}

      {finshedInsertOrUpdate &&
        <div className="modal-footer">
          <Button
            variant="success"
            type="button"
            onClick={() => {
              cleanUp();
              closeModal();
              handleCancelImport();
            }}
            disabled={uploadInProgress}
          >
            Close
          </Button>
        </div>}

    </form>
  );
};

const UploadForm = reduxForm({
  form: 'upload-products-form'
})(UploadProductsForm);
const selector = formValueSelector('upload-products-form');
const mapState = state => {
  const {
    saveProducts,
    downloadedExceptions,
    updatePriceFieldsOnly,
    updateAssetFieldsOnly
  } = selector(
    state,
    'saveProducts',
    'downloadedExceptions',
    'updatePriceFieldsOnly',
    'updateAssetFieldsOnly'
  );
  return {
    saveProducts,
    downloadedExceptions,
    updatePriceFieldsOnly,
    updateAssetFieldsOnly
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      handleChange: change
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(UploadForm);
