import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { Field, FieldArray } from 'redux-form';
import FormField from '../../../forms/FormField';
import Icon from '../../../ui/Icon';

const required = value => value ? null : 'Required';
const renderAssetDetails = ({ fields, lookupsResult }) => {
  const { AssetType, FileType, AssetQlty, LangCode } = lookupsResult || {};
  const assetTypeOptions = (AssetType || [])
    .map(({ value, enumKey }) => ({ value: enumKey, label: value }));
  const fileTypeOptions = (FileType || [])
    .map(({ value, enumKey }) => ({ value: enumKey, label: value }));
  const assetQualityOptions = (AssetQlty || [])
    .map(({ enumKey }) => ({ value: enumKey, label: enumKey }));
  const assetLangOptions = (LangCode || [])
    .map(({ value, enumKey }) => ({ value: enumKey, label: value }));
  return (
    <div>
      <div className="buttonContainer">
        <Button
          variant="outline-primary"
          size="sm"
          onClick={() => fields.push({})}
        >
          <Icon name="plus" /> Asset
        </Button>
      </div>
      <div>
        {fields.map((assetData, index) => {
          return (
            <div key={index}>
              <Row>
                <Col sm={12}>
                  <div className="header">
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => fields.remove(index)}
                    >
                      <Icon name="trash" />
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <Field
                    name={`${assetData}.AssetTypeEnum`}
                    label="Asset Type *"
                    type="text"
                    component={FormField.SelectReact}
                    options={assetTypeOptions}
                    validate={[required]}
                    isClearable
                  />
                </Col>

                <Col sm={4}>
                  <Field
                    name={`${assetData}.AssetName`}
                    label="Asset Name *"
                    type="text"
                    component={FormField.Input}
                    maxLength={120}
                    validate={[required]}
                  />
                </Col>

                <Col sm={4}>
                  <Field
                    name={`${assetData}.AssetURL`}
                    label="Asset URL *"
                    type="text"
                    component={FormField.Input}
                    maxLength={2000}
                    validate={[required]}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm={4}>
                  <Field
                    name={`${assetData}.FileName`}
                    label="FileName *"
                    type="text"
                    component={FormField.Input}
                    maxLength={200}
                    validate={[required]}
                  />
                </Col>

                <Col sm={4}>
                  <Field
                    name={`${assetData}.FileType`}
                    label="FileType"
                    type="text"
                    component={FormField.SelectReact}
                    options={fileTypeOptions}
                    isClearable
                  />
                </Col>

                <Col sm={4}>
                  <Field
                    name={`${assetData}.AssetQlty`}
                    label="Asset Quality"
                    type="text"
                    component={FormField.SelectReact}
                    options={assetQualityOptions}
                    isClearable
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <Field
                    name={`${assetData}.AssetLang`}
                    label="Asset Language"
                    type="text"
                    component={FormField.SelectReact}
                    options={assetLangOptions}
                    isClearable
                  />
                </Col>
                <Col sm={4}>
                  <Field
                    name={`${assetData}.ThumbURL`}
                    label="Thumb URL"
                    type="text"
                    component={FormField.Input}
                    maxLength={2000}
                  />
                </Col>

                <Col sm={4}>
                  <Field
                    name={`${assetData}.AssetVer`}
                    label="Asset Version"
                    type="text"
                    component={FormField.Input}
                    maxLength={20}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm={4}>

                  <Field
                    name={`${assetData}.AssetVerDate`}
                    label="Asset Version Date"
                    type="text"
                    component={FormField.Datepicker}
                  />
                </Col>
              </Row>
              {index !== fields.length - 1 && <div className="divider" />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const AssetDetails = ({ lookupsResult }) => {
  return (
    <Col sm={12}>
      <div className="box">
        <div className="box-header">
          <h3>Asset Details</h3>
        </div>
        <div className="box-body">
          <FieldArray
            name="Asset"
            component={renderAssetDetails}
            props={{ lookupsResult }}
          />
        </div>
      </div>
    </Col>
  );
};

export default AssetDetails;
