import React from 'react';
import { Modal, Row, Col, Button, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import FormField from '../../forms/FormField';
import Loading from '../../ui/Loading';

const required = value => value ? undefined : 'Required';

const ReferenceForm = (
  {
    closeModal,
    handleSubmit,
    submitting,
    selectedLookup,
    miscData,
    dataType,
    change,
    addError,
    editError
  }
) => {
  const error = addError || editError;
  const { dataTypes = [], references = [] } = miscData || {};
  const dataTypeOptions = dataTypes.map(e => ({
    value: e.toLowerCase(),
    label: e
  }));
  const referencesOptions = references.map(e => ({ value: e, label: e }));
  return (
    <form onSubmit={handleSubmit}>
      <Modal.Body>
        <Row>
          <Col sm={6}>
            <Field
              type="text"
              name="value"
              label="Name"
              component={FormField.Input}
              required
              validate={[required]}
            />
          </Col>

          <Col sm={6}>
            <Field
              type="text"
              name="enumKey"
              label="Enumeration"
              component={FormField.Input}
              validate={[required]}
              required
            />
          </Col>
        </Row>
        {selectedLookup === 'featureEnums' &&
          <Row>
            <Col sm={6}>
              <Field
                type="text"
                name="dataType"
                label="Type"
                component={FormField.SelectReact}
                options={dataTypeOptions}
                validate={[required]}
                onChange={value => {
                  if (value !== 'ref' && value !== 'list') {
                    change('ref', '');
                  }
                }}
                required
              />
            </Col>

            {dataType &&
              (dataType === 'ref' || dataType === 'list') &&
              <Col sm={6}>
                <Field
                  type="text"
                  name="ref"
                  label="Reference"
                  component={FormField.SelectReact}
                  options={referencesOptions}
                  validate={[required]}
                  required
                />
              </Col>}
          </Row>}
        {error &&
          <Row>
            <Col sm={12}>
              <Alert variant="danger">{error.message || ''} </Alert>
            </Col>
          </Row>}

      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'flex-end' }}>
        {submitting && <Loading small />}
        <Button
          size="sm"
          variant="outline-secondary"
          onClick={closeModal}
          disabled={submitting}
        >
          Cancel
        </Button>
        <Button size="sm" variant="primary" type="submit" disabled={submitting}>
          Confirm
        </Button>
      </Modal.Footer>
    </form>
  );
};

const formName = 'PRODUCT_REFERENCES_FORM';
const selector = formValueSelector(formName);

const mapState = state => {
  const dataType = selector(state, 'dataType');
  return {
    dataType
  };
};

const form = reduxForm({
  form: formName,
  enableReinitialize: true
})(ReferenceForm);

export default connect(mapState, null)(form);
