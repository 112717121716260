import React, { Fragment } from 'react';
import FormField from '../../../forms/FormField';
import { reduxForm, Field, change } from 'redux-form';
import Icon from '../../../ui/Icon';
import { Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const FileDetail = ({ input, valid }) => {
  const { value } = input || {};
  return (
    <div>
      <h5
        style={{
          marginBottom: 0,
          textTransform: 'uppercase',
          fontSize: '0.9rem'
        }}
      >
        <b>Filename:</b>
      </h5>
      <div>
        {value.name || ''}
        &nbsp;
        {' '}
        {valid && <Icon style={{ color: '#28a745' }} name="check" />}
      </div>
    </div>
  );
};

const validateFile = value => {
  const { name } = value || {};
  if (!value || !name) return 'Required';
  return undefined;
};

const validateFileType = value => {
  const { name } = value || {};
  const typeMatch = (name || '').match(/\.([^.]*)$/) || [];
  if (!(typeMatch || []).length) {
    return 'Invalid FileType';
  }
  const fileType = typeMatch[1].toLowerCase();
  if (fileType !== 'csv' && fileType !== 'json') {
    return 'Invalid FileType';
  }
};

const ValidateUploadForm = props => {
  const {
    submitting,
    handleSubmit,
    valid,
    handleChange
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <div
          className="modal-content"
          style={{ backgroundColor: '#f5f8fa', height: '40vh' }}
        >
          <div className="modal-body">
            <div
              style={{
                textAlign: 'center',
                backgroundColor: '#f5f8fa',
                height: '100%'
              }}
            >
              <Field
                disabled={submitting}
                name="file"
                component={FormField.InputFileDropzone}
                style={{ height: '100%' }}
              />
            </div>
          </div>
        </div>
        {valid &&
          <Fragment>
            <div className="file-info">
              <Field
                name="file"
                component={FileDetail}
                validate={[validateFile, validateFileType]}
                valid={valid}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end'
              }}
            >
              <Field
                name="updatePriceFieldsOnly"
                component={FormField.InputToggle}
                type="checkbox"
                label="Price Only"
                onChange={() => {
                  handleChange(
                    'validate-products-form',
                    'updateAssetFieldsOnly',
                    false
                  );
                }}
              />
              <Field
                name="updateAssetFieldsOnly"
                component={FormField.InputToggle}
                type="checkbox"
                label="Asset Only"
                onChange={() => {
                  handleChange(
                    'validate-products-form',
                    'updatePriceFieldsOnly',
                    false
                  );
                }}
              />
              <div style={{ float: 'right' }}>
                <Button type="submit" disabled={submitting}>
                  Validate
                </Button>
              </div>
            </div>

          </Fragment>}
      </div>

    </form>
  );
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      handleChange: change
    },
    dispatch
  );

const UploadForm = reduxForm({
  form: 'validate-products-form'
})(ValidateUploadForm);
export default connect(null, mapDispatch)(UploadForm);
