import React, { Component } from 'react';
import { Container, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';

import { downloadExceptionsFile } from '../../../../api/api';
import Loading from '../../../ui/Loading';
import download from 'downloadjs';

class DownloadExceptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
    this.controller = new AbortController();
  }

  componentWillUnmount() {
    this.controller.abort();
  }

  componentDidMount = async () => {
    try {
      const { match } = this.props;
      const { jobId } = match.params || {};
      const response = await downloadExceptionsFile({
        jobId,
        abortControllerSignal: this.controller.signal
      });
      const blob = await response.blob();
      download(blob, 'products_exceptions.csv');
      this.setState(
        {
          loading: false
        },
        () => {
          setTimeout(
            () => {
              window.location = '/products';
            },
            100
          );
        }
      );
    } catch (error) {
      const { message } = error;
      this.setState({
        errorMessage: message,
        loading: false
      });
    }
  };

  render() {
    return (
      <Layout route="upload-exceptions">
        <main>
          <div className="layout-header">
            <Container>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <Icon name="home" />
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/products">
                    Products
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  Download Product Exceptions
                </li>
              </ol>
              <div className="header">
                <h1>
                  <Icon name="download" /> Download Product Exceptions
                </h1>
              </div>
            </Container>
          </div>

          <Container>
            {this.state.loading &&
              <Loading message="Downloading... Please wait.." />}
            {this.state.errorMessage &&
              <Alert variant="danger">
                <h5>Failed to download product exceptions list.</h5>
                <div>{this.state.errorMessage || ''}</div>
              </Alert>}
          </Container>
        </main>
      </Layout>
    );
  }
}

export default DownloadExceptions;
