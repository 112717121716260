import React, { Fragment } from 'react';
import { Modal, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import fetchDux from '../../../../state/fetch-dux';
import { connect } from 'react-redux';
import DistributorUpdateApiKeyForm from './DistributorUpdateApiKeyForm';
import Loading from '../../../ui/Loading';

class DistributorUpdateApiKey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  handleDelete = () => {
    this.setState({ show: false });
  };

  handleLoading = () => {
    this.setState({ loading: true });
  };

  render() {
    const { isDistributor } = this.props;
    const { show, loading } = this.state;

    const {
      distributor,
      updateDistributorApiKeyById,
      getDistributorDetails
    } = this.props;

    const handleSubmit = values => {
      this.handleLoading();
      updateDistributorApiKeyById(
        { ...values, id: distributor._id },
        'distributorUpdateApiKey',
        null,
        () => {
          this.handleClose();
          setTimeout(
            () => {
              getDistributorDetails();
            },
            1000
          );
        }
      );
    };

    return (
      <Fragment>
        {!isDistributor
          ? <OverlayTrigger
              key="left"
              placement="left"
              overlay={
                <Tooltip id="tooltip-left">For Distributor Only!</Tooltip>
              }
            >
              <span className="d-inline-block">
                <Button
                  variant="primary"
                  size="sm"
                  disabled
                  className="disabled-button"
                >
                  Rekey
                </Button>
              </span>
            </OverlayTrigger>
          : <Button variant="primary" size="sm" onClick={this.handleShow}>
              Rekey
            </Button>}
        <Modal show={show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Request Key</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {loading && <Loading />}
            <div className="text-center align-center">
              <p>
                Existing keys will be invalid once accepted. Are you sure you want
                to get another key?
              </p>
            </div>
            <DistributorUpdateApiKeyForm
              onSubmit={handleSubmit}
              onClose={this.handleClose}
            />
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}

const mapState = state => {
  const {
    result: user,
    error
  } = state.login.toJS();

  const {
    result: distributor
  } = state.getDistributorDetails.toJS();

  const { result: values } = state.updateDistributorApiKeyById.toJS();

  return {
    user,
    distributor,
    error,
    values
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getDistributorDetails: fetchDux.getDistributorDetails.createAction,
      updateDistributorApiKeyById: fetchDux.updateDistributorApiKeyById.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(DistributorUpdateApiKey);
