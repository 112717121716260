import React, { Component } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../state/modals-dux';
import queryString from 'query-string';
import moment from 'moment';
import filtersDict from '../products/products-search/_filtersDict';
import Icon from '../../ui/Icon';

const formatTime = d => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).format('MMM D, Y h:mm A');
};

const formatToArray = input => {
  return Array.isArray(input) ? input : [input];
};

const getMathReadable = value => {
  switch (value) {
    case 'lt':
      return 'Less than';
    case 'lte':
      return 'Less than or equal to';
    case 'eq':
      return 'Equal to';
    case 'gt':
      return 'Greater than';
    case 'gte':
      return 'Greater than or equal to';
    case 'range':
      return 'Range';
  }
};

class ViewFTPModal extends Component {
  render() {
    const {
      openModal,
      closeModal,
      search,
      name
    } = this.props;
    const {
      label,
      startDate,
      frequency,
      lastRunDate,
      nextRunDate,
      address,
      port,
      directory,
      fileName,
      errflag 
    } = search || {};
    
    const allEmpty = [
      address || '',
      port || '',
      directory || '',
      fileName || ''
    ].every(value => {
      return !value || value === '';
    });

    return (
      <Modal show={openModal === name} onHide={closeModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>SFTP Upload</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { errflag &&
            <div className="alert alert-danger">
              <Row>
                <Col lg={1}>
                  <Icon name="exclamation-triangle" />
                </Col>
                <Col>
                  <span>An error occurred while attempting to connect to the sftp server or during upload. Please double check the settings and create a new SFTP upload with the correct information.This SFTP upload is no longer active and should be deleted.</span>
                </Col>
              </Row>
              <span> </span>
            </div>
          }
          <Row>
            <Col>
              <div><label className="form-label">Label</label></div>
              <p>{label}</p>
            </Col>
            <Col>
              <div><label className="form-label">Start Date</label></div>
              <p>{formatTime(startDate)}</p>
            </Col>
            <Col>
              <div><label className="form-label">Frequency</label></div>
              <p>{frequency}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div><label className="form-label">Last Run Date</label></div>
              <p>{formatTime(lastRunDate)}</p>
            </Col>
            <Col>
              <div><label className="form-label">Next Run Date</label></div>
              <p>{formatTime(nextRunDate)}</p>
            </Col>
            <Col />
          </Row>

          <div><label className="form-label">Details</label></div>
          {!allEmpty && 
          <table className="table table-sm table-filters">
            <thead>
              <tr>
                <th width="250">Field</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {address &&
                <tr>
                  <th>Address</th>
                  <td>{address}</td>
                </tr>}
              {port &&
                <tr>
                  <th>PORT</th>
                  <td>{port}</td>
                </tr>}
              {directory &&
                <tr>
                  <th>Directory</th>
                  <td>{directory}</td>
                </tr>}
              {fileName &&
                <tr>
                  <th>File Name</th>
                  <td>{fileName}</td>
                </tr>}
            </tbody>
          </table>}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-secondary" onClick={closeModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators
    },
    dispatch
  );
export default connect(mapState, mapDispatch)(ViewFTPModal);
