import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Button, Modal, Row, Col, Alert } from 'react-bootstrap';
import filtersDict from './_filtersDict';
import Icon from '../../../ui/Icon';

const required = value => value ? undefined : 'Required';

const formatToArray = input => {
  return Array.isArray(input) ? input : [input];
};

const getMathReadable = value => {
  switch (value) {
    case 'lt':
      return 'Less than';
    case 'lte':
      return 'Less than or equal to';
    case 'eq':
      return 'Equal to';
    case 'gt':
      return 'Greater than';
    case 'gte':
      return 'Greater than or equal to';
    case 'range':
      return 'Range';
    default:
      return '';
  }
};

const SaveSearchForm = props => {
  const {
    handleSubmit,
    submitting,
    closeModal,
    saveJobsError,
    searchParams,
    testConnection,
    clearTestConnection,
    username,
    address,
    directory,
    password,
    type,
    port,
    testingError,
    testingInprogress,
    testingResult
  } = props;
  const { success } = testingResult || {};
  const handleTesting = async () => {
    const payload = {
      username,
      address,
      directory,
      password,
      port
    };
    await testConnection(payload);
  };

  const {
    ProdCode,
    assetTypes,
    CatEnum,
    lastUpdatedDate,
    manufacturerId,
    priceTypes,
    priceAmount,
    ProdShortDesc,
    ProdLongDesc,
    Stocked,
    priceAmountMathSymbols,
    priceAmountMin,
    priceAmountMax,
    PriceLastUpdatedDatetime,
    priceLookback
  } = searchParams || {};

  const allEmpty = [
    ProdCode || '',
    assetTypes || '',
    CatEnum || '',
    lastUpdatedDate || '',
    manufacturerId || '',
    priceTypes || '',
    priceAmount || '',
    ProdShortDesc || '',
    ProdLongDesc || '',
    Stocked || '',
    priceAmountMin || '',
    priceAmountMax || '',
    PriceLastUpdatedDatetime || '',
    priceLookback || ''
  ].every(value => {
    return !value || value === '=';
  });

  const [showType, setShowType] = React.useState(false);

  const typeChange = value => {
    value === 'ftp'
      ? setShowType(showType => true)
      : setShowType(showType => false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Modal.Body>

        <p className="text-muted">
          This utility saves the current search filters and an automated email to download the results will be sent according to the start date/time and frequency selected below. You can find your saved downloads under the automated downloads tab.
        </p>

        <Field
          name="label"
          type="text"
          component={FormField.Input}
          label="Label"
          validate={[required]}
          required
        />

        <Row>
          <Col md={6}>
            <Field
              name="startDate"
              component={FormField.Datetimepicker}
              label="Start Date"
              validate={[required]}
              required
            />
          </Col>
          <Col md={6}>
            <Field
              name="frequency"
              options={[
                { value: 'daily', label: 'Daily' },
                { value: 'weekly', label: 'Weekly' },
                { value: 'monthly', label: 'Monthly' }
              ]}
              component={FormField.SelectReact}
              label="Frequency"
              validate={[required]}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field
              name="type"
              options={[
                { value: 'email', label: 'Email' },
                { value: 'ftp', label: 'SFTP' }
              ]}
              component={FormField.SelectReact}
              label="Type"
              validate={[required]}
              onChange={typeChange}
              required
            />
          </Col>
        </Row>
        {showType &&
          <Row>
            <Col md={6}>
              <Field
                name="address"
                type="text"
                component={FormField.Input}
                label="Host Name (Address)"
                validate={[required]}
                required
              />
            </Col>
            <Col md={6}>
              <Field
                name="port"
                type="number"
                component={FormField.Input}
                label="Port"
                validate={[required]}
                required
              />
            </Col>
            <Col md={6}>
              <Field
                name="username"
                type="text"
                component={FormField.Input}
                label="User"
                validate={[required]}
                required
              />
            </Col>
            <Col md={6}>
              <Field
                name="password"
                type="password"
                component={FormField.Input}
                label="Password"
                validate={[required]}
                required
              />
            </Col>
            <Col md={6}>
              <Field
                name="directory"
                type="text"
                component={FormField.Input}
                label="Directory"
                validate={[required]}
                required
              />
            </Col>
            <Col md={6}>
              <Field
                name="filename"
                type="text"
                component={FormField.Input}
                label="File Name"
                validate={[required]}
                required
              />
            </Col>
          </Row>}
        <div><label className="form-label">Filters</label></div>
        {allEmpty &&
          <Row>
            <Col md={7}>
              <p style={{ fontSize: 'small', color: 'red' }}>
                <b style={{ marginBottom: '5px' }}>
                  This search does not have any filters.
                </b>
                <br />
                Saving a search with no filters may take considerable time to download.
                <br />
                Please consider adding more filters.
              </p>
            </Col>
          </Row>}
        {!allEmpty &&
          <table className="table table-sm table-filters">
            <thead>
              <tr>
                <th width="250">Filter</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {ProdCode &&
                <tr>
                  <th>{filtersDict.ProdCode}</th>
                  <td>{ProdCode}</td>
                </tr>}
              {assetTypes &&
                <tr>
                  <th>{filtersDict.assetTypes}</th>
                  <td>{formatToArray(assetTypes).join(', ')}</td>
                </tr>}
              {CatEnum &&
                <tr>
                  <th>{filtersDict.CatEnum}</th>
                  <td>{formatToArray(CatEnum).join(', ')}</td>
                </tr>}
              {lastUpdatedDate &&
                <tr>
                  <th>{filtersDict.lastUpdatedDate}</th>
                  <td>{lastUpdatedDate}</td>
                </tr>}
              {PriceLastUpdatedDatetime &&
                <tr>
                  <th>{filtersDict.PriceLastUpdatedDatetime}</th>
                  <td>{PriceLastUpdatedDatetime}</td>
                </tr>}
              {priceLookback &&
                <tr>
                  <th>{filtersDict.priceLookback}</th>
                  <td>{priceLookback}</td>
                </tr>}
              {manufacturerId &&
                <tr>
                  <th>{filtersDict.manufacturerId}</th>
                  <td>{formatToArray(manufacturerId).join(', ')}</td>
                </tr>}
              {priceTypes &&
                <tr>
                  <th>{filtersDict.priceTypes}</th>
                  <td>{formatToArray(priceTypes).join(', ')}</td>
                </tr>}
              {priceAmountMathSymbols &&
                priceAmount &&
                <tr>
                  <th>{filtersDict.priceAmountMathSymbols}</th>
                  <td>{getMathReadable(priceAmountMathSymbols)}</td>
                </tr>}
              {(priceAmount || priceAmountMathSymbols === 'range') &&
                <tr>
                  <th>{filtersDict.priceAmount}</th>
                  {priceAmountMathSymbols === 'range' &&
                    <td>
                      min:
                      {' '}
                      {priceAmountMin || '-'}
                      {' '}
                      &nbsp;max:
                      {' '}
                      {priceAmountMax || '-'}
                    </td>}
                  <td>{priceAmount}</td>
                </tr>}
              {ProdShortDesc &&
                <tr>
                  <th>{filtersDict.ProdShortDesc}</th>
                  <td>{ProdShortDesc}</td>
                </tr>}
              {ProdLongDesc &&
                <tr>
                  <th>{filtersDict.ProdLongDesc}</th>
                  <td>{ProdLongDesc}</td>
                </tr>}
              {Stocked &&
                <tr>
                  <th>{filtersDict.Stocked}</th>
                  <td>{formatToArray(Stocked).join(', ')}</td>
                </tr>}
            </tbody>
          </table>}

      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          variant="outline-secondary"
          onClick={() => {
            clearTestConnection();
            closeModal();
          }}
          disabled={submitting || testingInprogress}
        >
          Cancel
        </Button>
        {type === 'ftp' &&
          <div className="test-connection">
            {testingError &&
              <div style={{ color: 'red', alignSelf: 'center' }}>
                {testingError.message}
              </div>}
            {success &&
              <div style={{ color: 'green', alignSelf: 'center' }}>
                Success! All checks complete.
              </div>}
            {!testingInprogress &&
              <Button
                size="sm"
                variant="secondary"
                disabled={testingInprogress}
                onClick={handleTesting}
                type="button"
                style={{ width: '150px' }}
              >
                Test
              </Button>}
            {testingInprogress &&
              <Button
                size="sm"
                variant="secondary"
                disabled={testingInprogress}
                type="button"
                style={{ width: '150px' }}
              >
                <Icon name="redo-alt" spin /> Testing
              </Button>}
          </div>}
        <Button
          size="sm"
          variant="success"
          onClick={handleSubmit}
          disabled={submitting || testingInprogress}
        >
          Save
        </Button>
      </Modal.Footer>
      {saveJobsError &&
        saveJobsError.message &&
        <Alert variant="danger">
          <h5>Failed to save search</h5>
          <div>{saveJobsError.message}</div>
        </Alert>}
    </form>
  );
};

const formName = 'save-search-modal-form';
const selector = formValueSelector(formName);
const mapState = state => {
  const username = selector(state, 'username');
  const address = selector(state, 'address');
  const directory = selector(state, 'directory');
  const password = selector(state, 'password');
  const type = selector(state, 'type');
  const port = selector(state, 'port');
  const {
    result: testingResult,
    inProgress: testingInprogress,
    error: testingError
  } = state.testConnection.toJS();
  return {
    username,
    address,
    directory,
    password,
    type,
    port,
    testingInprogress,
    testingError,
    testingResult
  };
};
const form = reduxForm({
  form: formName,
  enableReinitialize: true
})(SaveSearchForm);

export default connect(mapState)(form);
