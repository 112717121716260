import React, { Component } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../state/modals-dux';
import queryString from 'query-string';
import moment from 'moment';
import filtersDict from '../products/products-search/_filtersDict';
import Icon from '../../ui/Icon';

const formatTime = d => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).format('MMM D, Y h:mm A');
};

const formatToArray = input => {
  return Array.isArray(input) ? input : [input];
};

const getMathReadable = value => {
  switch (value) {
    case 'lt':
      return 'Less than';
    case 'lte':
      return 'Less than or equal to';
    case 'eq':
      return 'Equal to';
    case 'gt':
      return 'Greater than';
    case 'gte':
      return 'Greater than or equal to';
    case 'range':
      return 'Range';
  }
};

class ViewEmailModal extends Component {
  render() {
    const {
      openModal,
      closeModal,
      search,
      name
    } = this.props;
    const {
      label,
      startDate,
      frequency,
      lastRunDate,
      nextRunDate,
      parameters,
      type,
      errflag
    } = search || {};

    const query = queryString.parse(parameters) || {};

    const {
      ProdCode,
      assetTypes,
      CatEnum,
      lastUpdatedDate,
      manufacturerId,
      priceTypes,
      priceAmount,
      ProdShortDesc,
      ProdLongDesc,
      Stocked,
      priceAmountMathSymbols,
      priceAmountMin,
      priceAmountMax,
      PriceLastUpdatedDatetime,
      priceLookback
    } = query || {};

    const allEmpty = [
      ProdCode || '',
      assetTypes || '',
      CatEnum || '',
      lastUpdatedDate || '',
      manufacturerId || '',
      priceTypes || '',
      priceAmount || '',
      ProdShortDesc || '',
      ProdLongDesc || '',
      Stocked || '',
      priceAmountMin || '',
      priceAmountMax || '',
      PriceLastUpdatedDatetime || '',
      priceLookback || ''
    ].every(value => {
      return !value || value === '=';
    });

    return (
      <Modal show={openModal === name} onHide={closeModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Automated Download</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { errflag &&
            <div className="alert alert-danger">
              <Row>
                <Col lg={1}>
                  <Icon name="exclamation-triangle" />
                </Col>
                <Col>
                  <span>An error occurred while attempting to connect to the sftp server or during upload. Please double check the settings and create a new SFTP download with the correct information.This SFTP download is no longer active and should be deleted.</span>
                </Col>
              </Row>
              <span> </span>
            </div>
          }
          <Row>
            <Col>
              <div><label className="form-label">Label</label></div>
              <p>{label}</p>
            </Col>
            <Col>
              <div><label className="form-label">Start Date</label></div>
              <p>{formatTime(startDate)}</p>
            </Col>
            <Col>
              <div><label className="form-label">Frequency</label></div>
              <p>{frequency}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div><label className="form-label">Last Run Date</label></div>
              <p>{formatTime(lastRunDate)}</p>
            </Col>
            <Col>
              <div><label className="form-label">Next Run Date</label></div>
              <p>{formatTime(nextRunDate)}</p>
            </Col>
            <Col>
              <div><label className="form-label">Type</label></div>
              <p>{type}</p>
            </Col>
          </Row>

          <div><label className="form-label">Filters</label></div>
          {allEmpty &&
            <Row>
              <Col md={7}>
                <p style={{ fontSize: 'small', color: 'red' }}>
                  <b style={{ marginBottom: '5px' }}>
                    This search does not have any filters.
                  </b>
                  <br />
                  Saving a search with no filters may take considerable time to download.
                  <br />
                  Please consider adding more filters.
                </p>
              </Col>
            </Row>}

          {!allEmpty &&
            <table className="table table-sm table-filters">
              <thead>
                <tr>
                  <th width="250">Filter</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {ProdCode &&
                  <tr>
                    <th>{filtersDict.ProdCode}</th>
                    <td>{ProdCode}</td>
                  </tr>}
                {assetTypes &&
                  <tr>
                    <th>{filtersDict.assetTypes}</th>
                    <td>{formatToArray(assetTypes).join(', ')}</td>
                  </tr>}
                {CatEnum &&
                  <tr>
                    <th>{filtersDict.CatEnum}</th>
                    <td>{formatToArray(CatEnum).join(', ')}</td>
                  </tr>}
                {lastUpdatedDate &&
                  <tr>
                    <th>{filtersDict.lastUpdatedDate}</th>
                    <td>{lastUpdatedDate}</td>
                  </tr>}
                {PriceLastUpdatedDatetime &&
                  <tr>
                    <th>{filtersDict.PriceLastUpdatedDatetime}</th>
                    <td>{PriceLastUpdatedDatetime}</td>
                  </tr>}
                {priceLookback &&
                  <tr>
                    <th>{filtersDict.priceLookback}</th>
                    <td>{priceLookback}</td>
                  </tr>}
                {manufacturerId &&
                  <tr>
                    <th>{filtersDict.manufacturerId}</th>
                    <td>{formatToArray(manufacturerId).join(', ')}</td>
                  </tr>}
                {priceTypes &&
                  <tr>
                    <th>{filtersDict.priceTypes}</th>
                    <td>{formatToArray(priceTypes).join(', ')}</td>
                  </tr>}
                {priceAmountMathSymbols &&
                  priceAmount &&
                  <tr>
                    <th>{filtersDict.priceAmountMathSymbols}</th>
                    <td>{getMathReadable(priceAmountMathSymbols)}</td>
                  </tr>}
                {(priceAmount || priceAmountMathSymbols === 'range') &&
                  <tr>
                    <th>{filtersDict.priceAmount}</th>
                    {priceAmountMathSymbols === 'range' &&
                      <td>
                        min:
                        {' '}
                        {priceAmountMin || '-'}
                        {' '}
                        &nbsp;max:
                        {' '}
                        {priceAmountMax || '-'}
                      </td>}
                    <td>{priceAmount}</td>
                  </tr>}
                {ProdShortDesc &&
                  <tr>
                    <th>{filtersDict.ProdShortDesc}</th>
                    <td>{ProdShortDesc}</td>
                  </tr>}
                {ProdLongDesc &&
                  <tr>
                    <th>{filtersDict.ProdLongDesc}</th>
                    <td>{ProdLongDesc}</td>
                  </tr>}
                {Stocked &&
                  <tr>
                    <th>{filtersDict.Stocked}</th>
                    <td>{formatToArray(Stocked).join(', ')}</td>
                  </tr>}
              </tbody>
            </table>}

        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-secondary" onClick={closeModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators
    },
    dispatch
  );
export default connect(mapState, mapDispatch)(ViewEmailModal);
