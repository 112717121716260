import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import ProductsSearch from './products-search/ProductsSearch.js';
import ProductsDetails from './products-details/ProductsDetails.js';
import DownloadExceptions from './upload-exceptions/DownloadExceptions.js';
import DownloadJobResults from './download-job-results/DownloadJobResults.js';
import DownloadValidationResults
  from './asset-validation/DownloadValidationResults.js';
import ProductNew from './products-new/ProductNew.js';
import EditProduct from './products-edit/EditProduct.js';
import ProductsValidation from './products-validation/ProductsValidation.js';


export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={ProductsSearch} />
    <Route
      exact
      path={`${match.url}/upload-exceptions/:jobId`}
      component={DownloadExceptions}
    />
    <Route
      exact
      path={`${match.url}/download-products-list/:productFileName/:search`}
      component={DownloadJobResults}
    />
    <Route
      exact
      path={`${match.url}/download-asset-validation-report/:auditLogId`}
      component={DownloadValidationResults}
    />
    <Route exact path={`${match.url}/details/:id`} component={ProductsDetails} />
    <Route exact path={`${match.url}/edit/:id`} component={EditProduct} />
    <Route exact path={`${match.url}/new`} component={ProductNew} />
    <Route exact path={`${match.url}/validation`} component={ProductsValidation} />
    <Redirect to={`${match.url}`} />
  </Switch>
);
