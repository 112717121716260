import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import FormField from '../../../forms/FormField';

const requiredWithZero = value => value || value === 0 ? null : 'Required';

const getRequiredFields = (
  { shipmentData, packShippingDetails, shippingDetailsRequired }
) => {
  const {
    ShipVolUOM,
    ShipVol,
    ShipDimUOM,
    ShipHt,
    ShipDep,
    ShipWid
  } = shipmentData || {};
  if (!shippingDetailsRequired) return {};

  const shipDimUOMRequired = !(ShipVolUOM && (ShipVol || ShipVol === 0));
  const shipHtRequired = !(ShipVolUOM && (ShipVol || ShipVol === 0));
  const shipDepRequired = !(ShipVolUOM && (ShipVol || ShipVol === 0));
  const shipWidRequired = !(ShipVolUOM && (ShipVol || ShipVol === 0));
  const shipWtUOMRequired = true;
  const shipWtRequired = true;

  const shipVolUOMRequired = !(ShipDimUOM &&
    (ShipHt || ShipHt === 0) &&
    (ShipDep || ShipDep === 0) &&
    (ShipWid || ShipWid === 0));
  const shipVolRequired = !(ShipDimUOM &&
    (ShipHt || ShipHt === 0) &&
    (ShipDep || ShipDep === 0) &&
    (ShipWid || ShipWid === 0));

  return {
    shipDimUOMRequired,
    shipHtRequired,
    shipDepRequired,
    shipWidRequired,
    shipVolUOMRequired,
    shipVolRequired,
    shipWtUOMRequired,
    shipWtRequired
  };
};

const getShippingFields = (
  {
    shippingDetails,
    lookupsResult,
    packDetails,
    packShippingDetails,
    fieldName,
    packIndex
  }
) => {
  const { UOM, FrtClassCode, YesNo } = lookupsResult || {};
  const unitOfMeasurementOptions = (UOM || [])
    .map(({ value, enumKey }) => ({ value: enumKey, label: value }));
  const freightOptions = (FrtClassCode || [])
    .map(({ value, enumKey }) => ({
      value: enumKey,
      label: `${enumKey} : ${value}`
    }));
  const yesNoOptions = (YesNo || [])
    .map(({ enumKey }) => ({ value: enumKey, label: enumKey }));
  const shippingDetailsRequired = packShippingDetails
    ? !!!shippingDetails
    : (packDetails || []).filter(({ Shipping }) => Shipping).length === 0;
  const shipmentData = packShippingDetails
    ? ((packDetails || [])[packIndex] || {}).Shipping
    : shippingDetails;
  const {
    shipDimUOMRequired,
    shipHtRequired,
    shipDepRequired,
    shipWidRequired,
    shipVolUOMRequired,
    shipVolRequired,
    shipWtRequired,
    shipWtUOMRequired
  } = getRequiredFields({ shipmentData, shippingDetailsRequired });

  return (
    <div>
      <Row>
        <Col sm={6}>
          <Field
            name={
              packShippingDetails
                ? `${fieldName}.Shipping.ShipWtUOM`
                : 'Shipping.ShipWtUOM'
            }
            type="text"
            label={
              shipWtUOMRequired
                ? 'Shipment Weight UOM *'
                : 'Shipment Weight UOM'
            }
            options={unitOfMeasurementOptions}
            component={FormField.SelectReact}
            validate={shipWtUOMRequired ? [requiredWithZero] : []}
            isClearable
          />
        </Col>

        <Col sm={6}>
          <Field
            name={
              packShippingDetails
                ? `${fieldName}.Shipping.ShipWt`
                : 'Shipping.ShipWt'
            }
            type="text"
            label={shipWtRequired ? 'Shipment Weight *' : 'Shipment Weight'}
            component={FormField.Input}
            validate={shipWtRequired ? [requiredWithZero] : []}
            normalize={(value, previousValue) => {
              if (value && value.trim().length > 0) {
                const regExp = /^(\d+)?([.]?\d{0,2})?$/;
                if (regExp.test(value)) {
                  return value;
                }
                return previousValue;
              }
              return value;
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={6}>
          <Field
            name={
              packShippingDetails
                ? `${fieldName}.Shipping.ShipDimUOM`
                : 'Shipping.ShipDimUOM'
            }
            type="text"
            label={
              shipDimUOMRequired
                ? 'Shipment Dimension UOM *'
                : 'Shipment Dimension UOM'
            }
            options={unitOfMeasurementOptions}
            component={FormField.SelectReact}
            validate={shipDimUOMRequired ? [requiredWithZero] : []}
            isClearable
          />
        </Col>

        <Col sm={6}>
          <Field
            name={
              packShippingDetails
                ? `${fieldName}.Shipping.ShipHt`
                : 'Shipping.ShipHt'
            }
            type="text"
            label={shipHtRequired ? 'Shipment Height *' : 'Shipment Height'}
            component={FormField.Input}
            validate={shipHtRequired ? [requiredWithZero] : []}
            normalize={(value, previousValue) => {
              if (value && value.trim().length > 0) {
                const regExp = /^(\d+)?([.]?\d{0,2})?$/;
                if (regExp.test(value)) {
                  return value;
                }
                return previousValue;
              }
              return value;
            }}
          />
        </Col>

      </Row>

      <Row>
        <Col sm={6}>
          <Field
            name={
              packShippingDetails
                ? `${fieldName}.Shipping.ShipDep`
                : 'Shipping.ShipDep'
            }
            type="text"
            label={shipDepRequired ? 'Shipment Depth *' : 'Shipment Depth'}
            component={FormField.Input}
            validate={shipDepRequired ? [requiredWithZero] : []}
            normalize={(value, previousValue) => {
              if (value && value.trim().length > 0) {
                const regExp = /^(\d+)?([.]?\d{0,2})?$/;
                if (regExp.test(value)) {
                  return value;
                }
                return previousValue;
              }
              return value;
            }}
          />
        </Col>

        <Col sm={6}>
          <Field
            name={
              packShippingDetails
                ? `${fieldName}.Shipping.ShipWid`
                : 'Shipping.ShipWid'
            }
            type="text"
            label={shipWidRequired ? 'Shipment Width *' : 'Shipment Width'}
            component={FormField.Input}
            validate={shipWidRequired ? [requiredWithZero] : []}
            normalize={(value, previousValue) => {
              if (value && value.trim().length > 0) {
                const regExp = /^(\d+)?([.]?\d{0,2})?$/;
                if (regExp.test(value)) {
                  return value;
                }
                return previousValue;
              }
              return value;
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Field
            name={
              packShippingDetails
                ? `${fieldName}.Shipping.ShipVolUOM`
                : 'Shipping.ShipVolUOM'
            }
            type="text"
            label={
              shipVolUOMRequired
                ? 'Shipment Volume UOM *'
                : 'Shipment Volume UOM'
            }
            options={unitOfMeasurementOptions}
            component={FormField.SelectReact}
            validate={shipVolUOMRequired ? [requiredWithZero] : []}
            isClearable
          />
        </Col>

        <Col sm={6}>
          <Field
            name={
              packShippingDetails
                ? `${fieldName}.Shipping.ShipVol`
                : 'Shipping.ShipVol'
            }
            type="text"
            label={shipVolRequired ? 'Shipment Volume *' : 'Shipment Volume'}
            component={FormField.Input}
            validate={shipVolRequired ? [requiredWithZero] : []}
            normalize={(value, previousValue) => {
              if (value && value.trim().length > 0) {
                const regExp = /^(\d+)?([.]?\d{0,2})?$/;
                if (regExp.test(value)) {
                  return value;
                }
                return previousValue;
              }
              return value;
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={6}>
          <Field
            name={
              packShippingDetails
                ? `${fieldName}.Shipping.PrimShipZip`
                : 'Shipping.PrimShipZip'
            }
            type="text"
            label="Primary Shipment Zip"
            component={FormField.Input}
            maxLength={10}
          />
        </Col>

        <Col sm={6}>
          <Field
            name={
              packShippingDetails
                ? `${fieldName}.Shipping.FrtClassCode`
                : 'Shipping.FrtClassCode'
            }
            type="text"
            label="Freight Class Code"
            options={freightOptions}
            component={FormField.SelectReact}
            isClearable
          />
        </Col>
      </Row>

      <Row>
        <Col sm={4}>
          <Field
            name={
              packShippingDetails
                ? `${fieldName}.Shipping.NMFCCode`
                : 'Shipping.NMFCCode'
            }
            type="text"
            label="NMFCCode"
            component={FormField.Input}
            maxLength={10}
          />
        </Col>

        <Col sm={4}>
          <Field
            name={
              packShippingDetails
                ? `${fieldName}.Shipping.LTLOnlyFlg`
                : 'LTLOnlyFlg'
            }
            type="text"
            label="LTLOnlyFlg"
            options={yesNoOptions}
            component={FormField.SelectReact}
            isClearable
          />
        </Col>

        <Col sm={4}>
          <Field
            name={
              packShippingDetails
                ? `${fieldName}.Shipping.FreeShipAvail`
                : 'Shipping.FreeShipAvail'
            }
            type="text"
            label="FreeShipAvail"
            options={yesNoOptions}
            component={FormField.SelectReact}
            isClearable
          />
        </Col>

      </Row>

      <Row>
        <Col sm={12}>
          <Field
            name={
              packShippingDetails
                ? `${fieldName}.Shipping.ShipNotes`
                : 'Shipping.ShipNotes'
            }
            type="text"
            label="Shipment Notes"
            component={FormField.Input}
            maxLength={1000}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <Field
            name={
              packShippingDetails
                ? `${fieldName}.Shipping.FreeShipCond`
                : 'Shipping.FreeShipCond'
            }
            type="text"
            label="FreeShipCond"
            component={FormField.Input}
            maxLength={1000}
          />
        </Col>
      </Row>
    </div>
  );
};
const ShippingDetails = (
  {
    lookupsResult,
    packDetails,
    fieldName,
    shippingDetails,
    packShippingDetails = false,
    packIndex
  }
) => {
  if (packShippingDetails)
    return getShippingFields({
      shippingDetails,
      fieldName,
      lookupsResult,
      packDetails,
      packShippingDetails,
      packIndex
    });
  return (
    <Col md={12}>
      <div className="box">
        {!packShippingDetails &&
          <div className="box-header">
            <h3>Shipping Details</h3>
          </div>}
        {!packShippingDetails &&
          <div className="box-body">
            {getShippingFields({ lookupsResult, packDetails, shippingDetails })}
          </div>}

      </div>
    </Col>
  );
};

export default ShippingDetails;
