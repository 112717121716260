import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Button, Row, Col } from 'react-bootstrap';

const required = value => value ? undefined : 'Required';

const ManufacturersCreateForm = props => {
  const { handleSubmit, submitting,manufacturerCreateError } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col md={4}>
          <div className="box">
            <div className="box-body">

              <Field
                name="imisId"
                type="text"
                component={FormField.Input}
                label="IMIS ID"
                validate={[required]}
              />

              <Field
                name="name"
                type="text"
                component={FormField.Input}
                label="Manufacturer Name"
                validate={[required]}
              />

            </div>
          </div>
        </Col>
      </Row>

      <Button variant="primary" type="submit" disabled={submitting}>
        Create Manufacturer Company
      </Button>
      {manufacturerCreateError && manufacturerCreateError.message && (
        <p>{manufacturerCreateError.message}</p>
      )}
    </form>
  );
};

export default reduxForm({
  form: 'manufacturer-create-form'
})(ManufacturersCreateForm);
