import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import { connect } from 'react-redux';
import fetchDux from '../../../../state/fetch-dux';
import DistributorsSearchResults from './DistributorsSearchResults';
import { bindActionCreators } from 'redux';

class DistributorsSearch extends Component {
  componentDidMount() {
    // make our api call
    this.props.getDistributors();
  }
  render() {
    const { result, history } = this.props;
    
    const {
      inProgress,
      result: distributors
    } = result || {}
  return (
    <Layout route="distributors-search">
      <main>

        <div className="layout-header">
          <Container>

            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <Icon name="home" />
                </Link>
              </li>
              <li className="breadcrumb-item active">
                Distributor Companies
              </li>
            </ol>

            <div className="header">
              <h1>
                <Icon name="warehouse" /> Distributor Companies
              </h1>
              <div>
                <Link to="/distributors/create" className="btn btn-secondary">
                  Create Distributor Company
                </Link>
              </div>
            </div>

          </Container>
        </div>

        <Container>
          <DistributorsSearchResults distributors={distributors} history={history} />
        </Container>

        </main>
      </Layout>
    )
  }
}

const mapState = state => {
  const result = state.getDistributors.toJS();
  return {
    result
  }
}

const mapDispatch = dispatch => bindActionCreators({
  getDistributors: fetchDux.getDistributors.createAction
}, dispatch)

export default connect(mapState, mapDispatch)(DistributorsSearch);
