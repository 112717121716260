/* eslint-disable no-console */
/* eslint-disable array-callback-return */
/* eslint-disable default-case */
import React, { Component } from 'react';
import { Container, Button, DropdownButton, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import Loading from '../../../ui/Loading';
import { connect } from 'react-redux';
import fetchDux from '../../../../state/fetch-dux';
import { actionCreators } from '../../../../state/modals-dux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import ProductsSearchForm from './ProductsSearchForm';
import ProductsSearchResults from './ProductsSearchResults';
import ModalUploadProducts from './ModalUploadProducts';
import ModalSaveSearch from './ModalSaveSearch';
import { getFormValues } from 'redux-form';
// import { downloadProductsFile } from '../../../../api/api';

const filterOptions = [
  {
    value: 'AssetTypes',
    label: 'Asset Type',
    searchLabel: 'assetTypes',
    show: false
  },
  {
    value: 'Categories',
    label: 'Category',
    searchLabel: 'CatEnum',
    show: false
  },
  // { value: 'countryOfOrigin', label: 'Country Of Origin', show: false },
  // { value: 'htsCode', label: 'HTS Code', show: false },
  {
    value: 'lastUpdatedDate',
    label: 'Last Updated Timestamp',
    searchLabel: 'lastUpdatedDate',
    show: false
  },
  {
    value: 'PriceLastUpdatedDatetime',
    label: 'Price Last Updated Timestamp',
    searchLabel: 'PriceLastUpdatedDatetime',
    show: false
  },
  {
    value: 'priceLookback',
    label: 'Price Lookback',
    searchLabel: 'priceLookback',
    show: false
  },
  {
    value: 'Manufacturers',
    label: 'Manufacturer',
    searchLabel: 'manufacturerId',
    show: true
  },
  {
    value: 'PriceTypes',
    label: 'Price',
    searchLabel: 'priceTypes',
    show: false
  },
  {
    value: 'productDesc',
    label: 'Product Description',
    searchLabel: 'ProdLongDesc',
    show: false
  },
  { value: 'Stocked', label: 'Stocked', searchLabel: 'Stocked', show: false }
  // { value: 'salesClassCode', label: 'Sales Class Code', show: false },
  // { value: 'weight', label: 'Weight', show: false }
];

const getDesc = desc => {
  if (desc === false || desc === 'false') return 'false';
  return 'true';
};

const getFilters = ({ filters }) => {
  // consturct the selected filters
  const filterParams = ((filters || [])
    .filter(filter => filter.show === true) || [])
    .map(query => {
      if (
        query.value === 'productDesc' ||
        query.value === 'lastUpdatedDate' ||
        query.value === 'PriceLastUpdatedDatetime' ||
        query.value === 'priceLookback'
      ) {
        return;
      }
      return query.value;
    });
  // Removed undfiend fitlers
  const finalParams = filterParams.filter(element => {
    return element !== undefined;
  });
  return finalParams.toString();
};
const formatValue = (value, label) => {
  const intValues = [];
  if (intValues.includes(label)) return parseInt(value, 10);
  return value;
};

const formatLookupQuery = ({ filters }) => ({
  Filters: getFilters({ filters }) || ''
});

const formatQuery = (
  {
    page,
    pageSize,
    sortBy,
    desc,
    search,
    count,
    status,
    ...rest
  }
) => ({
  page: page || '0',
  pageSize: pageSize || '10',
  sortBy: sortBy || 'lastUpdatedDate',
  desc: getDesc(desc),
  count: 0,
  search: search || '',
  ...rest
  // status: status || 'Pending'
});

const getQueryFromSearch = search => {
  const query = queryString.parse(search) || {};
  return formatQuery(query);
};

class ProductsSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: filterOptions,
      pending: [],
      invalidSortColumn: null,
      initialValues: {},
      searchClicked: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFetchData = this.handleFetchData.bind(this);
    this.handleDownloadAllProducts = this.handleDownloadAllProducts.bind(this);
  }

  parseQuery(search) {
    const params = queryString.parse(search);
    const multiSelect = [
      'sortBy',
      'assetTypes',
      'priceTypes',
      'manufacturerId',
      'CatEnum',
      'Stocked'
    ];
    return Object.keys(params).reduce(
      (t, p) => {
        const value = formatValue(params[p], p);
        return !multiSelect.includes(p)
          ? Object.assign({}, t, { [p]: value })
          : Object.assign({}, t, {
            [p]: Array.isArray(value) ? value.toString() : [value]
          });
      },
      {}
    );
  }

  callSearch(search) {
    const { getProducts, clearSearch } = this.props;

    if (!search) {
      clearSearch();
      return;
    }

    getProducts(this.parseQuery(search), null, true);
  }

  parseSearch(search) {
    const { getLookups } = this.props;
    const parsedSearchQuery = this.parseQuery(search);
    const filters = Array.from(this.state.filters);
    const keys = Object.keys(parsedSearchQuery);
    let initialValues = {};
    keys.map(key => {
      const value = parsedSearchQuery[key] || '';
      const initivalValue = Array.isArray(value)
        ? value
        : value.indexOf(',') >= 0 ? value.split(',') : value;
      const index = filters.findIndex(f => f.searchLabel === key);
      if (index >= 0) {
        filters[index] = { ...filters[index], show: true };
        initialValues = { ...initialValues, [key]: initivalValue };
      } else {
        if (key === 'ProdCode') {
          initialValues = { ...initialValues, [key]: initivalValue };
        }
      }
    });
    const query = formatLookupQuery({ filters });
    const qs = queryString.stringify(query);
    if (qs !== 'Filters=') {
      getLookups(qs, null, null, () => {
        this.setState({
          filters: filters,
          initialValues
        });
      });
    } else {
      this.setState({
        filters: filters,
        initialValues
      });
    }
  }

  componentWillMount() {
    const { location: { search }, getProductsCount, getAllProductsFilename } = this.props;
    // getLookups();
    getAllProductsFilename();
    this.callSearch(search);
    getProductsCount();
  }

  componentDidMount() {
    const { location: { search } } = this.props;
    this.parseSearch(search);
  }

  componentWillReceiveProps(nextProps) {
    const { location: { search } } = nextProps;
    if (this.props.location.search !== search) {
      this.callSearch(search);
      this.parseSearch(search);
    }
  }

  componentDidUpdate(prevProps) {
    const { products } = this.props;

    if (products && prevProps.products !== products) {
      if (products.count > products.downloadLimit) {
        this.setState({ searchClicked: false });
        this.setState({ searchDownloadLimit: true });
      }
      else if (products.count === 0) {
        this.setState({ searchClicked: false });
        this.setState({ searchDownloadLimit: false });
      }
      else {
        this.setState({ searchClicked: true });
        this.setState({ searchDownloadLimit: false });
      }
    }
  }

  handleSubmit(values) {
    const { history, location: { pathname, search } } = this.props;
    const query = getQueryFromSearch(search);
    const { pageSize } = query || {};
    const params = { ...values, page: '0', pageSize };
    const qs = queryString.stringify(formatQuery(params));
    history.push({ pathname, search: qs });
  }

  handleFetchData(state) {
    this.setState({ invalidSortColumn: false });
    const invalidSortColumns = [
      'priceList',
      'priceMap',
      'priceUmrp',
      'priceMrp',
      'priceGsamrp',
      'priceNet'
    ];
    const {
      location: { search },
      history,
      location: { pathname }
    } = this.props;
    const {
      page,
      pageSize,
      sorted
    } = state || {};
    const {
      id,
      desc
    } = (sorted || [])[0] || {};

    if (!search) return;
    const params = {
      ...queryString.parse(search),
      page,
      pageSize,
      sortBy: id,
      desc
    };
    if (invalidSortColumns.includes(id)) {
      this.setState({ invalidSortColumn: true });
      delete params.sortBy;
      delete params.desc;
    }
    const query = formatQuery(params);
    const qs = queryString.stringify(query);
    history.push({ pathname, search: qs });
  }

  async handleDownloadAllProducts() {
    const { downloadAllProductsFile, filename, user } = this.props;
    if (filename && user.isAdmin) {     
      downloadAllProductsFile({ filename });
    } else {
      console.error('Filename not available');
    }
  }

  render() {
    const { searchClicked } = this.state;
    const {
      products,
      filename,
      productsError,
      history,
      user,
      showModal,
      location: { search, pathname },
      lookups,
      getAllProductsFilename,
      error,
      downloadProductsFile,
      downloadAllProductsFile,
      downloadProductsInProgress,
      downloadAllProductsInProgress,
      inProgress,
      allFormValues,
      downloadProductsInitialTemplate,
      downloadProductsPriceTemplate,
      downloadProductsAssetTemplate,
      productsCount
    } = this.props;
    const { manufacturerError, searchDownloadLimit } = this.state;

    const { filters, invalidSortColumn } = this.state;

    const { totalProducts } = productsCount || {};

    //search params
    const searchParams = getQueryFromSearch(search);

    // const {
    //   inProgress,
    //   result: products
    // } = result || {};
    const setFilterDisplay = (
      formName,
      filter,
      show,
      clearFields,
      change,
      searchParams
    ) => {
      // Set the filter
      const index = filters.map(f => f.value).indexOf(filter);
      let newState = filters;
      newState[index].show = !filters[index].show;
      this.setState({ filters: newState });
      //Lookup api call
      const query = formatLookupQuery({ filters });
      // if (query && query.Filters === "") return;
      const qs = queryString.stringify(query);
      const { getLookups } = this.props;

      if (
        filter !== 'lastUpdatedDate' &&
        filter !== 'productDesc' &&
        filter !== 'PriceLastUpdatedDatetime' &&
        filter !== 'priceLookback' &&
        qs !== 'Filters='
      )
        getLookups(qs);

      // clear fields based on the filer selection
      if (!show) return;
      switch (filter) {
        case 'Categories':
          clearFields(formName, false, false, 'CatEnum');
          break;
        case 'Manufacturers':
          clearFields(formName, false, false, 'manufacturerId');
          break;
        case 'PriceTypes':
          clearFields(
            formName,
            false,
            false,
            ...[
              'priceTypes',
              'priceAmount',
              'priceAmountMax',
              'priceAmountMin',
              'priceAmountMathSymbols'
            ]
          );
          break;
        case 'productDesc':
          clearFields(
            formName,
            false,
            false,
            ...['ProdShortDesc', 'ProdLongDesc']
          );
          break;
        case 'AssetTypes':
          clearFields(formName, false, false, 'assetTypes');
          break;
        case 'Stocked':
          clearFields(formName, false, false, 'Stocked');
          break;
        case 'lastUpdatedDate':
          clearFields(formName, false, false, 'lastUpdatedDate');
          break;
        case 'PriceLastUpdatedDatetime':
          clearFields(formName, false, false, 'PriceLastUpdatedDatetime');
          break;
        case 'priceLookback':
          clearFields(formName, false, false, 'priceLookback');
          break;
      }
      // const qsSearch = queryString.stringify(searchParams);
      // history.push({ search: qsSearch });
    };
    const shouldDisplayFilter = filter =>
      filters[filters.map(f => f.value).indexOf(filter)].show;

    const resetFilter = ({ reset }) => {
      // Reset the redux form
      reset();
      let resetState = filters.map(filter => {
        if (filter.value !== 'Manufacturers') {
          return { ...filter, show: false };
        }
        return filter;
      });
      this.setState({ filters: resetState });
      const { clearSearch, location: { pathname } } = this.props;
      history.push({ pathname });
      clearSearch();
    };
    const paging = getQueryFromSearch(search);

    const {
      accountType,
      childCompanyCount,
      isAdmin,
      viewOnly,
      enableManualProducts
    } = user || {};

    if (accountType === 'manufacturer' && childCompanyCount === 0) {
      const manufacturerFilter = filterOptions.find(
        option => option.value === 'Manufacturers'
      );
      manufacturerFilter.show = false;
    }

    const loadingComp = () => {
      return inProgress
        ? <div className="-loading -active loading-table">
          <Loading fullpage message="Searching, Please Wait..." />
        </div>
        : null;
    };

    const onShowSaveModal = () => {
      const params = { ...allFormValues, page: '0' };
      const search = queryString.stringify(formatQuery(params));
      history.push({ pathname, search });
      showModal('SaveSearchModal');
    };

    return (
      <Layout route="products-search">
        <main>

          <div className="layout-header">
            <Container>

              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <Icon name="home" />
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  Products
                </li>
              </ol>

              <div className="header">
                <h1>
                  <Icon name="boxes" /> Products
                </h1>
                <div className="text-right">
                  {accountType === 'manufacturer' &&
                    !viewOnly &&
                    enableManualProducts &&
                    <Link to={'products/new'} className="btn  btn-secondary">
                      Create Product
                    </Link>}
                  {accountType === 'manufacturer' &&
                    !viewOnly &&
                    <Link
                      className="btn btn-secondary"
                      to={'products/validation'}
                    >
                      Validate File
                    </Link>}
                  {accountType === 'manufacturer' &&
                    !viewOnly &&
                    <Button
                      className="btn btn-secondary"
                      onClick={() => showModal('UploadProductsModal')}
                    >
                      Upload File
                    </Button>}
                  {accountType === 'manufacturer' && !downloadAllProductsInProgress && !downloadProductsInProgress &&
                    <DropdownButton title="Download" variant="secondary">
                      <button
                        className="dropdown-item"
                        disabled={!searchClicked}
                        onClick={() => {
                          const { location: { search } } = this.props;
                          const payload = this.parseQuery(search);
                          downloadProductsFile({
                            payload,
                            Filename: 'products.csv'
                          });

                          const prodCount = products !== null
                            ? products.count
                            : productsCount.totalProducts;
                          if (prodCount > productsCount.downloadLimit) {
                            this.setState({ manufacturerError: true });
                          } else {
                            this.setState({ manufacturerError: false });
                          }
                        }}
                      >
                        Download Results
                      </button>
                      <li
                        className="dropdown-item"
                        onClick={() => {
                          const { location: { search } } = this.props;
                          const payload = this.parseQuery(search);
                          downloadProductsInitialTemplate({
                            payload,
                            Filename: 'initial-upload-template.csv'
                          });
                        }}
                      >
                        Initial Upload Template
                      </li>
                      {totalProducts > 0 &&
                        <li
                          className="dropdown-item"
                          onClick={() => {
                            const { location: { search } } = this.props;
                            const payload = this.parseQuery(search);
                            downloadProductsAssetTemplate({
                              payload,
                              Filename: 'asset-update-template.csv'
                            });
                          }}
                        >
                          Asset Update Template
                        </li>}

                      {totalProducts > 0 &&
                        <li
                          className="dropdown-item"
                          onClick={() => {
                            const { location: { search } } = this.props;
                            const payload = this.parseQuery(search);
                            downloadProductsPriceTemplate({
                              payload,
                              Filename: 'price-update-template.csv'
                            });
                          }}
                        >
                          Price Update Template
                        </li>}
                    </DropdownButton>}
                  {isAdmin === true && !downloadAllProductsInProgress && !downloadProductsInProgress &&
                    <Button
                      className="btn btn-secondary"
                      onClick={this.handleDownloadAllProducts}
                    >
                      Download All Products (Admin Only)
                    </Button>
                    }
 
                  {accountType !== 'manufacturer' &&  !downloadAllProductsInProgress && !downloadProductsInProgress &&
                    <Button
                      className="btn btn-secondary"
                      disabled={!searchClicked}
                      onClick={() => {
                        const { location: { search } } = this.props;
                        const payload = this.parseQuery(search);
                        downloadProductsFile({
                          payload,
                          Filename: 'products.csv'
                        });

                        const prodCount = products !== null
                          ? products.count
                          : productsCount.totalProducts;
                        if (prodCount > productsCount.downloadLimit) {
                          this.setState({ manufacturerError: true });
                        } else {
                          this.setState({ manufacturerError: false });
                        }
                      }}
                    >
                      Download Results
                    </Button>}
                </div>
              </div>
            </Container>
          </div>
          <div>
            <Container>
              {manufacturerError &&
                <Alert variant="info">
                  Your current selection exceeds the maximum download limit which is currently set to
                  {' '}
                  {productsCount.downloadLimit}
                  {' '}
                  records.
                  {' '}
                  If you need to download a larger file, please get in touch with our support team for assistance. Thank you!
                </Alert>}
              {searchDownloadLimit &&
                <Alert variant="info">
                  Download Results button is disabled due to the search results exceeding the download limit of
                  {' '}
                  {productsCount.downloadLimit}
                  . If you need to download a larger file, please get in touch with our support team for assistance. Thank you!
                </Alert>}
              {downloadProductsInProgress &&
                <Loading message="Downloading Products, Please Wait..." />}
              {downloadAllProductsInProgress &&
                <Loading message="Downloading Products, Please Wait..." />}
              {!downloadProductsInProgress && !downloadAllProductsInProgress &&
                <ProductsSearchForm
                  lookups={lookups}
                  accountType={accountType}
                  initialValues={{
                    ...this.state.initialValues,
                    priceAmountMathSymbols: '='
                  }}
                  {...{
                    setFilterDisplay,
                    shouldDisplayFilter,
                    resetFilter,
                    filters
                  }}
                  onSubmit={this.handleSubmit}
                  saveSearch={() => onShowSaveModal()}
                  isAdmin={isAdmin}
                />}
              {inProgress &&
                !products &&
                <Loading message="Searching, Please Wait..." />}
              {productsError &&
                <Alert variant="danger">
                  {productsError.status === 400
                    ? productsError.message
                    : 'An error occurred in fetching the products'}
                </Alert>}
              {products &&
                !downloadProductsInProgress && !downloadAllProductsInProgress &&
                !productsError &&
                <ProductsSearchResults
                  paging={paging}
                  error={error}
                  products={products || {}}
                  history={history}
                  onFetchData={this.handleFetchData}
                  inProgress={false}
                  loadingComp={loadingComp}
                  invalidSortColumn={invalidSortColumn}
                />}
            </Container>
          </div>
          <ModalUploadProducts />
          <ModalSaveSearch searchParams={searchParams} />
        </main>
      </Layout>
    );
  }
}
const mapState = state => {
  const allFormValues = getFormValues('products-search-form')(state);
  const user = state.login.toJS().result;
  const {
    result: products,
    inProgress: productsInProgress,
    error: productsError
  } = state.getProducts.toJS();
  const {
    result: lookups,
    inProgress: lookupsInProgress,
    error: lookupsError
  } = state.getLookups.toJS();
  const {
    result: filename,
    inProgress: filenameInProgress,
    error: filenameError
  } = state.getAllProductsFilename.toJS();
  const {
    inProgress: downloadProductsInProgress,
    error: downloadProductsError
  } = state.downloadProductsFile.toJS();

  const {
    inProgress: downloadAllProductsInProgress,
    error: downloadAllProductsError
  } = state.downloadAllProductsFile.toJS();

  const {
    result: productsCount
  } = state.getProductsCount.toJS();
  return {
    products,
    productsInProgress,
    productsError,
    lookups,
    lookupsInProgress,
    filename,
    filenameInProgress,
    filenameError,
    inProgress: productsInProgress,
    error: lookupsError,
    user,
    downloadProductsInProgress,
    downloadProductsError,
    downloadAllProductsInProgress,
    downloadAllProductsError,
    allFormValues,
    productsCount
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getLookups: fetchDux.getLookups.createAction,
      getProducts: fetchDux.getProducts.createAction,
      clearSearch: fetchDux.getProducts.clearAction,
      getAllProductsFilename: fetchDux.getAllProductsFilename.createAction,
      downloadProductsFile: fetchDux.downloadProductsFile.createAction,
      downloadAllProductsFile: fetchDux.downloadAllProductsFile.createAction,
      downloadProductsInitialTemplate: fetchDux.downloadProductInitialTemplate.createAction,
      downloadProductsPriceTemplate: fetchDux.downloadProductPriceTemplate.createAction,
      downloadProductsAssetTemplate: fetchDux.downloadProductAssetTemplate.createAction,
      getProductsCount: fetchDux.getProductsCount.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ProductsSearch);
