import React from 'react';
import { Col } from 'react-bootstrap';
import moment from 'moment';
import numeral from 'numeral';
const formatPrice = d => {
  if (!d || d === 0) return '';
  return numeral(d).format('0,0.00');
};

const PriceDetails = ({ Price }) => {
  return (Price || []).length > 0
    ? <Col md={12}>
        <div className="box">
          <div className="box-header">
            <h3>Price</h3>
          </div>
          <div className="box-body">

            <div className="table-responsive">
              <table className="table table-data-horizontal">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Currency Code</th>
                    <th>Country Code</th>
                    <th>Min. Qty</th>
                    <th>Qty</th>
                    <th>UOM</th>
                    <th>Sell Qty</th>
                    <th>Effective From</th>
                    <th>Effective To</th>
                    <th>Zero Price Reason</th>
                    <th>Upload Date</th>
                  </tr>
                </thead>
                <tbody>
                  {Price.map((a, index) => {
                    return (
                      <tr key={index}>
                        <td>{a.PriceType}</td>
                        <td>{formatPrice(a.PriceAmount)}</td>
                        <td>{a.PriceCurrCode || 'USD'}</td>
                        <td>{a.PriceCtryCode}</td>
                        <td>{a.MinPriceQty}</td>
                        <td>{a.PriceQty}</td>
                        <td>{a.PriceUOM}</td>
                        <td>{a.SellQty}</td>
                        <td width={118}>
                          {a.PriceEffFrom
                            ? moment
                                .utc(a.PriceEffFrom)
                                .format('MM/DD/YYYY')
                                .toUpperCase()
                            : ''}
                        </td>
                        <td width={100}>
                          {a.PriceEffTo
                            ? moment
                                .utc(a.PriceEffTo)
                                .format('MM/DD/YYYY')
                                .toUpperCase()
                            : ''}
                        </td>
                        <td>{a.ZeroPriceReason}</td>
                        <td width={100}>
                          {a.timeStamp
                            ? moment
                                .utc(a.timeStamp)
                                .format('MM/DD/YYYY, hh:mm:ss A')
                                .toUpperCase()
                            : '-'}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Col>
    : null;
};

export default PriceDetails;
