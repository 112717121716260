import React, { useState } from 'react';
import { Col, Button, Collapse } from 'react-bootstrap';
const PackDetails = ({ Pack }) => {
  const [packShippingIndex, setPackShippingIndex] = useState(false);
  return (Pack || []).length > 0
    ? <Col sm={12}>
        <div className="box">
          <div className="box-header">
            <h3>Pack</h3>
          </div>
          <div className="box-body">
            <table className="table table-data-features">
              <thead>
                <tr>
                  <th>PackCodeType</th>
                  <th>PackCode</th>
                  <th>PackUOM</th>
                  <th>PackQty</th>
                  <th>PackDesc</th>
                  <th />
                </tr>
              </thead>
              {Pack.map((p, index) => {
                const { Shipping } = p;
                return (
                  <tbody key={index}>
                    <tr>
                      <td>{p.PackCodeType || ''}</td>
                      <td>{p.PackCode || ''}</td>
                      <td>{p.PackUOM || ''}</td>
                      <td>{p.PackQty || ''}</td>
                      <td>{p.PackDesc || ''}</td>
                      <td className="text-right" style={{ width: '200px' }}>
                        {packShippingIndex === index
                          ? <Button
                              size="sm"
                              variant="outline-primary"
                              onClick={() => setPackShippingIndex(-1)}
                            >
                              Hide Shipping
                            </Button>
                          : <Button
                              size="sm"
                              variant="primary"
                              onClick={() => setPackShippingIndex(index)}
                            >
                              Show Shipping
                            </Button>}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={6}>
                        <Collapse in={packShippingIndex === index}>
                          <div className="price">
                            {Shipping &&
                              <table className="table table-data">
                                <tbody>
                                  <tr>
                                    <th>Frt Class Code</th>
                                    <td>{Shipping.FrtClassCode}</td>
                                  </tr>
                                  <tr>
                                    <th>LTL Only Flag</th>
                                    <td>{Shipping.LTLOnlyFlg}</td>
                                  </tr>
                                  <tr>
                                    <th>NMFC Code</th>
                                    <td>{Shipping.NMFCCode}</td>
                                  </tr>
                                  <tr>
                                    <th>Primary Shipping Zip</th>
                                    <td>{Shipping.PrimShipZip}</td>
                                  </tr>
                                  <tr>
                                    <th>Depth</th>
                                    <td>{Shipping.ShipDep}</td>
                                  </tr>
                                  <tr>
                                    <th>Height</th>
                                    <td>{Shipping.ShipHt}</td>
                                  </tr>
                                  <tr>
                                    <th>Width</th>
                                    <td>{Shipping.ShipWid}</td>
                                  </tr>
                                  <tr>
                                    <th>Dimensions Unit of Measure</th>
                                    <td>{Shipping.ShipDimUOM}</td>
                                  </tr>
                                  <tr>
                                    <th>Weight</th>
                                    <td>{Shipping.ShipWt}</td>
                                  </tr>
                                  <tr>
                                    <th>Weight Unit of Measure</th>
                                    <td>{Shipping.ShipWtUOM}</td>
                                  </tr>
                                  <tr>
                                    <th>Volume</th>
                                    <td>{Shipping.ShipVol}</td>
                                  </tr>
                                  <tr>
                                    <th>Volume Unit of Measure</th>
                                    <td>{Shipping.ShipVolUOM}</td>
                                  </tr>
                                  <tr>
                                    <th>Free Shipping Available</th>
                                    <td style={{ wordBreak: 'break-all' }}>
                                      {Shipping.FreeShipAvail}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Free Shipping Conditions</th>
                                    <td style={{ wordBreak: 'break-all' }}>
                                      {Shipping.FreeShipCond}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Notes</th>
                                    <td style={{ wordBreak: 'break-all' }}>
                                      {Shipping.ShipNotes}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>}
                          </div>
                        </Collapse>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>
      </Col>
    : null;
};

export default PackDetails;
