import React from 'react';
import Alert from 'react-bootstrap/Alert';
import {
  BsInfoCircle,
  BsExclamationTriangle,
  BsCheckCircle,
  BsXCircle
} from 'react-icons/bs';

const AlertBox = ({ children, variant }) => {
  const iconSize = '1.5rem';
  const getIcon = () => {
    switch (variant) {
      case 'info':
        return <BsInfoCircle size={iconSize} />;
      case 'warning':
        return <BsExclamationTriangle size={iconSize} />;
      case 'success':
        return <BsCheckCircle size={iconSize} />;
      case 'danger':
        return <BsXCircle size={iconSize} />;
      default:
        return null;
    }
  };

  return (
    <Alert variant={variant}>
      <div className="d-flex align-items-center">
        <div className="mr-3">
          {getIcon()}
        </div>
        <div>
          {children}
        </div>
      </div>
    </Alert>
  );
};

export default AlertBox;
