import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import fetchDux from '../../../../state/fetch-dux';

class ResetUserPasswordModal extends Component {
  render() {
    const {
      openModal,
      closeModal,
      user,
      name,
      inProgress
    } = this.props;
    const {
      _id,
      nameFirst
    } = user || {};
    const handleResetPassword = () => {
      //Api call for sending reset password email link
      this.props.resetPasswordById({ _id }, null, null, () => {
        closeModal();
      });
    };
    return (
      <Modal show={openModal === name} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Reset User Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            Are you sure you wish to reset password for user <b>{nameFirst}</b>?
          </h5>
          <div className="text-muted">
            User will receive an email with link to password reset page.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={closeModal}
            disabled={inProgress}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={handleResetPassword}
            disabled={inProgress}
          >
            Reset Password
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
const mapState = state => {
  const { inProgress } = state.resetPasswordById.toJS();
  return {
    inProgress,
    openModal: state.modals
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      resetPasswordById: fetchDux.resetPasswordById.createAction
    },
    dispatch
  );
export default connect(mapState, mapDispatch)(ResetUserPasswordModal);
