import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Reporting from './reporting/Reporting';
import FAQs from './faqs/FAQs';

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}/reporting`} component={Reporting} />
    <Route path={`${match.url}/faqs`} component={FAQs} />
    <Redirect to={`${match.url}`} />
  </Switch>
);
