import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Col, Row, Overlay, Tooltip } from 'react-bootstrap';
import numeral from 'numeral';
import Icon from '../../../../ui/Icon';
import ReactDOM from 'react-dom';
const ManufacturerMetrics = ({ adminMetricsResult }) => {
  const [show, toggleShow] = useState(false);
  const [target, setTarget] = useState();
  const [toolTipText, setToolTipText] = useState('');
  const [textFontSize, setTextFontSize] = useState(36);

  const totalManufacturersRef = React.createRef();
  const registeredManufacturersRef = React.createRef();
  const activeManufacturersRef = React.createRef();
  const uploadManufacturersRef = React.createRef();
  const uploadActivityRef = React.createRef();
  const textContainerRef = React.createRef();
  const textRef = React.createRef();

  const windowPointerUpHandler = event => {
    const id = event.target.id;
    if (id !== 'iconDiv') {
      toggleShow(false);
    }
  };
  useEffect(
    () => {
      window.addEventListener('pointerup', windowPointerUpHandler);
      return () => {
        window.removeEventListener('pointerup', windowPointerUpHandler);
      };
    },
    []
  );

  useLayoutEffect(() => {
    try {
      const textContainerRect = textContainerRef.current.getBoundingClientRect();
      const textRect = textRef.current.getBoundingClientRect();
      const { width: containerWidth } = textContainerRect;
      const { width: textWidth } = textRect;
      if (textWidth > containerWidth) {
        const fontSize = textFontSize - 1;
        setTextFontSize(fontSize);
      }
    } catch (e) { }
  });

  const getTarget = () => {
    return ReactDOM.findDOMNode(target);
  };

  const sharedProps = {
    container: this,
    target: getTarget,
    show: show
  };

  const showToolTip = (nextTraget, text) => {
    if (show && target === nextTraget) {
      toggleShow(false);
      return;
    }
    setTarget(nextTraget);
    setToolTipText(text);
    toggleShow(true);
  };

  const {
    totalManufacturersCount,
    registeredManufacturersCount,
    activeCompanies,
    atleaseOneProductUploadedCount,
    totalProducts,
    mfrCurrentWeekProductUploadCount,
    mfrLastWeekProductUploadCount,
    mfrThreeWeeksBeforeProductUploadCount,
    mfrTwoWeeksBeforeProductUploadCount
  } = adminMetricsResult || {};

  return (
    <Row>

      <Col sm={4}>
        <div className="metricsTile">
          <div className="title">Total Manufacturers</div>
          <div className="contentDiv">
            <div className="dataContainer">
              <span>{numeral(totalManufacturersCount).format('0,0')}</span>
              <span>companies</span>
            </div>
          </div>
          <div
            className="iconDiv"
            ref={totalManufacturersRef}
            id="iconDiv"
            onClick={() => {
              showToolTip(
                totalManufacturersRef.current,
                `Total manufacturer companies in the repository.`
              );
            }}
          >
            <Icon name="info" />
          </div>
        </div>
      </Col>

      <Col sm={4}>
        <div className="metricsTile">
          <div className="title">Current Manufacturer Company Users</div>
          <div className="contentDiv">
            <div className="dataContainer">
              <span>{numeral(registeredManufacturersCount).format('0,0')}</span>
              <span>users</span>
            </div>
          </div>
          <div
            className="iconDiv"
            ref={registeredManufacturersRef}
            id="iconDiv"
            onClick={() => {
              showToolTip(
                registeredManufacturersRef.current,
                `Companies with at least 1 user who has created a password.`
              );
            }}
          >
            <Icon name="info" />
          </div>
        </div>
      </Col>
      {/* <Col sm={4}>
                <div className="metricsTile">
                    <div className='title'>Active Manufacturers</div>
                    <div className='contentDiv'>
                        <div className='dataContainer'>
                            <span>{numeral(activeCompanies).format('0,0')}</span>
                            <span>companies</span>
                        </div>
                    </div>
                    <div
                        className='iconDiv'
                        ref={activeManufacturersRef}
                        id='iconDiv'
                        onClick={
                            () => {
                                showToolTip(
                                    activeManufacturersRef.current,
                                    `Companies with at least 1 user who has logged in.`
                                )
                            }
                        }
                    >
                        <Icon name='info' />
                    </div>
                </div>
            </Col> */}
      <Col sm={4}>
        <div className="metricsTile">
          <div className="title">Uploaded Manufacturers</div>
          <div className="contentDiv multi less-padding">
            <div className="dataContainer">
              <span>
                {numeral(atleaseOneProductUploadedCount).format('0,0')}
              </span>
              <span>companies</span>
            </div>
            <div className="dataContainer fixed-width" ref={textContainerRef}>
              <div ref={textContainerRef}>
                <span ref={textRef} style={{ fontSize: `${textFontSize}pt` }}>
                  {numeral(
                    parseInt(totalProducts / atleaseOneProductUploadedCount)
                  ).format('0,0')}
                </span>
              </div>

              <span>avg products per company</span>
            </div>
          </div>
          <div
            className="iconDiv"
            id="iconDiv"
            ref={uploadManufacturersRef}
            onClick={() => {
              showToolTip(
                uploadManufacturersRef.current,
                `Companies with at least 1 product uploaded. 
                                    As well as an average of the product upload count.`
              );
            }}
          >
            <Icon name="info" />
          </div>
        </div>
      </Col>
      <Col sm={6}>
        <div className="metricsTile with-margin-top">
          <div className="title">Upload Activity</div>
          <div className="contentDiv multi less-padding flex-start">
            <div className="containerVertical">
              <div className="dataContainer mini">
                <span>
                  {numeral(mfrCurrentWeekProductUploadCount).format('0,0')}
                </span>
                <span>companies</span>
              </div>
              <div className="iconContainer flex-end">
                <Icon name="circle" />
                <div className="ruler" />
              </div>
              <label>Current Week</label>
            </div>

            <div className="containerVertical">
              <div className="dataContainer mini">
                <span>
                  {numeral(mfrLastWeekProductUploadCount).format('0,0')}
                </span>
                <span>companies</span>
              </div>
              <div className="iconContainer">
                <div className="ruler" />
                <Icon name="clock" prefix="far" />
                <div className="ruler" />
              </div>
              <label>Last Week</label>
            </div>

            <div className="containerVertical">
              <div className="dataContainer mini">
                <span>
                  {numeral(mfrTwoWeeksBeforeProductUploadCount).format('0,0')}
                </span>
                <span>companies</span>
              </div>
              <div className="iconContainer">
                <div className="ruler" />
                <Icon name="clock" prefix="far" />
                <div className="ruler" />
              </div>
              <label>2 Weeks</label>

            </div>
            <div className="containerVertical">
              <div className="dataContainer mini">
                <span>
                  {numeral(mfrThreeWeeksBeforeProductUploadCount).format('0,0')}
                </span>
                <span>companies</span>
              </div>
              <div className="iconContainer">
                <div className="ruler" />
                <Icon name="clock" prefix="far" />
              </div>
              <label>3 Weeks</label>
            </div>
          </div>
          <div
            className="iconDiv extra"
            id="iconDiv"
            ref={uploadActivityRef}
            onClick={() => {
              showToolTip(
                uploadActivityRef.current,
                `How many companies have uploaded for
                                    each of the past 4 weeks. (Includes current week.)`
              );
            }}
          >
            <Icon name="info" />
          </div>
        </div>
      </Col>
      <Overlay {...sharedProps} placement="bottom">
        <Tooltip id="overload-bottom">{toolTipText}</Tooltip>
      </Overlay>
    </Row>
  );
};

export default ManufacturerMetrics;
