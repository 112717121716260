import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Row, Col, Alert } from 'react-bootstrap';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import FormField from '../../forms/FormField';
import Icon from '../../ui/Icon';
const required = value => value ? undefined : 'Required';

const FtpForm = (props) => {
  const {
    closeModal,
    handleSubmit,
    inProgress,
    createFTPJobsError,
    testConnection,
    clearTestConnection,
    username,
    address,
    directory,
    password,
    fileName,
    port,
    testingError,
    testingInprogress,
    testingResult
  } = props;

  const { success } = testingResult || {};
  const handleTesting = async () => {
    const payload = {
      username,
      address,
      directory,
      password,
      fileName,
      port
    };
    await testConnection(payload);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Modal.Body>
        <Row>
          <Col lg={6}>

            <Field
              name="label"
              type="text"
              component={FormField.Input}
              label="Label"
              validate={[required]}
            />

          </Col>
        </Row>
        <Row>
          <Col lg={6}>

            <Field
              name="address"
              type="text"
              component={FormField.Input}
              label="Address"
              validate={[required]}
            />

          </Col>
          <Col lg={6}>

            <Field
              name="port"
              type="text"
              component={FormField.Input}
              label="Port"
              validate={[required]}
            />

          </Col>
        </Row>

        <Row>
          <Col lg={6}>

            <Field
              name="username"
              type="text"
              component={FormField.Input}
              label="Username"
              validate={[required]}
            />

          </Col>
          <Col lg={6}>

            <Field
              name="password"
              type="password"
              component={FormField.Input}
              label="Password"
              validate={[required]}
            />

          </Col>
        </Row>

        <Row>
          <Col lg={6}>

            <Field
              name="directory"
              type="text"
              component={FormField.Input}
              label="Directory"
              validate={[required]}
            />

          </Col>
          <Col lg={6}>

            <Field
              name="fileName"
              type="text"
              component={FormField.Input}
              label="File Name"
              validate={[required]}
            />

          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field
              name="startDate"
              component={FormField.Datetimepicker}
              label="Start Date"
              validate={[required]}
              required
            />
          </Col>
          <Col md={6}>
            <Field
              name="frequency"
              options={[
                { value: 'daily', label: 'Daily' },
                { value: 'weekly', label: 'Weekly' },
                { value: 'monthly', label: 'Monthly' }
              ]}
              component={FormField.SelectReact}
              label="Frequency"
              validate={[required]}
              required
            />
          </Col>
        </Row>

        {createFTPJobsError && (
          <Row>
            <Col md={6}>
              <Alert variant="danger">{createFTPJobsError.message}</Alert>
            </Col>
          </Row>
        )}

      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          variant="outline-secondary"
          onClick={() => {
            clearTestConnection();
            closeModal();
          }}
          disabled={inProgress || testingInprogress}
        >
          Cancel
        </Button>
        <div className="test-connection">
          {testingError &&
            <div style={{ color: 'red', alignSelf:'center' }}>
              {testingError.message}
            </div>}
          {success &&
            <div style={{ color: 'green', alignSelf:'center' }}>
              Success! All checks complete.
            </div>}
          {!testingInprogress &&
            <Button
              size="sm"
              variant="secondary"
              disabled={testingInprogress}
              onClick={handleTesting}
              type="button"
              style={{width:'150px'}}
            >
              Test
            </Button>}
          {testingInprogress &&
            <Button
              size="sm"
              variant="secondary"
              disabled={testingInprogress}
              type="button"
              style={{width:'150px'}}
            >
              <Icon name="redo-alt" spin /> Testing
            </Button>}
          <Button
            size="sm"
            variant="primary"
            type="submit"
            disabled={inProgress || testingInprogress}
          >
            Create
          </Button>
        </div>
      </Modal.Footer>
    </form>
  );
};

const formName = 'save-ftp-modal-form';
const selector = formValueSelector(formName);
const mapState = state => {
  const username = selector(state, 'username');
  const address = selector(state, 'address');
  const directory = selector(state, 'directory');
  const password = selector(state, 'password');
  const fileName = selector(state, 'fileName');
  const port = selector(state, 'port');
  const {
    result: testingResult,
    inProgress: testingInprogress,
    error: testingError
  } = state.testConnection.toJS();
  return {
    username,
    address,
    directory,
    password,
    fileName,
    port,
    testingInprogress,
    testingError,
    testingResult
  };
};
const form = reduxForm({
  form: formName,
  enableReinitialize: true
})(FtpForm);

export default connect(mapState)(form);
