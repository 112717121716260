import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Icon from '../../../ui/Icon';

const FAQsGroupArticles = ({ articles }) => {
  const [expand, setExpand] = useState(false);
  return (
    <div>
      <ul>
        {(articles || []).map((article, j) => (
          <li key={j} className={j > 4 && !expand ? 'hide' : ''}>
            <Link to={`/faqs/${article.url}`}>
              {article.question}
            </Link>
          </li>
        ))}
      </ul>
      {(articles || []).length > 5 &&
        <div className="show-all" onClick={() => setExpand(!expand)}>
          {!expand && <span>Show all <Icon name="angle-down" /></span>}
          {expand && <span>Show fewer links <Icon name="angle-up" /></span>}
        </div>}
    </div>
  );
};

const FAQsSearchDefault = ({ results }) => {
  return (
    <div className="faq-default-results">
      <Row>
        {(results || []).map((group, i) => (
          <Col sm={2} md={4} key={i} className="faq-group">
            <h5>{group.category}</h5>
            <FAQsGroupArticles articles={group.articles} />
          </Col>
        ))}
      </Row>
    </div>
  );
};
export default FAQsSearchDefault;
