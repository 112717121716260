import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import localForage from 'localforage';
import store from './state/store';
import { actionNamespace } from './state/defaults';

import './styles/index.scss';

import App from './components/App';

const main = async () => {
  const identity = await localForage.getItem('identity');
  if (identity) {
    if (identity.exp < new Date().getTime() / 1000) {
      localForage.removeItem('identity');
    } else {
      store.dispatch({
        type: `${actionNamespace}/POST_LOGIN_SUCCESS`,
        payload: identity
      });
    }
  }

  ReactDOM.render(
    <Provider {...{ store }}>
      <App />
    </Provider>,
    document.getElementById('root')
  );
};

main();
