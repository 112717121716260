import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import UsersSearch from './users-search/UsersSearch.js';
import UsersCreate from './users-create/UsersCreate.js';
import UsersDetails from './users-details/UsersDetails.js';
import UsersEdit from './users-edit/UsersEdit.js';

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={UsersSearch} />
    <Route exact path={`${match.url}/create`} component={UsersCreate} />
    <Route exact path={`${match.url}/:id`} component={UsersDetails} />
    <Route exact path={`${match.url}/:id/edit`} component={UsersEdit} />
    <Redirect to={`${match.url}`} />
  </Switch>
);
