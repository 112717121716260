import { Modal, ModalTitle } from 'react-bootstrap';
import React from 'react';
import ReferenceForm from './ReferenceForm';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../state/modals-dux';
import fetchDux from '../../../state/fetch-dux';
import { connect } from 'react-redux';

const AddReferenceModal =
    ({
        openModal,
        name,
        closeModal,
        selectedLookup,
        addProductReference,
        inProgress,
        miscData,
        error,
        clearAddProductReference
    }) => {
        const onHideHandler = () => {
            if (!inProgress) {
                closeModal(name);
                clearAddProductReference();
            }
        }
        return (
            <Modal show={openModal === name} onHide={() => onHideHandler()}>
                <Modal.Header closeButton>
                    <ModalTitle>
                        Add Record
                    </ModalTitle>
                </Modal.Header>
                <ReferenceForm
                    closeModal={closeModal}
                    selectedLookup={selectedLookup}
                    miscData={miscData}
                    addError={error}
                    onSubmit={(values) => {
                        const payload = { ...values, lookup: selectedLookup };
                        addProductReference(payload, 'PRODUCT_REFERENCES_FORM', null, (result) => {
                            const { ok } = result;
                            if (ok) onHideHandler();
                        });
                    }}
                />
            </Modal>
        )
    }


const mapState = state => {
    const {
        error,
        inProgress
    } = state.addProductReference.toJS();

    const {
        result: miscData
    } = state.getProductMiscLookups.toJS();

    return {
        error,
        openModal: state.modals,
        inProgress,
        miscData
    }
}

const mapDispatch = dispatch =>
    bindActionCreators({
        ...actionCreators,
        addProductReference: fetchDux.addProductReference.createAction,
        clearAddProductReference: fetchDux.addProductReference.clearAction
    }, dispatch);


export default connect(mapState, mapDispatch)(AddReferenceModal);