import React from 'react';
import { Col } from 'react-bootstrap';
import Table from '../../../../ui/Table';
import { Link } from 'react-router-dom';

const getColumns = (reportType, companyType) => {
  switch (reportType) {
    case 'Contacts Report':
      return [
        {
          Header: 'Company Imis ID',
          accessor: 'companyImisId',
          sortable: true,
          resizable: true,
          show: true,
          className: 'text-center'
        },
        {
          Header: 'Company Name',
          accessor: 'companyName',
          sortable: true,
          resizable: true,
          show: true,
          className: 'text-center',
          Cell: ({ row }) => (
            <Link to={`/${companyType}s/${row.parentCompanyId}`} target='_blank'>
              {row.companyName}
            </Link>
          )
        },
        {
          Header: 'Parent Company ID',
          accessor: 'parentCompanyId',
          sortable: true,
          resizable: true,
          show: false,
          className: 'text-center'
        },
        {
          Header: 'User Imis ID',
          accessor: 'imisId',
          sortable: true,
          resizable: true,
          show: true,
          className: 'text-center'
        },
        {
          Header: 'User ID',
          accessor: 'userId',
          sortable: true,
          resizable: true,
          show: false,
          className: 'text-center'
        },
        {
          Header: 'First Name',
          accessor: 'first_name',
          sortable: true,
          resizable: true,
          show: true,
          className: 'text-center'
        },
        {
          Header: 'Last Name',
          accessor: 'last_name',
          sortable: true,
          resizable: true,
          show: true,
          className: 'text-center'
        },
        {
          Header: 'Email',
          accessor: 'email',
          sortable: true,
          resizable: true,
          show: true,
          className: 'text-center',
          Cell: ({ row }) => (
            <Link to={`/users/${row.userId}`} target='_blank'>
              {row.email}
            </Link>
          )
        }
      ];
    default:
  }
};

const DistributorsContactsReport = (
  {
    result,
    selectedReportType,
    defaultPageSize,
    inProgress,
    handleFetchData,
    activeColumns,
    companyTypeValue
  }
) => {
  const { results = [], count, numPages } = result || {};
  const columns = getColumns(selectedReportType, companyTypeValue);
  if (activeColumns.length !== 0) {
    columns.forEach(col => {
      col.show = activeColumns.includes(col.accessor);
    });
    columns[2].show = false;
  }
  return (
    <Col sm={12}>
      <div className="table-activity-log">
        <Table
          manual
          data={results}
          columns={columns}
          key={selectedReportType}
          defaultPageSize={defaultPageSize}
          showPagination={true}
          noDataText={inProgress ? 'Loading' : 'No data found'}
          pages={numPages || 0}
          defaultSorted={[
            {
              id: 'companyName',
              desc: false
            }
          ]}
          onFetchData={handleFetchData}
          onColumnChange={activeColumns => {
            window.localStorage.setItem('companyImisId', activeColumns);
          }}
          showColumnsSelector={true}
        />
      </div>

    </Col>
  );
};

export default DistributorsContactsReport;
