import React, { Component } from 'react';
import { Container, Button, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import Table from '../../../ui/Table';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import fetchDux from '../../../../state/fetch-dux';

import DeleteManufacturerModal from './DeleteManufacturerModal';
import DeleteChildModal from './DeleteChildModal';
import DisableManufacturerModal from './DisableManufacturerModal';
import AddUserModal from './AddUserModal';
import AddCompanyModal from './AddCompanyModal';
import Hidden from '../../../ui/Hidden';
import moment from 'moment';
import FileDownload from '../../../ui/FileDownload';
import Loading from '../../../ui/Loading';
import CopyButton from '../../../ui/CopyButton';

const columnsLogs = [
  {
    Header: 'Message',
    accessor: 'message'
  },
  {
    Header: 'File Name',
    accessor: 'filename'
  },
  // {
  //   Header: 'IP Address',
  //   accessor: 'ipAddress'
  // },
  {
    Header: 'User',
    accessor: 'user',
    Cell: ({ value, original: row }) => {
      const user = value[0] || row.user[0];
      if (!user) return '';
      return (
        <Link to={`/users/${user._id}`}>
          {user.nameFirst} {user.nameLast}
        </Link>
      );
    }
  },
  {
    Header: 'Date',
    accessor: 'transactionTime',
    Cell: ({ value }) => {
      if (!value || !moment(value).isValid()) return '';
      return moment(value).format('MM/DD/YYYY hh:mm a');
    }
  }
];

const columnsUsers = [
  // {
  //   Header: 'IMIS ID',
  //   accessor: 'imisId'
  // },
  {
    Header: 'First Name',
    accessor: 'nameFirst'
  },
  {
    Header: 'Last Name',
    accessor: 'nameLast'
  },
  {
    Header: 'User Id',
    accessor: '_id',
    Cell: ({ value, original: row }) => (
      <Link to={`/users/${row._id}`}>{value}</Link>
    )
  }
];

const columnsProducts = [
  {
    Header: 'Product Name',
    accessor: 'name'
  }
];

class ManufacturersDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: ''
    };
  }
  componentWillMount() {
    this.props.getUsers();
  }

  componentWillReceiveProps(nextProps) {
    const {
      match
    } = this.props;
    const { id } = match.params || {};

    const {
      match: nextMatch
    } = nextProps;
    const { id: nextId } = nextMatch.params || {};

    if (nextId !== id) {
      this.props.getManufacturerById(nextId);
      this.props.getManufacturerLogs(nextId);
    }
  }
  componentDidMount() {
    const {
      match
      //  getManufacturerById
    } = this.props;
    const { id } = match.params || {};
    this.props.getManufacturerById(id);
    this.props.getManufacturerLogs(id);
  }

  limitCharacters(string, limit) {
    if (string.length > limit) {
      return string.substring(0, limit) + '...';
    } else {
      return string;
    }
  }

  render() {
    const {
      inProgress,
      result,
      match,
      history,
      showModal,
      usersResult,
      logsResult,
      postToggleManufacturerStatus
    } = this.props;
    const { id } = match.params || {};
    const {
      result: manufacturer,
      inProgress: manufacturerLoading
    } = result || {};
    const {
      _id,
      name,
      imisId,
      users,
      products,
      apiKey,
      childManufacturers,
      parent,
      parentManufacturer,
      shortKey,
      enableManualProducts,
      inProgress: isLoading
    } = manufacturer || {};

    const {
      name: parentName
    } = parent || {};

    const {
      result: logs,
      inProgress: manufacturerLogsLoading
    } = logsResult || {};

    const columnsChildren = [
      {
        Header: 'IMIS ID',
        accessor: 'imisId'
      },
      {
        Header: 'Company  Name',
        accessor: 'name',
        Cell: ({ value, original }) => {
          const { _id } = original;
          return (
            <div className="text-left">
              <Link to={`/manufacturers/${_id}`}>{value}</Link>
            </div>
          );
        }
      },
      {
        Header: 'Actions',
        accessor: '_id',
        width: 240,
        Cell: ({ value, original: row }) => (
          <div className="text-right">
            <Button
              className="btn btn-danger btn-xs"
              style={{ padding: '0.25rem 0.5rem' }}
              onClick={() => {
                this.setState({
                  company: { companyId: value, name: row.name }
                });
                showModal('DeleteChildModal');
              }}
            >
              Delete
            </Button>
          </div>
        )
      }
    ];

    return (
      <Layout route="manufacturers-details">
        <main>
          <div className="layout-header">
            <Container>

              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <Icon name="home" />
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/manufacturers">
                    Manufacturers
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  Manufacturer Details
                </li>
              </ol>

              <div className="header">
                <h1>
                  <Icon name="industry" /> {name}
                </h1>
                <div>
                  {/* <Button
                    className="btn btn-secondary"
                    onClick={() => showModal('AddUserModal')}
                  >
                    Add User
                  </Button> */}
                  <Link
                    to={`/manufacturers/${id}/edit`}
                    className="btn btn-secondary"
                  >
                    Edit
                  </Link>
                  <Button
                    className="btn btn-danger"
                    onClick={() => showModal('DeleteManufacturerModal')}
                  >
                    Delete
                  </Button>
                </div>
              </div>

            </Container>
          </div>

          <Container>
            {manufacturerLoading && <Loading />}
            {!manufacturerLoading &&
              <div>

                <Row>
                  <Col md={6}>

                    <div className="box">
                      <div className="box-header">
                        <h3>Manufacturer Details</h3>
                      </div>
                      <div className="box-body">

                        <table className="table table-data">
                          <tbody>
                            <tr>
                              <th>IMIS ID</th>
                              <td>{imisId}</td>
                            </tr>
                            <tr>
                              <th>Short Key</th>
                              <td>{shortKey || '-'}</td>
                            </tr>
                            <tr>
                              <th>Name</th>
                              <td>{name}</td>
                            </tr>
                            <tr>
                              <th>Api Key</th>
                              <td>
                                <Row>
                                  <Col>
                                    {!apiKey
                                      ? 'No Api Key Generated'
                                      : this.limitCharacters(apiKey, 15)}
                                  </Col>
                                  <Col><CopyButton text={apiKey} /></Col>
                                </Row>
                              </td>
                            </tr>
                            <tr>
                              <th>Parent Company</th>
                              <td>{parentName || '-'}</td>
                            </tr>
                          </tbody>
                        </table>

                      </div>
                    </div>

                  </Col>

                  <Col md={6}>
                    <div className="box">
                      <div className="box-header">
                        <h3>Manufacturer Settings</h3>
                      </div>
                      <div className="box-body">
                        <table className="table table-data">
                          <tbody>
                            <tr>
                              <th>Product Editing</th>
                              <td>
                                {enableManualProducts
                                  ? <Icon
                                      name="check"
                                      className="text-success"
                                    />
                                  : <Icon
                                      name="times"
                                      className="text-danger"
                                    />}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Tabs defaultActiveKey="users" className="box-tabs">
                  {/* <Tab eventKey="products" title="Products">

                  <Table
                    showSearch
                    data={products || []}
                    columns={columnsProducts}
                    noDataText="No products found"
                  />

                </Tab> */}
                  <Tab
                    eventKey="users"
                    title={`Users (${(users || []).length || 0})`}
                  >
                    <div className="sm addCompany">
                      <Link to={`/users/create?id=${_id}`}>
                        <Button size="sm">Create User</Button>
                      </Link>
                    </div>
                    <Table
                      showSearch
                      data={users || []}
                      columns={columnsUsers}
                      noDataText={
                        isLoading
                          ? <Loading message="Loading users..." />
                          : 'No users found'
                      }
                      getTrProps={(c, value) => {
                        const { original } = value || {};
                        if (!original) return {};
                        const { _id } = original || {};
                        return {
                          style: {
                            cursor: 'pointer'
                          },
                          onClick: () => {
                            history.push(`/users/${_id}`);
                          }
                        };
                      }}
                    />

                  </Tab>
                  <Tab
                    eventKey="children"
                    title={
                      `Children (${(childManufacturers || []).length || 0})`
                    }
                  >
                    <div>
                      <Button
                        className="sm addCompany"
                        onClick={() => {
                          showModal('AddCompanyModal');
                        }}
                        disabled={!!parentManufacturer}
                      >
                        Add Company
                      </Button>
                      {!!parentManufacturer &&
                        <span className="sm danger addCompanyText">
                          Children companies may not add children records.
                        </span>}
                      <div>
                        <Table
                          showSearch
                          data={childManufacturers || []}
                          columns={columnsChildren}
                          noDataText={
                            isLoading
                              ? <Loading
                                  message="Loading child manufacturer..."
                                />
                              : 'No child found'
                          }
                        />
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="logs" title="Logs">

                    <Table
                      showSearch
                      data={logs || []}
                      columns={columnsLogs}
                      noDataText={
                        manufacturerLogsLoading
                          ? <Loading message="Loading logs..." />
                          : 'No logs found'
                      }
                    />

                  </Tab>
                </Tabs>

              </div>}

          </Container>

          <DeleteManufacturerModal
            name="DeleteManufacturerModal"
            manufacturer={manufacturer || {}}
            match={match}
            history={history}
          />
          <DeleteChildModal
            name="DeleteChildModal"
            manufacturerId={_id || {}}
            companyDetails={this.state.company || {}}
            match={match}
            history={history}
          />
          <AddUserModal name="AddUserModal" usersResult={usersResult} />
          <AddCompanyModal
            name="AddCompanyModal"
            manufacturerId={_id || {}}
            history={history}
          />

        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  const result = state.getManufacturerById.toJS();
  const usersResult = state.getUsers.toJS();
  const logsResult = state.getManufacturerLogs.toJS();

  return {
    result,
    usersResult,
    logsResult
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getManufacturerById: fetchDux.getManufacturerById.createAction,
      getUsers: fetchDux.getUsers.createAction,
      getManufacturerLogs: fetchDux.getManufacturerLogs.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ManufacturersDetails);
