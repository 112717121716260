import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import FAQsSearch from './faqs-search/FAQsSearch.js';
import FAQDetails from './faq-details/FAQDetails.js';

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={FAQsSearch} />
    <Route exact path={`${match.url}/:id`} component={FAQDetails} />
    <Redirect to={`${match.url}`} />
  </Switch>
);
