import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import fetchDux from '../../../../state/fetch-dux';
import ManufacturersCreateForm from './ManufacturersCreateForm';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class ManufacturersCreate extends Component {
  componentDidMount() {
    this.props.clearCreateManufacturer();
  }
  render() {
    const {
      history,
      error
    } = this.props;
    const handleSubmit = values => {
      this.props.createManufacturer(
        values,
        'manufacturer-create-form',
        null,
        manufacturer => {
          const { manufacturerId } = manufacturer || {};
          history.push(`/manufacturers/${manufacturerId}`);
        }
      );
    };
    return (
      <Layout route="manufacturers-create">
        <main>

          <div className="layout-header">
            <Container>

              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <Icon name="home" />
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/manufacturers">
                    Manufacturers
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  Create Manufacturer
                </li>
              </ol>

              <div className="header">
                <h1>
                  <Icon name="industry" /> Create New Manufacturer
                </h1>
              </div>

            </Container>
          </div>

          <Container>
            <ManufacturersCreateForm
              manufacturerCreateError={error}
              onSubmit={handleSubmit}
            />
          </Container>

        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result: values,
    error
  } = state.createManufacturer.toJS();

  return {
    values,
    error
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      createManufacturer: fetchDux.createManufacturer.createAction,
      clearCreateManufacturer: fetchDux.createManufacturer.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ManufacturersCreate);
