import React from 'react';
import { Route } from 'react-router';
import { Navbar } from 'react-bootstrap';

export default (
  {
    to,
    exact,
    strict,
    isActive,
    ...rest
  }
) => (
  <Route
    path={typeof to === 'object' ? to.pathname : to}
    exact={exact}
    strict={strict}
    children={({ location, match, history }) => {
      return (
        <Navbar.Brand
          {...rest}
          href={to}
          onClick={e => {
            e.preventDefault();
            history.push(to);
          }}
        />
      );
    }}
  />
);
