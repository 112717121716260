/* eslint-disable no-console */
import React, { useState, useRef, useEffect } from 'react';
import { Redirect } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import { actionCreators } from '../../../state/modals-dux';
import { Row, Col, Alert, Container, Modal, Button } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import LoginForm from './LoginForm';
import authorized from '../../../authorized';
import { withLastLocation } from 'react-router-last-location';
import localForage from 'localforage';
import Loading from '../../ui/Loading';

export const Login = (
  {
    user,
    error,
    terms,
    acceptTerms,
    cancelTerms,
    postLogin,
    getTerms,
    validateTerms,
    lastLocation,
    openModal,
    closeModal,
    showModal
  }
) => {
  const isAuthorized = authorized({ user });
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [enableAccept, setEnableAccept] = useState(false);
  const [redirectLocation, setRedirectLocation] = useState(null);
  const [loadingTerms, setLoadingTerms] = useState(true);
  const [showFooter, setShowFooter] = useState(false);
  const [showBody, setShowBody] = useState(false);
  const [newTerms, setNewTerms] = useState(false);


  // handles logout function
  const logout = async () => {
    await localForage.removeItem('identity');
  };

  // handles retreiving terms from backend and showing the terms modal
  const handleModal = async () => {
    setShowTermsModal(true);
    try {
      await getTerms();
    } finally {
      setTimeout(() => {
        setLoadingTerms(false);
        setShowBody(true);
        setShowFooter(true);
      }, 3000);
    }
  };

  // handles the enabling of the accept button when checkbox is checked
  const handleCheckboxChange = () => {
    setEnableAccept(!enableAccept);
  };

  // handles accept function
  const handleTermsAccept = () => {
    acceptTerms(user);
    setRedirectLocation(lastLocation || '/');
  };

  // handles cancel/close modal function
  const handleTermsModalClose = () => {
    cancelTerms(user);
    setLoadingTerms(true);
    setShowBody(false);
    setShowFooter(false);
    setEnableAccept(false);
    setShowTermsModal(false);
    logout();
  };

  // handles redirection to terms of service page
  const handleStaticTerms = () => {
    window.open(`/terms-of-service/${terms ? terms.termsVersion : 'active'}`, '_blank');
  };

  useEffect(
    () => {
      getTerms();

      // handles the validations to check if user logging in should be prompted with terms
      const isUserAccepted = user && user.terms && user.terms.accepted === true;
      const userTermsVersion = user ? user.terms ? user.terms.termsVersion : '' : '';

      if (isAuthorized && user.accountType === 'distributor' && !isUserAccepted && userTermsVersion === '') {
        handleModal();
      }
      else if (isAuthorized && user.accountType === 'distributor' && !isUserAccepted && user.activeTermsVersion === userTermsVersion) {
        handleModal();
      }
      else if (isAuthorized && user.accountType === 'distributor' && user.activeTermsVersion !== userTermsVersion) {
        setNewTerms(true);
        handleModal();
      }
      else if (isAuthorized) {
        setRedirectLocation(lastLocation || '/');
      }

      // handles logging out the user when page is refreshed on terms modal screen
      const handleBeforeUnload = () => {
        logout();
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    },
    [isAuthorized, lastLocation]
  );

  // handles redirection of user to dashboard after successful login
  if (redirectLocation) {
    return <Redirect to={redirectLocation} />;
  }

  return (
    <Layout route="login" hideNav>
      <Container>
        <Row className="justify-content-sm-center align-items-center">
          <Col sm md={4}>
            <div className="brand" />

            {error && <Alert variant="danger">{error.message}</Alert>}

            <LoginForm onSubmit={values => postLogin(values)} />

            &nbsp;

            <p style={{ fontSize: 'smaller' }}>
              By logging in, you are agreeing to the
              {' '}
              <a
                style={{
                  color: 'blue',
                  cursor: 'pointer',
                  textDecoration: 'underline'
                }}
                onClick={() => handleStaticTerms()}
              >
                Terms of Service
              </a>
            </p>
          </Col>
        </Row>
      </Container>
      <Modal
        show={showTermsModal}
        onHide={handleTermsModalClose}
        className="modal-success"
        size="xl"
      >
        <Modal.Header style={{ backgroundColor: '#12163f' }} closeButton>
          <Modal.Title>Terms of Use</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ overflowY: 'fixed' }}
        >
          {loadingTerms && <Loading fullPage />} {/* Show loading only when terms are being fetched */}
          {showBody && newTerms && <Alert variant="success" style={{ fontSize: '14px' }}>{'Please review the revised Terms of Use'}</Alert>}
          {showBody && (
            <div>
              <p
                className="text-muted"
                style={{ fontSize: '16px', overflowY: 'auto' }}
                dangerouslySetInnerHTML={{ __html: terms ? terms.content : '' }}
              />
            </div>
          )}
        </Modal.Body>
        {showFooter && (
          <Modal.Footer>
            <div>
              <div style={{ marginBottom: '10px' }}>
                <label>
                  <input
                    type="checkbox"
                    onChange={handleCheckboxChange}
                    style={{ transform: 'scale(1.25)', marginRight: '10px' }}
                  />
                  &nbsp;I agree to the Terms of Use
                </label>
              </div>
              <div>
                <Button
                  size="sm"
                  variant="primary"
                  onClick={handleTermsAccept}
                  disabled={!enableAccept}
                >
                  Accept and Continue
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  size="sm"
                  variant="danger"
                  onClick={handleTermsModalClose}
                  style={{ marginLeft: 'auto' }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal.Footer>
        )}
      </Modal>
    </Layout>
  );
};

const mapState = state => {
  const {
    result: user,
    error
  } = state.login.toJS();

  const {
    result: terms,
    errorTerms
  } = state.getTermsOfService.toJS();

  const {
    result: acceptTerms,
    errorAcceptTerms
  } = state.acceptTermsOfService.toJS();

  const {
    result: deleteTerms,
    errorDeleteTerms
  } = state.cancelTermsOfService.toJS();

  return {
    user,
    error,
    terms,
    errorTerms,
    acceptTerms,
    errorAcceptTerms,
    deleteTerms,
    errorDeleteTerms,
    openModal: state.modals
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      postLogin: fetchDux.login.createAction,
      getTerms: fetchDux.getTermsOfService.createAction,
      acceptTerms: fetchDux.acceptTermsOfService.createAction,
      cancelTerms: fetchDux.cancelTermsOfService.createAction,
      ...actionCreators
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(withLastLocation(Login));
