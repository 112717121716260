import React, { Component } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../../state/modals-dux';
import fetchDux from '../../../../../state/fetch-dux';

class ModalDeleteFAQ extends Component {
  render() {
    const {
      openModal,
      closeModal,
      deleteFAQArticle,
      name,
      deleteInProgress,
      getAllFAQs,
      deleteError
    } = this.props;
    const { name: modalName, faqId, faqName } = openModal || {};
    return (
      <Modal
        show={modalName === name}
        onHide={closeModal}
        className="modal-danger"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete FAQ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure you wish to delete <b>{faqName}</b>?</h5>
          {
            deleteError &&
            <Alert variant="danger">{deleteError.message || ''}</Alert>
          }
          <div className="text-muted">This action cannot be reverted</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={closeModal}
            disabled={deleteInProgress}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            variant="danger"
            onClick={() => {
              deleteFAQArticle(faqId, null, null, () => {
                getAllFAQs();
                closeModal();
              })
            }}
            disabled={deleteInProgress}
          >
            Delete FAQ
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
const mapState = state => {
  const {
    inProgress: deleteInProgress,
    error: deleteError
  } = state.deleteFAQArticle.toJS();

  return {
    openModal: state.modals,
    deleteInProgress,
    deleteError
  }
}
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      deleteFAQArticle: fetchDux.deleteFAQArticle.createAction,
      getAllFAQs: fetchDux.getAllFAQs.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ModalDeleteFAQ);
