import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import fetchDux from '../../../../state/fetch-dux';
import SaveSearchForm from './SaveSearchForm';
const { location } = window || {};

class ModalSaveSearch extends Component {
  componentDidMount() {
    this.props.clearSaveSearch();
  }
  closeSaveSearchModal = () => {
    this.props.closeModal();
    this.props.clearSaveSearch();
  };
  componentWillUnmount() {
    const { clearTestConnection } = this.props;
    clearTestConnection();
  }

  render() {
    const {
      openModal,
      closeModal,
      error,
      clearSaveSearch,
      searchParams,
      testConnection,
      clearTestConnection
    } = this.props;

    const handleSubmit = values => {
      const { search } = location;
      const params = { ...values, parameters: search };
      clearSaveSearch();
      this.props.saveCSVJobs(params, 'save-search-modal-form', null, data => {
        closeModal();
      });
    };
    return (
      <Modal
        size="lg"
        show={openModal === 'SaveSearchModal'}
        onHide={closeModal}
      >
        <Modal.Header
          closeButton
          onClick={() => {
            clearTestConnection();
          }}
        >
          <Modal.Title>Save Search</Modal.Title>
        </Modal.Header>
        <SaveSearchForm
          onSubmit={handleSubmit}
          testConnection={testConnection}
          clearTestConnection={clearTestConnection}
          saveJobsError={error}
          closeModal={this.closeSaveSearchModal}
          {...{ searchParams }}
        />
      </Modal>
    );
  }
}
const mapState = state => {
  const user = state.login.toJS().result;
  const {
    result: jobId,
    error
  } = state.saveCSVJobs.toJS();
  return {
    user,
    jobId,
    error,
    openModal: state.modals
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      saveCSVJobs: fetchDux.saveCSVJobs.createAction,
      clearSaveSearch: fetchDux.saveCSVJobs.clearAction,
      testConnection: fetchDux.testConnection.createAction,
      clearTestConnection: fetchDux.testConnection.clearAction
    },
    dispatch
  );
export default connect(mapState, mapDispatch)(ModalSaveSearch);
