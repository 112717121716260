import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import { actionCreators } from '../../../state/modals-dux';

import { Container, Navbar, Nav, Button } from 'react-bootstrap';

class termsOfService extends Component {

  // handles retreiving terms of service content
  componentDidMount() {
    const { match } = this.props;
    const termsVersion = match.params.version;
    this.props.getTerms(termsVersion);
  }

  // handles triggering the print function
  handlePrint = () => {
    window.print();
  };

  render() {
    const { terms } = this.props;

    return (
      <Fragment>
        <Navbar
          bg="white"
          expand="lg"
          fixed="top"
          style={{ minHeight: '80px' }}
        >
          <Navbar.Brand href="/" style={{ width: '150px' }}>FEDA</Navbar.Brand>
          <Nav className="ml-auto">
            <Navbar.Text className="user">
              <Button variant="primary" onClick={this.handlePrint}>
                Print
              </Button>
            </Navbar.Text>
          </Nav>
        </Navbar>

        <Container style={{ marginTop: '90px' }}>
          <div>
            <p
              className="text-muted"
              style={{ fontSize: '16px', overflowY: 'auto' }}
              dangerouslySetInnerHTML={{ __html: terms ? terms.content : '' }}
            />
          </div>
        </Container>
      </Fragment>
    );
  }
}
const mapState = state => {
  const {
    result: terms,
    errorterms
  } = state.getTermsByVersion.toJS();

  return {
    terms,
    errorterms,
    openModal: state.modals
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getTerms: fetchDux.getTermsByVersion.createAction,
      ...actionCreators
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(termsOfService);
