import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import FAQsSearch from './faqs-search/FAQsSearch.js';
import FAQDetails from './../../faqs/faq-details/FAQDetails.js';
import FAQCreate from './faq-create/FAQCreate.js';
import FAQUpdate from './faq-update/FAQUpdate.js';

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={FAQsSearch} />
    <Route exact path={`${match.url}/create`} component={FAQCreate} />
    <Route exact path={`${match.url}/:id`} component={FAQDetails} />
    <Route exact path={`${match.url}/:id/edit`} component={FAQUpdate} />
    <Redirect to={`${match.url}`} />
  </Switch>
);
