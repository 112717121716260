import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import fetchDux from '../../../../state/fetch-dux';
const { location } = window || {};

class DeleteUserModal extends Component {
  render() {
    const {
      openModal,
      closeModal,
      match,
      user,
      name
    } = this.props;
    const { id } = match.params || {};
    const {
      nameFirst
    } = user || {};
    const handleDelete = () => {
      user.id = id;
      this.props.deleteUserById(user);
      closeModal();
      location.href = '/users';
    };
    return (
      <Modal
        show={openModal === name}
        onHide={closeModal}
        className="modal-danger"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure you wish to delete <b>{nameFirst}</b>?</h5>
          <div className="text-muted">This action cannot be reverted</div>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button size="sm" variant="danger" onClick={handleDelete}>
            Delete User
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      deleteUserById: fetchDux.deleteUserById.createAction
    },
    dispatch
  );
export default connect(mapState, mapDispatch)(DeleteUserModal);
