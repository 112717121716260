import React, { Component, Fragment } from 'react';
import { Col } from 'react-bootstrap';
import DataQualityMetrics from './metrics/DataQualityMetrics';
import DistributorMetrics from './metrics/DistributorMetrics';
import ManufacturerMetrics from './metrics/ManufacturerMetrics';
import ProductMetrics from './metrics/ProductMetrics';
class ReportingMetrics extends Component {
  render() {
    const { selectedReportType } = this.props;
    return (
      <Fragment>
        <div className="horizontalRuler" />
        {selectedReportType === 'Products' &&
          <Col sm={12}>
            <ProductMetrics {...this.props} />
          </Col>}
        {selectedReportType === 'DataQuality' &&
          <Col sm={12}>
            <DataQualityMetrics {...this.props} />
          </Col>}
        {selectedReportType === 'Manufacturers' &&
          <Col sm={12}>
            <ManufacturerMetrics {...this.props} />
          </Col>}

        {selectedReportType === 'Distributors' &&
          <Col sm={12}>
            <DistributorMetrics {...this.props} />
          </Col>}
        <div className="horizontalRuler" />
      </Fragment>
    );
  }
}

export default ReportingMetrics;
