import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../../state/fetch-dux';
import moment from 'moment';
import Loading from '../../../ui/Loading';
import draftToHtml from 'draftjs-to-html';

// const faqDemo = {
//   dateLastUpdated: '2019-01-01',
//   category: 'General',
//   question: "What is Nike's shipping options?",
//   answer: 'Nike offers free shipping on all orders over $150. Orders under $150 will be charged a flat rate of $10.00. Orders are shipped via UPS Ground and typically arrive within 3-5 business days. You can also choose to upgrade to 2nd Day Air or Next Day Air for an additional fee. Please note that orders placed after 12pm EST will not be processed until the next business day. Orders placed on Saturday, Sunday, or a holiday will be processed on the next business day. Please note that Nike.com does not ship to P.O. Boxes, APO/FPO addresses, or international addresses. For more information, please visit our Shipping Information page.'
// };

class FAQDetails extends Component {
  componentDidMount() {
    const { match, getFAQsById } = this.props;
    const { id } = match.params || {};
    getFAQsById(id);
  }
  render() {
    const { result, inProgress } = this.props;
    const {
      dateLastUpdated,
      category,
      question,
      answer
    } = result || {};

    const getHTMLContent = () => {
      if (answer) {
        return draftToHtml(JSON.parse(answer));
      }
    };
    return (
      <Layout route="faqs-details">
        <main>

          <div className="layout-header">
            <Container>

              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <Icon name="home" />
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/faqs">
                    Frequently Asked Questions
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  {category}
                </li>
              </ol>

              <div className="header">
                <h1>
                  <Icon name="comments" /> {question}
                </h1>
              </div>

            </Container>
          </div>

          <Container>
            {inProgress && <Loading />}
            {!inProgress &&
              <div className="box">
                {dateLastUpdated &&
                  moment(dateLastUpdated).isValid() &&
                  <div className="box-header">
                    <h3>
                      Last update {moment(dateLastUpdated).format('MM/DD/YYYY')}
                    </h3>
                  </div>}
                <div
                  className="box-body faq"
                  dangerouslySetInnerHTML={{ __html: getHTMLContent() }}
                />
              </div>}

          </Container>

        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  const { result, inProgress } = state.getFAQsById.toJS();
  return {
    result,
    inProgress
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getFAQsById: fetchDux.getFAQsById.createAction
    },
    dispatch
  );
export default connect(mapState, mapDispatch)(FAQDetails);
