
import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import fetchDux from '../../../../state/fetch-dux';
import Icon from '../../../ui/Icon';
import Layout from '../../../ui/Layout';
import Loading from "../../../ui/Loading";
import Notification from '../../../ui/Notification';
import Exceptions from './../products-new/Exceptions';
import EditProductForm from './EditProductForm';


class EditProduct extends Component {


    componentDidMount() {
        const { getProductLookups, match, getProductById } = this.props;
        const { id } = match.params || {};
        getProductLookups();
        getProductById(id);
    }

    componentDidUpdate() {
        const { product, history } = this.props;
        if ((product || {}).Deleted === 'Yes') {
            history.push(`/products/details/${product._id}`);
        }
    }

    componentWillUnmount() {
        const { clearEditProduct } = this.props;
        clearEditProduct();
    }

    getInitialValues = () => {
        const { product } = this.props;
        return product;
    }

    render() {
        const {
            lookupsResult,
            getFeatEnumReferenceType,
            lookupInProgress,
            getProductLoading,
            editProduct,
            match,
            history,
            editProductResult,
            clearEditProduct,
            editProductError,
            product
        } = this.props;

        const { id } = match.params || {};


        return (
            <Layout route='product-new'>
                <main>
                    <div className="layout-header">
                        <Container>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/">
                                        <Icon name="home" />
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/products">
                                        Products
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    {(product || {}).ProdCode}
                                </li>
                            </ol>
                        </Container>
                    </div>

                    <Container>
                        {
                            (lookupInProgress || getProductLoading) &&
                            <Loading />
                        }
                        {editProductResult && <Exceptions result={editProductResult} />}
                        {
                            !lookupInProgress &&
                            !getProductLoading &&
                            <EditProductForm
                                lookupsResult={lookupsResult}
                                initialValues={this.getInitialValues()}
                                getFeatEnumReferenceType={getFeatEnumReferenceType}
                                onSubmit={() => { }}
                                submitHandler={
                                    (values) => {
                                        editProduct(values, null, null, () => {
                                            clearEditProduct();
                                            history.push(`/products/details/${id}`);
                                        })
                                    }
                                }
                            />
                        }
                    </Container>

                    {
                        editProductError
                        &&
                        <Notification
                            key="edit-product"
                            duration={3}
                            closable={true}
                            type="danger"
                            onClose={clearEditProduct}
                        >
                            {'Failed to update product, please try again.'}
                        </Notification>
                    }
                </main>

            </Layout>
        )
    }
}

const mapState = state => {

    const {
        result: lookupsResult,
        inProgress: lookupInProgress,
    } = state.getProductLookups.toJS();


    const {
        result: product,
        inProgress: getProductLoading
    } = state.getProductById.toJS();

    const {
        result: editProductResult,
        error: editProductError
    } = state.editProduct.toJS();


    return {
        lookupsResult,
        lookupInProgress,
        product,
        getProductLoading,
        editProductResult,
        editProductError
    }
}

const mapDispatch = dispatch => bindActionCreators({
    getProductLookups: fetchDux.getProductLookups.createAction,
    clearCreateNewProduct: fetchDux.postProduct.clearAction,
    getProductById: fetchDux.getProductById.createAction,
    editProduct: fetchDux.editProduct.createAction,
    clearEditProduct: fetchDux.editProduct.clearAction
}, dispatch)

export default connect(mapState, mapDispatch)(EditProduct);