import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { Field, FieldArray } from 'redux-form';
import FormField from '../../../forms/FormField';
import Icon from '../../../ui/Icon';

const required = value => value ? null : 'Required';

const renderContentDetails = ({ fields, lookupsResult }) => {
  const { ContType } = lookupsResult || {};
  const contentTypeOptions = (ContType || [])
    .map(({ enumKey, value }) => ({ value: enumKey, label: value }));
  return (
    <div>
      <div className="buttonContainer">
        <Button
          variant="outline-primary"
          size="sm"
          onClick={() => fields.push({})}
        >
          <Icon name="plus" /> Content
        </Button>
      </div>
      <div>
        {fields.map((contentData, index) => {
          return (
            <div key={index}>
              <Row>
                <Col sm={12}>
                  <div className="header">
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => fields.remove(index)}
                    >
                      <Icon name="trash" />
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <Field
                    name={`${contentData}.ContType`}
                    label="Content Type *"
                    type="text"
                    component={FormField.SelectReact}
                    options={contentTypeOptions}
                    validate={[required]}
                    isClearable
                  />
                </Col>

                <Col sm={4}>
                  <Field
                    name={`${contentData}.ContText`}
                    label="Content Text *"
                    type="text"
                    component={FormField.Input}
                    maxLength={2000}
                    validate={[required]}
                  />
                </Col>

                <Col sm={4}>
                  <Field
                    name={`${contentData}.ContSeqNum`}
                    label="Content Sequence Number"
                    type="text"
                    component={FormField.Input}
                    normalize={(value, previousValue) => {
                      if (value && value.trim().length > 0) {
                        const regExp = /^[0-9]+$/;
                        if (regExp.test(value)) {
                          return value;
                        }
                        return previousValue;
                      }
                      return value;
                    }}
                  />
                </Col>
              </Row>
              {index !== fields.length - 1 && <div className="divider" />}
            </div>
          );
        })}

      </div>

    </div>
  );
};

const ContentDetails = ({ lookupsResult }) => {
  return (
    <Col sm={12}>
      <div className="box">
        <div className="box-header">
          <h3>Content Details</h3>
        </div>
        <div className="box-body">
          <FieldArray
            name="Content"
            component={renderContentDetails}
            props={{ lookupsResult }}
          />
        </div>

      </div>
    </Col>
  );
};

export default ContentDetails;
