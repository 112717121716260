import React from 'react';
import { Col } from 'react-bootstrap';
const GroupDetails = ({ Group }) => {
    return (
        (Group || []).length > 0 ?
            <Col md={6}>
                <div className="box">
                    <div className="box-header">
                        <h3>Group</h3>
                    </div>
                    <div className="box-body">

                        <table className="table table-data-horizontal">
                            <thead>
                                <tr>
                                    <th>Label</th>
                                    <th>Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Group.map((a, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{a.GroupLbl || ''}</td>
                                            <td>{a.GroupName || ''}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>

                    </div>
                </div>
            </Col>
            :
            null
    )
}

export default GroupDetails;