import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Navbar, Nav, Button, NavDropdown } from 'react-bootstrap';
import { actionCreators } from '../../state/modals-dux';

import NavItemLink from './NavItemLink';
import NavbarBrandLink from './NavbarBrandLink';

import LogoutModal from '../routes/login/LogoutModal';
import fetchDux from '../../state/fetch-dux';
import ActAsModal from '../routes/homepage/ActAsModal';

const TopNav = ({ user, showModal, location, postAdminImpersonate }) => (
  <Navbar bg="white" expand="lg" fixed="top">
    <LogoutModal />
    <ActAsModal />
    <NavbarBrandLink to="/">FEDA</NavbarBrandLink>
    <Navbar.Toggle aria-controls="navbar-nav" />
    <Navbar.Collapse id="navbar-nav">
      <Nav className="mr-auto">
        <NavItemLink to="/">Dashboard</NavItemLink>
        <NavItemLink to="/products">Products</NavItemLink>
        {user.isAdmin && !user.impersonate
          ? <React.Fragment>
              <NavItemLink to="/manufacturers">Manufacturers</NavItemLink>
              <NavItemLink to="/distributors">Distributors</NavItemLink>
              <NavItemLink to="/users">Users</NavItemLink>
              {/* <NavItemLink to="/references">Ref</NavItemLink> */}
              <NavItemLink to="/logs">Logs</NavItemLink>
            </React.Fragment>
          : null}
        {((user.isAdmin &&
          user.impersonate &&
          user.accountType === 'distributor') ||
          user.accountType === 'distributor') &&
          <NavItemLink to="/automated-downloads">
            Automated Downloads
          </NavItemLink>}
        {((user.isAdmin &&
          user.impersonate &&
          user.accountType === 'manufacturer') ||
          user.accountType === 'manufacturer') &&
          !user.viewOnly &&
          <NavItemLink to="/ftp-uploads">SFTP Uploads</NavItemLink>}
        <NavItemLink to="/faqs">FAQs</NavItemLink>
        {user.isAdmin &&
          <NavDropdown
            title="Admin"
            active={location.pathname.startsWith('/admin')}
          >
            {!user.impersonate &&
              <NavItemLink to="/references">Reference Lists</NavItemLink>}
            {!user.impersonate &&
              <NavItemLink to="/admin/reporting">Reporting</NavItemLink>}
            {!user.impersonate &&
              <NavItemLink to="/admin/faqs">FAQs</NavItemLink>}
            {user.impersonate &&
              <NavItemLink to="#" style={{ padding: 0 }}>
                <div
                  onClick={() => postAdminImpersonate({})}
                  style={{ padding: '0.5rem' }}
                >
                  Switch to Admin
                </div>
              </NavItemLink>}
            <NavItemLink to="#" style={{ padding: 0 }}>
              <div
                onClick={() => showModal('ActAsModal')}
                style={{ padding: '0.5rem' }}
              >
                Impersonate
              </div>
            </NavItemLink>
          </NavDropdown>}
      </Nav>
      {user &&
        <Nav>
          <Navbar.Text className="user">
            {user.firstName && user.lastName
              ? `${user.firstName} ${user.lastName.charAt(0)}.`
              : user.email}
            <Button variant="primary" onClick={() => showModal('LogoutModal')}>
              Logout
            </Button>
          </Navbar.Text>
        </Nav>}
    </Navbar.Collapse>
  </Navbar>
);

const mapState = state => {
  const user = state.login.toJS().result;
  return { user };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      postAdminImpersonate: fetchDux.postAdminImpersonate.createAction
    },
    dispatch
  );
export default connect(mapState, mapDispatch)(TopNav);
