import React, { useEffect, useState } from 'react';
import { Row, Col, Overlay, Tooltip } from 'react-bootstrap';
import numeral from 'numeral';
import Icon from '../../../../ui/Icon';
import moment from 'moment-timezone';
import ReactDOM from 'react-dom';

const ProductMetrics = (
    {
        adminMetricsResult
    }) => {

    const [show, toggleShow] = useState(false);
    const [target, setTarget] = useState();
    const [toolTipText, setToolTipText] = useState('');
    const totalProductsRef = React.createRef();
    const activeProductsRef = React.createRef();
    const lastUploadProductsRef = React.createRef();
    const windowPointerUpHandler = (event) => {
        const id = event.target.id;
        if (id !== 'iconDiv') {
            toggleShow(false);
        }
    }
    useEffect(() => {
        window.addEventListener('pointerup', windowPointerUpHandler);
        return () => {
            window.removeEventListener('pointerup', windowPointerUpHandler);
        }
    }, []);

    const getTarget = () => {
        return ReactDOM.findDOMNode(target);
    }

    const sharedProps = {
        container: this,
        target: getTarget,
        show: show
    };

    const showToolTip = (nextTraget, text) => {
        if (show && target === nextTraget) {
            toggleShow(false)
            return;
        }
        setTarget(nextTraget);
        setToolTipText(text);
        toggleShow(true);
    }

    const {
        totalProductsCount,
        activeProductsCount,
        lastUpdatedDateTotalProducts,
        lastUpdatedDate
    } = adminMetricsResult || {}

    return (
        <Row>
            <Col sm={4}>
                <div className="metricsTile">
                    <div className='title'>Total Products</div>
                    <div className='contentDiv'>
                        <div className='dataContainer'>
                            <span>{numeral(totalProductsCount).format('0,0')}</span>
                            <span>products</span>
                        </div>
                    </div>
                    <div
                        id='iconDiv'
                        className='iconDiv'
                        ref={totalProductsRef}
                        onClick={
                            () => {
                                showToolTip(totalProductsRef.current,
                                    'Count of all products uploaded to the repository.')
                            }
                        }
                    >
                        <Icon name='info' />
                    </div>
                </div>
            </Col>
            <Col sm={4}>
                <div className="metricsTile">
                    <div className='title'>Active Products</div>
                    <div className='contentDiv'>
                        <div className='dataContainer'>
                            <span>{numeral(activeProductsCount).format('0,0')}</span>
                            <span>products</span>
                        </div>
                    </div>
                    <div
                        id='iconDiv'
                        className='iconDiv'
                        ref={activeProductsRef}
                        onClick={
                            () => {
                                showToolTip(activeProductsRef.current,
                                    `Count of all active products uploaded to the repository.
                                    Excludes "deleted" and "Discontinued" products.`)
                            }
                        }
                    >
                        <Icon name='info' />
                    </div>
                </div>
            </Col>
            <Col sm={4}>
                <div className="metricsTile">
                    <div className='title'>Last Upload</div>
                    <div className='contentDiv multi'>
                        <div className='dataContainer multi'>
                            <span>{moment(lastUpdatedDate).format('MM/DD/YY')}</span>
                            <span>last date</span>
                        </div>
                        <div className='dataContainer multi'>
                            <span>{numeral(lastUpdatedDateTotalProducts).format('0,0')}</span>
                            <span>products</span>
                        </div>
                    </div>
                    <div
                        id='iconDiv'
                        className='iconDiv multi'
                        ref={lastUploadProductsRef}
                        onClick={
                            () => {
                                showToolTip(lastUploadProductsRef.current,
                                    `Date that the last upload occured  at.
                                    As well as the number of products in that upload.`)
                            }
                        }
                    >
                        <Icon name='info' />
                    </div>
                </div>
            </Col>
            <Overlay {...sharedProps} placement="bottom">
                <Tooltip id="overload-bottom">{toolTipText}</Tooltip>
            </Overlay>
        </Row>

    )
}

export default ProductMetrics;