import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { Field, FieldArray } from 'redux-form';
import FormField from '../../../forms/FormField';
import Icon from '../../../ui/Icon';

const required = value => value ? null : 'Required';

const renderMeasureDetails = ({ fields, lookupsResult }) => {
  const { measEnums, UOM } = lookupsResult || {};
  const unitOfMeasurementOptions = (UOM || [])
    .map(({ value, enumKey }) => ({ value: enumKey, label: value }));
  const measureEnumOptions = (measEnums || []).map(({ enumKey, value }) => {
    if (enumKey.trim().length === 0) {
      return {
        value: value,
        label: value
      };
    } else {
      return { value: enumKey, label: value };
    }
  });
  return (
    <div>
      <div className="buttonContainer">
        <Button
          variant="outline-primary"
          size="sm"
          onClick={() => fields.push({})}
        >
          <Icon name="plus" /> Measure
        </Button>
      </div>
      <div>
        {fields.map((measureData, index) => {
          return (
            <div key={index}>
              <Row>
                <Col sm={12}>
                  <div className="header">
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => fields.remove(index)}
                    >
                      <Icon name="trash" />
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <Field
                    name={`${measureData}.MeasEnum`}
                    label="MeasEnum *"
                    type="text"
                    component={FormField.SelectReact}
                    options={measureEnumOptions}
                    validate={[required]}
                    isClearable
                  />
                </Col>

                <Col sm={4}>
                  <Field
                    name={`${measureData}.UOMCode`}
                    label="UOMCode *"
                    type="text"
                    component={FormField.SelectReact}
                    options={unitOfMeasurementOptions}
                    validate={[required]}
                    isClearable
                  />
                </Col>

                <Col sm={4}>
                  <Field
                    name={`${measureData}.MeasVal`}
                    label="Measure Val *"
                    type="text"
                    component={FormField.Input}
                    validate={[required]}
                  />
                </Col>
              </Row>
              {index !== fields.length - 1 && <div className="divider" />}
            </div>
          );
        })}

      </div>

    </div>
  );
};

const MeasureDetails = ({ lookupsResult }) => {
  return (
    <Col sm={12}>
      <div className="box">
        <div className="box-header">
          <h3>Measure Details</h3>
        </div>

        <div className="box-body">
          <FieldArray
            name="Measure"
            component={renderMeasureDetails}
            props={{ lookupsResult }}
          />
        </div>

      </div>
    </Col>
  );
};

export default MeasureDetails;
