import React from 'react';
import { Col } from 'react-bootstrap';
import moment from 'moment';

const formatTime = d => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).format('MMM D, Y');
};
const AssetDetails = (
  {
    Asset,
    Product,
    HandleDownload,
    Response,
    downloadAsset,
    downloadAssetError
  }
) => {
  const {
    manufacturerId,
    ProdCode
  } = Product || {};

  return (Asset || []).length > 0
    ? <Col md={12}>
        <div className="box">
          <div className="box-header">
            <h3>Assets</h3>
          </div>
          <div className="box-body">

            <div className="table-responsive">
              <table className="table table-data-horizontal">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Quality</th>
                    <th>Type</th>
                    <th>URL</th>
                    <th>URL Thumbnail</th>
                    <th>Lang</th>
                    <th>Version</th>
                    <th>Version Date</th>
                    <th>File Type</th>
                    <th>File Name</th>
                  </tr>
                </thead>
                <tbody>
                  {Asset.map((a, index) => {
                    const {
                      status,
                      AssetTypeEnum,
                      AssetLang,
                      AssetName,
                      AssetQlty,
                      AssetURL,
                      assetDownloadUrl,
                      AssetVer,
                      AssetVerDate,
                      FileType,
                      FileName,
                      thumbnailDownloadUrl,
                      ThumbURL,
                      thumbnailStatus
                    } = a || {};

                    const isValidated = status === 'validated';
                    const isThumbnailValidated = thumbnailStatus ===
                      'validated';

                    return (
                      <tr key={index}>
                        <td>{AssetName || ''}</td>
                        <td>{AssetQlty || ''}</td>
                        <td>{AssetTypeEnum || ''}</td>
                        <td>
                          {AssetURL &&
                            <a
                              onClick={e => {
                                if (!isValidated) return;
                                e.preventDefault();
                                const payload = {
                                  manufacturerId,
                                  productCode: ProdCode,
                                  assetIndex: index
                                };
                                HandleDownload({
                                  payload,
                                  Filename: `${ProdCode}-${index}-${AssetTypeEnum}`
                                });
                              }}
                              href={isValidated ? '#' : AssetURL}
                              target="_blank"
                            >
                              {isValidated ? assetDownloadUrl : AssetURL}
                            </a>}
                        </td>
                        <td>
                          {thumbnailDownloadUrl &&
                            <a
                              onClick={e => {
                                if (!ThumbURL) e.preventDefault();
                                if (!isThumbnailValidated) return;
                                e.preventDefault();
                                const payload = {
                                  manufacturerId,
                                  productCode: ProdCode,
                                  assetIndex: index,
                                  thumbnail: true
                                };
                                HandleDownload({
                                  payload,
                                  Filename: `${ProdCode}-${index}-${AssetTypeEnum}_thumbnail`
                                });
                              }}
                              href={
                                isThumbnailValidated
                                  ? '#'
                                  : thumbnailDownloadUrl
                              }
                              target="_blank"
                            >
                              {isThumbnailValidated
                                ? thumbnailDownloadUrl || '-'
                                : ThumbURL || '-'}
                            </a>}
                        </td>
                        <td>{AssetLang || ''}</td>
                        <td>{AssetVer || ''}</td>
                        <td>{formatTime(AssetVerDate)}</td>
                        <td>{FileType || ''}</td>
                        <td>{decodeURIComponent(FileName || '')}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </Col>
    : null;
};

export default AssetDetails;
