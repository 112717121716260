import { Modal, ModalTitle } from 'react-bootstrap';
import React from 'react';
import ReferenceForm from './ReferenceForm';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../state/modals-dux';
import fetchDux from '../../../state/fetch-dux';
import { connect } from 'react-redux';

const EditReferenceModal =
    ({
        openModal,
        name,
        closeModal,
        selectedLookup,
        editProductReference,
        inProgress,
        rowData,
        miscData,
        error,
        clearEditProductReference
    }) => {

        const onHideHandler = () => {
            if (!inProgress) {
                closeModal(name);
                clearEditProductReference();
            }

        }
        return (
            <Modal show={openModal === name} onHide={() => onHideHandler()}>
                <Modal.Header closeButton>
                    <ModalTitle>
                        Edit Record
                    </ModalTitle>
                </Modal.Header>
                <ReferenceForm
                    closeModal={closeModal}
                    selectedLookup={selectedLookup}
                    miscData={miscData}
                    editError={error}
                    onSubmit={(values) => {
                        const payload = { ...values, lookup: selectedLookup };
                        editProductReference(payload, 'PRODUCT_REFERENCES_FORM', null, (result) => {
                            const { ok } = result;
                            if (ok) onHideHandler();
                        });
                    }}
                    initialValues={{ ...rowData, dataType: ((rowData || {}).dataType || '').toLowerCase() }}
                />
            </Modal>
        )
    }


const mapState = state => {
    const {
        error,
        inProgress
    } = state.editProductReference.toJS();

    const {
        result: miscData
    } = state.getProductMiscLookups.toJS();

    return {
        error,
        openModal: state.modals,
        inProgress,
        miscData
    }
}

const mapDispatch = dispatch =>
    bindActionCreators({
        ...actionCreators,
        editProductReference: fetchDux.editProductReference.createAction,
        clearEditProductReference: fetchDux.editProductReference.clearAction
    }, dispatch);


export default connect(mapState, mapDispatch)(EditReferenceModal);