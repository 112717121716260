import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import fetchDux from '../../../../state/fetch-dux';
import { change } from 'redux-form';

import UsersEditForm from './UsersEditForm';
import CreateManufacturerModal from '../users-create/CreateManufacturerModal';
import CreateDistributorModal from '../users-create/CreateDistributorModal';
import Notification from '../../../ui/Notification';

class UsersEdit extends Component {
  componentDidMount() {
    const {
      match,
      getUserById
    } = this.props;
    const { id } = match.params || {};
    getUserById(id);
  }
  componentWillMount() {
    // make our api call
    this.props.getManufacturers();
    this.props.getDistributors();
  }
  render() {
    const {
      history,
      match,
      updateUserById,
      updateUserError,
      clearUpdateUserById,
      userResult,
      showModal,
      manufacturersResult,
      distributorsResult,
      changeForm
    } = this.props;
    const { id } = match.params || {};
    const {
      result: user
    } = userResult || {};
    const {
      imisId,
      name,
      email,
      nameFirst,
      nameLast,
      accountType,
      accountTypeJoinId,
      showApiKey,
      emailNotifications,
      viewOnly
    } = user || {};
    const handleSubmit = values => {
      values.id = id;
      values.accountType = document
        .querySelector('.box-tabs.nav.nav-tabs>a.active')
        .text.toLocaleLowerCase();
      values.viewOnly = values.viewOnly ? values.viewOnly : false;
      updateUserById(values, 'user-edit-form', null, user => {
        const { _id } = user || {};
        history.push(`/users/${_id}`);
      });
    };
    const reloadManufacturers = data => {
      this.props.getManufacturers(null, null, null, () => {
        const { manufacturerId } = data || {};
        changeForm('user-edit-form', 'accountTypeJoinId', manufacturerId);
      });
    };
    const reloadDistributors = data => {
      this.props.getDistributors(null, null, null, () => {
        const { distributorId } = data || {};
        changeForm('user-edit-form', 'accountTypeJoinId', distributorId);
      });
    };
    return (
      <Layout route="users-edit">
        <main>

          <div className="layout-header">
            <Container>

              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <Icon name="home" />
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/users">
                    Users
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  Edit User
                </li>
              </ol>

              <div className="header">
                <h1>
                  <Icon name="users" /> Edit User
                </h1>
              </div>

            </Container>
          </div>

          <Container>
            <UsersEditForm
              enableReinitialize
              showModal={showModal}
              onSubmit={handleSubmit}
              distributors={distributorsResult.result}
              manufacturers={manufacturersResult.result}
              initialValues={{
                imisId: imisId,
                name: name,
                email: email,
                nameFirst: nameFirst,
                nameLast: nameLast,
                accountType,
                accountTypeJoinId,
                showApiKey: showApiKey || false,
                emailNotifications: emailNotifications || false,
                viewOnly: viewOnly || false
              }}
            />
          </Container>
          {updateUserError &&
            <Notification
              key="company-load-fail"
              duration={3}
              closable={true}
              type="danger"
              onClose={clearUpdateUserById}
            >
              {updateUserError.message ||
                'An error occurred updating the user, please try again.'}
            </Notification>}
          <CreateManufacturerModal
            name="CreateManufacturerModal"
            reload={reloadManufacturers}
          />
          <CreateDistributorModal
            name="CreateDistributorModal"
            reload={reloadDistributors}
          />
        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result: values,
    error: updateUserError
  } = state.updateUserById.toJS();

  const userResult = state.getUserById.toJS();
  const manufacturersResult = state.getManufacturers.toJS();
  const distributorsResult = state.getDistributors.toJS();
  return {
    values,
    userResult,
    manufacturersResult,
    distributorsResult,
    updateUserError
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      updateUserById: fetchDux.updateUserById.createAction,
      clearUpdateUserById: fetchDux.updateUserById.clearAction,
      getUserById: fetchDux.getUserById.createAction,
      getManufacturers: fetchDux.getManufacturers.createAction,
      getDistributors: fetchDux.getDistributors.createAction,
      changeForm: change
    },
    dispatch
  );
export default connect(mapState, mapDispatch)(UsersEdit);
