import React from 'react';
import { Col } from 'react-bootstrap';

const ProductDescription = ({ ProdShortDesc, ProdLongDesc }) => {

    return (
        <Col md={12}>
            <div className="box">
                <div className="box-header">
                    <h3>Product Description</h3>
                </div>
                <div className="box-body">
                    <table className="table table-data">
                        <tbody>
                            <tr>
                                <th className="short">Short</th>
                                <td>{ProdShortDesc}</td>
                            </tr>
                            <tr>
                                <th className="short">Long</th>
                                <td>{ProdLongDesc}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>

        </Col>
    )
}

export default ProductDescription;