import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import fetchDux from '../../../../state/fetch-dux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DistributorsEditForm from './DistributorsEditForm';

class DistributorsEdit extends Component {
  componentDidMount() {
    const {
      match,
      getDistributorById
    } = this.props;
    const { id } = match.params || {};
    getDistributorById(id);
  }
  render() {
    const {
      inProgress,
      result,
      history,
      match,
      updateDistributorById,
      distributorResult
    } = this.props;
    const { id } = match.params || {};
    const {
      result: distributors
    } = distributorResult || {}
    const {
      imisId,
      name
    } = distributors || {}
    const handleSubmit = values => {
      values.id=id
      updateDistributorById(values, 'manufacturers-edit-form', null, distributor => {
        const { _id } = distributor || {};
        history.push(`/distributors/${_id}`)
      })
    }
    return (
      <Layout route="distributors-edit">
        <main>

          <div className="layout-header">
            <Container>

              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <Icon name="home" />
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/distributors">
                    Distributors
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  Edit Distributor
                </li>
              </ol>

              <div className="header">
                <h1>
                  <Icon name="warehouse" /> Edit Distributor
                </h1>
              </div>

            </Container>
          </div>

          <Container>
            <DistributorsEditForm onSubmit={handleSubmit} initialValues={{ imisId: imisId, name: name }} />
          </Container>

        </main>
      </Layout>
    );
  }
}
const mapState = state => {
  const {
    result: values,
    error
  } = state.updateDistributorById.toJS();

  const distributorResult = state.getDistributorById.toJS();

  return {
    values,
    distributorResult,
    error
  };
};
const mapDispatch = dispatch => bindActionCreators(
  {
    updateDistributorById: fetchDux.updateDistributorById.createAction,
    getDistributorById: fetchDux.getDistributorById.createAction
  },
  dispatch
);
export default connect(mapState, mapDispatch)(DistributorsEdit);
