import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import fetchDux from '../../../../state/fetch-dux';
import { createDistributor } from '../../../../api/api';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DistributorsCreateForm from './DistributorsCreateForm';

class DistributorsCreate extends Component {
  componentDidMount() {
    this.props.clearCreateDistributor();
  }
  render() {
    const {
      history,
      error
    } = this.props;
    const handleSubmit = values => {
      this.props.createDistributor(values, "distributors-create-form", null, distributor => {
        const { distributorId } = distributor || {};
        history.push(`/distributors/${distributorId}`)
      })
    }
    return (
      <Layout route="distributors-create">
        <main>

          <div className="layout-header">
            <Container>

              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <Icon name="home" />
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/distributors">
                    Distributor Companies
                </Link>
                </li>
                <li className="breadcrumb-item active">
                  Create Distributor Company
              </li>
              </ol>

              <div className="header">
                <h1>
                  <Icon name="warehouse" /> Create New Distributor Company
              </h1>
              </div>

            </Container>
          </div>

          <Container>
            <DistributorsCreateForm distributorCreateError={error} onSubmit={handleSubmit} />
          </Container>

        </main>
      </Layout>
    );
  };
}
const mapState = state => {
  const {
    result: values,
    error
  } = state.createDistributor.toJS();

  return {
    values,
    error
  };
};
const mapDispatch = dispatch => bindActionCreators(
  {
    createDistributor: fetchDux.createDistributor.createAction,
    clearCreateDistributor: fetchDux.createDistributor.clearAction,
  },
  dispatch
);

export default connect(mapState, mapDispatch)(DistributorsCreate);

