import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import LogsSearch from './logs-search/LogsSearch.js';

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={LogsSearch} />
    <Redirect to={`${match.url}`} />
  </Switch>
);
