import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../../state/modals-dux';
import ModalEditFAQsGroupForm from './ModalEditFAQsGroupForm';
import fetchDux from '../../../../../state/fetch-dux';

class ModalEditFAQsGroup extends Component {
  render() {
    const {
      name,
      openModal,
      closeModal,
      updateFAQGroupById,
      getAllFAQs,
      updateError
    } = this.props;
    const { name: modalName, faqGroup } = openModal || {};
    const { _id } = faqGroup || {};
    return (
      <Modal show={modalName === name} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update FAQ Group</Modal.Title>
        </Modal.Header>
        <ModalEditFAQsGroupForm
          {...{ closeModal }}
          initialValues={{ ...faqGroup, error: updateError }}
          onSubmit={
            (values) => {
              updateFAQGroupById({ payload: { ...values }, id: _id }, 'faq-group-update-form', null, () => {
                getAllFAQs();
                closeModal();
              })
            }
          }
        />
      </Modal>
    );
  }
}
const mapState = state => {

  const {
    error: updateError
  } = state.updateFAQGroupById.toJS();
  return {
    openModal: state.modals,
    updateError
  }
}
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      updateFAQGroupById: fetchDux.updateFAQGroupById.createAction,
      getAllFAQs: fetchDux.getAllFAQs.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ModalEditFAQsGroup);
