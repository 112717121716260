import React from 'react';
import { Col } from 'react-bootstrap';
import Icon from '../../../../ui/Icon';
import Table from '../../../../ui/Table';
import moment from 'moment';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const getColumns = reportType => {
  switch (reportType) {
    case 'Product Analytics':
      return [
        {
          Header: 'Manufacturer',
          accessor: 'manufacturerName',
          sortable: true,
          resizable: true,
          show: true,
          className: 'text-center',
          Cell: ({ value, original }) => {
            const { manufacturerId } = original;
            return <Link to={`/manufacturers/${manufacturerId}`}>{value}</Link>;
          }
        },
        {
          Header: 'Imis ID',
          accessor: 'manufacturerImisId',
          sortable: true,
          resizable: true,
          show: true,
          className: 'text-center'
        },
        {
          Header: 'Total Products',
          accessor: 'totalProducts',
          sortable: true,
          resizable: true,
          show: true,
          className: 'text-center',
          Cell: ({ value }) => {
            return value || '0';
          }
        },
        {
          Header: 'Active Products',
          accessor: 'totalActiveProducts',
          sortable: true,
          resizable: true,
          show: true,
          className: 'text-center',
          Cell: ({ value }) => {
            return value || '0';
          }
        },
        {
          Header: 'Created (UTC)',
          accessor: 'manufacturerCreatedDate',
          sortable: true,
          resizable: true,
          show: true,
          Cell: ({ value }) => {
            return (
              <span>
                {value !== undefined && moment.utc(value).isValid()
                  ? moment.utc(value).format('MM/DD/YYYY')
                  : '-'}
              </span>
            );
          },
          className: 'text-center'
        },
        {
          Header: 'Last Activity (UTC)',
          accessor: 'manufacturerLastActivity',
          sortable: true,
          resizable: true,
          show: true,
          Cell: ({ value }) => {
            return (
              <span>
                {value !== undefined && moment.utc(value).isValid()
                  ? moment.utc(value).format('MM/DD/YYYY')
                  : '-'}
              </span>
            );
          },
          className: 'text-center'
        },
        {
          Header: 'Edit Capability',
          accessor: 'manufacturerEditCapability',
          sortable: true,
          resizable: true,
          show: true,
          Cell: ({ value }) => {
            return (
              <div>
                {value === true
                  ? <Icon className="text-success" name="check" />
                  : <Icon className="text-danger" name="times" />}
              </div>
            );
          },
          className: 'text-center'
        },
        {
          Header: 'Last Product Update (UTC)',
          accessor: 'maxLastUpdatedDateProducts',
          sortable: true,
          resizable: true,
          show: true,
          Cell: ({ value }) => {
            return (
              <span>
                {value !== undefined && moment.utc(value).isValid()
                  ? moment.utc(value).format('MM/DD/YYYY')
                  : '-'}
              </span>
            );
          },
          className: 'text-center'
        },
        {
          Header: 'Last Price Update (UTC)',
          accessor: 'maxPriceLastUpdatedDateProducts',
          sortable: true,
          resizable: true,
          show: true,
          Cell: ({ value }) => {
            return (
              <span>
                {value !== undefined && moment.utc(value).isValid()
                  ? moment.utc(value).format('MM/DD/YYYY')
                  : '-'}
              </span>
            );
          },
          className: 'text-center'
        },
        {
          Header: 'Short Duplicates',
          accessor: 'totalShortDescDuplicatesProducts',
          sortable: true,
          resizable: true,
          show: false,
          className: 'text-center',
          Cell: ({ value }) => {
            return value || '0';
          }
        },
        {
          Header: 'Short/Long Match',
          accessor: 'totalShortDescEqualsLongDescProducts',
          sortable: true,
          resizable: true,
          show: true,
          className: 'text-center',
          Cell: ({ value }) => {
            return value || '0';
          }
        },
        {
          Header: 'Assets',
          accessor: 'totalProductAssetsCount',
          sortable: true,
          resizable: true,
          show: true,
          className: 'text-center',
          Cell: ({ value }) => {
            return value || '0';
          }
        },
        {
          Header: 'Features',
          accessor: 'totalProductFeaturesCount',
          sortable: true,
          resizable: true,
          show: true,
          Cell: ({ value }) => {
            return value || '0';
          },
          className: 'text-center'
        },
        {
          Header: 'Measures',
          accessor: 'totalProductMeasuresCount',
          sortable: true,
          resizable: true,
          show: true,
          className: 'text-center',
          Cell: ({ value }) => {
            return value || '0';
          }
        },
        {
          Header: 'Zero Ship Wt',
          accessor: 'totalProductZeroShipWt',
          sortable: true,
          resizable: true,
          show: true,
          className: 'text-center',
          Cell: ({ value }) => {
            return value || '0';
          }
        },
        {
          Header: 'Zero Ship Dims',
          accessor: 'totalProductZeroShipDims',
          sortable: true,
          resizable: true,
          show: true,
          className: 'text-center',
          Cell: ({ value }) => {
            return value || '0';
          }
        }
      ];
    default:
  }
};

const ProductsAnalytics = (
  {
    result,
    selectedReportType,
    defaultPageSize,
    inProgress,
    handleFetchData,
    activeColumns
  }
) => {
  const { results = [], count, numPages } = result || {};
  const columns = getColumns(selectedReportType);
  if (activeColumns.length !== 0) {
    columns.forEach(col => {
      col.show = activeColumns.includes(col.accessor);
    });
  }
  return (
    <Col sm={12} style={{ marginTop: '1rem' }}>
      <div className="table-products" style={{ marginTop: '1rem' }}>
        <Table
          manual
          data={results}
          columns={columns}
          key={selectedReportType}
          defaultPageSize={defaultPageSize}
          showPagination={true}
          noDataText={inProgress ? 'Loading' : 'No data found'}
          pages={numPages || 0}
          defaultSorted={[
            {
              id: 'manufacturerName',
              desc: false
            }
          ]}
          onFetchData={handleFetchData}
          onColumnChange={activeColumns => {
            window.localStorage.setItem(
              'productAnalyticsColumns',
              activeColumns
            );
          }}
          showColumnsSelector={true}
        />
      </div>

    </Col>
  );
};

export default ProductsAnalytics;
