import React from 'react';
import { reduxForm } from 'redux-form';
import { Button, Row, Col } from 'react-bootstrap';

const DistributorUpdateApiKeyForm = props => {
  const { handleSubmit, submitting, distributorCreateError, onClose } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col md={12} className="d-flex justify-content-between">
          <Button variant="primary" type="submit" disabled={submitting}>
            Request Key
          </Button>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Col>
      </Row>
      {distributorCreateError &&
        distributorCreateError.message &&
        <p>{distributorCreateError.message}</p>}
    </form>
  );
};

export default reduxForm({
  form: 'distributorUpdateApiKey'
})(DistributorUpdateApiKeyForm);
