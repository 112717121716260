import React from 'react';
import classNames from 'classnames';

const Checkbox = ({ value, label, size, labelStyle, ...rest }) => (
  <div className={classNames('toggle-group', size)}>
    <label className="toggle">
      <input type="checkbox" checked={value} {...rest} />
      <div className="toggle-slider" />
    </label>
    {label && <div className="toggle-label" style={labelStyle || {}}>{label}</div>}
  </div>
);

const Radio = ({ value, label, size, ...rest }) => (
  <div className={classNames('toggle-group', size)}>
    <label className="toggle">
      <input type="radio" checked={value} {...rest} />
      <div className="toggle-slider" />
    </label>
    {label && <div className="toggle-label">{label}</div>}
  </div>
);

export default {
  Checkbox,
  Radio
};
