import React, { useEffect, useState } from 'react';
import { Row, Col, Overlay, Tooltip } from 'react-bootstrap';
import numeral from 'numeral';
import Icon from '../../../../ui/Icon';
import ReactDOM from 'react-dom';

const DataQualityMetrics = ({ adminMetricsResult }) => {

    const [show, toggleShow] = useState(false);
    const [target, setTarget] = useState();
    const [toolTipText, setToolTipText] = useState('');
    const duplicateProductsRef = React.createRef();
    const noLastUpadteDateProductsRef = React.createRef();

    const windowPointerUpHandler = (event) => {
        const id = event.target.id;
        if (id !== 'iconDiv') {
            toggleShow(false);
        }
    }
    useEffect(() => {
        window.addEventListener('pointerup', windowPointerUpHandler);
        return () => {
            window.removeEventListener('pointerup', windowPointerUpHandler);
        }
    }, []);

    const getTarget = () => {
        return ReactDOM.findDOMNode(target);
    }

    const sharedProps = {
        container: this,
        target: getTarget,
        show: show
    };

    const showToolTip = (nextTraget, text) => {
        if (show && target === nextTraget) {
            toggleShow(false)
            return;
        }
        setTarget(nextTraget);
        setToolTipText(text);
        toggleShow(true);
    }

    const [
        duplicateProducts,
        withoutLastUpdatedDate
    ] = adminMetricsResult || [];

    const { productsWithoutLastUpdatedDate } = withoutLastUpdatedDate || {};


    return (
        <Row>
            <Col sm={4}>
                <div className="metricsTile">
                    <div className='title'>Product Duplicates</div>
                    <div className='contentDiv'>
                        <div className='dataContainer'>
                            <span>{numeral((duplicateProducts || []).length).format('0,0')}</span>
                            <span>products</span>
                        </div>
                    </div>
                    <div
                        id='iconDiv'
                        className='iconDiv'
                        ref={duplicateProductsRef}
                        onClick={
                            () => {
                                showToolTip(
                                    duplicateProductsRef.current,
                                    `Count of products that have duplicate product codes
                                     within the same manufacturer. (Should be 0.)`);
                            }
                        }
                    >
                        <Icon name='info' />
                    </div>
                </div>
            </Col>
            <Col sm={4}>
                <div className="metricsTile">
                    <div className='title'>No Last Upload Date</div>
                    <div className='contentDiv'>
                        <div className='dataContainer'>
                            <span>{numeral(productsWithoutLastUpdatedDate).format('0,0')}</span>
                            <span>products</span>
                        </div>
                    </div>
                    <div
                        className='iconDiv'
                        ref={noLastUpadteDateProductsRef}
                        onClick={
                            () => {
                                showToolTip(
                                    noLastUpadteDateProductsRef.current,
                                    `Count of products that did not receive a
                                    LastUpdatedDate property. (Should be 0.)
                                    `
                                )
                            }
                        }
                    >
                        <Icon name='info' />
                    </div>
                </div>
            </Col>
            <Overlay {...sharedProps} placement="bottom">
                <Tooltip id="overload-bottom">{toolTipText}</Tooltip>
            </Overlay>
        </Row>

    )
}

export default DataQualityMetrics;