import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { Field, FieldArray } from 'redux-form';
import FormField from '../../../forms/FormField';
import Icon from '../../../ui/Icon';
import PriceDetails from './PriceDetails';

const renderFeatureDetails = (
  { fields, lookupsResult, featureDetails, handleFormFieldChange }
) => {
  const { featEnums } = lookupsResult || {};
  const featEnumOptions = (featEnums || [])
    .map(({ enumKey, value }) => ({ label: value, value: enumKey }));
  return (
    <div>
      <div className="buttonContainer">
        <Button
          variant="outline-primary"
          size="sm"
          onClick={() => fields.push({})}
        >
          <Icon name="plus" /> Feature
        </Button>
      </div>

      <div>
        {fields.map((featureData, index) => {
          const feature = (featureDetails || [])[index] || {};
          const { FeatEnum, Price } = feature;
          const featEnumData = (featEnums || [])
            .find(({ enumKey }) => enumKey === FeatEnum);
          const { dataType, ref = '' } = featEnumData || {};
          const referenceOptions = ((lookupsResult || {})[ref] || []).map(({
            value,
            enumKey
          }) => {
            if (value.trim().length > 0) {
              return { value: enumKey, label: value };
            } else {
              return { value: enumKey, label: enumKey };
            }
          });
          return (
            <div key={index}>
              <Row>
                <Col sm={12}>
                  <div className="header">
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => fields.remove(index)}
                    >
                      <Icon name="trash" />
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Field
                    name={`${featureData}.FeatEnum`}
                    label="Feature Enum"
                    type="text"
                    component={FormField.SelectReact}
                    options={featEnumOptions}
                    onChange={() => {
                      handleFormFieldChange(
                        'NewProductForm',
                        `${featureData}.FeatVal`,
                        -1
                      );
                    }}
                    isClearable
                  />
                </Col>
                {ref.trim().length === 0
                  ? dataType === 'Date'
                      ? <Col sm={6}>
                          <Field
                            name={`${featureData}.FeatVal`}
                            label="Feature Value"
                            type="text"
                            component={FormField.Datepicker}
                            maxLength={1000}
                          />
                        </Col>
                      : <Col sm={6}>
                          <Field
                            name={`${featureData}.FeatVal`}
                            label="Feature Value"
                            type="text"
                            component={FormField.Input}
                            maxLength={1000}
                          />
                        </Col>
                  : <Col sm={6}>
                      <Field
                        name={`${featureData}.FeatVal`}
                        label="Feature Value"
                        type="text"
                        component={FormField.SelectReact}
                        options={referenceOptions}
                        maxLength={1000}
                        isClearable
                      />
                    </Col>}
              </Row>
              <PriceDetails
                lookupsResult={lookupsResult}
                featurePriceDetails={true}
                featureKey={featureData}
                priceDetails={Price}
              />
              {index !== fields.length - 1 && <div className="divider" />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const FeatureDetails = (
  { lookupsResult, featureDetails, handleFormFieldChange }
) => {
  return (
    <Col md={12}>
      <div className="box">
        <div className="box-header">
          <h3>Feature Details</h3>
        </div>
        <div className="box-body">
          <FieldArray
            name="Feature"
            component={renderFeatureDetails}
            props={{
              lookupsResult,
              featureDetails,
              handleFormFieldChange
            }}
          />
        </div>
      </div>
    </Col>
  );
};

export default FeatureDetails;
