import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import ReactSelect from 'react-select';
import DatePicker from 'react-datepicker';
import { useDropzone } from 'react-dropzone';
import FormError from './FormError';
import Toggle from '../ui/Toggle';
import Icon from '../ui/Icon';
import classNames from 'classnames';
import moment from 'moment';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw } from 'draft-js';
const getValidationState = meta => {
  if (meta.touched) {
    if (meta.valid === false) {
      return 'has-error';
    }
    if (meta.warning) {
      return 'has-warning';
    }
    if (meta.valid === true) {
      // return 'success';
    }
  }

  return null;
};

const Input = (
  {
    input,
    label,
    type,
    meta,
    help,
    ...props
  }
) => (
  <Form.Group controlId={input.name} className={getValidationState(meta)}>
    {label && <Form.Label>{label}</Form.Label>}
    <div className="form-container">
      <Form.Control {...input} type={type} {...props} />
      {help && <Form.Text>{help}</Form.Text>}
      {meta.touched &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

const SelectHTML = (
  {
    input,
    label,
    type,
    meta,
    help,
    ...props
  }
) => (
  <Form.Group controlId={input.name} className={getValidationState(meta)}>
    {label && <Form.Label>{label}</Form.Label>}
    <div className="form-container">
      <Form.Control {...input} as="select" {...props} />
      {help && <Form.Text>{help}</Form.Text>}
      {meta.touched &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

const SelectReact = (
  {
    input,
    label,
    type,
    meta,
    help,
    onClear,
    ...props
  }
) => {
  const ref = React.createRef();
  useEffect(
    () => {
      if (input.value === -1) ref.current.select.clearValue();
    },
    [input.value]
  );
  return (
    <Form.Group controlId={input.name} className={getValidationState(meta)}>
      {label && <Form.Label>{label}</Form.Label>}
      <div className="form-container">
        <ReactSelect
          {...props}
          classNamePrefix="react-select"
          value={(props.options || []).find(o => o.value === input.value)}
          onChange={(value, opt) => {
            input.onChange((value || {}).value || null);
            const { action } = opt || {};
            if (action === 'clear' && onClear) onClear();
            // if (afterChange) afterChange((value || {}).value);
          }}
          onBlur={() => input.onBlur(input.value)}
          ref={ref}
        />
        {help && <Form.Text>{help}</Form.Text>}
        {meta.touched &&
          (meta.error || meta.warning) &&
          <FormError message={meta.error || meta.warning} />}
      </div>
    </Form.Group>
  );
};

const Creatable = (
  {
    input,
    label,
    type,
    meta,
    help,
    ...props
  }
) => (
  <Form.Group controlId={input.name} className={getValidationState(meta)}>
    {label && <Form.Label>{label}</Form.Label>}
    <div className="form-container">
      <ReactSelect.Creatable
        {...props}
        value={input.value}
        onChange={input.onChange}
        onBlur={() => input.onBlur(input.value)}
      />
      {help && <Form.Text>{help}</Form.Text>}
      {meta.touched &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

const InputGroup = (
  {
    input,
    label,
    type,
    meta,
    help,
    children,
    ...props
  }
) => (
  <Form.Group controlId={input.name} className={getValidationState(meta)}>
    {label && <Form.Label>{label}</Form.Label>}
    <div className="form-container">
      {children}
      {help && <Form.Text>{help}</Form.Text>}
      {meta.touched &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

const formatFiles = value => {
  if (!value) return [];
  const keys = Object.keys(value);
  return (keys || []).map(key => value[key]);
};

const InputFileUpload = (
  {
    input,
    label,
    type,
    meta,
    help,
    multiple,
    ...props
  }
) => {
  const handleFileChange = e => {
    e.preventDefault();
    const { files: inputFiles } = e.target;
    if (!inputFiles) return;
    const f = formatFiles(inputFiles);
    if (!multiple) {
      input.onChange(f[0]);
      return;
    }
    input.onChange([...input.value, ...f]);
  };
  return (
    <Form.Group controlId={input.name} className={getValidationState(meta)}>
      {label && <Form.Label>{label}</Form.Label>}
      <div className="form-container">
        <div className="file-upload">
          <div className="btn-file-upload btn btn-secondary">
            Choose Files
            <Form.Control
              value=""
              type="file"
              name={input.name}
              onChange={handleFileChange}
              multiple={multiple || false}
            />
          </div>
        </div>
        {help && <Form.Text>{help}</Form.Text>}
        {meta.touched &&
          (meta.error || meta.warning) &&
          <FormError message={meta.error || meta.warning} />}
      </div>
    </Form.Group>
  );
};

const InputFileDropzone = (
  {
    input,
    label,
    type,
    meta,
    help,
    multiple,
    disabled,
    style,
    ...props
  }
) => {
  const onDrop = useCallback(
    files => {
      if (!multiple) {
        input.onChange(files[0]);
        return;
      }
      input.onChange(files);
    },
    []
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Form.Group
      controlId={input.name}
      className={getValidationState(
        Object.assign({}, meta, { touched: meta.touched || meta.dirty })
      )}
      style={style || {}}
    >
      <div className="file-import" {...getRootProps()} style={style || {}}>
        {label && <Form.Label>{label}</Form.Label>}
        <div className="form-container" style={style || {}}>
          <div className="file-upload" style={style || {}}>
            <Form.Control
              value=""
              type="file"
              name={input.name}
              {...getInputProps({
                disabled: disabled || false,
                multiple: multiple || false
              })}
            />
            {isDragActive
              ? <p>Drop the files here...</p>
              : <p>
                Drag and drop a CSV file here, or click to select a CSV file
              </p>}
          </div>
        </div>
      </div>
      {help && <Form.Text>{help}</Form.Text>}
      {(meta.touched || meta.dirty) &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </Form.Group>
  );
};

const InputToggle = (
  {
    input,
    label,
    meta,
    required,
    help,
    ...props
  }
) => (
  <Form.Group controlId={input.name} className={getValidationState(meta)}>
    <div className="form-container">
      {props.type === 'radio' &&
        <Toggle.Radio {...input} {...props} label={label} />}
      {props.type === 'checkbox' &&
        <Toggle.Checkbox {...input} {...props} label={label} />}
      {meta.touched &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

const Textarea = (
  {
    input,
    label,
    type,
    meta,
    required,
    help,
    ...props
  }
) => (
  <Form.Group controlId={input.name} className={getValidationState(meta)}>
    {label && <Form.Label>{label}</Form.Label>}
    <div className="form-container">
      <Form.Control {...input} type={type} {...props} as="textarea" />
      {help && <Form.Text>{help}</Form.Text>}
      {meta.touched &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

const Datepicker = (
  {
    input,
    label,
    meta,
    help,
    required,
    disableWeekends,
    ...props
  }
) => {
  return (
    <Form.Group
      controlId={input.name}
      className={classNames('form-group-datepicker', getValidationState(meta))}
    >
      {label && <Form.Label>{label}</Form.Label>}
      <div
        className={
          disableWeekends ? 'form-container disable-weekends' : 'form-container'
        }
      >
        <DatePicker
          className="form-control"
          onChange={value => input.onChange((value || '').toString())}
          onFocus={input.onFocus}
          onBlur={e => input.onBlur()}
          dateFormat="MM/dd/yyyy"
          disabledKeyboardNavigation
          selected={
            input.value && moment(input.value).isValid()
              ? moment(input.value).toDate()
              : null
          }
        />
        <Icon name="calendar" />
        {help && <Form.Text>{help}</Form.Text>}
        {meta.touched &&
          (meta.error || meta.warning) &&
          <FormError message={meta.error || meta.warning} />}
      </div>
    </Form.Group>
  );
};

const Datetimepicker = (
  {
    input,
    label,
    meta,
    help,
    required,
    disableWeekends,
    ...props
  }
) => {
  return (
    <Form.Group
      controlId={input.name}
      className={classNames(
        'form-group-datepicker datetimepicker',
        getValidationState(meta)
      )}
    >
      {label && <Form.Label>{label}</Form.Label>}
      <div
        className={
          disableWeekends ? 'form-container disable-weekends' : 'form-container'
        }
      >
        <DatePicker
          showTimeSelect
          className="form-control"
          onChange={value => input.onChange((value || '').toString())}
          onFocus={input.onFocus}
          onBlur={e => input.onBlur()}
          dateFormat="MM/dd/yyyy h:mm aa"
          disabledKeyboardNavigation
          selected={
            input.value && moment(input.value).isValid()
              ? moment(input.value).toDate()
              : null
          }
        />
        <Icon name="calendar" />
        {help && <Form.Text>{help}</Form.Text>}
        {meta.touched &&
          (meta.error || meta.warning) &&
          <FormError message={meta.error || meta.warning} />}
      </div>
    </Form.Group>
  );
};

const getClassInlineCheckbox = (required, disabled) => {
  if (required && disabled) return 'required text-muted';
  if (required) return 'required';
  if (disabled) return 'text-muted';
  return '';
};

const InlineCheckbox = (
  {
    input,
    label,
    altLabel,
    meta,
    required,
    help,
    disabled,
    hideErrorMessage,
    style = {},
    checkBoxProps = {},
    inputContainerStyle,
    ...props
  }
) => (
  <Form.Group
    controlId={input.name}
    className={classNames(getValidationState(meta))}
    style={disabled ? { opacity: '.75', ...style } : style}
  >
    <div
      className="form-container inline-checkbox"
      style={inputContainerStyle || {}}
    >
      <label className="checkbox">
        <Form.Control
          {...input}
          type="checkbox"
          {...props}
          disabled={disabled}
          checked={input.value === true}
        />
        <div className="checkbox-slider" {...checkBoxProps}>
          <Icon name="check" />
        </div>
      </label>
      {label &&
        <strong className={getClassInlineCheckbox(required, disabled)}>
          {label}
        </strong>}
      {altLabel && altLabel}
      {help && <Form.Text>{help}</Form.Text>}
      {!hideErrorMessage &&
        meta.touched &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

const MultiSelect = (
  {
    input,
    label,
    type,
    meta,
    required,
    help,
    className,
    onClear,
    afterChange,
    ...props
  }
) => {
  const values = input.value || (props.defaultValue || []);
  return (
    <Form.Group
      controlId={input.name}
      className={className}
    >
      {getValidationState(meta)}
      {label &&
        <Form.Label
          className={required ? 'control-label required' : 'control-label'}
        >
          {label}
        </Form.Label>}
      <div className="form-container">
        <ReactSelect
          isMulti
          {...props}
          value={(values || [])
            .map(v => (props.options || []).find(o => o.value === v))}
          onChange={(value, opt) => {
            const vals = (value || []).map(v => v.value);
            input.onChange(vals);
            if (opt.action === 'clear' && onClear) onClear();
            if (afterChange) afterChange(vals);
          }}
          onBlur={() => input.onBlur ? input.onBlur(input.value) : () => { }}
          classNamePrefix="react-select"
        />
        {help && <Form.Text>{help}</Form.Text>}
        {meta.touched &&
          (meta.error || meta.warning) &&
          <FormError message={meta.error || meta.warning} />}
      </div>
    </Form.Group>
  );
};

const Wysiwyg = (
  {
    input,
    label,
    meta,
    help,
    toolbar,
    ...props
  }
) => {

  let [editorState, setEdidorState] = useState(EditorState.createEmpty());
  const { value } = input || {};
  useEffect(() => {
    if (value) {
      if (typeof value === 'string') {
        try {
          const contentState = convertFromRaw(JSON.parse(value));
          const editorState = EditorState.createWithContent(contentState);
          setEdidorState(editorState);
        } catch (e) {

        }
      }
    }
  }, [value]);
  return (
    <Form.Group controlId={input.name} className={getValidationState(meta)}>
      {label && <Form.Label>{label}</Form.Label>}
      <div className="form-container">
        <Editor
          {...input}
          wrapperClassName="wysiwyg-wrapper"
          editorClassName="wysiwyg-editor"
          toolbarClassName="wysiwyg-toolbar"
          toolbar={toolbar}
          editorState={editorState}
          onEditorStateChange={(state) => setEdidorState(state)}
          {...props}
        />
        {help && <Form.Text>{help}</Form.Text>}
        {meta.touched &&
          (meta.error || meta.warning) &&
          <FormError message={meta.error || meta.warning} />}
      </div>
    </Form.Group>
  )
}

export default {
  Input,
  InputGroup,
  InputFileUpload,
  InputFileDropzone,
  InputToggle,
  Textarea,
  SelectHTML,
  SelectReact,
  Creatable,
  Datepicker,
  Datetimepicker,
  InlineCheckbox,
  MultiSelect,
  Wysiwyg
};
