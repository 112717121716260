import React, { Component } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../ui/Layout';
import Icon from '../../ui/Icon';
import Table from '../../ui/Table';
import Hidden from '../../ui/Hidden';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import { actionCreators } from '../../../state/modals-dux';
import ModalUploadProducts
  from '../products/products-search/ModalUploadProducts';
import ActAsModal from './ActAsModal';
import CopyButton from '../../ui/CopyButton';

const columns = [
  {
    Header: 'Product Name',
    accessor: 'name'
  }
];

class ManufacturerHomepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiKey: '',
      copied: false
    };
  }

  componentDidMount() {
    const { user, getManufacturerById } = this.props;
    const { accountTypeJoinId } = user || {};
    getManufacturerById(accountTypeJoinId);
  }

  componentWillReceiveProps(nextProps) {
    const { user } = nextProps;
    const { accountTypeJoinId } = user;
    if (accountTypeJoinId !== this.props.user.accountTypeJoinId) {
      this.props.getManufacturerById(accountTypeJoinId);
    }
  }

  limitCharacters(string, limit) {
    if (string.length > limit) {
      return string.substring(0, limit) + '...';
    } else {
      return string;
    }
  }

  render() {
    const {
      user,
      manufacturer,
      showModal
    } = this.props;
    const {
      products,
      name: manufacturerName,
      apiKey
    } = manufacturer || {};

    const {
      imisId,
      nameFirst,
      nameLast,
      email,
      showApiKey,
      viewOnly
    } = user || {};

    return (
      <Layout route="user-homepage">
        <main>

          <div className="layout-header">
            <Container>

              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <Icon name="home" />
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  Dashboard
                </li>
              </ol>

              <div className="header">
                <h1>
                  <Icon name="clipboard" /> Dashboard
                </h1>
              </div>

            </Container>
          </div>

          <Container>
            <div>

              <Row>
                <Col md={6}>

                  <div className="box">
                    <div className="box-header">
                      <h3>Account Details</h3>
                    </div>
                    <div className="box-body">

                      <table className="table table-data">
                        <tbody>
                          <tr>
                            <th>Company</th>
                            <td>{manufacturerName}</td>
                          </tr>
                          {showApiKey &&
                            !viewOnly &&
                            <tr className="align-middle">
                              <th>ApiKey</th>
                              <td>
                                <Row>
                                  <Col>
                                    {!apiKey
                                      ? 'No Api Key Generated'
                                      : this.limitCharacters(apiKey, 15)}
                                  </Col>
                                  <Col><CopyButton text={apiKey} /></Col>
                                </Row>
                              </td>
                            </tr>}
                          <tr>
                            <th>Name</th>
                            <td>{nameFirst} {nameLast}</td>
                          </tr>
                          <tr>
                            <th>Email</th>
                            <td>{email}</td>
                          </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>

                </Col>
                <Col md={4}>

                  <div className="quick-search">
                    <div className="actions">
                      <Link className="action" to="/products">
                        <Icon name="boxes" />
                        <strong>Search<br />Products</strong>
                      </Link>
                      {/* <Button
                        className="action"
                        onClick={() => showModal('UploadProductsModal')}
                      >
                        <Icon name="upload" />
                        <strong>Upload<br />Products</strong>
                      </Button> */}
                    </div>
                  </div>

                </Col>
              </Row>

              {/* <h3 className="layout-small-header">Latest Products</h3>

              <Table
                data={products || []}
                columns={columns}
                noDataText="No products found"
              />

              <ModalUploadProducts /> */}

            </div>
          </Container>

        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result: user,
    error
  } = state.login.toJS();

  const {
    result: manufacturer
  } = state.getManufacturerById.toJS();

  return {
    user,
    manufacturer,
    error
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getManufacturerById: fetchDux.getManufacturerById.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ManufacturerHomepage);
