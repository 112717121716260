import React from 'react';
import Table from '../../../ui/Table';

const columns = [
  {
    Header: 'Product Code',
    accessor: 'ProdCode',
    className: 'text-center',
    id: 'prodCode',
    width: 100,
    resizable: false,
    sortable: false
  },
  {
    Header: 'Row Index',
    accessor: 'actualIndex',
    className: 'text-center',
    id: 'rowIndex',
    width: 100,
    resizable: false,
    sortable: false
  },
  {
    Header: 'Missing Columns',
    Cell: ({ original }) => {
      const { missingColumns } = original;
      const result = missingColumns.reduce(
        (previous, { columnName }) => {
          const value = previous.trim().length > 0
            ? `${previous} , ${columnName}`
            : columnName;
          return value;
        },
        ''
      );
      return <span>{result}</span>;
    },
    id: 'missingColumns',
    sortable: false
  },
  {
    Header: 'FailedValidation Columns',
    Cell: ({ original }) => {
      const { failedValidationColumns } = original;
      const result = failedValidationColumns.reduce(
        (previous, { columnName }) => {
          const value = previous.trim().length > 0
            ? `${previous} , ${columnName}`
            : columnName;
          return value;
        },
        ''
      );
      return <span>{result}</span>;
    },
    id: 'failedValidation',
    sortable: false
  },
  {
    Header: 'InvalidDataType Columns',
    Cell: ({ original }) => {
      const { invalidDataTypeColumns } = original;
      const result = invalidDataTypeColumns.reduce(
        (previous, { columnName }) => {
          const value = previous.trim().length > 0
            ? `${previous} , ${columnName}`
            : columnName;
          return value;
        },
        ''
      );
      return <span>{result}</span>;
    },
    id: 'invalidDataType',
    sortable: false
  },
  {
    Header: 'InvalidProdLink Columns',
    Cell: original => {
      const { invalidProdLinks } = original.original;
      const result = invalidProdLinks.reduce(
        (previous, { columnName, expected, received }) => {
          const v = `${columnName} - expected: ${expected} - received: ${received}`;
          const value = previous.trim().length > 0
            ? `${previous} , ${v}`
            : `${v}`;
          return value;
        },
        ''
      );
      return <span>{result}</span>;
    }
  }
];

const ProductExceptions = ({ exceptionsResult }) => {
  return (
    <div className="table-products">
      <Table
        data={exceptionsResult || []}
        columns={columns}
        defaultPageSize={20}
      />
    </div>
  );
};

export default ProductExceptions;
