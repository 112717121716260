import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../../forms/FormField';
import { Alert, Button, Modal } from 'react-bootstrap';

const required = value => value ? undefined : 'Required';

const ModalEditFAQsGroupForm = props => {
  const { handleSubmit, submitting, closeModal, initialValues } = props;
  const { error } = initialValues;
  return (
    <form onSubmit={handleSubmit}>
      <Modal.Body>
        {
          error &&
          <Alert variant="danger">{error.message || ''}</Alert>
        }
        <Field
          name="groupName"
          type="text"
          component={FormField.Input}
          label="Group Name"
          validate={[required]}
        />
        <label className="form-label">View Options</label>
        <Field
          name="viewOptionsManufacturers"
          type="checkbox"
          component={FormField.InputToggle}
          label="Manufacturers"
        />
        <Field
          name="viewOptionsDistributors"
          type="checkbox"
          component={FormField.InputToggle}
          label="Distributors"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" type="submit" disabled={submitting}>
          Update Group
        </Button>
        <Button size="sm" variant="outline-secondary" onClick={closeModal} disabled={submitting}>
          Cancel
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default reduxForm({
  form: 'faq-group-update-form',
  enableReinitialize: true
})(ModalEditFAQsGroupForm);
