import React, { useState, useEffect } from 'react';
import { Col, Row, Overlay, Tooltip } from 'react-bootstrap';
import numeral from 'numeral';
import Icon from '../../../../ui/Icon';
import ReactDOM from 'react-dom';

const DistributorMetrics = ({ adminMetricsResult }) => {
  const [show, toggleShow] = useState(false);
  const [target, setTarget] = useState();
  const [toolTipText, setToolTipText] = useState('');

  const totalDistributorsRef = React.createRef();
  const registeredDistributorsRef = React.createRef();
  const activeDistributorsRef = React.createRef();
  const loginActivityRef = React.createRef();

  const windowPointerUpHandler = event => {
    const id = event.target.id;
    if (id !== 'iconDiv') {
      toggleShow(false);
    }
  };
  useEffect(
    () => {
      window.addEventListener('pointerup', windowPointerUpHandler);
      return () => {
        window.removeEventListener('pointerup', windowPointerUpHandler);
      };
    },
    []
  );

  const getTarget = () => {
    return ReactDOM.findDOMNode(target);
  };

  const sharedProps = {
    container: this,
    target: getTarget,
    show: show
  };

  const showToolTip = (nextTraget, text) => {
    if (show && target === nextTraget) {
      toggleShow(false);
      return;
    }
    setTarget(nextTraget);
    setToolTipText(text);
    toggleShow(true);
  };

  const {
    totalDistributorsCount,
    registeredDistributorsCount,
    activeCompanies,
    distCurrentWeekLoginCount,
    distLastWeekLoginCount,
    distTwoWeeksBeforeLoginCount,
    distThreeWeeksBeforeLoginCount
  } = adminMetricsResult || {};

  return (
    <Row>
      <Col sm={4}>
        <div className="metricsTile">
          <div className="title">Total Distributors</div>
          <div className="contentDiv">
            <div className="dataContainer">
              <span>{numeral(totalDistributorsCount).format('0,0')}</span>
              <span>companies</span>
            </div>
          </div>
          <div
            className="iconDiv"
            id="iconDiv"
            ref={totalDistributorsRef}
            onClick={() => {
              showToolTip(
                totalDistributorsRef.current,
                `Total distributor companies in the repository.`
              );
            }}
          >
            <Icon name="info" />
          </div>
        </div>
      </Col>

      <Col sm={4}>
        <div className="metricsTile">
          <div className="title">Registered Distributors</div>
          <div className="contentDiv">
            <div className="dataContainer">
              <span>{numeral(registeredDistributorsCount).format('0,0')}</span>
              <span>companies</span>
            </div>
          </div>
          <div
            className="iconDiv"
            id="iconDiv"
            ref={registeredDistributorsRef}
            onClick={() => {
              showToolTip(
                registeredDistributorsRef.current,
                `Companies with at least 1 user who has created a password.`
              );
            }}
          >
            <Icon name="info" />
          </div>
        </div>
      </Col>

      <Col sm={4}>
        <div className="metricsTile">
          <div className="title">Active Distributors</div>
          <div className="contentDiv">
            <div className="dataContainer">
              <span>{numeral(activeCompanies).format('0,0')}</span>
              <span>companies</span>
            </div>
          </div>
          <div
            className="iconDiv"
            id="iconDiv"
            ref={activeDistributorsRef}
            onClick={() => {
              showToolTip(
                activeDistributorsRef.current,
                `Companies with at least 1 user who has logged in.`
              );
            }}
          >
            <Icon name="info" />
          </div>
        </div>
      </Col>

      <Col sm={6}>
        <div className="metricsTile with-margin-top">
          <div className="title">Login Activity</div>
          <div className="contentDiv multi less-padding flex-start">
            <div className="containerVertical">
              <div className="dataContainer mini">
                <span>{numeral(distCurrentWeekLoginCount).format('0,0')}</span>
                <span>Companies</span>
              </div>
              <div className="iconContainer flex-end">
                <Icon name="circle" />
                <div className="ruler" />
              </div>
              <label>Current Week</label>
            </div>

            <div className="containerVertical">
              <div className="dataContainer mini">
                <span>{numeral(distLastWeekLoginCount).format('0,0')}</span>
                <span>Companies</span>
              </div>

              <div className="iconContainer">
                <div className="ruler" />
                <Icon name="clock" prefix="far" />
                <div className="ruler" />
              </div>
              <label>Last Week</label>
            </div>

            <div className="containerVertical">
              <div className="dataContainer mini">
                <span>
                  {numeral(distTwoWeeksBeforeLoginCount).format('0,0')}
                </span>
                <span>Companies</span>
              </div>
              <div className="iconContainer">
                <div className="ruler" />
                <Icon name="clock" prefix="far" />
                <div className="ruler" />
              </div>
              <label>2 Weeks</label>
            </div>

            <div className="containerVertical">
              <div className="dataContainer mini">
                <span>
                  {numeral(distThreeWeeksBeforeLoginCount).format('0,0')}
                </span>
                <span>Companies</span>
              </div>

              <div className="iconContainer">
                <div className="ruler" />
                <Icon name="clock" prefix="far" />
              </div>
              <label>3 Weeks</label>
            </div>

          </div>
          <div
            className="iconDiv extra"
            id="iconDiv"
            ref={loginActivityRef}
            onClick={() => {
              showToolTip(
                loginActivityRef.current,
                `How many users have logged in for each of the 
                                    past 4 weeks. (Includes current week.)`
              );
            }}
          >
            <Icon name="info" />
          </div>
        </div>
      </Col>
      <Overlay {...sharedProps} placement="bottom">
        <Tooltip id="overload-bottom">{toolTipText}</Tooltip>
      </Overlay>
    </Row>
  );
};

export default DistributorMetrics;
