import React from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../state/modals-dux';
import ActAsForm from '../login/ActAsForm';
import fetchDux from '../../../state/fetch-dux';
import { useHistory } from 'react-router-dom';

const ActAsModal = ({ openModal, closeModal, postAdminImpersonate, loading, getProductsCount }) => {
    const history = useHistory();

    const handleSubmit = values => {
        postAdminImpersonate(values, null, null, () => {
            getProductsCount();
            closeModal();
            history.push('/'); 
            window.location.reload();
        });
    };

    return (
        <Modal show={openModal === 'ActAsModal'} onHide={() => {
            if (!loading)
                closeModal();
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Impersonate As</Modal.Title>
            </Modal.Header>
            <ActAsForm
                onSubmit={handleSubmit}
                loading={loading}
            />
        </Modal>
    )
}

const mapState = state => {

    const {
        inProgress: loading,
    } = state.postAdminImpersonate.toJS();
    return {
        openModal: state.modals,
        loading
    }
}
const mapDispatch = dispatch => bindActionCreators({
    ...actionCreators,
    postAdminImpersonate: fetchDux.postAdminImpersonate.createAction,
    getProductsCount: fetchDux.getProductsCount.createAction
}, dispatch);

export default connect(mapState, mapDispatch)(ActAsModal);
