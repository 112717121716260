import React, { useState } from 'react';
import { Col, Button, Collapse } from 'react-bootstrap';
import moment from 'moment';
import numeral from 'numeral';

const formatTime = d => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).format('MMM D, Y');
};

const formatPrice = d => {
  if (!d || d === 0) return '';
  return numeral(d).format('0,0.00');
};

const FeatureDetails = ({ Feature }) => {
  const [toggleFeaturePrice, showFeaturePrice] = useState(-1);
  return (Feature || []).length > 0
    ? <Col md={12}>
        <div className="box">
          <div className="box-header">
            <h3>Features</h3>
          </div>
          <div className="box-body">
            <table className="table table-data-features">
              <thead>
                <tr>
                  <th>Feature</th>
                  <th>Value</th>
                  <th />
                </tr>
              </thead>
              {Feature.map((a, index) => {
                return (
                  <tbody key={index}>
                    <tr>
                      <td>{a.FeatEnum || ''}</td>
                      <td>{a.FeatVal || ''}</td>
                      <td className="text-right">
                        {toggleFeaturePrice === index
                          ? <Button
                              size="sm"
                              variant="outline-primary"
                              onClick={() => showFeaturePrice()}
                            >
                              Hide Price
                            </Button>
                          : <Button
                              size="sm"
                              variant="primary"
                              onClick={() => showFeaturePrice(index)}
                            >
                              Show Price
                            </Button>}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <Collapse in={toggleFeaturePrice === index}>
                          <div>
                            {(a.Price || []).length > 0 &&
                              <div className="price">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Type</th>
                                      <th>Amount</th>
                                      <th>Currency Code</th>
                                      <th>Country Code</th>
                                      <th>Min. Quantity</th>
                                      <th>Quantity</th>
                                      <th>Unit of Measure</th>
                                      <th>Sell Quantity</th>
                                      <th>Effective From</th>
                                      <th>Effective To</th>
                                      <th>Zero Price Reason</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {a.Price.map((a, i) => {
                                      return (
                                        <tr key={i}>
                                          <td>
                                            {a.PriceType || ''}
                                          </td>
                                          <td>
                                            {formatPrice(a.PriceAmount)}
                                          </td>
                                          <td>
                                            {a.PriceCurrCode || 'USD'}
                                          </td>
                                          <td>
                                            {a.PriceCtryCode || ''}
                                          </td>
                                          <td>
                                            {a.MinPriceQty || ''}
                                          </td>
                                          <td>
                                            {a.PriceQty || ''}
                                          </td>
                                          <td>
                                            {a.PriceUOM || ''}
                                          </td>
                                          <td>{a.SellQty || ''}</td>
                                          <td>
                                            {formatTime(a.PriceEffFrom)}
                                          </td>
                                          <td>
                                            {formatTime(a.PriceEffTo)}
                                          </td>
                                          <td>{a.ZeroPriceReason || ''}</td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>}
                          </div>
                        </Collapse>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>
      </Col>
    : null;
};

export default FeatureDetails;
