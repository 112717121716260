import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Field, FieldArray } from 'redux-form';
import FormField from '../../../forms/FormField';
import Icon from '../../../ui/Icon';
import ShippingDetails from './ShippingDetails';

const renderPackDetails = (
  {
    fields,
    unitOfMeasurementOptions,
    packCodeOptions,
    lookupsResult,
    shippingDetails,
    packDetails
  }
) => {
  return (
    <div>
      <div className="buttonContainer">
        <Button
          variant="outline-primary"
          size="sm"
          onClick={() => fields.push({})}
        >
          <Icon name="plus" /> Pack
        </Button>
      </div>
      <div>
        {fields.map((packData, index) => {
          return (
            <div key={index}>
              <Row>
                <Col sm={12}>
                  <div className="header">
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => fields.remove(index)}
                    >
                      <Icon name="trash" />
                    </Button>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={4}>
                  <Field
                    name={`${packData}.PackUOM`}
                    label="Pack Unit of Measurement"
                    type="text"
                    component={FormField.SelectReact}
                    options={unitOfMeasurementOptions}
                    isClearable
                  />
                </Col>

                <Col sm={4}>
                  <Field
                    name={`${packData}.PackQty`}
                    label="Pack Quantity"
                    type="text"
                    component={FormField.Input}
                    normalize={(value, previousValue) => {
                      if (value && value.trim().length > 0) {
                        const regExp = /^[0-9]+$/;
                        if (regExp.test(value)) {
                          return value;
                        }
                        return previousValue;
                      }
                      return value;
                    }}
                  />
                </Col>

                <Col sm={4}>
                  <Field
                    name={`${packData}.PackCodeType`}
                    label="Pack Code Type"
                    type="text"
                    component={FormField.SelectReact}
                    options={packCodeOptions}
                    isClearable
                  />
                </Col>

              </Row>
              <Row>
                <Col sm={6}>
                  <Field
                    name={`${packData}.PackCode`}
                    label="Pack Code"
                    type="text"
                    component={FormField.Input}
                    maxLength={60}
                  />
                </Col>
                <Col sm={6}>
                  <Field
                    name={`${packData}.PackDesc`}
                    label="Pack Description"
                    type="text"
                    component={FormField.Input}
                    maxLength={60}
                  />
                </Col>
              </Row>

              <ShippingDetails
                lookupsResult={lookupsResult}
                fieldName={packData}
                shippingDetails={shippingDetails}
                packDetails={packDetails}
                packIndex={index}
                packShippingDetails
              />

              {index !== fields.length - 1 && <div className="divider" />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const PackDetails = ({ lookupsResult, shippingDetails, packDetails }) => {
  const { UOM, PackCodeType } = lookupsResult || {};
  const unitOfMeasurementOptions = (UOM || [])
    .map(({ value, enumKey }) => ({ value: enumKey, label: value }));
  const packCodeOptions = (PackCodeType || [])
    .map(({ enumKey }) => ({ value: enumKey, label: enumKey }));
  return (
    <Col md={12}>
      <div className="box">

        <div className="box-header">
          <h3>Pack Details</h3>
        </div>
        <div className="box-body">
          <FieldArray
            name="Pack"
            component={renderPackDetails}
            props={{
              unitOfMeasurementOptions,
              packCodeOptions,
              lookupsResult,
              shippingDetails,
              packDetails
            }}
          />
        </div>

      </div>
    </Col>
  );
};

export default PackDetails;
