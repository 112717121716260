/* eslint-disable array-callback-return */
/* eslint-disable no-throw-literal */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  formValueSelector,
  reduxForm,
  change,
  getFormValues
} from 'redux-form';
import AssetDetails from './AssetDetails';
import ContentDetails from './ContentDetails';
import FeatureDetails from './FeatureDetails';
import GroupDetails from './GroupDetails';
import MeasureDetails from './MeasureDetails';
import PackDetails from './PackDetails';
import PriceDetails from './PriceDetails';
import ProdLinkDetails from './ProdLinkDetails';
import ProductDetails from './ProductDetails';
import ProductInfo from './ProductInfo';
import ShippingDetails from './ShippingDetails';
import { Button } from 'react-bootstrap';
import fetchDux from '../../../../state/fetch-dux';
import moment from 'moment';
import { Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
// import ProductOwner from './ProductOwner';

const validateFieldsAsync = (values, dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(
      fetchDux.asyncValidateProductFields.createAction(
        values,
        null,
        null,
        respose => {
          resolve(respose);
        }
      )
    );
  }).then(result => {
    const { ProdCode, message } = result;
    if (ProdCode) {
      throw { ProdCode: message };
    }
  });
};

class NewProductForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitButtonClicked: false,
      isLoading: false
    };
  }

  onCancelHandler = () => {
    const { history } = this.props;
    history.push('/products');
  };

  render() {
    const { submitButtonClicked, isLoading } = this.state;
    const {
      result,
      lookupsResult,
      priceDetails,
      packDetails,
      shippingDetails,
      featureDetails,
      handleFormFieldChange,
      surchargeDetails,
      submitHandler,
      formValues,
      invalid,
      priceEffectDatesValid,
      priceDetailsValid,
      measureDetailsValid,
      contentDetailsValid,
      prodLinkDetailsValid,
      assetDetailsValid,
      groupDetailsValid,
      packDetailsValid,
      shippingDetailsValid,
      productInfoValid,
      handleSubmit,
      productDetailsValid
    } = this.props;

    const {
      result: manufacturer
    } = result || {};

    const onSubmitClickHandler = () => {
      if (!submitButtonClicked)
        this.setState({ submitButtonClicked: true, isLoading: true });
      if (!invalid && priceEffectDatesValid) {
        submitHandler(formValues);
      }
    };

    return (
      <form onSubmit={handleSubmit}>
        <div
          className="buttonContainer"
          style={{ marginBottom: '15px', display: 'flex', gap: 4 }}
        >
          <Button
            type="submit"
            variant="danger"
            size="sm"
            onClick={this.onCancelHandler}
          >
            Cancel
          </Button>

          {invalid
            ? <Button
                type="submit"
                onClick={() => onSubmitClickHandler()}
                size="sm"
                disabled
              >
                Save Product
              </Button>
            : <Button
                type="submit"
                onClick={() => onSubmitClickHandler()}
                size="sm"
                className={isLoading ? 'disabled' : ''}
              >
                {isLoading ? 'Saving...' : 'Save Product'}
              </Button>}
        </div>
        {(invalid || !priceEffectDatesValid) &&
          submitButtonClicked &&
          <Col md={12}>
            <div className="box">
              <div className="box-body">
                {!priceEffectDatesValid &&
                  <div>
                    <span className="text-danger">
                      Price Effect To date older than Price Effect From date for some of the Price Data
                    </span>
                    <br />
                  </div>}
                {!priceDetailsValid &&
                  <div>
                    <span className="text-danger">
                      Price details are invalid.
                    </span>
                    <br />
                  </div>}
                {!measureDetailsValid &&
                  <div>
                    <span className="text-danger">
                      Measure details are invalid.
                    </span>
                    <br />
                  </div>}
                {!contentDetailsValid &&
                  <div>
                    <span className="text-danger">
                      Content details are invalid.
                    </span>
                    <br />
                  </div>}
                {!prodLinkDetailsValid &&
                  <div>
                    <span className="text-danger">
                      ProdLink details are invalid.
                    </span>
                    <br />
                  </div>}
                {!assetDetailsValid &&
                  <div>
                    <span className="text-danger">
                      Asset details are invalid.
                    </span>
                    <br />
                  </div>}

                {!groupDetailsValid &&
                  <div>
                    <span className="text-danger">
                      Group details are invalid.
                    </span>
                    <br />
                  </div>}
                {!packDetailsValid &&
                  <div>
                    <span className="text-danger">
                      Pack details are invalid.
                    </span>
                    <br />
                  </div>}
                {!shippingDetailsValid &&
                  <div>
                    <span className="text-danger">
                      Shipping details are invalid.
                    </span>
                    <br />
                  </div>}
                {!productInfoValid &&
                  <div>
                    <span className="text-danger">
                      Product Info details are invalid.
                    </span>
                    <br />
                  </div>}
                {!productDetailsValid &&
                  <div>
                    <span className="text-danger">
                      Surcharge details are invalid.
                    </span>
                    <br />
                  </div>}
              </div>
            </div>
          </Col>}

        <ProductInfo
          lookupsResult={lookupsResult}
          manufacturer={manufacturer}
        />
        <ProductDetails
          lookupsResult={lookupsResult}
          surchargeDetails={surchargeDetails}
        />
        <ShippingDetails
          packDetails={packDetails}
          lookupsResult={lookupsResult}
          shippingDetails={shippingDetails}
        />

        <PriceDetails
          lookupsResult={lookupsResult}
          priceDetails={priceDetails}
        />

        <PackDetails
          lookupsResult={lookupsResult}
          shippingDetails={shippingDetails}
          packDetails={packDetails}
        />

        <FeatureDetails
          lookupsResult={lookupsResult}
          featureDetails={featureDetails}
          handleFormFieldChange={handleFormFieldChange}
        />

        <GroupDetails />

        <AssetDetails lookupsResult={lookupsResult} />

        <ProdLinkDetails lookupsResult={lookupsResult} />

        <ContentDetails lookupsResult={lookupsResult} />

        <MeasureDetails lookupsResult={lookupsResult} />

        <div
          className="buttonContainer"
          style={{ marginBottom: '15px', display: 'flex', gap: 4 }}
        >
          <Button
            type="submit"
            variant="danger"
            size="sm"
            onClick={this.onCancelHandler}
          >
            Cancel
          </Button>
          {invalid
            ? <Button
                type="submit"
                onClick={() => onSubmitClickHandler()}
                size="sm"
                disabled
              >
                Save Product
              </Button>
            : <Button
                type="submit"
                onClick={() => onSubmitClickHandler()}
                size="sm"
                className={isLoading ? 'disabled' : ''}
              >
                {isLoading ? 'Saving...' : 'Save Product'}
              </Button>}
        </div>
      </form>
    );
  }
}

const formName = 'NewProductForm';

const selector = formValueSelector(formName);

const mapState = state => {
  const priceDetails = selector(state, 'Price');
  const packDetails = selector(state, 'Pack');
  const measureDetails = selector(state, 'Measure');
  const contentDetails = selector(state, 'Content');
  const prodLinkDetails = selector(state, 'ProdLink');
  const assetDetails = selector(state, 'Asset');
  const groupDetails = selector(state, 'Group');
  const shippingDetails = selector(state, 'Shipping');
  const featureDetails = selector(state, 'Feature');
  const SurchargeType = selector(state, 'SurchargeType');
  const SurchargeBasis = selector(state, 'SurchargeBasis');
  const SurchargeValue = selector(state, 'SurchargeValue');
  const SurchargeInclMap = selector(state, 'SurchargeInclMAP');

  const prodCode = selector(state, 'ProdCode');
  const categoryEnum = selector(state, 'CatEnum');
  const stocked = selector(state, 'Stocked');
  const productShortDesc = selector(state, 'ProdShortDesc');
  const productLognDesc = selector(state, 'ProdLongDesc');

  const result = state.getManufacturerById.toJS();

  const productInfoValid = (() => {
    if (
      !prodCode ||
      !categoryEnum ||
      !stocked ||
      !productShortDesc ||
      !productLognDesc
    )
      return false;
    return true;
  })();

  const surchargeDetails = {
    SurchargeType,
    SurchargeBasis,
    SurchargeValue,
    SurchargeInclMap
  };

  const formValues = getFormValues(formName)(state);

  const priceEffectDatesValid = (priceDetails || []).some(priceData => {
    const { PriceEffFrom, PriceEffTo } = priceData;
    if (PriceEffFrom && PriceEffTo) {
      const difference = moment(PriceEffFrom).diff(moment(PriceEffTo));
      if (difference > 0) return true;
    }
  });

  const priceDetailsValid = (priceDetails || []).some(priceData => {
    const { PriceAmount, PriceType, ZeroPriceReason } = priceData;
    if (!PriceType) return true;
    if (isNaN(PriceAmount) && !ZeroPriceReason) return true;
    if (parseInt(PriceAmount, 10) === 0 && !ZeroPriceReason) return true;
  });

  const measureDetailsValid = (measureDetails || []).some(measureData => {
    const { MeasEnum, MeasVal, UOMCode } = measureData;
    if (!MeasEnum || !MeasVal || !UOMCode) return true;
  });

  const contentDetailsValid = (contentDetails || []).some(contentData => {
    const { ContText, ContType } = contentData;
    if (!ContText || !ContType) return true;
  });

  const prodLinkDetailsValid = (prodLinkDetails || []).some(prodLinkData => {
    const { LinkProdCode, LinkType } = prodLinkData;
    if (!LinkProdCode || !LinkType) return;
  });

  const assetDetailsValid = (assetDetails || []).some(assetData => {
    const { AssetName, AssetTypeEnum, AssetURL, FileName } = assetData;
    if (!AssetName || !AssetTypeEnum || !AssetURL || !FileName) return true;
  });

  const groupDetailsValid = (groupDetails || []).some(groupData => {
    const { GroupLbl, GroupName } = groupData;
    if (!GroupLbl || !GroupName) return true;
  });

  const packDetailsValid = (packDetails || []).some(packData => {
    const { Shipping } = packData;
    const {
      ShipDep,
      ShipDimUOM,
      ShipHt,
      ShipVol,
      ShipVolUOM,
      ShipWid,
      ShipWt,
      ShipWtUOM
    } = Shipping || {};
    if (!shippingDetails) {
      if (!ShipVol && !ShipVolUOM) {
        if (
          !ShipDep ||
          !ShipDimUOM ||
          !ShipHt ||
          !ShipWid ||
          !ShipWt ||
          !ShipWtUOM
        )
          return true;
      }

      if (
        !ShipDep && !ShipDimUOM && !ShipHt && !ShipWid && !ShipWt && !ShipWtUOM
      ) {
        if (!ShipVol || !ShipVolUOM) return true;
      }
    }
  });

  const shippingDetailsValid = (() => {
    const {
      ShipDep,
      ShipDimUOM,
      ShipHt,
      ShipVol,
      ShipVolUOM,
      ShipWid,
      ShipWt,
      ShipWtUOM
    } = shippingDetails || {};
    const isPackDetailsValid = !packDetailsValid;
    if (!isPackDetailsValid || (packDetails || []).length === 0) {
      if (!ShipVol && !ShipVolUOM) {
        if (
          !ShipDep ||
          !ShipDimUOM ||
          !ShipHt ||
          !ShipWid ||
          !ShipWt ||
          !ShipWtUOM
        )
          return false;
      }

      if (
        !ShipDep && !ShipDimUOM && !ShipHt && !ShipWid && !ShipWt && !ShipWtUOM
      ) {
        if (!ShipVol || !ShipVolUOM) return false;
      }
    }
    return true;
  })();

  const productDetailsValid = (() => {
    if (SurchargeBasis || SurchargeValue || SurchargeInclMap) {
      if (!SurchargeType) return false;
    }
    if (SurchargeType || SurchargeValue || SurchargeInclMap) {
      if (!SurchargeBasis) return false;
    }
    if (SurchargeType || SurchargeBasis || SurchargeInclMap) {
      if (!SurchargeValue) return false;
    }
    if (SurchargeType || SurchargeBasis || SurchargeValue) {
      if (!SurchargeInclMap) return false;
    }
    return true;
  })();

  return {
    result,
    priceDetails,
    packDetails,
    shippingDetails,
    featureDetails,
    surchargeDetails,
    formValues,
    priceEffectDatesValid: !priceEffectDatesValid,
    priceDetailsValid: !priceDetailsValid,
    measureDetailsValid: !measureDetailsValid,
    contentDetailsValid: !contentDetailsValid,
    prodLinkDetailsValid: !prodLinkDetailsValid,
    assetDetailsValid: !assetDetailsValid,
    groupDetailsValid: !groupDetailsValid,
    packDetailsValid: !packDetailsValid,
    shippingDetailsValid,
    productInfoValid,
    productDetailsValid
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getManufacturerById: fetchDux.getManufacturerById.createAction,
      handleFormFieldChange: change
    },
    dispatch
  );

const form = reduxForm({
  form: formName,
  enableReinitialize: true,
  asyncValidate: validateFieldsAsync,
  asyncBlurFields: ['ProdCode']
})(withRouter(NewProductForm));
export default connect(mapState, mapDispatch)(form);
