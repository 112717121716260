import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import { connect } from 'react-redux';
import fetchDux from '../../../../state/fetch-dux';
import FAQsSearchDefault from './FAQsSearchDefault';
import FAQsSearchResults from './FAQsSearchResults';
import FAQsSearchForm from './FAQsSearchForm';
import { bindActionCreators } from 'redux';
import { change } from 'redux-form';

const defaultFAQs = [
  {
    category: 'General',
    articles: [
      {
        url: 'how-do-i-get-started',
        question: "What are Nike's shipping options?"
      },
      {
        url: 'how-do-i-create-an-account',
        question: 'How do I get free shipping on Nike orders?'
      },
      {
        url: 'how-do-i-get-started',
        question: "What is Nike's return policy?"
      },
      {
        url: 'how-do-i-create-an-account',
        question: 'How do I return my Nike order?'
      }
    ]
  },
  {
    category: 'Products, Pricing Updates, and CSV validation',
    articles: [
      {
        url: 'how-do-i-get-started',
        question: 'What is Nike Membership?'
      },
      {
        url: 'how-do-i-create-an-account',
        question: 'How do I get Nike’s newest sneaker releases?'
      },
      {
        url: 'how-do-i-create-an-account',
        question: 'How do I reset my Nike password?'
      },
      {
        url: 'how-do-i-get-started',
        question: 'Where is my Nike order?'
      },
      {
        url: 'how-do-i-create-an-account',
        question: 'Can I cancel or change my Nike order?'
      },
      {
        url: 'how-do-i-get-started',
        question: 'How do I get started?'
      },
      {
        url: 'how-do-i-create-an-account',
        question: 'How do I create an account?'
      },
      {
        url: 'how-do-i-get-started',
        question: 'What is Nike Membership?'
      },
      {
        url: 'how-do-i-create-an-account',
        question: 'How do I get Nike’s newest sneaker releases?'
      },
      {
        url: 'how-do-i-create-an-account',
        question: 'How do I reset my Nike password?'
      },
      {
        url: 'how-do-i-get-started',
        question: 'Where is my Nike order?'
      },
      {
        url: 'how-do-i-create-an-account',
        question: 'Can I cancel or change my Nike order?'
      },
      {
        url: 'how-do-i-get-started',
        question: 'How do I get started?'
      },
      {
        url: 'how-do-i-create-an-account',
        question: 'How do I create an account?'
      }
    ]
  },
  {
    category: 'Downloads',
    articles: [
      {
        url: 'how-do-i-get-started',
        question: 'Do Nike shoes have a warranty?'
      },
      {
        url: 'how-do-i-create-an-account',
        question: 'Can I recycle my Nike shoes?'
      }
    ]
  },
  {
    category: 'Exports to 3rd Party System',
    articles: [
      {
        url: 'how-do-i-get-started',
        question: 'Does Nike offer a student discount?'
      },
      {
        url: 'how-do-i-create-an-account',
        question: 'Does Nike offer a military discount?'
      },
      {
        url: 'how-do-i-create-an-account',
        question: 'Does Nike offer a first responder and medical professional discount?'
      },
      {
        url: 'how-do-i-get-started',
        question: 'Does Nike offer a first responder and medical professional discount Does Nike offer a first responder and medical professional discount?'
      }
    ]
  },
  {
    category: 'Analytics',
    articles: [
      {
        url: 'how-do-i-get-started',
        question: 'Do Nike shoes have a warranty?'
      },
      {
        url: 'how-do-i-create-an-account',
        question: 'Can I recycle my Nike shoes?'
      }
    ]
  },
  {
    category: 'Billing',
    articles: [
      {
        url: 'how-do-i-get-started',
        question: 'Do Nike shoes have a warranty?'
      },
      {
        url: 'how-do-i-create-an-account',
        question: 'Can I recycle my Nike shoes?'
      },
      {
        url: 'how-do-i-get-started',
        question: "What are Nike's shipping options?"
      },
      {
        url: 'how-do-i-create-an-account',
        question: 'How do I get free shipping on Nike orders?'
      },
      {
        url: 'how-do-i-get-started',
        question: "What is Nike's return policy?"
      },
      {
        url: 'how-do-i-create-an-account',
        question: 'How do I return my Nike order?'
      }
    ]
  }
];

const searchResultsFQS = [
  {
    url: 'how-do-i-get-started',
    category: 'General',
    question: 'How do I get free shipping on Nike orders?'
  },
  {
    url: 'how-do-i-get-started',
    category: 'Products, Pricing Updates, and CSV validation',
    question: 'Do Nike shoes have a warranty?'
  },
  {
    url: 'how-do-i-get-started',
    category: 'Downloads',
    question: "What are Nike's shipping options?"
  },
  {
    url: 'how-do-i-get-started',
    category: 'Exports to 3rd Party System',
    question: 'Does Nike offer a first responder and medical professional discount Does Nike offer a first responder and medical professional discount?'
  },
  {
    url: 'how-do-i-get-started',
    category: 'Analytics',
    question: 'How do I get free shipping on Nike orders?'
  }
];

class FAQsSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillReceiveProps(nextProps) {
    const { user } = nextProps;
    const { user: currentUser } = this.props;
    if (currentUser.accountType !== user.accountType) {
      this.props.getFAQs();
    }
  }
  componentDidMount() {
    this.props.getFAQs();
  }
  render() {
    const { result, getFAQs, inProgress, changeHandler } = this.props;
    return (
      <Layout route="faqs-search">
        <main>

          <div className="layout-header">
            <Container>

              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <Icon name="home" />
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  Frequently Asked Questions
                </li>
              </ol>

              <div className="header">
                <h1>
                  <Icon name="comments" /> Frequently Asked Questions
                </h1>
              </div>

            </Container>
          </div>

          <Container>
            <FAQsSearchForm
              onSubmit={values => {
                const { search } = values;
                this.setState({ search });
                getFAQs(values, 'faq-search-form');
              }}
              onClear={
                () => {
                  this.props.getFAQs()
                  this.setState({ search: null })
                  changeHandler('faq-search-form', 'search', '')
                }
              }
            />
            {this.state.search &&
              <FAQsSearchResults results={result} search={this.state.search} inProgress={inProgress} />}
            {!this.state.search && <FAQsSearchDefault results={result} />}

          </Container>

        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  const { result, inProgress } = state.getFAQs.toJS();
  const user = state.login.toJS().result;
  return {
    result,
    user,
    inProgress
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getFAQs: fetchDux.getFAQs.createAction,
      changeHandler: change
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(FAQsSearch);
