import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Button } from 'react-bootstrap';

const FAQsSearchForm = props => {
  const { handleSubmit, submitting, onClear } = props;
  return (
    <form onSubmit={handleSubmit} className="faq-search">
      <h5>
        Need quick help? Browse for solutions to the most commonly known problems.
      </h5>
      <div className="faq-search-form">
        <Field
          name="search"
          type="text"
          component={FormField.Input}
          placeholder="Search help articles…"
        />
        <Button
          variant="primary"
          type="submit"
          disabled={submitting}
          style={{ right: '120px' }}
        >
          Search
        </Button>
        <Button
          variant="seconday"
          type="button"
          disabled={submitting}
          onClick={() => onClear()}
        >
          Clear
        </Button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'faq-search-form'
})(FAQsSearchForm);
