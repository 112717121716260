import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Button } from 'react-bootstrap';

const required = value => value ? undefined : 'Required';
const passwordsmatch = (value, form) => {
  if (form.password === form.confirmPassword) return undefined;
  return 'Password does not match';
};
const passwordsLength = (value, form) => {
  if ((form.password || '').length > 7) return undefined;
  return 'Minimum 8 characters';
};
const ResetPasswordForm = props => {
  const { handleSubmit, inProgress } = props;
  return (
    <form onSubmit={handleSubmit}>
      <p className="text-muted">
        Please update your account password to access FEDA Data Portal.
      </p>
      <Field
        name="password"
        type="password"
        component={FormField.Input}
        label="New Password"
        validate={[required, passwordsLength]}
      />
      <Field
        name="confirmPassword"
        type="password"
        component={FormField.Input}
        label="Confirm New Password"
        validate={[required, passwordsmatch]}
      />
      <div>
        <Button variant="primary" type="submit" disabled={inProgress}>
          Reset Password
        </Button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'reset-password-form'
})(ResetPasswordForm);
