import {
  getJson,
  postJson,
  patchJson,
  putJson,
  delJson,
  postFile,
  getFile
} from './fetch';

import localForage from 'localforage';
import download from 'downloadjs';

const apiBase = process.env.REACT_APP_API_BASE;

export const postLogin = payload =>
  postJson({
    path: `${apiBase}/identity/login`,
    payload
  }).then(result => {
    localForage.setItem('identity', result);
    return result;
  });

export const acceptTermsOfService = payload =>
  postJson({
    path: `${apiBase}/identity/acceptTermsOfService/${payload._id}`
  });

export const cancelTermsOfService = payload =>
  postJson({
    path: `${apiBase}/identity/cancelTermsOfService/${payload._id}`
  });

export const getTermsOfService = () =>
  getJson({
    path: `${apiBase}/identity/getTermsOfService`
  });

export const getTermsByVersion = payload =>
  getJson({
    path: `${apiBase}/identity/getTermsByVersion/${payload}`
  });

export const validateLogin = () =>
  getJson({
    path: `${apiBase}/identity/validate`
  });

export const validateToken = payload =>
  getJson({
    path: `${apiBase}/identity/validateToken/${payload}`
  });

export const getDistributors = () =>
  getJson({
    path: `${apiBase}/distributors`
  });

export const getUserById = id =>
  getJson({
    path: `${apiBase}/users/${id}`
  });

export const getUsers = () =>
  getJson({
    path: `${apiBase}/users`
  });

export const getManufacturers = () =>
  getJson({
    path: `${apiBase}/manufacturers`
  });

export const getChildManufacturers = () =>
  getJson({
    path: `${apiBase}/manufacturers/children`
  });

export const createUser = payload =>
  postJson({
    path: `${apiBase}/users`,
    payload
  });
export const updateUserById = payload =>
  putJson({
    path: `${apiBase}/users/${payload.id}`,
    payload
  });

export const createManufacturer = payload =>
  postJson({
    path: `${apiBase}/manufacturers`,
    payload
  });

export const getManufacturerById = id =>
  getJson({
    path: `${apiBase}/manufacturers/${id}`
  });

export const updateManufacturerById = payload =>
  putJson({
    path: `${apiBase}/manufacturers/${payload.id}`,
    payload
  });

export const getDistributorById = id =>
  getJson({
    path: `${apiBase}/distributors/${id}`
  });

export const createDistributor = payload =>
  postJson({
    path: `${apiBase}/distributors`,
    payload
  });

export const updateDistributorById = payload =>
  putJson({
    path: `${apiBase}/distributors/${payload.id}`,
    payload
  });

export const deleteDistributorById = payload =>
  delJson({
    path: `${apiBase}/distributors/${payload.id}`,
    payload
  });

export const createDistributorApiKeyById = payload =>
  putJson({
    path: `${apiBase}/distributors/${payload.id}/create-apikey`,
    payload
  });

export const updateDistributorApiKeyById = payload =>
  putJson({
    path: `${apiBase}/distributors/${payload.id}/update-apikey`,
    payload
  });

export const deleteDistributorApiKeyById = payload =>
  putJson({
    path: `${apiBase}/distributors/${payload.id}/delete-apikey`,
    payload
  });

export const deleteManufacturerById = payload =>
  delJson({
    path: `${apiBase}/manufacturers/${payload.id}`,
    payload
  });
export const addChildrensManufacturers = ({ id, payload }) =>
  putJson({
    path: `${apiBase}/manufacturers/addChildCompanies/${id}`,
    payload
  });
export const removeChildManufacturerById = ({ companyId, id, payload }) =>
  putJson({
    path: `${apiBase}/manufacturers/removeChildCompany/${companyId}/${id}`,
    payload
  });
export const deleteUserById = payload =>
  delJson({
    path: `${apiBase}/users/${payload.id}`,
    payload
  });

export const resetPasswordById = ({ _id }) =>
  postJson({
    path: `${apiBase}/users/${_id}/reset-password`
  });

export const createPasswordById = ({ _id }) =>
  postJson({
    path: `${apiBase}/users/${_id}/create-password`
  });
export const validateTokenAndSavePassword = payload =>
  postJson({
    path: `${apiBase}/identity/${payload.id}/changepassword`,
    payload
  });

export const getAllFiles = payload =>
  getJson({
    path: `${apiBase}/manufacturers/files`
  });
export const getAllLogs = (payload = {}) =>
  getJson({
    path: `${apiBase}/logs`,
    payload
  });
export const getUserLogs = id =>
  getJson({
    path: `${apiBase}/logs/user/${id}`
  });
export const getManufacturerLogs = id =>
  getJson({
    path: `${apiBase}/logs/manufacturer/${id}`
  });
export const getDistributorLogs = id =>
  getJson({
    path: `${apiBase}/logs/distributor/${id}`
  });

export const postToggleManufacturerStatus = ({ _id, payload }) =>
  postJson({
    path: `${apiBase}/manufacturers/${_id}/status`,
    payload
  });

export const getDistributorDetails = () =>
  getJson({
    path: `${apiBase}/identity/distributor`
  });
export const getLookups = (payload = {}) =>
  getJson({
    path: `${apiBase}/lookups`,
    payload
  });
export const getProductById = id =>
  getJson({
    path: `${apiBase}/manufacturers/products/${id}`
  });
export const getProducts = (payload = {}) =>
  getJson({
    path: `${apiBase}/manufacturers/products`,
    payload
  });
export const deleteProductById = payload =>
  delJson({
    path: `${apiBase}/manufacturers/products/${payload.id}`,
    payload
  });

export const uploadManufacturerProducts = payload =>
  postFile({
    path: `${apiBase}/manufacturers/upload`,
    payload
  });
export const getProductsUploadStatus = jobId =>
  getJson({
    path: `${apiBase}/manufacturers/upload/${jobId}`
  });

export const downloadProductsFile = ({ payload = {}, Filename }) =>
  getFile({
    path: `${apiBase}/files/products`,
    payload
  })
    .then(function (response) {
      return response.blob();
    })
    .then(function (blob) {
      download(blob, Filename);
    });


export const downloadAllProductsFile = ({ filename }) =>
  getFile({
    path: `${apiBase}/files/products/all-products-download`,
  })
    .then(function (response) {
      return response.blob().then(function (blob) {
        download(blob, filename.value);
      });
    });

export const getAllProductsFilename = (payload = {}) =>
  getJson({
    path: `${apiBase}/files/products/all-products-filename`,
    payload
  });


export const downloadActivityLog = ({ payload = {}, Filename }) =>
  getFile({
    path: `${apiBase}/files/activityLog`,
    payload
  })
    .then(function (response) {
      return response.blob();
    })
    .then(function (blob) {
      download(blob, Filename);
    });

export const downloadContactsReport = ({ payload = {}, Filename }) =>
  getFile({
    path: `${apiBase}/files/contactsReport`,
    payload
  })
    .then(function (response) {
      return response.blob();
    })
    .then(function (blob) {
      download(blob, Filename);
    });

export const downloadTermsReport = ({ payload = {}, Filename }) =>
  getFile({
    path: `${apiBase}/files/termsReport`,
    payload
  })
    .then(function (response) {
      return response.blob();
    })
    .then(function (blob) {
      download(blob, Filename);
    });

export const downloadProductAnalytics = ({ payload = {}, Filename }) =>
  getFile({
    path: `${apiBase}/files/productAnalytics`,
    payload
  })
    .then(function (response) {
      return response.blob();
    })
    .then(function (blob) {
      download(blob, Filename);
    });

export const downloadExceptionsFile = ({ jobId, abortControllerSignal }) =>
  getFile({
    path: `${apiBase}/manufacturers/upload/exceptions/${jobId}`,
    abortControllerSignal
  });

export const downloadDistributorProductsFile = (
  { search, abortControllerSignal }
) =>
  getFile({
    path: `${apiBase}/distributors/scheduler/download?${search}`,
    abortControllerSignal
  });

export const saveCSVJobs = payload =>
  postJson({
    path: `${apiBase}/distributors/csvJobs`,
    payload
  });
export const getCSVJobs = (payload = {}) =>
  getJson({
    path: `${apiBase}/distributors/getCSVJobs`,
    payload
  });
export const deleteCSVJobById = payload =>
  delJson({
    path: `${apiBase}/distributors/delete-csv-job/${payload.jobId}`,
    payload
  });
export const createFTPJobs = payload =>
  postJson({
    path: `${apiBase}/manufacturers/ftpJobs`,
    payload
  });
export const getFTPJobs = (payload = {}) =>
  getJson({
    path: `${apiBase}/manufacturers/ftpJobs`,
    payload
  });
export const deleteFTPJobById = payload =>
  delJson({
    path: `${apiBase}/manufacturers/delete-ftp-job/${payload.jobId}`,
    payload
  });
export const testConnection = payload =>
  postJson({
    path: `${apiBase}/files/test-connection`,
    payload
  });

export const downloadAssetValidationResults = (
  { auditLogId, abortControllerSignal }
) =>
  getFile({
    path: `${apiBase}/manufacturers/asset-validation/${auditLogId}`,
    abortControllerSignal
  });

export const downloadProductAsset = ({ payload, Filename }) =>
  getFile({
    path: `${apiBase}/files/asset`,
    payload
  })
    .then(function (response) {
      return response.blob();
    })
    .then(function (blob) {
      download(blob, Filename);
    });

// getFile({
//   path: `${apiBase}/manufacturers/upload/exceptions/${jobId}`
// }).then(function (response) {
//   return response.blob();
// }).then(function (blob) {
//   download(blob, fileName);
// })

export const getProductReferences = payload =>
  getJson({
    path: `${apiBase}/admin/product-references`,
    payload
  });

export const addProductReference = payload =>
  postJson({
    path: `${apiBase}/admin/product-references`,
    payload
  });

export const editProductReference = payload =>
  putJson({
    path: `${apiBase}/admin/product-references`,
    payload
  });

export const getProductMiscLookups = () =>
  getJson({
    path: `${apiBase}/admin/misc-lookups`
  });

export const deleteProductReference = payload =>
  delJson({
    path: `${apiBase}/admin/product-references`
  });
export const downloadReports = (
  { payload = {}, Filename, abortControllerSignal }
) =>
  getFile({
    path: `${apiBase}/admin/reports/download`,
    payload,
    abortControllerSignal
  })
    .then(function (response) {
      return response.blob();
    })
    .then(function (blob) {
      download(blob, Filename);
    });

export const getAdminReports = payload =>
  getJson({
    path: `${apiBase}/admin/reports`,
    payload
  });

export const getAdminMetrics = payload =>
  getJson({
    path: `${apiBase}/admin/reports/metrics`,
    payload
  });

export const getProductLookups = () =>
  getJson({
    path: `${apiBase}/manufacturers/lookups`
  });

export const getProductCodes = () =>
  getJson({
    path: `${apiBase}/manufacturers/lookups/prodcodes`
  });

export const postProduct = payload =>
  postJson({
    path: `${apiBase}/manufacturers/product`,
    payload
  });

export const asyncValidateProductFields = payload =>
  postJson({
    path: `${apiBase}/manufacturers/product/asyncvalidate`,
    payload
  });

export const editProduct = payload =>
  putJson({
    path: `${apiBase}/manufacturers/product`,
    payload
  });

export const getFAQs = payload =>
  getJson({
    path: `${apiBase}/faqs`,
    payload
  });

export const getFAQsById = id =>
  getJson({
    path: `${apiBase}/faqs/${id}`
  });

export const getUploadExceptions = ({ jobId, ...rest }) =>
  getJson({
    path: `${apiBase}/manufacturers/upload/exceptions/${jobId}`,
    payload: rest
  });

export const postAdminImpersonate = payload =>
  postJson({
    path: `${apiBase}/admin/impersonate`,
    payload
  }).then(result => {
    localForage.setItem('identity', result);
    return result;
  });

export const getAdminLookups = () =>
  getJson({
    path: `${apiBase}/identity/admin-lookups`
  });

export const createFAQGroup = payload =>
  postJson({
    path: `${apiBase}/admin/faq/group`,
    payload
  });

export const getAllFAQs = () =>
  getJson({
    path: `${apiBase}/admin/faqs`
  });

export const getAllFAQGroups = () =>
  getJson({
    path: `${apiBase}/admin/faq/groups`
  });

export const createFAQArticle = payload =>
  postJson({
    path: `${apiBase}/admin/faq/article`,
    payload
  });

export const deleteFAQArticle = id =>
  delJson({
    path: `${apiBase}/admin/faq/article/${id}`,
    payload: {}
  });

export const getFAQArticleById = id =>
  getJson({
    path: `${apiBase}/admin/faq/article/${id}`
  });

export const updateFAQArticleById = ({ payload, id }) =>
  putJson({
    path: `${apiBase}/admin/faq/article/${id}`,
    payload
  });

export const updateFAQGroupById = ({ payload, id }) =>
  putJson({
    path: `${apiBase}/admin/faq/group/${id}`,
    payload
  });

export const deleteFAQGroup = id =>
  delJson({
    path: `${apiBase}/admin/faq/group/${id}`,
    payload: {}
  });

export const downloadProductsNotFoundFile = (
  { jobId, abortControllerSignal }
) =>
  getFile({
    path: `${apiBase}/manufacturers/upload/productsnotfound/${jobId}`,
    abortControllerSignal
  });

export const downloadProductInitialTemplate = ({ payload = {}, Filename }) =>
  getFile({
    path: `${apiBase}/files/products/initial-template`,
    payload
  })
    .then(function (response) {
      return response.blob();
    })
    .then(function (blob) {
      download(blob, Filename);
    });

export const downloadProductPriceTemplate = ({ payload = {}, Filename }) =>
  getFile({
    path: `${apiBase}/files/products/price-template`,
    payload
  })
    .then(function (response) {
      return response.blob();
    })
    .then(function (blob) {
      download(blob, Filename);
    });

export const downloadProductAssetTemplate = ({ payload = {}, Filename }) =>
  getFile({
    path: `${apiBase}/files/products/asset-template`,
    payload
  })
    .then(function (response) {
      return response.blob();
    })
    .then(function (blob) {
      download(blob, Filename);
    });

export const getProductsCount = () =>
  getJson({
    path: `${apiBase}/manufacturers/products/count`
  });
