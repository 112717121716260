import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Field, FieldArray } from 'redux-form';
import FormField from '../../../forms/FormField';
import Icon from '../../../ui/Icon';
const required = value => value ? null : 'Required';

const renderPriceDetails = (
  {
    fields,
    priceTypeOptions,
    currencyTypeOptions,
    countryCodeOptions,
    unitOfMeasurementOptions,
    zeroPriceReasonOptions,
    priceDetails = []
  }
) => {
  return (
    <div>
      <div className="buttonContainer">
        <Button
          variant="outline-primary"
          size="sm"
          onClick={() => fields.push({})}
        >
          <Icon name="plus" /> Price
        </Button>
      </div>
      <div>
        {fields.map((priceData, index) => {
          const priceAmount = (priceDetails[index] || {}).PriceAmount;
          const isZeroPriceReasonRequired = !priceAmount ||
            parseInt(priceAmount, 10) === 0;
          return (
            <div key={index}>
              <Row>
                <Col sm={12}>
                  <div className="header">
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => fields.remove(index)}
                    >
                      <Icon name="trash" />
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row key={index}>
                <Col sm={4}>
                  <Field
                    name={`${priceData}.PriceType`}
                    type="text"
                    label="Price Type *"
                    component={FormField.SelectReact}
                    options={priceTypeOptions}
                    validate={[required]}
                    isClearable
                  />
                </Col>

                <Col sm={4}>
                  <Field
                    name={`${priceData}.PriceAmount`}
                    label="Price Amount *"
                    component={FormField.Input}
                    normalize={(value, previousValue) => {
                      if (value && value.trim().length > 0) {
                        const regExp = /^(\d+)?([.]?\d{0,2})?$/;
                        if (regExp.test(value)) {
                          return value;
                        }
                        return previousValue;
                      }
                      return value;
                    }}
                    validate={[required]}
                  />
                </Col>

                <Col sm={4}>
                  <Field
                    name={`${priceData}.PriceCurrCode`}
                    label="Price Currency Code"
                    component={FormField.SelectReact}
                    options={currencyTypeOptions}
                    isClearable
                  />
                </Col>
              </Row>

              <Row>
                <Col sm={4}>
                  <Field
                    name={`${priceData}.PriceCtryCode`}
                    label="Price Country Code"
                    type="text"
                    component={FormField.SelectReact}
                    options={countryCodeOptions}
                    isClearable
                  />
                </Col>

                <Col sm={4}>
                  <Field
                    name={`${priceData}.PriceUOM`}
                    label="Price Unit of Measurement"
                    type="text"
                    options={unitOfMeasurementOptions}
                    component={FormField.SelectReact}
                    isClearable
                  />
                </Col>

                <Col sm={4}>
                  <Field
                    name={`${priceData}.PriceQty`}
                    label="Price Quantity"
                    type="text"
                    component={FormField.Input}
                    normalize={(value, previousValue) => {
                      if (value && value.trim().length > 0) {
                        const regExp = /^[0-9]+$/;
                        if (regExp.test(value)) {
                          return value;
                        }
                        return previousValue;
                      }
                      return value;
                    }}
                  />
                </Col>

              </Row>

              <Row>
                <Col sm={4}>
                  <Field
                    name={`${priceData}.SellQty`}
                    label="Sell Quantity"
                    type="text"
                    component={FormField.Input}
                    normalize={(value, previousValue) => {
                      if (value && value.trim().length > 0) {
                        const regExp = /^[0-9]+$/;
                        if (regExp.test(value)) {
                          return value;
                        }
                        return previousValue;
                      }
                      return value;
                    }}
                  />
                </Col>

                <Col sm={4}>
                  <Field
                    name={`${priceData}.MinPriceQty`}
                    label="Min Price Quantity"
                    type="text"
                    component={FormField.Input}
                    normalize={(value, previousValue) => {
                      if (value && value.trim().length > 0) {
                        const regExp = /^[0-9]+$/;
                        if (regExp.test(value)) {
                          return value;
                        }
                        return previousValue;
                      }
                      return value;
                    }}
                  />
                </Col>

                <Col sm={4}>
                  <Field
                    name={`${priceData}.PriceEffFrom`}
                    label="Price Effect From"
                    type="text"
                    component={FormField.Datepicker}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm={4}>
                  <Field
                    name={`${priceData}.PriceEffTo`}
                    label="Price Effect To"
                    type="text"
                    component={FormField.Datepicker}
                  />
                </Col>

                {isZeroPriceReasonRequired &&
                  <Col sm={4}>
                    <Field
                      name={`${priceData}.ZeroPriceReason`}
                      label={
                        isZeroPriceReasonRequired
                          ? 'Zero Price Reason *'
                          : 'Zero Price Reason'
                      }
                      type="text"
                      component={FormField.SelectReact}
                      options={zeroPriceReasonOptions}
                      validate={isZeroPriceReasonRequired ? [required] : []}
                      isClearable
                    />
                  </Col>}

              </Row>

              {index !== fields.length - 1 && <div className="divider" />}
            </div>
          );
        })}
      </div>

    </div>
  );
};
const PriceDetails = (
  { lookupsResult, priceDetails, featurePriceDetails, featureKey }
) => {
  const {
    PriceType,
    CurrCode,
    CtryCode,
    UOM,
    ZeroPriceReasonList
  } = lookupsResult || {};
  const priceTypeOptions = (PriceType || []).map(({ value, enumKey }) => ({
    value: enumKey,
    label: `${enumKey} : ${value}`
  }));
  const currencyTypeOptions = (CurrCode || [])
    .map(({ value, enumKey }) => ({ label: value, value: enumKey }));
  const countryCodeOptions = (CtryCode || [])
    .map(({ value, enumKey }) => ({ label: value, value: enumKey }));
  const unitOfMeasurementOptions = (UOM || [])
    .map(({ value, enumKey }) => ({ value: enumKey, label: value }));
  const zeroPriceReasonOptions = (ZeroPriceReasonList || [])
    .map(({ enumKey }) => ({ value: enumKey, label: enumKey }));
  if (featurePriceDetails) {
    return (
      <FieldArray
        name={`${featureKey}.Price`}
        component={renderPriceDetails}
        props={{
          priceTypeOptions,
          currencyTypeOptions,
          countryCodeOptions,
          unitOfMeasurementOptions,
          zeroPriceReasonOptions,
          priceDetails
        }}
      />
    );
  }
  return (
    <Col sm={12}>
      <div className="box">
        <div className="box-header">
          <h3>Price Details</h3>
        </div>
        <div className="box-body">
          <FieldArray
            name="Price"
            component={renderPriceDetails}
            props={{
              priceTypeOptions,
              currencyTypeOptions,
              countryCodeOptions,
              unitOfMeasurementOptions,
              zeroPriceReasonOptions,
              priceDetails
            }}
          />
        </div>
      </div>
    </Col>
  );
};

export default PriceDetails;
