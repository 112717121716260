import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import fetchDux from '../../../../state/fetch-dux';
import AddCompanyModalForm from './AddCompanyModalForm';
const { location } = window || {};

export class AddCompanyModal extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        // make our api call
        this.props.getChildManufacturers();
    }
    handleSubmit(values) {
        const {
            manufacturerId:id,
            addChildrensManufacturers,
            closeModal,
            getChildManufacturers
        } = this.props;
        const { companies } = values || [];
        addChildrensManufacturers(
            {
              id,
              payload: { companies }, 
            },
            null,
            null,
            ()=>{
              getChildManufacturers();
            }
        );
        closeModal();
    }
    render() {
        const {
            openModal,
            closeModal,
            name,
            result,
            manufacturerId
        } = this.props;
        return (
            <Modal
                show={openModal === name}
                onHide={closeModal}
                className="modal-success"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Create New Manufacturer</Modal.Title>
                </Modal.Header>
                <AddCompanyModalForm 
                    closeModal={closeModal} 
                    manufacturerId={manufacturerId} 
                    companyOptions={(result || {}).options} 
                    onSubmit={this.handleSubmit}
                />
            </Modal>
        );
    }
}
const mapState = state => { 
    const {result} = state.getChildManufacturers.toJS();
    const updatedResults = state.addChildrensManufacturers.toJS();
    return {
        openModal: state.modals,
        result,
        updatedResults 
    }
};
const mapDispatch = dispatch =>
    bindActionCreators(
        {
            ...actionCreators,
            getChildManufacturers: fetchDux.getChildManufacturers.createAction,
            addChildrensManufacturers: fetchDux.addChildrensManufacturers.createAction
        },
        dispatch
    );
export default connect(mapState, mapDispatch)(AddCompanyModal);
