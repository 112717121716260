import React from 'react';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import DistributorCreateApiKey
  from '../routes/distributors/distributors-create-apikey/DistributorCreateApiKey';
class CopyButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false
    };
  }

  handleCopy = async () => {
    try {
      const { text } = this.props;
      await navigator.clipboard.writeText(text);
      this.setState({ copied: true });
      setTimeout(
        () => {
          this.setState({ copied: false });
        },
        3000
      );
    } catch (error) {}
  };

  render() {
    const { text, isDistributor } = this.props;
    const { copied } = this.state;

    return (
      <div>
        {text
          ? <Button
              onClick={this.handleCopy}
              className={!copied && 'disabled-button'}
              disabled={!text}
              variant="primary"
              size="sm"
            >
              {copied ? 'Copied!' : 'Copy'}
            </Button>
          : !isDistributor
              ? <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top">For Distributor Only!</Tooltip>
                  }
                >
                  <span className="d-inline-block">
                    <Button
                      onClick={this.handleCopy}
                      className={!copied && 'disabled-button'}
                      disabled={!text}
                      variant="primary"
                      size="sm"
                    >
                      {copied ? 'Requesting...' : 'Request Key'}
                    </Button>
                  </span>
                </OverlayTrigger>
              : <DistributorCreateApiKey />}
      </div>
    );
  }
}

export default CopyButton;
