import React, { Component } from 'react';
import ReactTable from 'react-table';
import { Form, DropdownButton, Dropdown, Row, Col } from 'react-bootstrap';
import Icon from './Icon';
import 'react-table/react-table.css';

const filterData = ({ columns, search, data }) => {
  const s = (search || '').toLowerCase().replace(/\s/g, '');
  const keys = columns.map(({ accessor }) => accessor);
  return (data || []).filter(d => {
    const str = keys
      .reduce((prev, key) => `${prev}${d[key] || ''}`, '')
      .toLowerCase()
      .replace(/\s/g, '');
    return str.indexOf(s) !== -1;
  });
};

const ColumnButton = (
  {
    formattedColumns,
    toggleColumn
  }
) => (
  <DropdownButton
    variant="outline-secondary"
    size="sm"
    id="vendor-columns"
    title="Columns"
    alignRight
    className="checkbox-dropdown"
    style={{ display: 'inline-block' }}
  >
    {formattedColumns.filter(({ show }) => show).map(({
      Header,
      id,
      accessor
    }, index) => (
      <div
        key={index}
        className="dropdown-item"
        onClick={() =>
          toggleColumn({
            show: false,
            columns: [id || accessor]
          })}
      >
        <Icon name="toggle-on" />
        {Header}
      </div>
    ))}
    {formattedColumns.filter(({ show }) => !show).length > 0 &&
      <Dropdown.Divider />}
    {formattedColumns.filter(({ show }) => !show).map(({
      Header,
      id,
      accessor
    }, index) => (
      <div
        key={index}
        className="dropdown-item"
        onClick={() =>
          toggleColumn({
            show: true,
            columns: [id || accessor]
          })}
      >
        <Icon name="toggle-off" />
        {Header}
      </div>
    ))}
  </DropdownButton>
);

const ExportButton = (
  {
    onExport,
    onExportAll
  }
) => (
  <DropdownButton
    variant="outline-secondary"
    size="sm"
    id="export-columns"
    title="Export"
    alignRight
    className="checkbox-dropdown"
    style={{ display: 'inline-block', marginLeft: '5px' }}
  >
    <div className="dropdown-item" onClick={() => onExport ? onExport() : null}>
      Export
    </div>
    <div
      className="dropdown-item"
      onClick={() => onExportAll ? onExportAll() : null}
    >
      Export All
    </div>
  </DropdownButton>
);

class Table extends Component {
  constructor(props) {
    super(props);
    const { columns } = props;
    const activeColumns = (columns || [])
      .filter(({ show }) => show !== false)
      .map(({ id, accessor }) => id || accessor);
    this.state = { activeColumns, searchState: '' };
    this.toggleColumn = this.toggleColumn.bind(this);
  }
  toggleColumn(
    {
      show,
      columns
    }
  ) {
    const { activeColumns } = this.state;
    if (show === true) {
      const next = columns.filter(c => !activeColumns.some(a => a === c));
      if (this.props.onColumnChange) this.props.onColumnChange([...activeColumns, ...next]);
      return this.setState({ activeColumns: [...activeColumns, ...next] });
    }

    const next = activeColumns.filter(a => !columns.some(c => c === a));
    if (this.props.onColumnChange) this.props.onColumnChange(next);
    this.setState({ activeColumns: next });
  }
  render() {
    const {
      data,
      columns,
      onSearchChange,
      showSearch,
      search,
      showNumResults,
      totalResults,
      showColumnsSelector,
      showExport,
      onExport,
      onExportAll,
      searchProps,
      searchInputProps,
      AfterSearch,
      BeforeSearch,
      invalidSortColumn,
      ...props
    } = this.props;
    const { activeColumns, searchState } = this.state;
    const filteredData = filterData({
      columns,
      search: onSearchChange ? search : searchState,
      data
    });

    const formattedColumns = columns.map(column => {
      const show = activeColumns.some(
        a => a === (column.id || column.accessor)
      );
      return Object.assign({}, column, { show });
    });

    const handleSearchChange = e => {
      const { value } = e.target || {};
      if (onSearchChange) return onSearchChange(value);
      this.setState({ searchState: value });
    };
    return (
      <div>
        {(showSearch || showColumnsSelector) &&
          <Row className="table-actions">
            {AfterSearch &&
              <Col sm="auto">
                {AfterSearch}
              </Col>}
            {showSearch &&
              <Col>
                <div {...searchInputProps || {}}>
                  <Form.Control
                    type="text"
                    placeholder="Search..."
                    className="table-search"
                    onChange={handleSearchChange}
                    value={onSearchChange ? search : searchState}
                  />
                </div>
              </Col>}
            {BeforeSearch &&
              <Col sm="auto">
                {BeforeSearch}
              </Col>}
            {showNumResults &&
              <Col>
                <div className="table-total">
                  <b>Results:</b>
                  {' '}
                  {totalResults ? totalResults : (data || []).length}
                </div>
              </Col>}
            {invalidSortColumn &&
              <Col
                sm={6}
                className="text-center"
                style={{ fontSize: 'smaller', alignSelf: 'end', color: 'red' }}
              >
                Unable to sort by price, please select another column to sort by.
              </Col>}
            {(showColumnsSelector || showExport) &&
              <Col className="text-right">
                {showColumnsSelector &&
                  <ColumnButton
                    formattedColumns={formattedColumns}
                    toggleColumn={this.toggleColumn}
                  />}
                {showExport &&
                  <ExportButton
                    onExport={onExport}
                    onExportAll={onExportAll}
                  />}
              </Col>}
          </Row>}
        <ReactTable
          data={filteredData || []}
          columns={formattedColumns || []}
          {...props}
        />
      </div>
    );
  }
}

export default Table;
