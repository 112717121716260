
import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import fetchDux from '../../../../state/fetch-dux';
import Icon from '../../../ui/Icon';
import Layout from '../../../ui/Layout';
import Loading from "../../../ui/Loading";
import Notification from '../../../ui/Notification';
import Exceptions from './Exceptions';
import NewProductForm from './NewProductForm';


class ProductNew extends Component {


    componentDidMount() {
        const { getProductLookups } = this.props;
        getProductLookups();
    }

    componentDidUpdate() {
        const { createProductResult, history, clearCreateNewProduct } = this.props;
        const { insertedId } = createProductResult || {};
        if (insertedId) {
            clearCreateNewProduct();
            history.push(`/products/details/${insertedId}`);
        }
    }

    componentWillUnmount() {
        const { clearCreateNewProduct } = this.props;
        clearCreateNewProduct();
    }


    getInitialValues = () => {
        return {
            Deleted: 'No',
            Price: [{}]
        }
    }

    render() {
        const {
            lookupsResult,
            getFeatEnumReferenceType,
            createNewProduct,
            lookupInProgress,
            createProductResult,
            createProductError,
            clearCreateNewProduct
        } = this.props;
        return (
            <Layout route='product-new'>
                <main>
                    <div className="layout-header">
                        <Container>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/">
                                        <Icon name="home" />
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/products">
                                        Products
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    New Product
                                </li>
                            </ol>
                        </Container>
                    </div>

                    <Container>
                        {
                            lookupInProgress &&
                            <Loading />
                        }
                        {createProductResult && <Exceptions result={createProductResult} />}
                        {
                            !lookupInProgress &&
                            <NewProductForm
                                lookupsResult={lookupsResult}
                                initialValues={this.getInitialValues()}
                                getFeatEnumReferenceType={getFeatEnumReferenceType}
                                onSubmit={() => { }}
                                submitHandler={
                                    (values) => {
                                        createNewProduct(values);
                                    }
                                }
                            />
                        }
                    </Container>

                    {
                        createProductError &&
                        <Notification key="create-product"
                            duration={3}
                            closable={true}
                            type="danger"
                            onClose={clearCreateNewProduct}
                        >
                            {'Failed to create product, please try again.'}
                        </Notification>
                    }
                </main>

            </Layout>
        )
    }
}

const mapState = state => {

    const {
        result: lookupsResult,
        inProgress: lookupInProgress,
    } = state.getProductLookups.toJS();

    const {
        result: createProductResult,
        inProgress: createProductInProgress,
        error: createProductError
    } = state.postProduct.toJS();

    const {
        error
    } = state.asyncValidateProductFields.toJS()


    return {
        lookupsResult,
        lookupInProgress,
        createProductResult,
        createProductInProgress,
        createProductError: createProductError || error
    }
}

const mapDispatch = dispatch => bindActionCreators({
    getProductLookups: fetchDux.getProductLookups.createAction,
    createNewProduct: fetchDux.postProduct.createAction,
    clearCreateNewProduct: fetchDux.postProduct.clearAction
}, dispatch)

export default connect(mapState, mapDispatch)(ProductNew);