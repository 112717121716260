import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import DistributorsSearch from './distributors-search/DistributorsSearch.js';
import DistributorsCreate from './distributors-create/DistributorsCreate.js';
import DistributorsEdit from './distributors-edit/DistributorsEdit.js';
import DistributorsDetails from './distributors-details/DistributorsDetails.js';
import DistributorCreateApiKey
  from './distributors-create-apikey/DistributorCreateApiKey.js';
import DistributorDeleteApiKey
  from './distributors-delete-apikey/DistributorDeleteApiKey.js';
import DistributorUpdateApiKey
  from './distributors-update-apikey/DistributorUpdateApiKey.js';

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={DistributorsSearch} />
    <Route exact path={`${match.url}/create`} component={DistributorsCreate} />
    <Route exact path={`${match.url}/:id`} component={DistributorsDetails} />
    <Route exact path={`${match.url}/:id/edit`} component={DistributorsEdit} />
    {/* <Route exact path={`${match.url}/:id/get-apikey`} component={Distributor} /> */}
    <Route
      exact
      path={`${match.url}/:id/create-apikey`}
      component={DistributorCreateApiKey}
    />
    <Route
      exact
      path={`${match.url}/:id/update-apikey`}
      component={DistributorUpdateApiKey}
    />
    <Route
      exact
      path={`${match.url}/:id/delete-apikey`}
      component={DistributorDeleteApiKey}
    />
    <Redirect to={`${match.url}`} />
  </Switch>
);
