import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import fetchDux from '../../../../state/fetch-dux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ManufacturersEditForm from './ManufacturersEditForm';

class ManufacturersEdit extends Component {
  componentDidMount() {
    //api call to get manufacturer details
    const {
      match,
      getManufacturerById
    } = this.props;
    const { id } = match.params || {};
    getManufacturerById(id);
  }
  render() {
    const {
      history,
      match,
      updateManufacturerById,
      manufacturerResult
    } = this.props;
    const { id } = match.params || {};
    const {
      result: manufacturers
    } = manufacturerResult || {}
    const {
      imisId,
      name,
      enableManualProducts
    } = manufacturers || {}
    const handleSubmit = values => {
      values.id = id
      updateManufacturerById(values, 'manufacturers-edit-form', null, manufacturer => {
        const { updatedManufacturer: manufacturerDetails } = manufacturer || {};
        const { _id } = manufacturerDetails || {};
        history.push(`/manufacturers/${_id}`)
      })
    }
    return (
      <Layout route="manufacturers-edit">
        <main>

          <div className="layout-header">
            <Container>

              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <Icon name="home" />
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/manufacturers">
                    Manufacturers
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  Edit Manufacturer
                </li>
              </ol>

              <div className="header">
                <h1>
                  <Icon name="industry" /> Edit Manufacturer
                </h1>
              </div>

            </Container>
          </div>

          <Container>
            <ManufacturersEditForm onSubmit={handleSubmit} initialValues={{ imisId: imisId, name: name, enableManualProducts }} />
          </Container>

        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result: values,
    error
  } = state.updateManufacturerById.toJS();

  const manufacturerResult = state.getManufacturerById.toJS();

  return {
    values,
    manufacturerResult,
    error
  };
};
const mapDispatch = dispatch => bindActionCreators(
  {
    updateManufacturerById: fetchDux.updateManufacturerById.createAction,
    getManufacturerById: fetchDux.getManufacturerById.createAction
  },
  dispatch
);
export default connect(mapState, mapDispatch)(ManufacturersEdit);
