import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import fetchDux from '../../../../state/fetch-dux';
import UsersCreateForm from './UsersCreateForm';
import CreateManufacturerModal from './CreateManufacturerModal';
import CreateDistributorModal from './CreateDistributorModal';
import { change } from 'redux-form';
import qs from 'query-string';

const getAllOptions = ({
  manufacturersResult,
  distributorsResult
}) => {
  const { result: r1 } = manufacturersResult || {};
  const { result: r2 } = distributorsResult || {};
  const { items: i1 } = r1 || {};
  const { items: i2 } = r2 || {};
  const l1 = (i1 || []).map(r => ({...r, accountType: 'manufacturer' }))
  const l2 = (i2 || []).map(r => ({...r, accountType: 'distributor' }))
  return [...(l1 || []), ...(l2 || [])]
}

const getInitialValues = ({
  id,
  manufacturersResult,
  distributorsResult
}) => {

  const allOptions = getAllOptions({ manufacturersResult, distributorsResult });
  if (!id) return {
    sendPasswordEmail: true,
    accountType: 'manufacturer'
  }

  const found = allOptions.find(({ _id }) => _id === id);

  if (!found) return {
    sendPasswordEmail: true,
    accountType: 'manufacturer'
  }

  const { accountType, _id } = found || {};

  return {
    sendPasswordEmail: true,
    accountType,
    accountTypeJoinId: _id
  }
}

class UsersCreate extends Component {

  componentDidMount() {
    this.props.clearCreateUser();
  }
  componentWillMount() {
    // make our api call
    this.props.getManufacturers();
    this.props.getDistributors();
  }
  render() {
    const {
      history,
      location,
      showModal,
      manufacturersResult,
      distributorsResult,
      error,
      changeForm
    } = this.props;

    const { search } = location || {};

    const query = qs.parse(search || '');

    const { id } = query || {};

    const initialValues = getInitialValues({
      id,
      manufacturersResult,
      distributorsResult
    })


    // console.dir(manufacturersResult);
    // console.dir(distributorsResult);
    const handleSubmit = values => {
      this.props.createUser(values, 'user-create-form', null, user => {
        const { userId } = user || {};
        history.push(`/users/${userId}`)
      })
    
    }
    const reloadManufacturers = (data) => {
      this.props.getManufacturers(null, null, null, () => {
        const { manufacturerId } = data || {};
        changeForm('user-create-form', 'accountTypeJoinId', manufacturerId);
      });
    }
    const reloadDistributors = (data) => {
      this.props.getDistributors(null, null, null, () => {
        const { distributorId } = data || {};
        changeForm('user-create-form', 'accountTypeJoinId', distributorId);
      });
    }
    return (
      <Layout route="users-create">
        <main>

          <div className="layout-header">
            <Container>

              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <Icon name="home" />
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/users">
                    Users
                </Link>
                </li>
                <li className="breadcrumb-item active">
                  Create User
              </li>
              </ol>

              <div className="header">
                <h1>
                  <Icon name="users" /> Create New User
              </h1>
              </div>

            </Container>
          </div>

          <Container>
            <UsersCreateForm
              userCreateError={error}
              showModal={showModal}
              distributors={distributorsResult.result}
              manufacturers={manufacturersResult.result}
              onSubmit={handleSubmit}
              initialValues={initialValues}
              enableReinitialize
            />
          </Container>

          <CreateManufacturerModal
            name="CreateManufacturerModal"
            reload={reloadManufacturers}
          />
          <CreateDistributorModal
            name="CreateDistributorModal"
            reload={reloadDistributors}
          />

        </main>
      </Layout>
    );
  };

}
const mapState = state => {
  const {
    result: values,
    error
  } = state.createUser.toJS();

  const manufacturersResult = state.getManufacturers.toJS();
  const distributorsResult = state.getDistributors.toJS();

  return {
    values,
    manufacturersResult,
    distributorsResult,
    error
  };
};
const mapDispatch = dispatch => bindActionCreators(
  {
    ...actionCreators,
    createUser: fetchDux.createUser.createAction,
    clearCreateUser: fetchDux.createUser.clearAction,
    getManufacturers: fetchDux.getManufacturers.createAction,
    getDistributors: fetchDux.getDistributors.createAction,
    changeForm: change
  },
  dispatch
);

export default connect(mapState, mapDispatch)(UsersCreate);
