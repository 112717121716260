import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../state/fetch-dux';

import SecureRoute from './ui/SecureRoute';
import Homepage from './routes/homepage/Homepage';
import ManufacturerHomepage from './routes/homepage/ManufacturerHomepage';
import DistributorsHomepage from './routes/homepage/DistributorsHomePage';
import Users from './routes/users/Users';
import Distributors from './routes/distributors/Distributors';
import Manufacturers from './routes/manufacturers/Manufacturers';
import Logs from './routes/aws-logs/Logs';
import Login from './routes/login/Login';
import termsOfService from './routes/terms/termsOfService';
import CreatePassword from './routes/create-password/CreatePassword';
import ResetPassword from './routes/reset-password/ResetPassword';
import Products from './routes/products/Products';
import FtpJobs from './routes/ftp-jobs/FtpJobs';
import AutomatedDownloads from './routes/automated-emails/AutomatedDownloads';
import { LastLocationProvider } from 'react-router-last-location';
import References from './routes/references/References';
import Admin from './routes/admin/Admin';
import FAQs from './routes/faqs/FAQs';

const DashboardSwitch = (
  {
    user
  }
) => {
  const {
    accountType,
    isAdmin
  } = user || {};
  if (isAdmin) return <SecureRoute path="/" component={Homepage} exact />;
  if (accountType === 'manufacturer')
    return <SecureRoute path="/" component={ManufacturerHomepage} exact />;
  if (accountType === 'distributor')
    return <SecureRoute path="/" component={DistributorsHomepage} exact />;
  return false;
};

const MySwitch = ({ user }) => {
  const {
    accountType,
    isAdmin,
    impersonate
  } = user || {};
  if (isAdmin && !impersonate)
    return (
      <Switch>
        <SecureRoute path="/" component={Homepage} exact />
        <SecureRoute adminOnly path="/users" component={Users} />
        <SecureRoute adminOnly path="/distributors" component={Distributors} />
        <SecureRoute
          adminOnly
          path="/manufacturers"
          component={Manufacturers}
        />
        <SecureRoute adminOnly path="/logs" component={Logs} />
        <SecureRoute path="/products" component={Products} />
        <SecureRoute path="/ftp-uploads" component={FtpJobs} />
        <SecureRoute
          path="/automated-downloads"
          component={AutomatedDownloads}
        />
        <SecureRoute path="/faqs" component={FAQs} />
        <Route path="/login" component={Login} exact />
        <Route path="/create-password" component={CreatePassword} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/terms-of-service/:version" component={termsOfService} />
        <SecureRoute adminOnly path="/references" component={References} />
        <SecureRoute path="/admin" component={Admin} />
        <Redirect to="/" />
      </Switch>
    );
  if (
    accountType === 'manufacturer' ||
    (isAdmin && impersonate && accountType === 'manufacturer')
  )
    return (
      <Switch>
        <SecureRoute path="/" component={ManufacturerHomepage} exact />
        <SecureRoute path="/products" component={Products} />
        <SecureRoute path="/ftp-uploads" component={FtpJobs} />
        <SecureRoute path="/faqs" component={FAQs} />
        <Route path="/login" component={Login} exact />
        <Route path="/create-password" component={CreatePassword} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/terms-of-service/:version" component={termsOfService} />
        <Redirect to="/" />
      </Switch>
    );
  if (
    accountType === 'distributor' ||
    (isAdmin && impersonate && accountType === 'distributor')
  )
    return (
      <Switch>
        <SecureRoute path="/" component={DistributorsHomepage} exact />
        <SecureRoute path="/products" component={Products} />
        <SecureRoute path="/faqs" component={FAQs} />
        <Route path="/login" component={Login} exact />
        <Route path="/create-password" component={CreatePassword} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/terms-of-service/:version" component={termsOfService} />
        <SecureRoute
          path="/automated-downloads"
          component={AutomatedDownloads}
        />
        <Redirect to="/" />
      </Switch>
    );
  return (
    <Switch>
      <Route path="/login" component={Login} exact />
      <Route path="/create-password" component={CreatePassword} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/terms-of-service/:version" component={termsOfService} />
      <Redirect to="/login" />
    </Switch>
  );
};

class App extends Component {
  componentDidMount() {
    this.props.validateLogin();
  }
  render() {
    const { user } = this.props;
    return (
      <Router>
        <LastLocationProvider>
          <MySwitch user={user} />
        </LastLocationProvider>
      </Router>
    );
  }
}

const mapState = state => {
  const {
    result: user,
    error
  } = state.login.toJS();

  return {
    user,
    error
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      validateLogin: fetchDux.validateLogin.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(App);
