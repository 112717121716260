import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Route } from 'react-router';

import TopNav from './TopNav';

const Layout = ({ children, fluid, route, user, hideNav }) => {
  return (
    <div className={classNames(`route-${route}`)}>
      {!hideNav && <Route component={TopNav} />}
      <div className="layout">
        {children}
      </div>
    </div>
  );
};

const mapState = state => ({ user: state.login.toJS().result });
export default connect(mapState)(Layout);
