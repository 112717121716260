import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../../forms/FormField';
import { Button, Row, Col, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const required = value => {
  if (typeof value === 'object') {
    const { blocks } = value;
    const [element] = blocks
    const { text } = element;
    return text ? undefined : 'Required';
  } else {
    return value ? undefined : 'Required';
  }
}

const FAQCreateForm = props => {
  const { handleSubmit, submitting, faqGroupOptions, createError } = props;
  return (
    <div className="box">
      <div className="box-body">
        <form onSubmit={handleSubmit}>
          <div>
            {createError &&
              <Alert variant="danger">{createError.message || ''}</Alert>}
            <Row>
              <Col md={6} lg={4}>
                <Field
                  name="group"
                  component={FormField.SelectReact}
                  label="FAQ Group"
                  options={faqGroupOptions}
                  validate={[required]}
                />
              </Col>
              <Col md={6} lg={4}>
                <label
                  for="isPublished"
                  class="form-label"
                  style={{ marginBottom: '.85rem' }}
                >
                  Publish article
                </label>
                <Field
                  name="isPublished"
                  type="checkbox"
                  component={FormField.InputToggle}
                />
              </Col>
            </Row>
            <Field
              name="question"
              rows="2"
              component={FormField.Textarea}
              label="Question"
              validate={[required]}
            />
            <Field
              name="answer"
              rows="6"
              component={FormField.Wysiwyg}
              label="Answer"
              validate={[required]}
              toolbar={{
                options: [
                  'inline',
                  'list',
                  'link',
                  'embedded',
                  'remove',
                  'history'
                ],
                inline: {
                  options: ['bold', 'italic', 'underline']
                },
                list: {
                  options: ['unordered', 'ordered']
                }
              }}
            />
            <Button variant="primary" type="submit" disabled={submitting}>
              Create New FAQ
            </Button>
            <Link
              to="/admin/faqs"
              className="btn btn-default"
              disabled={submitting}
            >
              {' '}Cancel{' '}
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default reduxForm({
  form: 'faq-create-form',
  enableReinitialize: true
})(FAQCreateForm);
