import React, { Fragment } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import fetchDux from '../../../../state/fetch-dux';
import DistributorDeleteApiKeyForm from './DistributorDeleteApiKeyForm';
import Loading from '../../../ui/Loading';
const { location } = window || {};

class DistributorDeleteApiKey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,
      id: ''
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  handleDelete = () => {
    const { distributor } = this.props;
    this.setState({ show: false, id: distributor && distributor._id });
  };

  handleLoading = () => {
    this.setState({ loading: true });
  };

  render() {
    const { show, loading } = this.state;

    const {
      distributor,
      deleteDistributorApiKeyById,
      getDistributorDetails,
      distributorId
    } = this.props;

    const handleSubmit = values => {
      this.handleLoading();
      const id = distributor ? distributor._id : distributorId;
      deleteDistributorApiKeyById(
        { ...values, id },
        'distributorDeleteApiKey',
        null,
        () => {
          this.handleClose();
          setTimeout(
            () => {
              getDistributorDetails();
              location.reload();
            },
            500
          );
        }
      );
    };

    return (
      <Fragment>

        <Button variant="danger" size="sm" onClick={this.handleShow}>
          Delete
        </Button>

        <Modal show={show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete API Key</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {loading && <Loading />}
            <div className="text-center align-center">
              <p>
                This will remove the key and all the access. Are you sure you want to delete this API key?
              </p>
            </div>

            <DistributorDeleteApiKeyForm
              onSubmit={handleSubmit}
              onClose={this.handleClose}
            />
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}

const mapState = state => {
  const {
    result: user,
    error
  } = state.login.toJS();

  const {
    result: distributor
  } = state.getDistributorDetails.toJS();

  const { result: values } = state.deleteDistributorApiKeyById.toJS();

  return {
    user,
    distributor,
    error,
    values
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getDistributorDetails: fetchDux.getDistributorDetails.createAction,
      deleteDistributorApiKeyById: fetchDux.deleteDistributorApiKeyById.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(DistributorDeleteApiKey);
