import React, { Fragment } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import fetchDux from '../../../../state/fetch-dux';
import { connect } from 'react-redux';
import DistributorCreateApiKeyForm from './DistributorCreateApiKeyForm';
import Loading from '../../../ui/Loading';

class DistributorCreateApiKey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loading: false,
      description: '',
      multipleDistributors: false
    };
  }

  componentDidMount() {
    this.props.getDistributorDetails();
  }

  componentWillReceiveProps(nextProps) {
    const { user } = nextProps;
    const { accountTypeJoinId } = user;
    if (accountTypeJoinId !== this.props.user.accountTypeJoinId) {
      this.props.getDistributorDetails();
    }
  }

  closeDistributorModal = () => {
    this.props.closeModal();
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: !this.state.show });
  };

  handleGetApiKey = () => {
    this.setState({ show: false });
  };

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleCheckboxChange = event => {
    this.setState({
      supportsMultipleDealers: event.target.checked
    });
  };

  handleLoading = () => {
    this.setState({ loading: true });
  };

  handleSubmit = values => {
    this.handleLoading();
    const { distributor, createDistributorApiKeyById } = this.props;

    const data = {
      ...values,
      id: distributor._id,
      ip: this.state.ip
    };

    createDistributorApiKeyById(data, 'distributorCreateApiKey', null, () => {
      this.handleClose();
      setTimeout(
        () => {
          this.props.getDistributorDetails();
        },
        1000
      );
    });
  };

  render() {
    const { show, loading } = this.state;

    return (
      <Fragment>
        <Button variant="primary" size="sm" onClick={this.handleShow}>
          Request Key
        </Button>

        <Modal show={show} onHide={this.handleClose}>
          <Modal.Header>
            <div className="font-weight-bold">
              Request API Key - Please tell us more about your project.
            </div>
          </Modal.Header>
          <Modal.Body>
            {loading && <Loading />}
            <DistributorCreateApiKeyForm
              onSubmit={this.handleSubmit}
              onClose={this.handleClose}
            />
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}

const mapState = state => {
  const {
    result: user
  } = state.login.toJS();

  const {
    result: distributor
  } = state.getDistributorDetails.toJS();

  const {
    result: values,
    error
  } = state.createDistributorApiKeyById.toJS();

  const distributorResult = state.getDistributorById.toJS();

  return {
    user,
    distributor,
    values,
    distributorResult,
    error
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getDistributorDetails: fetchDux.getDistributorDetails.createAction,
      createDistributorApiKeyById: fetchDux.createDistributorApiKeyById.createAction,
      getDistributorById: fetchDux.getDistributorById.createAction,
      updateDistributorById: fetchDux.updateDistributorById.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(DistributorCreateApiKey);
