import React, { Component, createElement } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const renderComponent = ({ render, component, validateLoginResult, adminOnly }) => props => {
  const {
    result,
    error
  } = validateLoginResult || {};
  if (error) return <Redirect to="/login" />
  if(result && !result.isAdmin && adminOnly){
    return <Redirect to="/" />
  }
  if (result) return render ? render(props) : createElement(component, props);
  return <div></div>;
}

class SecureRoute extends Component {
  render() {
    const { component, render, validateLoginResult, permissions,adminOnly, ...rest } = this.props;
    return <Route
      {...rest}
      render={renderComponent({ render, component, validateLoginResult ,adminOnly})}
    />
  }
}

const mapState = state => ({ validateLoginResult: state.validateLogin.toJS() });

export default connect(mapState)(SecureRoute);