import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../ui/Layout';
import Icon from '../../ui/Icon';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import fetchDux from '../../../state/fetch-dux';
import CopyButton from '../../ui/CopyButton';
import AlertBox from '../../ui/AlertBox';
import moment from 'moment';
import DistributorDeleteApiKey
  from '../distributors/distributors-delete-apikey/DistributorDeleteApiKey';
import DistributorUpdateApiKey
  from '../distributors/distributors-update-apikey/DistributorUpdateApiKey';

class DistributorsHomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiKey: '',
      copied: false,
      manufacturerFilter: null,
      showDeleteModal: false,
      showRekey: false,
      loading: false
    };
  }

  componentDidMount() {
    this.props.getDistributorDetails();
  }

  componentWillReceiveProps(nextProps) {
    const { user } = nextProps;
    const { accountTypeJoinId } = user;
    if (accountTypeJoinId !== this.props.user.accountTypeJoinId) {
      this.props.getDistributorDetails();
    }
  }

  limitCharacters(string, limit) {
    if (string.length > limit) {
      return string.substring(0, limit) + '...';
    } else {
      return string;
    }
  }

  handleRekeyShow = () => {
    this.setState({ showRekey: true });
  };

  handleShowDeleteModal = () => {
    this.setState({ showDeleteModal: true });
  };

  handleCloseDeleteModal = () => {
    this.setState({ showDeleteModal: false });
  };

  handleShowRekeyModal = () => {
    this.setState({ showRekey: true });
  };

  handleCloseRekeyModal = () => {
    this.setState({ showRekey: false });
  };

  render() {
    const {
      user,
      distributor
    } = this.props;

    const {
      nameFirst,
      nameLast,
      email,
      showApiKey,
      viewOnly,
      accountType,
      isAdmin
    } = user || {};

    const isDistributor = accountType === 'distributor' && !isAdmin;

    const {
      name: distributorName,
      apiKey,
      projects,
      _id: distributorId
    } = distributor || {};

    const projectData = projects && projects.length > 0
      ? projects.find(project => project.status === 'active')
      : null;

    const histroy = projectData &&
      projectData.history &&
      projectData.history.length > 0
      ? projectData.history[projectData.history.length - 1]
      : null;

    const submittedDate = moment(histroy && histroy.submittedDate).format(
      'MM/DD/YYYY hh:mm A'
    );

    return (
      <Layout route="user-homepage">
        <main>

          <div className="layout-header">
            <Container>

              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <Icon name="home" />
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  Dashboard
                </li>
              </ol>

              <div className="header">
                <h1>
                  <Icon name="clipboard" /> Dashboard
                </h1>
              </div>

            </Container>
          </div>

          <Container>

            <div>
              <Row>
                <Col md={6}>
                  {!apiKey &&
                    <AlertBox variant="info">
                      Please note that in order to use the API, you will need to request a new key below.
                    </AlertBox>}

                  <div className="box">
                    <div className="box-header">
                      <h3>Account Details</h3>
                    </div>
                    <div className="box-body">

                      <table className="table table-data">
                        <tbody>
                          <tr>
                            <th>Company</th>
                            <td>{distributorName}</td>
                          </tr>
                          {showApiKey &&
                            !viewOnly &&
                            <tr className="align-middle">
                              <th>ApiKey</th>
                              <td>
                                <Row>
                                  <Col>
                                    {!apiKey
                                      ? 'No Api Key Generated'
                                      : this.limitCharacters(apiKey, 15)}
                                  </Col>
                                  <Col>
                                    <CopyButton
                                      text={apiKey}
                                      isDistributor={isDistributor}
                                    />
                                  </Col>
                                </Row>
                              </td>
                            </tr>}

                          <tr>
                            <th>Name</th>
                            <td>{nameFirst} {nameLast}</td>
                          </tr>
                          <tr>
                            <th>Email</th>
                            <td>{email}</td>
                          </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>

                  {projectData &&
                    <div className="box">
                      <div className="box-header">
                        <h3>API Key Details</h3>
                      </div>
                      <div className="box-body">

                        <table className="table table-data">
                          <tbody>
                            <tr>
                              <th>Description of Use</th>
                              <td>
                                <Row>
                                  <Col>{projectData.description}</Col>
                                </Row>
                              </td>
                            </tr>
                            <tr>
                              <th>Accepted By:</th>
                              <td>{histroy.actionBy.name}</td>
                            </tr>
                            <tr>
                              <th>Date/Time</th>
                              <td>{submittedDate}</td>
                            </tr>
                            <tr>
                              {/* // <th>IP Address:</th>
                              // <td>{histroy.actionBy.ipAddress}</td> */}
                            </tr>
                          </tbody>

                          <tfoot>
                            <tr>
                              <td colSpan="2" className="text-right">
                                <DistributorUpdateApiKey
                                  show={this.state.showRekey}
                                  handleShow={this.handleShowRekeyModal}
                                  handleClose={this.handleCloseRekeyModal}
                                  isDistributor={isDistributor}
                                />
                                {' '}
                                <DistributorDeleteApiKey
                                  show={this.state.showDeleteModal}
                                  handleShow={this.handleShowDeleteModal}
                                  handleClose={this.handleCloseDeleteModal}
                                  distributorId={distributorId}
                                />
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>}

                </Col>

                <Col md={4}>
                  <div className="quick-search">
                    <div className="actions">
                      <Link className="action" to="/products">
                        <Icon name="boxes" />
                        <strong>Search<br />Products</strong>
                      </Link>
                      <div className="action empty" />
                    </div>
                  </div>

                </Col>

              </Row>

            </div>
          </Container>

        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result: user,
    error
  } = state.login.toJS();

  const {
    result: distributor
  } = state.getDistributorDetails.toJS();

  return {
    user,
    distributor,
    error
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getDistributorDetails: fetchDux.getDistributorDetails.createAction,
      deleteDistributorApiKeyById: fetchDux.deleteDistributorApiKeyById.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(DistributorsHomePage);
