import React, { Component } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import FtpJobsForm from './FtpJobsForm';
import { connect } from 'react-redux';
import { actionCreators } from '../../../state/modals-dux';
import fetchDux from '../../../state/fetch-dux';

const required = value => value ? undefined : 'Required';

class ViewFTPModal extends Component {
  componentWillUnmount() {
    const { clearTestConnection } = this.props;
    clearTestConnection();
  }
  render() {
    const {
      openModal,
      closeModal,
      onSubmit,
      isAdmin,
      name,
      saveJobsError,
      createFTPJobsInProgress,
      createFTPJobsError,
      testConnection,
      clearTestConnection
    } = this.props;

    return (
      <Modal show={openModal === name} onHide={closeModal} size="lg">
        <Modal.Header
          closeButton
          onClick={() => {
            clearTestConnection();
          }}
        >
          <Modal.Title>Create SFTP Upload</Modal.Title>
        </Modal.Header>
        <FtpJobsForm
          onSubmit={onSubmit}
          saveJobsError={saveJobsError}
          closeModal={closeModal}
          inProgress={createFTPJobsInProgress}
          createFTPJobsError={createFTPJobsError}
          testConnection={testConnection}
          clearTestConnection={clearTestConnection}
        />
      </Modal>
    );
  }
}

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      testConnection: fetchDux.testConnection.createAction,
      clearTestConnection: fetchDux.testConnection.clearAction
    },
    dispatch
  );
export default connect(mapState, mapDispatch)(ViewFTPModal);
