import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Button, Modal } from 'react-bootstrap';

const required = value => value ? undefined : 'Required';

const AddCompanyModalForm = props => {
    const { handleSubmit, submitting, closeModal, companyOptions, manufacturerId } = props;
    let companyResultOptions = [];
    if (companyOptions != null) {
        const filterCompanyResult = (companyOptions || []).filter(c => c.parentManufacturer !== manufacturerId && c._id != manufacturerId);
        companyResultOptions = filterCompanyResult && (filterCompanyResult || []).map(({ _id, name }) => ({
            value: _id,
            label: name
        }))
    }
    return (
        <form onSubmit={handleSubmit}>
            <Modal.Body>
                <Field
                    name="companies"
                    component={FormField.MultiSelect}
                    label="Select Companies"
                    options={companyResultOptions || []}
                    isMulti
                />

            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant="outline-secondary" onClick={closeModal}>
                    Cancel
                </Button>
                <Button size="sm" variant="success" onClick={handleSubmit}>
                    Create Manufacturer
                </Button>
            </Modal.Footer>
        </form>
    );
};

export default reduxForm({
    form: 'add-company-modal-form'
})(AddCompanyModalForm);
