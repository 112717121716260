import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import FormField from '../../../forms/FormField';


const required = value => value ? null : 'Required';

const ProductInfo = ({ lookupsResult, manufacturer }) => {
  // const { lookupsResult, edit } = props;
  const { catEnums, Stocked, edit } = lookupsResult || {};
  const categoryOptions = (catEnums || [])
    .map(({ enumKey, value }) => ({ value: enumKey, label: value }));
  const stocktedOptions = (Stocked || [])
    .map(({ enumKey }) => ({ value: enumKey, label: enumKey }));

  const { childManufacturers, name: manufacturerName, _id: manufacturerId } = manufacturer || {};
  const childCompanyOptions = (childManufacturers || []).map(({ _id, name }) => ({
      value: _id,
      label: name,
  }));

  if (childManufacturers && childCompanyOptions.length > 0 && manufacturer) {
    childCompanyOptions.push({
      value: manufacturerId,
      label: manufacturerName,
    });
  }

  // Create a logic to make the childCompanyOptions sort alphabetically
  childCompanyOptions.sort((a, b) => {
    const nameA = a.label.toUpperCase(); // ignore upper and lowercase
    const nameB = b.label.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) { return -1; }
    if (nameA > nameB) { return 1; }

    // names must be equal
    return 0;
  });

  return (
    <Col md={12}>
      <div className="box">
        <div className="box-header">
          <h3>Product Info</h3>
        </div>
        <div className="box-body">
          {childManufacturers && childCompanyOptions.length > 0 ? (<Row>
            <Col sm={4}>
              <Field
                disabled={edit}
                name="ProdCode"
                type="text"
                component={FormField.Input}
                label="Product Code *"
                validate={[required]}
              />
            </Col>
            <Col sm={4}>
              <Field
                name="manufacturerId"
                type="text"
                component={FormField.SelectReact}
                label="Company *"
                options={childCompanyOptions}
                isClearable
              />
            </Col>
            <Col sm={4}>
              <Field
                name="CatEnum"
                type="text"
                component={FormField.SelectReact}
                label="Category *"
                validate={[required]}
                options={categoryOptions}
                isClearable
              />
            </Col>
          </Row>) : (<Row>
            <Col sm={6}>
              <Field
                disabled={edit}
                name="ProdCode"
                type="text"
                component={FormField.Input}
                label="Product Code *"
                validate={[required]}
              />
            </Col>
            <Col sm={6}>
              <Field
                name="CatEnum"
                type="text"
                component={FormField.SelectReact}
                label="Category *"
                validate={[required]}
                options={categoryOptions}
                isClearable
              />
            </Col>
          </Row>)}

          <Row>
            <Col sm={8}>
              <Field
                name="ProdShortDesc"
                type="text"
                component={FormField.Input}
                label="Product Short Description *"
                validate={[required]}
              />
            </Col>
            <Col sm={4}>
              <Field
                name="Stocked"
                type="text"
                component={FormField.SelectReact}
                options={stocktedOptions}
                label="Stocked *"
                validate={[required]}
                isClearable
              />
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <Field
                name="ProdLongDesc"
                type="text"
                component={FormField.Input}
                label="Product Long Description *"
                validate={[required]}
              />
            </Col>
          </Row>

        </div>
      </div>
    </Col>
  );
};

export default ProductInfo;
