import React, { Component } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../ui/Layout';
import Icon from '../../ui/Icon';
import Table from '../../ui/Table';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../state/modals-dux';
import fetchDux from '../../../state/fetch-dux';
import moment from 'moment';
import queryString from 'query-string';
import Loading from '../../ui/Loading';
import DeleteEmailModal from './DeleteEmailModal';
import ViewEmailModal from './ViewEmailModal';

const formatTime = d => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).format('MMM D, Y LT');
};

const getDesc = desc => {
  if (desc === false || desc === 'false') return 'false';
  return 'true';
};

const formatQuery = (
  {
    page,
    pageSize,
    sortBy,
    desc,
    search
  }
) => ({
  page: page || '0',
  pageSize: pageSize || '10',
  sortBy: sortBy || 'updated',
  desc: getDesc(desc),
  search: search || ''
});

const getQueryFromSearch = search => {
  const query = queryString.parse(search) || {};
  return formatQuery(query);
};

class AutomatedDownloads extends Component {
  constructor(props) {
    super(props);
    const { history, location: { pathname, search } } = this.props;

    this.state = {
      prevSearch: search,
      label:null
    };

    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);
    history.push({ pathname, search: qs });

    this.handleFetchData = this.handleFetchData.bind(this);
    this.handleSearchOrders = this.handleSearchOrders.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  componentDidMount() {
    const { location: { search }, getCSVJobs } = this.props;
    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);
    getCSVJobs(qs);
  }

  handleDelete = () => {
    const { closeModal, deleteCSVJobById, location: { search } } = this.props;

    const {
      page,
      pageSize,
      sorted,
      jobId
    } = this.state || {};

    const {
      id,
      desc
    } = (sorted || [])[0] || {};

    const params = {
      ...queryString.parse(search),
      page,
      pageSize,
      sortBy: id,
      desc
    };

    deleteCSVJobById({ jobId }, null, null, data => {
      const query = formatQuery(params);
      const qs = queryString.stringify(query);
      this.callSearch(qs);
      closeModal();
    });
  };

  handleFetchData(state) {
    const {
      location: { search }
    } = this.props;
    const query = getQueryFromSearch(search);
    const {
      page,
      pageSize,
      sorted
    } = state || {};

    const {
      id,
      desc
    } = (sorted || [])[0] || {};

    this.handleSearchOrders({
      page,
      pageSize,
      sortBy: id,
      desc,
      search: query.search
    });
  }

  callSearch(search) {
    const {
      getCSVJobs,
      location: { pathname },
      history
    } = this.props;
    history.push({ pathname, search });
    getCSVJobs(search, null, true);
  }

  handleSearchOrders(values) {
    const { history, location: { pathname } } = this.props;

    const query = formatQuery(values);
    const qs = queryString.stringify(query);
    history.push({ pathname, search: qs });
  }

  handleSearchChange(value) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    this.handleSearchOrders(
      Object.assign({}, query, { search: value, page: '0' })
    );
  }

  static getDerivedStateFromProps(nextProps, currentState) {
    const { location: { search } } = nextProps;
    if (search !== currentState.prevSearch) {
      const qs = getQueryFromSearch(search);
      const query = queryString.stringify(qs);
      nextProps.getCSVJobs(query);
      return {
        prevSearch: search
      };
    } else {
      return null;
    }
  }

  render() {
    const {
      showModal,
      result,
      user,
      inProgress,
      location: { search },
      error
    } = this.props;

    const{label} = this.state;
    const { isAdmin } = user || {};
    const paging = getQueryFromSearch(search);
    const {
      numPages,
      items,
      count
    } = result || {};
    const {
      sortBy,
      desc,
      page,
      pageSize
    } = paging || {};

    const sorted = [
      {
        id: sortBy,
        desc: desc === 'true'
      }
    ];

    const loadingComp = () => {
      return inProgress
        ? <div className="-loading -active loading-table">
            <Loading fullpage message="Searching, Please Wait..." />
          </div>
        : null;
    };

    const previewSearch = (items || []).find(d => d._id === this.state.jobId);

    const columns = [
      {
        Header: 'Label',
        accessor: 'label',
        className: 'text-center'
      },
      {
        Header: 'Frequency',
        accessor: 'frequency',
        className: 'text-center'
      },
      {
        Header: 'Last Run Date',
        accessor: 'lastRunDate',
        className: 'text-center',
        Cell: ({ value }) => {
          return value ? <span>{formatTime(value)}</span> : 'NA';
        }
      },
      {
        Header: 'Next Run Date',
        accessor: 'nextRunDate',
        className: 'text-center',
        Cell: ({ value }) => { 
          return value ? <span>{formatTime(value)}</span> : 'NA';
        }
      },
      isAdmin
        ? {
            Header: 'Distributor',
            accessor: 'DbrName',
            className: 'text-center',
            show: true
          }
        : {
            Header: 'Distributor',
            accessor: 'DbrName',
            className: 'text-center',
            show: false
          },
      {
        Header: 'Status',
        accessor: 'errflag',
        className: 'text-center',
        width: 100,
        Cell: ({ value }) => { 
          return value ? <span style={{color:'red'}}>Error</span> : 'Active';
        }
      },
      {
        Header: '',
        accessor: '_id',
        width: 230,
        Cell: ({ value, original }) => {
          const {
            label
          } = original || {};
          return (
            <div className="text-right">
              <Button
                className="btn btn-primary btn-sm"
                onClick={() => {
                  this.setState({ jobId: value });
                  showModal('ViewEmailModal');
                }}
              >
                View
              </Button>
              {' '}
              <Button
                className="btn btn-danger btn-sm"
                onClick={() => {
                  this.setState({ jobId: value, label });
                  showModal('DeleteEmailModal');
                }}
              >
                Delete
              </Button>
            </div>
          );
        }
      }
    ];
    return (
      <Layout route="automated-downloads">
        <main>
          <div className="layout-header">
            <Container>

              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <Icon name="home" />
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  Automated Downloads
                </li>
              </ol>

              <div className="header">
                <h1>
                  <Icon name="envelope" /> Automated Downloads
                </h1>
              </div>

            </Container>
          </div>

          <Container>
            <div>
              <Table
                showSearch
                serverSearch
                onSearchChange={this.handleSearchChange}
                data={items || []}
                columns={columns}
                showColumnsSelector={false}
                noDataText={inProgress ? 'Loading' : 'No saved downloads found. To add an automated download, please use the products search to define a query then click save.'}
                showNumResults={true}
                defaultSorted={sorted}
                page={Number(page)}
                pages={error ? 0 : numPages}
                defaultPageSize={Number(pageSize)}
                inProgress={inProgress}
                paging={paging}
                error={error}
                onFetchData={this.handleFetchData}
                keepTable
                manual
                showPageJump={false}
                totalResults={count}
                LoadingComponent={loadingComp}
              />
              <DeleteEmailModal
                name="DeleteEmailModal"
                handleDelete={this.handleDelete}
                label={label}
              />
              <ViewEmailModal name="ViewEmailModal" search={previewSearch} />
            </div>
          </Container>

        </main>
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result: user
  } = state.login.toJS();
  const {
    result,
    inProgress,
    error
  } = state.getCSVJobs.toJS();
  return {
    user,
    result,
    inProgress,
    error
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getCSVJobs: fetchDux.getCSVJobs.createAction,
      deleteCSVJobById: fetchDux.deleteCSVJobById.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(AutomatedDownloads);
