/* eslint-disable no-console */
/* eslint-disable no-alert */
import React, { Fragment } from 'react';
import { Col } from 'react-bootstrap';
import Table from '../../../../ui/Table';
import Icon from '../../../../ui/Icon';
import moment from 'moment';

const getColumns = reportType => {
  switch (reportType) {
    case 'Activity Log':
      return [
        {
          Header: 'Name',
          accessor: 'user.name',
          sortable: true,
          resizable: true,
          show: true,
          className: 'text-center'
        },
        {
          Header: 'Company Name',
          accessor: 'user.companyName',
          sortable: true,
          resizable: true,
          show: true,
          className: 'text-center'
        },
        {
          Header: 'Account Type',
          accessor: 'user.accountType',
          sortable: true,
          resizable: true,
          show: true,
          className: 'text-center'
        },
        {
          Header: 'Date (UTC)',
          accessor: 'dateTime',
          sortable: true,
          resizable: true,
          show: true,
          Cell: ({ value }) => {
            return (
              <span>
                {value !== undefined && moment.utc(value).isValid()
                  ? moment.utc(value).format('MM/DD/YYYY, hh:mm A')
                  : '-'}
              </span>
            );
          },
          className: 'text-center',
          defaultSorted: [{ id: 'dateTime', desc: true }]
        },
        {
          Header: 'Application',
          accessor: 'application',
          sortable: true,
          resizable: true,
          show: true,
          className: 'text-center',
          Cell: ({ value }) => {
            return value || '0';
          }
        },
        {
          Header: 'Operating System',
          accessor: 'os',
          sortable: true,
          resizable: true,
          show: true,
          className: 'text-center'
        },
        {
          Header: 'Method',
          accessor: 'method',
          sortable: true,
          resizable: true,
          show: true,
          className: 'text-center'
        },
        {
          Header: 'API Route',
          accessor: 'request.apiRoute',
          sortable: true,
          resizable: true,
          show: true,
          className: 'text-center',
          Cell: ({ value, row }) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <span style={{ flex: 1, textAlign: 'center' }}>{value}</span>
              {row._original.method !== 'GET'
                ? null
                : <Fragment>
                  <span title={row._original.request.url}>
                    <Icon
                      name="external-link-alt"
                      size="xs"
                      style={{
                        marginRight: '10px',
                        cursor: 'pointer'
                      }}
                      // Tooltip text
                      onClick={() =>
                        handleApiRouteButtonClick(
                          value,
                          row._original.request.url
                        )}
                    />
                  </span>
                </Fragment>}
            </div>
          )
        },
        {
          Header: 'API Key',
          accessor: 'request.apiKey',
          sortable: true,
          resizable: true,
          show: true,
          className: 'text-center'
        },
        {
          Header: 'Results Count',
          accessor: 'response.resultsCount',
          sortable: true,
          resizable: true,
          show: true,
          className: 'text-center',
          Cell: ({ value }) => {
            return value || '0';
          }
        }
      ];

    default:
  }
};

const handleApiRouteButtonClick = (apiRoute, apiUrl) => {
  window.open(apiUrl);
};

const ActivityLog = (
  {
    result,
    selectedReportType,
    defaultPageSize,
    inProgress,
    handleFetchData,
    activeColumns
  }
) => {
  const { results = [], count, numPages } = result || {};
  const columns = getColumns(selectedReportType);
  if (activeColumns.length !== 0) {
    columns.forEach(col => {
      col.show = activeColumns.includes(col.accessor);
    });
  }
  return (
    <Col sm={12}>
      <div className="table-activity-log">
        <Table
          manual
          data={results}
          columns={columns}
          key={selectedReportType}
          defaultPageSize={defaultPageSize}
          showPagination={true}
          noDataText={inProgress ? 'Loading' : 'No data found'}
          pages={numPages || 0}
          defaultSorted={[
            {
              id: 'dateTime',
              desc: true
            }
          ]}
          onFetchData={handleFetchData}
          onColumnChange={activeColumns => {
            window.localStorage.setItem(
              'productAnalyticsColumns',
              activeColumns
            );
          }}
          showColumnsSelector={true}
        />
      </div>

    </Col>
  );
};

export default ActivityLog;
