import React, { Component } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../ui/Layout';
import Icon from '../../ui/Icon';
import Table from '../../ui/Table';
import Loading from '../../ui/Loading';
import queryString from 'query-string';
import FtpJobsForm from './FtpJobsForm';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../state/modals-dux';
import fetchDux from '../../../state/fetch-dux';
import ViewFTPModal from './ViewFTPModal';
import DeleteFTPModal from './DeleteFTPModal';
import CreateFTPModal from './CreateFTPModal';

const getDesc = desc => {
  if (desc === false || desc === 'false') return 'false';
  return 'true';
};

const formatQuery = (
  {
    page,
    pageSize,
    sortBy,
    desc,
    search
  }
) => ({
  page: page || '0',
  pageSize: pageSize || '10',
  sortBy: sortBy || 'updated',
  desc: getDesc(desc),
  search: search || ''
});

const getQueryFromSearch = search => {
  const query = queryString.parse(search) || {};
  return formatQuery(query);
};

class FtpJobs extends Component {
  constructor(props) {
    super(props);
    const { history, location: { pathname, search } } = this.props;

    this.state = {
      prevSearch: search,
      label:null      
    };

    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);
    history.push({ pathname, search: qs });

    this.handleFetchData = this.handleFetchData.bind(this);
    this.handleSearchOrders = this.handleSearchOrders.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  componentDidMount() {
    const { location: { search }, getFTPJobs } = this.props;
    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);
    getFTPJobs(qs);
  }

  handleDelete = () => {
    const { closeModal, deleteFTPJobById, location: { search } } = this.props;

    const {
      page,
      pageSize,
      sorted,
      jobId
    } = this.state || {};

    const {
      id,
      desc
    } = (sorted || [])[0] || {};

    const params = {
      ...queryString.parse(search),
      page,
      pageSize,
      sortBy: id,
      desc
    };

    deleteFTPJobById({ jobId }, null, null, data => {
      const query = formatQuery(params);
      const qs = queryString.stringify(query);
      this.callSearch(qs);
      closeModal();
    });
  };

  handleFetchData(state) {
    const {
      location: { search }
    } = this.props;
    const query = getQueryFromSearch(search);
    const {
      page,
      pageSize,
      sorted
    } = state || {};

    const {
      id,
      desc
    } = (sorted || [])[0] || {};

    this.handleSearchOrders({
      page,
      pageSize,
      sortBy: id,
      desc,
      search: query.search
    });
  }

  handleSearchOrders(values) {
    const { history, location: { pathname } } = this.props;

    const query = formatQuery(values);
    const qs = queryString.stringify(query);
    history.push({ pathname, search: qs });
  }

  handleSearchChange(value) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    this.handleSearchOrders(
      Object.assign({}, query, { search: value, page: '0' })
    );
  }

  static getDerivedStateFromProps(nextProps, currentState) {
    const { location: { search } } = nextProps;
    if (search !== currentState.prevSearch) {
      const qs = getQueryFromSearch(search);
      const query = queryString.stringify(qs);
      nextProps.getFTPJobs(query);
      return {
        prevSearch: search
      };
    } else {
      return null;
    }
  }

  callSearch(search) {
    const {
      getFTPJobs,
      location: { pathname },
      history
    } = this.props;
    history.push({ pathname, search });
    getFTPJobs(search, null, true);
  }

  render() {
    const {
      showModal,
      user,
      jobId,
      result,
      inProgress,
      error,
      location: { search, pathname },
      createFTPJobsInProgress,
      closeModal,
      createFTPJobsError
    } = this.props;

    const{label} = this.state;

    const { isAdmin } = user || {};
    const paging = getQueryFromSearch(search);
    const {
      numPages,
      items,
      count
    } = result || {};
    const {
      sortBy,
      desc,
      page,
      pageSize
    } = paging || {};

    const sorted = [
      {
        id: sortBy,
        desc: desc === 'true'
      }
    ];

    const loadingComp = () => {
      return inProgress
        ? <div className="-loading -active loading-table">
          <Loading fullpage message="Searching, Please Wait..." />
        </div>
        : null;
    };

    const previewSearch = (items || []).find(d => d._id === this.state.jobId);

    const columns = [
      {
        Header: 'Label',
        accessor: 'label',
        className: 'text-center'
      },
      {
        Header: 'Address',
        accessor: 'address',
        className: 'text-center'
      },
      {
        Header: 'Port',
        accessor: 'port',
        className: 'text-center'
      },
      // {
      //   Header: 'Username',
      //   accessor: 'username',
      //   className: 'text-center'
      // },
      isAdmin
        ? {
          Header: 'Manufacturer',
          accessor: 'MfrName',
          className: 'text-center',
          show: true
        }
        : {
          Header: 'Manufacturer',
          accessor: 'MfrName',
          className: 'text-center',
          show: false
        },
      {
        Header: 'directory',
        accessor: 'directory',
        className: 'text-center'
      },
      {
        Header: 'File Name',
        accessor: 'fileName',
        className: 'text-center'
      },
      {
        Header: 'Status',
        accessor: 'errflag',
        className: 'text-center',
        width: 100,
        Cell: ({ value }) => {
          return value ? <span style={{ color: 'red' }}>Error</span> : 'Active';
        }
      },
      {
        Header: '',
        accessor: '_id',
        width: 230,
        Cell: ({ value, original }) => {
          const {
            label
          } = original || {};
          return (
            <div className="text-right">
              <Button
                className="btn btn-primary btn-sm"
                onClick={() => {
                  this.setState({ jobId: value });
                  showModal('ViewFTPModal');
                }}
              >
                View
              </Button>
              {' '}
              <Button
                className="btn btn-danger btn-sm"
                onClick={() => {
                  this.setState({ jobId: value, label });
                  this.setState({ jobId: value });
                  showModal('DeleteFTPModal');
                }}
              >
                Delete
              </Button>
            </div>
          );
        }
      }
    ];

    const handleSubmit = values => {
      const {
        history,
        location: { search, pathname },
        createFTPJobs,
        getFTPJobs
      } = this.props;
      createFTPJobs(
        values,
        'ftp-jobs-form',
        null,
        () => {
          closeModal('CreateFTPJobsModal')
          history.push({ pathname, search });
          getFTPJobs(search, null, true);
        }
      );
    }

    return (
      <Layout route="homepage">
        <main>
          <div className="layout-header">
            <Container>

              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <Icon name="home" />
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  SFTP Uploads Schedule
                </li>
              </ol>

              <div className="header">
                <h1>
                  <Icon name="server" /> SFTP Jobs Schedule
                </h1>
              </div>

            </Container>
          </div>

          <Container>
            <div>

              <Row>
                <Col style={{ textAlign: 'end' }}>
                  <Button
                    style={{ marginBottom: '20px' }}
                    className="btn btn-primary btn-md"
                    onClick={() => {
                      showModal('CreateFTPModal');
                    }}
                  >
                    Create SFTP upload
                  </Button>
                </Col>
              </Row>

              <Table
                showSearch
                serverSearch
                onSearchChange={this.handleSearchChange}
                data={items || []}
                columns={columns}
                showColumnsSelector={false}
                showNumResults={true}
                noDataText={inProgress ? 'Loading' : 'No SFTP Jobs found'}
                defaultSorted={sorted}
                page={Number(page)}
                pages={error ? 0 : numPages}
                defaultPageSize={Number(pageSize)}
                inProgress={inProgress}
                paging={paging}
                error={error}
                onFetchData={this.handleFetchData}
                keepTable
                manual
                showPageJump={false}
                totalResults={count}
                LoadingComponent={loadingComp}
              />
              <DeleteFTPModal
                name="DeleteFTPModal"
                handleDelete={this.handleDelete}
                label={label}
              />
              <ViewFTPModal name="ViewFTPModal" search={previewSearch} />
              <CreateFTPModal
                name="CreateFTPModal"
                onSubmit={handleSubmit}
                saveJobsError={error}
                isAdmin={isAdmin}
                createFTPJobsInProgress={createFTPJobsInProgress}
                createFTPJobsError={createFTPJobsError}
              />
            </div>
          </Container>

        </main>
      </Layout>
    )
  };
};

const mapState = state => {
  const user = state.login.toJS().result;
  const {
    result,
    inProgress,
    error
  } = state.getFTPJobs.toJS();
  const {
    jobId,
    inProgress: createFTPJobsInProgress,
    error: createFTPJobsError
  } = state.createFTPJobs.toJS();
  return {
    user,
    jobId,
    result,
    inProgress,
    error,
    openModal: state.modals,
    createFTPJobsInProgress,
    createFTPJobsError
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getFTPJobs: fetchDux.getFTPJobs.createAction,
      createFTPJobs: fetchDux.createFTPJobs.createAction,
      deleteFTPJobById: fetchDux.deleteFTPJobById.createAction
    },
    dispatch
  );
export default connect(mapState, mapDispatch)(FtpJobs);